export class Auth {

  email: string;
  password: string;
  status: number;

  constructor(email: string, password: string, status: number) {
    this.email = email;
    this.password = password;
    this.status = status;
  }

}
