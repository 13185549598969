import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable()
export class DataService {

  private messageSource = new BehaviorSubject('0');
  currentMessage = this.messageSource.asObservable();

  private loginUserName = new BehaviorSubject('0');
  currentUserName = this.loginUserName.asObservable();

  private messageMenuClient = new BehaviorSubject('1');
  currentMenuClient = this.messageMenuClient.asObservable();

  private messageRole = new BehaviorSubject([]);
  currentRole = this.messageRole.asObservable();

  constructor() { }

  changeUserName(message: string) {
    this.loginUserName.next(message)
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeMenuClient(message: string) {
    this.messageMenuClient.next(message)
  }

  changeRole(message: MenuItem[]) {
    this.messageRole.next(message)
  }
}