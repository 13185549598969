import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { WorklistService } from '../worklist.service';
import { Worklist } from '../worklist';
import { Message, SelectItem } from 'primeng/api';
import { emailValidator } from '../../shared';
import { SpinnerService } from '../../core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-worklist-edit',
  templateUrl: './worklist-edit.component.html',
  styleUrls: ['./worklist-edit.component.css']
})
export class WorklistEditComponent implements OnInit {

  worklistForm: FormGroup;
  id: number;

  worklist: Worklist = {} as Worklist;

  msgs: Message[] = [];

  newWorklist = 0;
  allUsers: any = [];

  redError = 'red';
  submitted:boolean = false;

  worklistname_message = 'Please enter worklist name.';
  usersSelectedError_message = 'Please select users.';

  constructor(
    private formBuilder: FormBuilder,
    aroute: ActivatedRoute,
    private router: Router,
    private worklistService: WorklistService,
    private dataService: DataService,
    private spinnerService: SpinnerService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
    
  }

  ngOnInit()
  {
    this.spinnerService.show();

    this.worklistForm = this.formBuilder.group({
      id:0,
      name: ['', Validators.required],
      worklist_users: [ [], Validators.required ],
      description:'Description.'
    });

    if (typeof this.id != 'undefined') {
      this.newWorklist = this.id;
    }

    // Get all users
    this.worklistService.getMasterUsers().subscribe(res => {
      this.allUsers = res;
    });
    
    this.loadWorklist();
  }

  loadWorklist()
  {
    // Load Worklist
    if (typeof this.id != 'undefined')
    {
      this.worklistService.getWorklist(this.id).subscribe(res =>
      {
        this.worklist = res;

        this.worklistForm.patchValue({
          id: this.worklist.id,
          name: this.worklist.name,
        });

        var editWorklistUsers = [];
        if( this.worklist != undefined && this.worklist != null && this.worklist.worklist_users != undefined && this.worklist.worklist_users != null )
        {
          for( let currUser of this.worklist.worklist_users )
          {
            editWorklistUsers.push(currUser.user_id);
          }
        }
        this.worklistForm.patchValue({
          worklist_users: editWorklistUsers,
        });

        this.spinnerService.hide();
      });
    }
    else
    {
      this.spinnerService.hide();
    }
  }

  saveWorklist()
  {
    this.submitted = true;

    this.msgs = [];

    if (this.worklistForm.valid)
    {
      this.spinnerService.show();
      if (this.newWorklist == 0)
      {
        this.worklistService.createWorklist(this.worklistForm.value).subscribe(res => {
          if (res.output == 1) {
            this.msgs.push({ severity: 'success', summary: 'Message', detail: res.message });
          }
          else {
            this.msgs.push({ severity: 'error', summary: 'Warnnig', detail: 'Worklist already exists' });
          }
          setTimeout(() => {
            if (res.output == 1) {
              this.msgs = [];
              this.spinnerService.hide();
              this.router.navigate(['settings/worklists']);
            }
          }, 2000);
        });
      }
      else
      {
        this.worklistService.updateWorklist(this.id, this.worklistForm.value).subscribe(res => {
          if (res.output == 1) {
            this.msgs.push({ severity: 'success', summary: 'Message', detail: res.message });
          } else {
            this.msgs.push({ severity: 'error', summary: 'Warnnig', detail: 'Worklist already exists' });
          }
          setTimeout(() => {
            if (res.output == 1) {
              this.msgs = [];
              this.spinnerService.hide();
              this.router.navigate(['settings/worklists']);
            }
          }, 2000);
        });
      }
    }
  }

}
