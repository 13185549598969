import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class TemplateConfigService {

  constructor(private router: Router) { }

  loadComponent(name: string) {
    switch(name) {
      case 'sidebar':
        return (this.router.url != '/login');
    }

    return true;
  }

}
