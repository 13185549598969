import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DocumentService } from '../document.service';
import { Message, SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentTemplate } from '../document-template';
import { MenuItem } from "primeng/api";
import { SpinnerService } from '../../core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'upload-invoices',
  templateUrl: './upload-invoices.component.html',
  styleUrls: ['./upload-invoices.component.css']
})
export class UploadInvoicesComponent implements OnInit {
  uploadInvoiceForm: FormGroup;
  msgs: Message[] = [];
  public items: MenuItem[];
  constructor(aroute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private docService: DocumentService,
    private cd: ChangeDetectorRef,
    private spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Settings',
        icon: 'fa-gear',
        routerLink: ['/settings'],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Create Invoices',
        icon: 'fa-file-text-o',
        routerLink: ['/settings/upload-invoices'],
        routerLinkActiveOptions: { exact: true }
      }
    ];
    this.uploadInvoiceForm = this.formBuilder.group({
      file: [null, Validators.required]
    });
  }

  onFileChange(event) {
    const reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        this.uploadInvoiceForm.patchValue({
          file: reader.result
       });
      
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  saveUploadInvoices(){
    this.msgs = [];
    this.docService.uploadInvoices(this.uploadInvoiceForm.value).subscribe((res) => {
      this.spinnerService.hide();
      if(res.output == '1'){
        if(res.newClients.length != 0 && res.checkDates.length != 0){
          var msgsd = '<div>Invoices created successfuly</div><div>Some new clients invoices are not created ( '+res.newClients.toString()+' )</div><div>Some check dates already exists ( '+res.checkDates.toString().replace("%%%%"," - ")+' )</div>';
        }else if(res.newClients.length == 0 && res.checkDates.length != 0){
          var msgsd = '<div>Invoices created successfuly</div><div>Some check dates already exists ( '+res.checkDates.toString().replace("%%%%"," - ")+' )</div>';
        }else if(res.checkDates.length == 0 && res.newClients.length != 0){
          var msgsd = '<div>Invoices created successfuly</div><div>Some new clients invoices are not created ( '+res.newClients.toString()+' )</div>';
        }else{
          if(res.noPolycies.length != 0){
            var msgsd = '<div>Invoices created successfuly</div><div>Some clients do not have policies ( '+res.noPolycies.toString()+' )</div>';
          }else{
            var msgsd = '<div>Invoices created successfuly</div>';
          }
        }
        this.msgs.push({ severity: 'success', summary: 'Success', detail: msgsd });
      }else{
        var msgsd = '<div>'+res.message+'</div>';
        this.msgs.push({ severity: 'error', summary: 'Error', detail: msgsd });
      }
      setTimeout(() => {
        this.uploadInvoiceForm.patchValue({
          file: null
       });
      }, 4000);
    });
  }
}
