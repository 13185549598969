import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, RouterEvent, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { PolicyService } from '../policy.service';
import { ClientService } from '../client.service';
import { Client } from '../client';
import { Policy } from "../policy";
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Message, SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Invoice } from '../invoice';
import { InvoiceDetail } from '../invoice-detail';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SpinnerService } from '../../core';
import { ConfirmationService } from 'primeng/components/common/api';
import { ConfigServiceService } from '../../config-service.service';
import { isNumber } from 'util';
import { DocumentInvoiceDescriptions } from '../../settings/document-invoice-descriptions';

import {BrowserModule, DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { DocumentService } from '../../settings/document.service';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.css']
})
export class InvoiceEditComponent implements OnInit {
  id: number;
  invoice_id: number;
  client: Client;

  requiredCheckDate: Date;
  invPolPostObj: any = {};
  requiredPolicy: any = {};
  ratesNeededArr: any = [];
  originalRatesArr: any = [];

  invoice_types: SelectItem[];
  invoice_typess: SelectItem[];
  states: SelectItem[];
  class_codes: SelectItem[];

  invoice: Invoice;
  new_line: InvoiceDetail;

  invoiceNoteForm: FormGroup;
  invoiceNoteFormPF: FormGroup;
  invoiceNoteFormNPR: FormGroup;
  invoiceNotePostObj: any = {};

  msgs: Message[] = [];

  invoiceSubmitted = false;
  invoicePostObj: any = {};

  premInvFormA: FormGroup; 
  premInvFormB: FormGroup; 

  in_check_date: String;
  in_payroll_end_date: String;
  in_report_date: String;
  in_ach_withdrawal_date: String;
  in_backup_ach_withdrawal_date: String;
  pf_db_ach_dt: String;

  pf_backup_ach_withdrawal_date: Date;

  db_report_date: String = '';
  db_withdrawal_date: String = '';
  save_dates_nsf: boolean = false;

  queue_manual_type: number = 0;
  fax_queued:number = 0;
  text_queued:number = 0;

  formValid: boolean = false;
  showMsg: boolean = false;

  datesLoaded: boolean = false;

  statusBeingUpdated = false;
  invoiceStatus: string = 'Incomplete';
  isQueueStatus: boolean = false;

  oriAdminFee: number = 0;
  oriFirst: number = 0;
  oricc: number = 0;
  orinsf: number = 0;
  orirp: number = 0;

  is_void: number = 0;
  is_nsf: number = 0;
  is_revised: number = 0;

  ori_cust_inv_number: string = '';
  ori_nsf_inv_number: string = '';
  ori_rev_inv_number: string = '';

  ori_inv_number: string = '';

  custom_inv_number: string = '';
  invSysNumber: string = '';

  isFreshInvoice: boolean = false;
  currInvoiceSettingId: number = 0;

  fireIntimation: boolean = false;

  PFPolicies: any = [];
  actionDates: any = [];
  currPol: any = {};
  tc_types: SelectItem[];
  bill_to_types: SelectItem[];

  bill_to_id: number = 0;

  tc_type: number = 1;
  policy_fee_form: FormGroup;
  brokers: any;

  qnts: SelectItem[];
  descSrvcs: DocumentInvoiceDescriptions[];
  serviceFeeForm: FormGroup; 

  rate_btn_add = 'block';
  rate_btn_upd = 'none';
  newRatesCount: number = -1;
  pfRates = [];
  pfInvoiceTotal: number = 0;
  pfOverallTotal: number = 0;

  pfRatesNeededArr: any = [];
  oriPFRatesArr: any = [];

  pfFormValid: boolean = false;
  pfFormChanged: boolean = false;
  pfAchInvalid: boolean = false;
  pfShowAchDate: boolean = false; 
  pfEditDate: String;
  showAchAlert: boolean = false;

  isCorRev: number = 0;

  oriBillToId: number = 0;
  oriBillToType: any;
  oriPolId: number = 0;
  oriActionId: number = 0;
  oriTCType: number = 0;
  oriPFCc: boolean = false;
  oriPFAfee:boolean=false;

  npr_form: FormGroup;
  NPRPolicies: any = [];
  NPRactionDates: any = [];
  nprInvoiceTotal: number = 0;
  nprOverallTotal: number = 0;
  nprCarrierName: any = '';
  db_payroll_due_date: String = '';
  db_report_dt: String = '';
  db_ach_dt: String = '';

  nprFormValid: boolean = false;
  nprFormChanged: boolean = false;
  oriNPRCc: boolean = false;

  pfData: any = {};
  pfLoaded: boolean = false;

  premiumValid: boolean = false;
  redError = 'red';
  quantityError = 'none';
  descSrvcError = 'none';
  noteError = 'none';
  priceError = 'none';
  priceMessageError = 'none';
  priceButtonError = 'none';
  THOUSANDS_SEPARATOR = ",";
  totalGW = 0.00;
  totalOW = 0.00;

  previousUrl: string;

  compNameToDisp:string='';
  feinToDisp:string='';
  clientEmailToDisp:string='';
  nprTopTableDisp:boolean=false;
  srAgencyName:string='';
  srAgencyPhone:string='';
  prInvEditLoaded:boolean=false;
  prInvData:any=0;

  pfShowName:string='';
  pfShowAddr:string='';
  pfShowCity:string='';
  pfShowSt:string='';
  pfShowZip:any=0;
  pfShowPh:string='';
  pfShowEmail:string='';
  pfBillToId:number=0;

  payrollTotal:number=0;
  showPayrollTotal=null;
  oriPT:number=0;

  piPTMismatch:boolean=false;

  isUploadedInv:boolean=false;

  is_pay_reporting:number = 0;

  showGenFailed:boolean = false;
  showGenFailed1:boolean = false;
  showGenFailed2:boolean = false;

  default_credit_card:number = 0;

  PFAgentEmails: any = [];
  pfOriAgEmail:string='';

  dtValStr:string='';
  serverYr:string='';
  chromePrevStr:string='';

  rpDtInv:boolean=false;
  achDtInv:boolean = false;
  nprPPDtInv:boolean = false;

  pfHasAdminFee:boolean=false;

  wagesCopyBtnShow:boolean = false;
  dataObjWagesCopy:any = {};
  wagesCopyObjsArr:any = [];
  statesArr:any = [];

  invNotesBk:SafeHtml='';
  notesInvSave:string='';

  selIdNPRPF:any = {};
  payrollDtStr: Date;
  nprFocusPolId:any = 0;
  showNPRMsg1:boolean = false;
  showNPRMsg2:boolean = false;
  miscType = 0;

  constructor(private aroute: ActivatedRoute, private router: Router, public location: Location,
    private clientService: ClientService, private policyService: PolicyService, private fb: FormBuilder,
    private messageService: MessageService, @Inject(LOCAL_STORAGE) private storage: WebStorageService
    , private spinnerService: SpinnerService, private confirmationService: ConfirmationService, private configServiceService: ConfigServiceService,private sanitizer: DomSanitizer, private docService: DocumentService) {
    this.aroute.params.subscribe(params => {
      this.id = params['id'];
      this.invoice_id = params['invoice_id'];
    });

    router.events.subscribe((event1: RouterEvent) => {
      if (event1 instanceof NavigationStart) {
        if (!this.statusBeingUpdated && (this.previousUrl.includes("create") || this.previousUrl.includes("revise"))) {
          if ((this.invoice.metadata.invoice_type == "premium_invoice" || this.invoice.metadata.invoice_type == "payroll_reporting") && !this.invoiceSubmitted) {
            this.showMsg = true;
            this.checkFormValid();
            if (this.formValid) {
              this.fireIntimation = false;
              var arr = event1.url.split('/');
              if (typeof arr !== 'undefined' && arr.length && typeof arr[arr.length - 1] !== 'undefined' && arr[arr.length - 1] == "invoice") {
                this.fireIntimation = true;
              }
              // console.log("Auto Saved Called 1");
              this.saveInvoice(false);
            }
          }
          else if (this.invoice.metadata.invoice_type == "policy_fee" && !this.invoiceSubmitted) {
            // this.checkFormValid();
            this.fireIntimation = false;
            var arr = event1.url.split('/');
            if (typeof arr !== 'undefined' && arr.length && typeof arr[arr.length - 1] !== 'undefined' && arr[arr.length - 1] == "invoice") {
              this.fireIntimation = true;
            }
            this.checkPFForm();
            this.chckPFFormChanged();
            if (this.pfFormValid && this.pfFormChanged) {
              // console.log("Auto Saved Called 2");
              this.savePFInvoice(false);
            }
          }
          else if (this.invoice.metadata.invoice_type == "npr" && !this.invoiceSubmitted) {
            this.fireIntimation = false;
            var arr = event1.url.split('/');
            if (typeof arr !== 'undefined' && arr.length && typeof arr[arr.length - 1] !== 'undefined' && arr[arr.length - 1] == "invoice") {
              this.fireIntimation = true;
            }
            this.checkNPRForm();
            this.chckNPRChanged();
            if (this.nprFormValid && this.nprFormChanged) {
              // console.log("Auto Saved Called 3");
              this.saveNPRInvoice(false);
            }
          }
          // console.log(this.previousUrl);
        }
      }
      if (event1 instanceof NavigationEnd) {
        this.previousUrl = event1.url;
      }
    });

  }

  GetMonthName(monthNumber) {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthNumber] || 'December';
  }
  setMPFDefault() {
    var matchInvoice = this.descSrvcs.filter(p => p.value == this.serviceFeeForm.value.descSrvc);

    this.serviceFeeForm.controls['note'].setValue('');
    this.serviceFeeForm.controls['price'].setValue('');
    
    this.serviceFeeForm.controls['price'].setValue(matchInvoice[0].price);
    this.serviceFeeForm.controls['commissionable'].setValue(matchInvoice[0].commission);

    // if (this.serviceFeeForm.value.descSrvc == "One-Time Implementation") {
    //   this.serviceFeeForm.controls['price'].setValue('225');
    // }
    // if (this.serviceFeeForm.value.descSrvc == "Delivery-2 Day") {
    //   this.serviceFeeForm.controls['price'].setValue('12');
    // }
    // if (this.serviceFeeForm.value.descSrvc == "Delivery-Overnight") {
    //   this.serviceFeeForm.controls['price'].setValue('17');
    // }
  }

  chckNPRChanged() {
    this.nprFormChanged = false;
    if (this.isFreshInvoice && this.nprFormValid) {
      console.log("a");
      this.nprFormChanged = true;
    }

    if (!this.isFreshInvoice) {
      if (this.oriPolId != this.npr_form.value.policy_id) {
        this.nprFormChanged = true;
        console.log("b");
      }
      if (this.oriActionId != this.npr_form.value.npr_pol_action_id) {
        this.nprFormChanged = true;
        console.log("c");
      }

      // console.log(this.db_payroll_due_date);
      var pddNew = this.npr_form.value.payroll_due_date;
      // console.log("Checking Chng: " + pddNew);
      if( this.npr_form.controls['payroll_due_date'].dirty === true )
      {
        // pddNew = this.npr_form.value.payroll_due_date.toLocaleDateString('en-US');
        // console.log(pddNew);
      }
      if (this.db_payroll_due_date != '' && this.db_payroll_due_date != pddNew)
      {
        this.nprFormChanged = true;
        console.log("d");
      }
      if (this.invoice.nprCC != undefined && this.invoice.nprCC != null && this.oriNPRCc != undefined && this.oriNPRCc != null && this.invoice.nprCC != this.oriNPRCc) {
        this.nprFormChanged = true;
        console.log("NPR CC Changed");
      }
      if (this.invoice.nprCC != undefined && this.invoice.nprCC != null && this.invoice.nprCC && this.oricc != this.invoice.metadata.credit_card_fee) {
        this.nprFormChanged = true;
        console.log("NPR CC Changed");
      }
      if (this.invoice.is_nsf && this.orinsf != undefined && this.orinsf != null && this.invoice.metadata.nsf_fee != undefined && this.invoice.metadata.nsf_fee != null && this.invoice.metadata.nsf_fee != this.orinsf)
      {
        this.nprFormChanged = true;
        console.log("NPR NSF Fee Changed");
      }
      if (this.invoice.is_nsf && this.orirp != undefined && this.orirp != null && this.invoice.metadata.retry_payment_fee != undefined && this.invoice.metadata.retry_payment_fee != null && this.invoice.metadata.retry_payment_fee != this.orirp) {
        this.nprFormChanged = true;
        console.log("NPR RPF Changed");
      }
      if( this.invoice.is_nsf )
      {
        var pddNew1 = this.npr_form.value.report_date;
        if( this.npr_form.controls['report_date'].dirty === true )
        {
          pddNew1 = this.npr_form.value.report_date;
        }
        if( this.db_report_dt != '' && this.db_report_dt != pddNew1 )
        {
          this.nprFormChanged = true;
          console.log("RD Changed");
        }

        var pddNew2 = this.npr_form.value.ach_withdrawal_date;
        if( this.npr_form.controls['ach_withdrawal_date'].dirty === true )
        {
          pddNew2 = this.npr_form.value.ach_withdrawal_date;
        }
        if (this.db_ach_dt != '' && this.db_ach_dt != pddNew2)
        {
          this.nprFormChanged = true;
          console.log("ACH Changed");
        }
    }
    }
    console.log("Changed " + this.nprFormChanged);
  }

  checkNPRForm() {
    this.nprFormValid = true;
    if (this.npr_form.value.policy_id == "") {
      this.nprFormValid = false;
      console.log("1");
    }
    console.log("--" + this.npr_form.value.npr_pol_action_id + "--");
    if (this.npr_form.value.npr_pol_action_id == "") {
      this.nprFormValid = false;
      console.log("2");
    }
    if (this.npr_form.value.payroll_due_date == undefined || this.npr_form.value.payroll_due_date == null || this.npr_form.value.payroll_due_date == "")
    {
      this.nprFormValid = false;
      console.log("3");
    }
    if( this.invoice.is_nsf )
    {
      if (this.npr_form.value.report_date == undefined || this.npr_form.value.report_date == null || this.npr_form.value.report_date == "")
      {
        this.nprFormValid = false;
        console.log("RD Invalid");
      }
      if (this.npr_form.value.ach_withdrawal_date == undefined || this.npr_form.value.ach_withdrawal_date == null || this.npr_form.value.ach_withdrawal_date == "")
      {
        this.nprFormValid = false;
        console.log("ACH Dt Invalid");
      }
      }
    console.log("Valid: " + this.nprFormValid);
  }

  chckPFFormChanged() {
    this.pfFormChanged = false;
    if (this.isFreshInvoice && this.pfFormValid) {
      console.log("a");
      this.pfFormChanged = true;
    }

    if (!this.isFreshInvoice) {
      if (this.oriBillToType != this.policy_fee_form.value.bill_to_type) {
        this.pfFormChanged = true;
        console.log("b");
      }
      if (this.oriBillToId != this.policy_fee_form.value.bill_to_id) {
        this.pfFormChanged = true;
        console.log("c");
      }
      if( (this.policy_fee_form.value.bill_to_type == 4) && this.pfShowEmail != this.pfOriAgEmail )
      {
        this.pfFormChanged = true;
        console.log("New Ag Email");
      }
      if (this.oriPolId != this.policy_fee_form.value.policy_id) {
        this.pfFormChanged = true;
        console.log(this.oriPolId + '--' + this.policy_fee_form.value.policy_id);
        console.log("d");
      }
      if (this.oriActionId != this.policy_fee_form.value.pf_pol_action_id) {
        this.pfFormChanged = true;
        console.log("e");
      }
      if (this.oriTCType != this.tc_type) {
        this.pfFormChanged = true;
        console.log("f");
      }
      if (this.pfRates != undefined && this.pfRates != null && this.oriPFRatesArr != undefined && this.oriPFRatesArr != null && this.pfRates.length != this.oriPFRatesArr.length) {
        this.pfFormChanged = true;
        console.log("g");
      }

      if (this.pfRates != undefined && this.pfRates != null && this.oriPFRatesArr != undefined && this.oriPFRatesArr != null) {
        for (let currRate of this.pfRates) {
          for (let currOriRate of this.oriPFRatesArr) {
            if (currRate != undefined && currRate != null && currOriRate != undefined && currOriRate != null) {
              if (currRate.id == 0) {
                this.pfFormChanged = true;
                console.log("New added");
              }
              if ((currRate.id > 0) && (currRate.uniqueIdentifier == currOriRate.uniqueIdentifier)) {
                if (currRate.quantity != undefined && currRate.quantity != null && currOriRate.quantity != undefined && currOriRate.quantity != null && (currRate.quantity != currOriRate.quantity)) {
                  this.pfFormChanged = true;
                  console.log("qua");
                }
                if (currRate.price != undefined && currRate.price != null && currOriRate.price != undefined && currOriRate.price != null && (currRate.price != currOriRate.price)) {
                  this.pfFormChanged = true;
                  console.log("price");
                }
                if (currRate.descSrvc != undefined && currRate.descSrvc != null && currOriRate.descSrvc != undefined && currOriRate.descSrvc != null && (currRate.descSrvc != currOriRate.descSrvc)) {
                  this.pfFormChanged = true;
                  console.log("desc");
                }
                if (currRate.note != undefined && currRate.note != null && currOriRate.note != undefined && currOriRate.note != null && (currRate.note != currOriRate.note)) {
                  this.pfFormChanged = true;
                  console.log("note");
                }
                if (currRate.commissionable != undefined && currRate.commissionable != null && currOriRate.commissionable != undefined && currOriRate.commissionable != null && (currRate.commissionable != currOriRate.commissionable)) {
                  this.pfFormChanged = true;
                  console.log("commissionable");
                }
                // alert();
                // console.log( currRate );
                // console.log( currOriRate );
              }
            }
          }
        }
      }

      if( this.invoice.pfAdminFee != undefined && this.invoice.pfAdminFee != null && this.oriPFAfee != undefined && this.oriPFAfee != null && this.invoice.pfAdminFee != this.oriPFAfee )
      {
        this.pfFormChanged = true;
        console.log("PF AF CB Changed");
      }
      if( this.invoice.pfAdminFee != undefined && this.invoice.pfAdminFee != null && this.invoice.pfAdminFee  && this.oriAdminFee != undefined && this.oriAdminFee != null && this.invoice.metadata.admin_fee != undefined && this.invoice.metadata.admin_fee != null && this.invoice.metadata.admin_fee != this.oriAdminFee )
      {
        this.pfFormChanged = true;
        console.log("PF AF Amt Changed");
      }

      if (this.invoice.pfCC != undefined && this.invoice.pfCC != null && this.oriPFCc != undefined && this.oriPFCc != null && this.invoice.pfCC != this.oriPFCc) {
        this.pfFormChanged = true;
        console.log("PF CC Changed");
      }

      if (this.invoice.pfCC != undefined && this.invoice.pfCC != null && this.invoice.pfCC && this.oricc != this.invoice.metadata.credit_card_fee) {
        this.pfFormChanged = true;
        console.log("PF CC Changed");
      }
      if (this.invoice.is_nsf && this.orinsf != undefined && this.orinsf != null && this.invoice.metadata.nsf_fee != undefined && this.invoice.metadata.nsf_fee != null && this.invoice.metadata.nsf_fee != this.orinsf) {
        this.pfFormChanged = true;
        console.log("PF NSF Fee Changed");
      }
      if (this.invoice.is_nsf && this.orirp != undefined && this.orirp != null && this.invoice.metadata.retry_payment_fee != undefined && this.invoice.metadata.retry_payment_fee != null && this.invoice.metadata.retry_payment_fee != this.orirp) {
        this.pfFormChanged = true;
        console.log("PF RPF Changed");
      }
    if( this.pf_db_ach_dt != this.in_ach_withdrawal_date ) {
        console.log(this.pf_db_ach_dt);
        console.log(this.in_ach_withdrawal_date);
        this.pfFormChanged = true;
      }
      if (this.db_report_date != this.in_report_date ) {
        this.pfFormChanged = true;
      }
    }
    console.log("Changed " + this.pfFormChanged);
  }

  checkPFForm() {
    this.pfFormValid = true;
    if (this.policy_fee_form.value.bill_to_type == "") {
      this.pfFormValid = false;
      console.log("1");
    }
    if (!this.isFreshInvoice) {
      if (this.client == undefined || this.client == null) {
        this.pfFormValid = false;
        console.log("2");
      }
      else {
        if (this.policy_fee_form.value.bill_to_type == 1) {
          if (this.client.broker_data == undefined || this.client.broker_data == null) {
            this.pfFormValid = false;
            console.log("2");
          }
        }
        else if (this.policy_fee_form.value.bill_to_type == 2) {
          if (this.client.payroll_company_data == undefined || this.client.payroll_company_data == null) {
            this.pfFormValid = false;
            console.log("2");
          }
        }
      }
    }
    // if (this.policy_fee_form.value.policy_id == "") {
    //   this.pfFormValid = false;
    //   console.log("3");
    // }
    // if (this.policy_fee_form.value.pf_pol_action_id == "") {
    //   console.log("--" + this.policy_fee_form.value.pf_pol_action_id + "!!");
    //   this.pfFormValid = false;
    //   console.log("4");
    // }
    // if (this.pfRates != undefined && this.pfRates != null && this.oriPFRatesArr != undefined && this.oriPFRatesArr != null && this.pfRates.length != this.oriPFRatesArr.length) {
    //   this.pfFormValid = false;
    //   console.log("5");
    // }
    if (this.pfAchInvalid) {
      this.pfFormValid = false;
      console.log("Payroll Service Fee Invoice-Ach Invalid:" + this.pfFormValid);
    }
  }

  changeDateFormate(oldDate,tp=0)
  {
    var dtArr = [];
    var retStr = '';

    if( tp != 4 )
    {
      dtArr = oldDate.toLocaleDateString('en-Us').split("/");
    }
    else if( tp==4 )
    {
      dtArr = oldDate.toString().split("-");
    }

    if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
    {
      if( tp != 4 )
      {
        if( dtArr[0].length == 1 )
        {
          dtArr[0] = "0" + dtArr[0]; 
        }
        if( dtArr[1].length == 1 )
        {
          dtArr[1] = "0" + dtArr[1]; 
        }
        retStr = dtArr[0] + '/' + dtArr[1] + '/' + dtArr[2];
      }
      else if( tp==4 )
      {
        var dtArr1 = dtArr[2].split(" ");
        if( typeof dtArr1[0] !== 'undefined' )
        {
          dtArr[2] = dtArr1[0]; 
        }
        retStr = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
      }
  
        return retStr;   
    }
    else
    {
      return 'Not available.';
    }
  }

  checkFormValid() {
    this.formValid = false;

    if (this.invoice != undefined && this.invoice != null && this.invoice.detail != undefined && this.invoice.detail != null && this.invoice.detail.length) {
      for (let currInvDetail of this.invoice.detail) {
        if (currInvDetail != undefined && currInvDetail !== null && (currInvDetail.gross_wages != undefined && currInvDetail.gross_wages != null && currInvDetail.gross_wages > 0)) {
          this.formValid = true;
        }
        if (currInvDetail != undefined && currInvDetail !== null && (currInvDetail.overtime_wages != undefined && currInvDetail.overtime_wages != null && currInvDetail.overtime_wages > 0)) {
          this.formValid = true;
        }
      }
    }

    if (this.invoice.metadata.admin_fee != undefined && this.invoice.metadata.admin_fee != null && this.invoice.metadata.admin_fee > 0) {
      // this.formValid = true;
    }

    if (this.invoice != undefined && this.invoice != null && this.invoice.flags != undefined && this.invoice.flags != null && this.invoice.flags.includes('first')) {
      if (this.invoice.metadata.setup_fee != undefined && this.invoice.metadata.setup_fee != null && this.invoice.metadata.setup_fee > 0) {
        // this.formValid = true;
      }
    }

    if (this.invoice != undefined && this.invoice != null && this.invoice.flags != undefined && this.invoice.flags != null && this.invoice.flags.includes('credit_card')) {
      if (this.invoice.metadata.credit_card_fee != undefined && this.invoice.metadata.credit_card_fee != null && this.invoice.metadata.credit_card_fee > 0) {
        // this.formValid = true;
      }
    }

    this.premiumValid = this.formValid;

    // console.log(this.invoice.detail);
    if (this.formValid) {
      var notChanged = true;
      var isNewMode = false;
      if (this.invoice != undefined && this.invoice != null && this.invoice.detail != undefined && this.invoice.detail != null && this.invoice.detail.length) {
        if (this.originalRatesArr != undefined && this.originalRatesArr != null && this.originalRatesArr.length) {
          for (let currInvDetail of this.invoice.detail) {
            for (let currOri of this.originalRatesArr) {
              if (currInvDetail != undefined && currInvDetail != null && (currInvDetail.id == 0)) {
                notChanged = false;
              }
              if (currInvDetail != undefined && currInvDetail != null && (currInvDetail.id == undefined || currInvDetail.id == null)) {
                isNewMode = true;
                notChanged = false;
              }
              if (currOri != undefined && currOri !== null && currInvDetail != undefined && currInvDetail !== null && currInvDetail.id != undefined && currInvDetail.id != null && currOri.id != undefined && currOri.id != null && (currOri.id == currInvDetail.id)) {
                if (currOri != undefined && currOri !== null && currInvDetail != undefined && currInvDetail !== null && currInvDetail.gross_wages != undefined && currInvDetail.gross_wages != null && currOri.gross_wages != undefined && currOri.gross_wages != null && (currOri.gross_wages != currInvDetail.gross_wages)) {
                  notChanged = false;
                }
                if (currOri != undefined && currOri !== null && currInvDetail != undefined && currInvDetail !== null && currInvDetail.overtime_wages != undefined && currInvDetail.overtime_wages != null && currOri.overtime_wages != undefined && currOri.overtime_wages != null && (currOri.overtime_wages != currInvDetail.overtime_wages)) {
                  notChanged = false;
                }
              }
            }
          }
        }
      }

      if (notChanged && !isNewMode) {
        if (this.oriAdminFee != undefined && this.oriAdminFee != null && this.invoice.metadata.admin_fee != undefined && this.invoice.metadata.admin_fee != null && this.invoice.metadata.admin_fee != this.oriAdminFee) {
          notChanged = false;
        }
        if (this.oriFirst != undefined && this.oriFirst != null && this.invoice.metadata.setup_fee != undefined && this.invoice.metadata.setup_fee != null && this.invoice.metadata.setup_fee != this.oriFirst) {
          notChanged = false;
        }
        if (this.oricc != undefined && this.oricc != null && this.invoice.metadata.credit_card_fee != undefined && this.invoice.metadata.credit_card_fee != null && this.invoice.metadata.credit_card_fee != this.oricc) {
          notChanged = false;
        }
        if (this.orinsf != undefined && this.orinsf != null && this.invoice.metadata.nsf_fee != undefined && this.invoice.metadata.nsf_fee != null && this.invoice.metadata.nsf_fee != this.orinsf) {
          notChanged = false;
        }
        if (this.orirp != undefined && this.orirp != null && this.invoice.metadata.retry_payment_fee != undefined && this.invoice.metadata.retry_payment_fee != null && this.invoice.metadata.retry_payment_fee != this.orirp) {
          notChanged = false;
        }
      }
      if (notChanged) {
        this.formValid = false;
      }
    }

    var notChanged1 = true;
    if (this.save_dates_nsf != undefined && this.save_dates_nsf != null && this.save_dates_nsf) {
      if (this.db_report_date != '' && this.db_report_date != this.in_report_date ) {
        notChanged1 = false;
      }
      if (this.invoice.metadata.invoice_type == "premium_invoice" && this.db_withdrawal_date != '' && this.db_withdrawal_date != this.in_ach_withdrawal_date ) {
        notChanged1 = false;
      }
    }

    if (this.isCorRev > 0) {
      if (this.db_report_date != '' && this.db_report_date != this.in_report_date ) {
        notChanged1 = false;
      }
      if (this.invoice.metadata.invoice_type == "premium_invoice" && this.db_withdrawal_date != '' && this.db_withdrawal_date != this.in_ach_withdrawal_date ) {
        notChanged1 = false;
      }
    }

    if (this.isUploadedInv ) {
      if (this.db_report_date != '' && this.db_report_date != this.in_report_date ) {
        notChanged1 = false;
      }
      if (this.invoice.metadata.invoice_type == "premium_invoice" && this.db_withdrawal_date != '' && this.db_withdrawal_date != this.in_ach_withdrawal_date ) {
        notChanged1 = false;
      }
      if (this.db_report_date == '' && this.in_report_date != undefined && this.in_report_date != null && this.in_report_date != "" ) {
        notChanged1 = false;
      }
      if (this.invoice.metadata.invoice_type == "premium_invoice" && this.db_withdrawal_date == '' && this.in_ach_withdrawal_date != undefined && this.in_ach_withdrawal_date != null && this.in_ach_withdrawal_date != "" ) {
        notChanged1 = false;
      }
    }

    if( this.oriPT != undefined && this.payrollTotal != undefined && this.oriPT != null && this.payrollTotal != null )
    {
      if( this.oriPT != this.payrollTotal )
      {
        notChanged1 = false;
      }
    }

    if (!notChanged1) {
      this.formValid = true;
    }

    // console.log(this.formValid);
  }

  pfQtyOnlyNumbers(event)
  {
    var onlyNums = false;

    // Ensure that it is a number and stop the keypress
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        onlyNums = true;
    }

    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
    // Allow: Ctrl+A
    (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
    // Allow: Ctrl+C
    (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
    // Allow: Ctrl+V
    (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
    // Allow: Ctrl+X
    (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
    // Allow: home, end, left, right
    (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      onlyNums = false;
    }

    if( onlyNums )
    {
      event.preventDefault();
    }
  }

  addRatePF(newLocInfo) {

    var rateValdtnFailed = false;
    this.quantityError = 'none';
    this.descSrvcError = 'none';
    this.noteError = 'none';
    this.priceError = 'none';
    this.priceMessageError = 'none';
    this.priceButtonError = 'none';

    var quantity = this.serviceFeeForm.value.quantity;
    var descSrvc = this.serviceFeeForm.value.descSrvc;
    var note = this.serviceFeeForm.value.note;
    var price = this.serviceFeeForm.value.price;

    if (quantity == '' || quantity == null || typeof quantity == 'undefined') {
      this.quantityError = 'block';
      rateValdtnFailed = true;
    }
    if (descSrvc == '' || descSrvc == null || typeof descSrvc == 'undefined') {
      this.descSrvcError = 'block';
      rateValdtnFailed = true;
    }
    if (note == '' || note == null || typeof note == 'undefined') {
      if( note == null || typeof note == 'undefined' )
      {
        note = "";
      }
      // this.noteError = 'block';
      // rateValdtnFailed = true;
    }
    if (price == '' || price == null || typeof price == 'undefined' || isNaN(price.replace(/,/g, ''))) {
      this.priceError = 'block';
      rateValdtnFailed = true;
    } else {
      // var regexp = /^[0-9]+([.-][0-9]+)?$/g;
      var regexp =/^-?[0-9]+(?:\.[0-9]+)?$/
      var newPrice = parseFloat(price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      if (regexp.test(newPrice.toString()) == false) {
        this.priceMessageError = 'block';
        rateValdtnFailed = true;
      }
    }
    //  if( ! this.serviceFeeForm.valid )
    //  {
    //    alert("Please fill all the description charge details.");
    //    rateValdtnFailed = true;
    //  }

    if (!rateValdtnFailed) {
      this.newRatesCount = this.newRatesCount + 1;
      var newRate1: any = {};
      newRate1.id = 0;
      newRate1.uniqueIdentifier = this.newRatesCount;

      newRate1.quantity = newLocInfo.quantity;
      newRate1.descSrvc = newLocInfo.descSrvc;
      newRate1.note = newLocInfo.note;
      newRate1.commissionable = newLocInfo.commissionable;
      //newRate1.price = parseFloat(newLocInfo.price).toFixed(2);
      newRate1.price = parseFloat(newLocInfo.price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      newRate1.amount = (newRate1.price * newLocInfo.quantity);
      newRate1.amount = parseFloat(newRate1.amount).toFixed(2);

      // console.log(this.pfInvoiceTotal);
      // console.log(newRate1.amount);
      this.pfInvoiceTotal += parseFloat(newRate1.amount);
      this.pfInvoiceTotal = parseFloat(parseFloat(this.pfInvoiceTotal.toString()).toFixed(2));
      // console.log(this.pfInvoiceTotal);

      this.pfInvoiceTotal = parseFloat(this.pfInvoiceTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      this.pfRates.push(newRate1);

      this.pfFlagChange(2);
      this.serviceFeeForm.patchValue({
        commissionable: false
      });

      this.serviceFeeForm.reset();
    }else{
      this.priceButtonError = 'block';
    }
  }


  updRatePF(rateId) {
    this.quantityError = 'none';
    this.descSrvcError = 'none';
    this.noteError = 'none';
    this.priceError = 'none';
    this.priceMessageError = 'none';
    this.priceButtonError = 'none';

    for (var i = 0; i < this.pfRates.length; i++) {
      var obj = this.pfRates[i];

      if (rateId == obj.uniqueIdentifier) {
        this.serviceFeeForm.controls['id'].setValue(obj.id);
        this.serviceFeeForm.controls['uniqueIdentifier'].setValue(obj.uniqueIdentifier);
        this.serviceFeeForm.controls['quantity'].setValue(obj.quantity);
        this.serviceFeeForm.controls['descSrvc'].setValue(obj.descSrvc);
        this.serviceFeeForm.controls['note'].setValue(obj.note);
        this.serviceFeeForm.controls['commissionable'].setValue(obj.commissionable);
        // this.serviceFeeForm.controls['price'].setValue(obj.price);
        this.serviceFeeForm.controls['price'].setValue(obj.price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR));

        this.rate_btn_add = 'none';
        this.rate_btn_upd = 'block';
      }
    }

    // console.log(this.nrcRates);
  }

  changePF(updLocInfo) {
    var rateValdtnFailed = false;
    this.quantityError = 'none';
    this.descSrvcError = 'none';
    this.noteError = 'none';
    this.priceError = 'none';
    this.priceMessageError = 'none';
    this.priceButtonError = 'none';

    var quantity = this.serviceFeeForm.value.quantity;
    var descSrvc = this.serviceFeeForm.value.descSrvc;
    var note = this.serviceFeeForm.value.note;
    var price = this.serviceFeeForm.value.price;

    if (quantity == '' || quantity == null || typeof quantity == 'undefined') {
      this.quantityError = 'block';
      rateValdtnFailed = true;
    }
    if (descSrvc == '' || descSrvc == null || typeof descSrvc == 'undefined') {
      this.descSrvcError = 'block';
      rateValdtnFailed = true;
    }
    if (note == '' || note == null || typeof note == 'undefined') {
      if( note == null || typeof note == 'undefined' )
      {
        note = "";
      }
      // this.noteError = 'block';
      // rateValdtnFailed = true;
    }
    if (price == '' || price == null || typeof price == 'undefined' || isNaN(price.replace(/,/g, ''))) {
      this.priceError = 'block';
      rateValdtnFailed = true;
    } else {
      var regexp = /^-?[0-9]+(?:\.[0-9]+)?$/;
      var newPrice = parseFloat(price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      if (regexp.test(newPrice.toString()) == false) {
        this.priceMessageError = 'block';
        rateValdtnFailed = true;
      }
    }
    // if( ! this.serviceFeeForm.valid )
    // {
    //   alert("Please fill all the description charge details.");
    //   rateValdtnFailed = true;
    // }

    if (!rateValdtnFailed) {

      // console.log(updLocInfo);
      var rateId = updLocInfo.uniqueIdentifier;

      for (var i = 0; i < this.pfRates.length; i++) {
        var obj = this.pfRates[i];

        if (rateId == obj.uniqueIdentifier) {
          this.pfInvoiceTotal -= (obj.quantity * obj.price);

          obj.quantity = updLocInfo.quantity;
          obj.descSrvc = updLocInfo.descSrvc;
          obj.note = updLocInfo.note;
          obj.commissionable = updLocInfo.commissionable;
          // obj.price = parseFloat(updLocInfo.price).toFixed(2);
          obj.price = parseFloat(updLocInfo.price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
          obj.amount = (obj.quantity * obj.price);
          obj.amount = parseFloat(obj.amount).toFixed(2);

          this.pfInvoiceTotal += (obj.quantity * obj.price);

          this.pfInvoiceTotal = parseFloat(this.pfInvoiceTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);

          this.pfRates[i] = obj;
          this.serviceFeeForm.reset();
          this.rate_btn_upd = 'none';
          this.rate_btn_add = 'block';

          this.pfFlagChange(2);
          this.serviceFeeForm.patchValue({
            commissionable: false
          });
          break;
        }
      }
    }else{
      this.priceButtonError = 'block';
    }
  }

  deleteRatePF(rateId) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this row?',
      accept: () => {
        for (var i = 0; i < this.pfRates.length; i++) {
          var obj = this.pfRates[i];
    
          if (rateId == obj.uniqueIdentifier) {
            this.pfRates.splice(i, 1);
            this.pfInvoiceTotal -= obj.amount;
            this.pfFlagChange(2);
          }
        }
      },
      reject: () => {
      }
    });
  }

  pfBillToChanged()
  {
    var bill_to_type: any = this.policy_fee_form.value.bill_to_type;

    this.pfShowName='';
    this.pfShowAddr='';
    this.pfShowCity='';
    this.pfShowSt='';
    this.pfShowZip=0;
    this.pfShowPh='';
    this.pfShowEmail='';
    this.pfBillToId=0;
  
    if( bill_to_type == 1 )
    {
      if( this.client != undefined && this.client != null && this.client.broker_data != undefined && this.client.broker_data != null )
      {
        if( this.client.broker_data.broker_name != undefined && this.client.broker_data.broker_name != null )
        {
          this.pfShowName = this.client.broker_data.broker_name;
        }
        if( this.client.broker_data.physical_address != undefined && this.client.broker_data.physical_address != null )
        {
          this.pfShowAddr = this.client.broker_data.physical_address;
        }
        if( this.client.broker_data.physical_city != undefined && this.client.broker_data.physical_city != null )
        {
          this.pfShowCity = this.client.broker_data.physical_city;
        }
        if( this.client.broker_data.physical_state != undefined && this.client.broker_data.physical_state != null )
        {
          this.pfShowSt = this.client.broker_data.physical_state;
        }
        if( this.client.broker_data.physical_zip != undefined && this.client.broker_data.physical_zip != null )
        {
          this.pfShowZip = this.client.broker_data.physical_zip;
        }
        if( this.client.broker_data.broker_main_phone != undefined && this.client.broker_data.broker_main_phone != null )
        {
          this.pfShowPh = this.client.broker_data.broker_main_phone;
        }
        if( this.client.broker_data.contact_1_email != undefined && this.client.broker_data.contact_1_email != null )
        {
          this.pfShowEmail = this.client.broker_data.contact_1_email;
        }
        if( this.client.broker_data.id != undefined && this.client.broker_data.id != null )
        {
          this.pfBillToId = this.client.broker_data.id;
        }
      }
    }
    else if( bill_to_type == 2 )
    {
      if( this.client != undefined && this.client != null && this.client.payroll_company_data != undefined && this.client.payroll_company_data != null )
      {
        if( this.client.payroll_company_data.payroll_company_name != undefined && this.client.payroll_company_data.payroll_company_name != null )
        {
          this.pfShowName = this.client.payroll_company_data.payroll_company_name;
        }
        if( this.client.payroll_company_data.main_phone_line != undefined && this.client.payroll_company_data.main_phone_line != null )
        {
          this.pfShowPh = this.client.payroll_company_data.main_phone_line;
        }
        if( this.client.payroll_company_data.contact_1_email != undefined && this.client.payroll_company_data.contact_1_email != null )
        {
          this.pfShowEmail = this.client.payroll_company_data.contact_1_email;
        }
        if( this.client.payroll_company_data.id != undefined && this.client.payroll_company_data.id != null )
        {
          this.pfBillToId = this.client.payroll_company_data.id;
        }
      }
    }
    else if( bill_to_type == 3 )
    {
      if( this.client.company_name != undefined && this.client.company_name != null )
      {
        this.pfShowName = this.client.company_name;
      }
      if( this.client.address != undefined && this.client.address != null )
      {
        this.pfShowAddr = this.client.address;
      }
      if( this.client.city != undefined && this.client.city != null )
      {
        this.pfShowCity = this.client.city;
      }
      if( this.client.state != undefined && this.client.state != null )
      {
        this.pfShowSt = this.client.state;
      }
      if( this.client.zip != undefined && this.client.zip != null )
      {
        this.pfShowZip = this.client.zip;
      }
      if( this.client.phone != undefined && this.client.phone != null )
      {
        this.pfShowPh = this.client.phone;
      }
      if( this.client.email != undefined && this.client.email != null )
      {
        this.pfShowEmail = this.client.email;
      }
      if( this.client.id != undefined && this.client.id != null )
      {
        this.pfBillToId = this.client.id;
      }
    }
    else if( bill_to_type == 4 )
    {
      if( this.client != undefined && this.client != null && this.client.agency_data != undefined && this.client.agency_data != null )
      {
        if( this.client.agency_data.agency_name != undefined && this.client.agency_data.agency_name != null )
        {
          this.pfShowName = this.client.agency_data.agency_name;
        }
        if( this.client.agency_data.physical_address != undefined && this.client.agency_data.physical_address != null )
        {
          this.pfShowAddr = this.client.agency_data.physical_address;
        }
        if( this.client.agency_data.physical_city != undefined && this.client.agency_data.physical_city != null )
        {
          this.pfShowCity = this.client.agency_data.physical_city;
        }
        if( this.client.agency_data.physical_state != undefined && this.client.agency_data.physical_state != null )
        {
          this.pfShowSt = this.client.agency_data.physical_state;
        }
        if( this.client.agency_data.physical_zip != undefined && this.client.agency_data.physical_zip != null )
        {
          this.pfShowZip = this.client.agency_data.physical_zip;
        }
        if( this.client.agency_data.agency_main_phone != undefined && this.client.agency_data.agency_main_phone != null )
        {
          this.pfShowPh = this.client.agency_data.agency_main_phone;
        }
        this.pfShowEmail = this.policy_fee_form.value.pfSelAgentEmail;
        if( this.client.agency_data.id != undefined && this.client.agency_data.id != null )
        {
          this.pfBillToId = this.client.agency_data.id;
        }
      }
    }
  }


  setNPRPolSelected(polPolActId,polNumber:string='',mode:number = 0)
  {
    this.npr_form.value.policy_id = '';
    this.NPRPolicies = [];
    this.NPRPolicies.push({
      label: 'Select',
      value: ''
    });
    this.npr_form.controls['policy_id'].setValue('');

    if( polPolActId == 0 && mode == 1 )
    {
      this.selIdNPRPF = null;
    }

    var actionId: any = this.npr_form.value.npr_pol_action_id;
    var selActionPolId = 0;
    var selActionEfDt:any = '';

    for (let policy of this.client.policies)
    {
      if (policy != undefined && policy !== null && policy.id != undefined && policy.id !== null && policy.policy_number != undefined && policy.policy_number !== null)
      {
        if (policy.actions_eff_date_change != undefined && policy.actions_eff_date_change != null)
        {
          for( let action of policy.actions_eff_date_change )
          {
            if (action != undefined && action != null && action.id != undefined && action.id != null && action.policy_id != undefined && action.policy_id != null && action.action_effective_date != undefined && action.action_effective_date != null )
            {
              if( action.id == actionId )
              {
                selActionPolId = action.policy_id;
                selActionEfDt = action.action_effective_date.toString();
              }
            }
          }
          
          for (let action of policy.actions_eff_date_change)
          {
            if (action != undefined && action != null && action.id != undefined && action.id != null && action.policy_id != undefined && action.policy_id != null && action.action_effective_date != undefined && action.action_effective_date != null )
            {
              if( selActionPolId == policy.id && selActionEfDt == action.action_effective_date.toString() )
              {
                if( (action.notCancelledAction != undefined && action.notCancelledAction == 1) || this.invoice.metadata.invoice_type == "npr" )
                {
                  this.currPol = {};
                  this.currPol.value = action.id;
                  this.currPol.label = policy.policy_number;
                  if( action.policy_number != undefined && action.policy_number != null && action.policy_number != "" )
                  {
                    this.currPol.label = action.policy_number;
                  }
                  this.NPRPolicies.push(this.currPol);
                }
              }
            }
          }
        }
      }
    }

    // console.log(this.NPRPolicies);

    // console.log( "Before Policies Set : " + polPolActId + polNumber );
    // console.log( this.NPRPolicies );
    if( polPolActId != 0 )
    {
      if( polNumber != "" )
      {
        if( this.NPRPolicies != undefined && this.NPRPolicies != null )
        {
          var enteredPolBased: boolean = false;
          for( let pfPol of this.NPRPolicies )
          {
            if( pfPol != undefined && pfPol != null && pfPol.label != undefined && pfPol.label != null && pfPol.label == polNumber )
            {
              enteredPolBased = true;
              this.npr_form.controls['policy_id'].setValue(pfPol.value);
              this.setNPRCarr(3);
            }
          }
          if( ! enteredPolBased )
          {
            this.npr_form.controls['policy_id'].setValue(polPolActId);
            setTimeout(() =>
            {
              this.setNPRCarr(3);
            }, 50);
          }
        }
      }
      else
      {
        this.npr_form.controls['policy_id'].setValue(polPolActId);
        this.setNPRCarr(3);
      }
    }
    else
    {
      if( (this.selIdNPRPF != undefined && this.selIdNPRPF != null && this.selIdNPRPF.id != undefined && this.selIdNPRPF.id != null) && (actionId != '') )
      {
        this.npr_form.controls['policy_id'].setValue(this.selIdNPRPF.id);
        this.setNPRCarr(3);
      }
      else if (typeof this.NPRPolicies != undefined && typeof this.NPRPolicies[1] !== 'undefined' && this.NPRPolicies[1].value != undefined && this.NPRPolicies[1].value != null)
      {
        this.npr_form.controls['policy_id'].setValue(this.NPRPolicies[1].value);
        this.setNPRCarr(3);
      }
      else
      {
        this.nprCarrierName = '';
        this.setNPRTotal();
      }
    }
  }
  

  setPFPolSelected(polPolActId,polNumber:string='')
  {
    this.policy_fee_form.value.policy_id = '';
    this.PFPolicies = [];
    this.PFPolicies.push({
      label: 'Select',
      value: ''
    });
    this.policy_fee_form.controls['policy_id'].setValue('');

    var actionId: any = this.policy_fee_form.value.pf_pol_action_id;
    var selActionPolId = 0;
    var selActionEfDt:any = '';

    for (let policy of this.client.policies)
    {
      if (policy != undefined && policy !== null && policy.id != undefined && policy.id !== null && policy.policy_number != undefined && policy.policy_number !== null)
      {
        if (policy.actions_eff_date_change != undefined && policy.actions_eff_date_change != null)
        {
          for( let action of policy.actions_eff_date_change )
          {
            if (action != undefined && action != null && action.id != undefined && action.id != null && action.policy_id != undefined && action.policy_id != null && action.action_effective_date != undefined && action.action_effective_date != null )
            {
              if( action.id == actionId )
              {
                selActionPolId = action.policy_id;
                selActionEfDt = action.action_effective_date.toString();
              }
            }
          }
          
          for (let action of policy.actions_eff_date_change)
          {
            if (action != undefined && action != null && action.id != undefined && action.id != null && action.policy_id != undefined && action.policy_id != null && action.action_effective_date != undefined && action.action_effective_date != null )
            {
              if( selActionPolId == policy.id && selActionEfDt == action.action_effective_date.toString() )
              {
                if( action.notCancelledAction != undefined && action.notCancelledAction == 1 )
                {
                  this.currPol = {};
                  this.currPol.value = action.id;
                  this.currPol.label = policy.policy_number;
                  if( action.policy_number != undefined && action.policy_number != null && action.policy_number != "" )
                  {
                    this.currPol.label = action.policy_number;
                  }
                  this.PFPolicies.push(this.currPol);
                }
              }
            }
          }
        }
      }
    }

    if( polPolActId != 0 )
    {
      if( polNumber != "" )
      {
        if( this.PFPolicies != undefined && this.PFPolicies != null )
        {
          for( let pfPol of this.PFPolicies )
          {
            if( pfPol != undefined && pfPol != null && pfPol.label != undefined && pfPol.label != null && pfPol.label == polNumber )
            {
              this.policy_fee_form.controls['policy_id'].setValue(pfPol.value);
              this.setPFCarr(2);
            }
          }
        }
      }
      else
      {
        this.policy_fee_form.controls['policy_id'].setValue(polPolActId);
        this.setPFCarr(2);
      }
    }
    else
    {
      if (typeof this.PFPolicies != undefined && typeof this.PFPolicies[1] !== 'undefined' && this.PFPolicies[1].value != undefined && this.PFPolicies[1].value != null)
      {
        this.policy_fee_form.controls['policy_id'].setValue(this.PFPolicies[1].value);
        this.setPFCarr(3);
      }
    }
  }

  setPFCarr( mode )
  {
    if( mode == 3 )
    {
      this.nprCarrierName = '';
    }
    var actionId: any = this.policy_fee_form.value.policy_id;

    for (let policy of this.client.policies)
    {
      if (policy != undefined && policy !== null && policy.id != undefined && policy.id !== null && policy.policy_number != undefined && policy.policy_number !== null)
      {
        if (policy.actions_eff_date_change != undefined && policy.actions_eff_date_change != null)
        {
          for (let action of policy.actions_eff_date_change)
          {
            if (action != undefined && action != null && action.id != undefined && action.id != null && action.id != undefined && action.id == actionId )
            {
              if( action.carrier != undefined && action.carrier != null && action.carrier.carrier_name != undefined && action.carrier.carrier_name != null && action.carrier.carrier_name != "" )
              {
                if( mode == 3 )
                {
                  this.nprCarrierName = action.carrier.carrier_name;
                }
              }
            }
          }
        }
      }
    }
  }

  setBkNPRPol(event)
  {
    if( event != undefined && event != null && event.rangeParent != undefined && event.rangeParent != null && event.rangeParent.className != undefined && event.rangeParent.className != null && event.rangeParent.className == "ui-clickable fa fa-fw fa-caret-down" )
    {
      this.nprFocusPolId = this.npr_form.value.policy_id;
    }
  }
  revertNPRPol( type:number  )
  {
    if( type == 1 )
    {
      this.showNPRMsg1 = false;
      setTimeout(() =>
      {
        this.npr_form.controls['policy_id'].setValue(this.nprFocusPolId);
        setTimeout(() =>
        {
          this.setNPRCarr(3);
        },50);
      },50);
    }
    else if( type == 2 )
    {
      this.showNPRMsg2 = false;
      setTimeout(() =>
      {
        this.npr_form.controls['policy_id'].setValue(this.nprFocusPolId);
        setTimeout(() =>
        {
          this.setNPRCarr(3);
        },50);
      },50);
    }
  }
  checkNPRPolicy( mode )
  {
    if( mode == 3 )
    {
      this.nprCarrierName = '';
    }
    var actionId: any = this.npr_form.value.policy_id;

    for (let policy of this.client.policies)
    {
      if (policy != undefined && policy !== null && policy.id != undefined && policy.id !== null && policy.policy_number != undefined && policy.policy_number !== null)
      {
        if (policy.actions_eff_date_change != undefined && policy.actions_eff_date_change != null)
        {
          for (let action of policy.actions_eff_date_change)
          {
            if (action != undefined && action != null && action.id != undefined && action.id != null && action.id != undefined && action.id == actionId )
            {
              if( mode == 3 )
              {
                var goAheadNPRPol:boolean = true;
                var nprPopup:number = 0;
                if( action.isCancelledAction != undefined && action.isCancelledAction != null && action.isCancelledAction == 1 )
                {
                  goAheadNPRPol = false;
                  nprPopup = 1;
                }
                if( action.isDateCrossedAction != undefined && action.isDateCrossedAction != null && action.isDateCrossedAction == 1 )
                {
                  goAheadNPRPol = false;
                  nprPopup = 2;
                }

                if( goAheadNPRPol )
                {
                  this.setNPRCarr(3);
                }
                else
                {
                  setTimeout(() =>
                  {
                    if( nprPopup == 1 )
                    {
                      this.showNPRMsg2 = true;
                    }
                    if( nprPopup == 2 )
                    {
                      this.showNPRMsg1 = true;
                    }
                  },50);
                }
              }
            }
          }
        }
      }
    }

    if( actionId == '' )
    {
      this.setNPRTotal();
    }
  }
  setNPRCarr( mode )
  {
    if( mode == 3 )
    {
      this.nprCarrierName = '';
    }
    var actionId: any = this.npr_form.value.policy_id;

    for (let policy of this.client.policies)
    {
      if (policy != undefined && policy !== null && policy.id != undefined && policy.id !== null && policy.policy_number != undefined && policy.policy_number !== null)
      {
        if (policy.actions_eff_date_change != undefined && policy.actions_eff_date_change != null)
        {
          for (let action of policy.actions_eff_date_change)
          {
            if (action != undefined && action != null && action.id != undefined && action.id != null && action.id != undefined && action.id == actionId )
            {
              if( action.carrier != undefined && action.carrier != null && action.carrier.carrier_name != undefined && action.carrier.carrier_name != null && action.carrier.carrier_name != "" )
              {
                if( mode == 3 )
                {
                  this.nprCarrierName = action.carrier.carrier_name;
                }
              }
            }
          }
        }
      }
    }

    this.setNPRTotal();
  }
  
  setPFCName() {
    
    var actionId: any = this.policy_fee_form.value.pf_pol_action_id;
    var policy_id: any = this.policy_fee_form.value.policy_id;
    if (actionId == "") {
      this.nprCarrierName = '';
    }
    else {
      if (this.client != undefined && this.client != null)
      {
        if (this.client.policies != undefined && this.client.policies != null)
        {
          for (let policy of this.client.policies) {
            if (policy != undefined && policy !== null && policy.id != undefined && policy.id !== null && policy_id != undefined && policy_id !== null && policy_id == policy.id)
            {
              if (policy.actions_eff_date_change != undefined && policy.actions_eff_date_change != null)
              {
                for (let action of policy.actions_eff_date_change)
                {
                  if (action.id != undefined && actionId != undefined && action.id != null && actionId != null && action.id == actionId)
                  {
                    this.nprCarrierName = '';
                    if (action != undefined && action != null && action.carrier != undefined && action.carrier != null && action.carrier.carrier_name != undefined && action.carrier.carrier_name != null)
                    {
                      this.nprCarrierName = action.carrier.carrier_name;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  setNPRTotal()
  {
    var actionId: any = this.npr_form.value.npr_pol_action_id;
    var policy_id: any = this.npr_form.value.policy_id;
    if (actionId == "" || policy_id == "")
    {
      this.nprInvoiceTotal = 0;
    }
    else
    { 
      var pickAfee = 0;

      if (this.client != undefined && this.client != null)
      {
        if (this.client.policies != undefined && this.client.policies != null)
        {
          for (let policy of this.client.policies)
          {
            if (policy != undefined && policy !== null && policy.id != undefined && policy.id !== null && policy_id != undefined && policy_id !== null)
            {
              if (policy.actions_eff_date_change != undefined && policy.actions_eff_date_change != null)
              {
                for (let action of policy.actions_eff_date_change)
                {
                  if ( action.id == policy_id )
                  {
                    if( action.rpm_admin_fee != undefined && action.rpm_admin_fee != null)
                    {
                      pickAfee = action.rpm_admin_fee;
                    }
                  }
                }
              }
            }
          }
        }
      }

      this.nprInvoiceTotal = pickAfee;
      if( this.invoice.is_nsf )
      {
        this.invoice.metadata.retry_payment_fee = this.nprInvoiceTotal;
        this.orirp = this.nprInvoiceTotal;
      }
    }

    this.nprFlagChange(2);
  }

  setPolActions(mode: number, polActionId,polPolActId,polNumber:string='')
  {
    if (this.invoice.metadata.invoice_type == "policy_fee")
    {
      if (mode != 2)
      {
        this.nprCarrierName = '';
      }

      this.actionDates = [];
      this.actionDates.push({
        label: 'Select',
        value: ''
      });
    }
    else if (this.invoice.metadata.invoice_type == "npr")
    {
      if (mode != 2)
      {
        this.nprInvoiceTotal = 0;
        this.nprCarrierName = '';
      }

      this.NPRactionDates = [];
      this.NPRactionDates.push({
        label: 'Select',
        value: ''
      });
    }

    var policy_id: any = 0;
    if (this.invoice.metadata.invoice_type == "policy_fee")
    {
      policy_id = this.policy_fee_form.value.policy_id;
    }
    else if (this.invoice.metadata.invoice_type == "npr")
    {
      policy_id = this.npr_form.value.policy_id;
    }

    if (this.client != undefined && this.client != null)
    {
      if (this.client.policies != undefined && this.client.policies != null)
      {
        for (let policy of this.client.policies)
        {
          if (policy != undefined && policy !== null && policy.id != undefined && policy.id !== null && policy_id != undefined && policy_id !== null )
          {
            if (policy.actions_eff_date_change != undefined && policy.actions_eff_date_change != null)
            {
              if( mode == 2 &&  this.invoice.metadata.invoice_type == "npr" )
              {
                for (let action of policy.actions_eff_date_change)
                {
                  if (action != undefined && action != null && action.id != undefined && action.id != null && action.action_effective_date != undefined && action.action_effective_date != null)
                  {
                    if( polActionId == action.id )
                    {
                      // this.selIdNPRPF = action;
                    }
                  }
                }
              }
                          
              var actionsFilled: any = [];
              var actionDateFound = false;

              for (let action of policy.actions_eff_date_change)
              {
                if (action != undefined && action != null && action.id != undefined && action.id != null && action.action_effective_date != undefined && action.action_effective_date != null)
                {
                  if( (action.notCancelledAction != undefined && action.notCancelledAction == 1) || this.invoice.metadata.invoice_type == "npr" )
                  {
                      var polActionDispDt = '';
                      this.currPol = {};
                      this.currPol.value = action.id;
                      // this.currPol.label = new Date(action.action_effective_date).toLocaleDateString('en-US');
    
                      if (typeof action.action_effective_date !== 'undefined' && action.action_effective_date !== '' && action.action_effective_date !== null)
                      {
                        var dtArrr = action.action_effective_date.toString().split("-");
                        if (typeof dtArrr[0] !== 'undefined' && typeof dtArrr[1] !== 'undefined' && typeof dtArrr[2] !== 'undefined')
                        {
                          actionDateFound = false;
    
                          polActionDispDt = dtArrr[1] + '/' + dtArrr[2] + '/' + dtArrr[0];
                          if( this.invoice.metadata.invoice_type == "npr" && ((this.selIdNPRPF != undefined && this.selIdNPRPF != null) && (this.selIdNPRPF.action_effective_date != undefined && this.selIdNPRPF.action_effective_date != null) && (this.selIdNPRPF.action_effective_date.toString() == action.action_effective_date.toString()) ) )
                          {
                            actionsFilled.push( action.action_effective_date.toString() );
                          }
                        }
                      }
                                    
                      for (var ai = 0; ai < actionsFilled.length; ai++)
                      {
                        if( this.invoice.metadata.invoice_type == "npr" && this.selIdNPRPF != undefined && this.selIdNPRPF != null && (this.selIdNPRPF.action_effective_date != undefined && this.selIdNPRPF.action_effective_date != null) )
                        {
                          if( this.selIdNPRPF.action_effective_date.toString() == action.action_effective_date.toString() )
                          {
                            if( this.selIdNPRPF.id == action.id )
                            {
                              actionDateFound = false;
                            }
                            else
                            {
                              actionDateFound = true;
                            }
                          }
                          else if( actionsFilled[ai] == action.action_effective_date.toString() )
                          {
                            actionDateFound = true;
                          }
                        }
                        else if( actionsFilled[ai] == action.action_effective_date.toString() )
                        {
                          actionDateFound = true;
                        }
                      }
                      if (this.invoice.metadata.invoice_type == "policy_fee")
                      {
                        if (!actionDateFound)
                        {
                          this.currPol = {};
                          this.currPol.value = action.id;
                          this.currPol.label = polActionDispDt;
                          this.actionDates.push(this.currPol);
                          actionsFilled.push( action.action_effective_date.toString() );
                        }
                      }
                      else if (this.invoice.metadata.invoice_type == "npr")
                      {
                        if( ! actionDateFound )
                        {
                          this.currPol = {};
                          this.currPol.value = action.id;
                          this.currPol.label = polActionDispDt;
                          this.NPRactionDates.push(this.currPol);
                          actionsFilled.push( action.action_effective_date.toString() );
                        }
                      }
                  }
                }
              }

              if (this.invoice.metadata.invoice_type == "policy_fee")
              {
                this.policy_fee_form.controls['pf_pol_action_id'].setValue('');
              }
              else if (this.invoice.metadata.invoice_type == "npr")
              {
                this.npr_form.controls['npr_pol_action_id'].setValue('');
              }

              if (mode == 2)
              {
                if (this.invoice.metadata.invoice_type == "policy_fee")
                {
                  this.policy_fee_form.controls['pf_pol_action_id'].setValue(polActionId);
                  this.setPFPolSelected(polPolActId,polNumber);
                }
                else if (this.invoice.metadata.invoice_type == "npr")
                {
                  this.npr_form.controls['npr_pol_action_id'].setValue(polActionId);

                  if( this.selIdNPRPF != undefined && this.selIdNPRPF != null && this.selIdNPRPF.id != undefined && this.selIdNPRPF.id != null )
                  {
                    this.npr_form.controls['npr_pol_action_id'].setValue(this.selIdNPRPF.id);
                    setTimeout(() =>
                    {
                      this.setNPRPolSelected(this.selIdNPRPF.id,polNumber);
                    }, 50);
                  }
                  else
                  {
                    this.setNPRPolSelected(polPolActId,polNumber);
                  }
                }
              }
              if (mode == 3)
              {
                var latestDateId: any = '';
                if (this.invoice.metadata.invoice_type == "policy_fee")
                {
                  if (typeof this.actionDates != undefined && typeof this.actionDates[1] !== 'undefined' && this.actionDates[1].value != undefined && this.actionDates[1].value != null)
                  {
                    latestDateId = this.actionDates[1].value;
                  }
                  this.policy_fee_form.controls['pf_pol_action_id'].setValue(latestDateId);
                  this.setPFPolSelected(polPolActId);
                }
                else if (this.invoice.metadata.invoice_type == "npr")
                {
                  if( this.selIdNPRPF != undefined && this.selIdNPRPF != null )
                  {
                    latestDateId = this.selIdNPRPF.id;
                  }
                  else if (typeof this.NPRactionDates != undefined && typeof this.NPRactionDates[1] !== 'undefined' && this.NPRactionDates[1].value != undefined && this.NPRactionDates[1].value != null)
                  {
                    latestDateId = this.NPRactionDates[1].value;
                  }
                  this.npr_form.controls['npr_pol_action_id'].setValue(latestDateId);
                  this.setNPRPolSelected(polPolActId);
                }
              }
            }
          }
        }

        if (this.invoice.metadata.invoice_type == "npr" && mode == 2 )
        {
          this.nprTopTableDisp = true;
        }

      }
    }
  }

  checkPT()
  {
      this.piPTMismatch = false;

      if( this.showPayrollTotal != undefined && this.showPayrollTotal != null && this.showPayrollTotal.trim() != "" )
      {
        var tv = parseFloat(this.showPayrollTotal.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        var parts = tv.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");
    
        this.payrollTotal = Number(figure.replace(/[^0-9\.-]+/g,""));
        this.showPayrollTotal = figure;
         
        if( this.payrollTotal != undefined && this.payrollTotal != null && this.payrollTotal != 2 )
        {
          if( (parseFloat((this.getTotalGW()).toFixed(2))) != this.payrollTotal )
          {
            this.piPTMismatch = true;
          }
        }
      }
      else
      {
        this.piPTMismatch = true;
      }
  }

  validatedateInv(inputText)
  {
    var isDateValid = true;
    var dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  // Match the date format through regular expression
  if(inputText.match(dateformat))
  {
  //Test which seperator is used '/' or '-'
  var opera1 = inputText.split('/');
  var opera2 = inputText.split('-');
  var lopera1 = opera1.length;
  var lopera2 = opera2.length;
  // Extract the string into month, date and year
  if (lopera1>1)
  {
  var pdate = inputText.split('/');
  }
  else if (lopera2>1)
  {
  var pdate = inputText.split('-');
  }
  var mm  = parseInt(pdate[0]);
  var dd = parseInt(pdate[1]);
  var yy = parseInt(pdate[2]);
  // Create list of days of a month [assume there is no leap year by default]
  var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
  if (mm==1 || mm>2)
  {
  if (dd>ListofDays[mm-1])
  {
  isDateValid = false;
  }
  }
  if (mm==2)
  {
  var lyear = false;
  if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
  {
  lyear = true;
  }
  if ((lyear==false) && (dd>=29))
  {
    isDateValid = false;
  }
  if ((lyear==true) && (dd>29))
  {
    isDateValid = false;
  }
  }
  }
  else
  {
    isDateValid = false;
  }

  return isDateValid;
  }
  
  appendYr( eleId )
  {
      var isValidDate = true;
      var checkingDt = this.dtValStr;
      var dtGoAhead = false;

      if( checkingDt.length == 4 && ! checkingDt.includes('/') )
      {
        checkingDt = checkingDt.substring(0,2) + "/" + checkingDt.substring(2,4) + "/";
        if( this.validatedateInv(checkingDt) )
        {
          this.dtValStr = checkingDt;
          dtGoAhead = true;
        } 
      }
      else if( checkingDt.length == 5 )
      {
        var dtArr = this.dtValStr.split("/");
        if( dtArr != undefined && dtArr != null && typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' )
        {
          checkingDt += "/";
          if( this.validatedateInv(checkingDt) )
          {
            this.dtValStr += "/";
            dtGoAhead = true;
          }
        }
      }

      if( dtGoAhead )
      {
        if( eleId == "rpDtInp" )
        {
          this.rpDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
            this.corRevPremChgReptDt(1);
            setTimeout(() =>
            {
              this.rpDtInv = false;
            }, 50);
            }, 100);
        }
        else if( eleId == "achDtInp" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
            this.corRevPremChgReptDt(2);
            setTimeout(() =>
            {
              this.achDtInv = false;
            }, 50);
            }, 100);
        }
        else if( eleId == "rpDtInp1" )
        {
          this.rpDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
            this.uplPremChgReptDt();
            setTimeout(() =>
            {
              this.rpDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "rpDtInp2" )
        {
          this.rpDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
            this.corRevRepDateChgnd(this.invoice.metadata.invoice_type);
            setTimeout(() =>
            {
              this.rpDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "achDtInp2" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
            this.pfCheckAchDate(2);
            setTimeout(() =>
            {
              this.achDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "achDtInp3" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
            this.corRevChkAchDate( this.invoice.metadata.invoice_type,2 );
            setTimeout(() =>
            {
              this.achDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "nprPayPDtInp" )
        {
          this.nprPPDtInv = true;
          setTimeout(() =>
          {
            this.npr_form.controls['payroll_due_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.nprPPDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "rpDtInp4" )
        {
          this.rpDtInv = true;
          setTimeout(() =>
          {
            this.npr_form.controls['report_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.rpDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "achDtInp5" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.npr_form.controls['ach_withdrawal_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.achDtInv = false;
            }, 50);
          }, 100);
        }
      }
  }

  fillDtTab( eleId )
  {
    var goFillField = false;

    var chkTempStr = this.dtValStr;
    if( chkTempStr.length == 8 && chkTempStr.includes("/") && chkTempStr.charAt(2)=="/" && chkTempStr.charAt(5)=="/" )
    {
        var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
            if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[2].length == 2 )
              {
                var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                if( this.validatedateInv(checkingDt1) )
                {
                  this.dtValStr = checkingDt1;
                  goFillField = true;
                }
              }
            }
        }
    }
    
    if( ! goFillField && chkTempStr != "" )
    {
	      var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
          if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[0] != "" && tempStrArr[1] != "" && tempStrArr[2] != "" )
              {
                if( tempStrArr[0].length == 1 )
                {
                  tempStrArr[0] = "0" + tempStrArr[0];
                }
                if( tempStrArr[1].length == 1 )
                {
                  tempStrArr[1] = "0" + tempStrArr[1];
                }
                
                if( tempStrArr[2].length == 2 )
                {
                  var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                  if( this.validatedateInv(checkingDt1) )
                  {
                    this.dtValStr = checkingDt1;
                    goFillField = true;
                  }
                }
                else if( tempStrArr[2].length == 4 )
                {
                  var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + tempStrArr[2];
                  if( this.validatedateInv(checkingDt1) )
                  {
                    this.dtValStr = checkingDt1;
                    goFillField = true;
                  }
                }
              }
            }
        }
    }

    if( goFillField )
    {
      if( eleId == "rpDtInp" )
      {
        this.rpDtInv = false;
        setTimeout(() =>
        {
          this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
          this.corRevPremChgReptDt(1);
        }, 100);
      }
      else if( eleId == "achDtInp" )
      {
        this.achDtInv = false;
        setTimeout(() =>
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
          this.corRevPremChgReptDt(2);
        }, 100);
      }
      else if( eleId == "rpDtInp1" )
      {
        this.rpDtInv = false;
        setTimeout(() =>
        {
          this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
          this.uplPremChgReptDt();
        }, 100);
      }
      else if( eleId == "rpDtInp2" )
      {
        this.rpDtInv = false;
        setTimeout(() =>
        {
          this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
          this.corRevRepDateChgnd(this.invoice.metadata.invoice_type);
        }, 100);
      }
      else if( eleId == "achDtInp2" )
      {
        this.achDtInv = false;
        setTimeout(() =>
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
          this.pfCheckAchDate(2);
        }, 100);
      }
      else if( eleId == "achDtInp3" )
      {
        this.achDtInv = false;
        setTimeout(() =>
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
          this.corRevChkAchDate( this.invoice.metadata.invoice_type,2 );
        }, 100);
      }
      else if( eleId == "nprPayPDtInp" )
      {
        this.nprPPDtInv = false;
        setTimeout(() =>
        {
          this.npr_form.controls['payroll_due_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "rpDtInp4" )
      {
        this.rpDtInv = false;
        setTimeout(() =>
        {
          this.npr_form.controls['report_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "achDtInp5" )
      {
        this.achDtInv = false;
        setTimeout(() =>
        {
          this.npr_form.controls['ach_withdrawal_date'].setValue(this.dtValStr);
        }, 100);
      }
    }
  }

  outOfDtField( event,eleId )
  {
    this.fillDtTab( eleId );

    if( eleId == "rpDtInp" )
    {
      this.rpDtInv = false;
      setTimeout(() =>
      {
        if( ! this.rpDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "achDtInp" )
    {
      this.achDtInv = false;
      setTimeout(() =>
      {
        if( ! this.achDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
        }
      }, 15);
    }  
    else if( eleId == "rpDtInp1" )
    {
      this.rpDtInv = false;
      setTimeout(() =>
      {
        if( ! this.rpDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "rpDtInp2" )
    {
      this.rpDtInv = false;
      setTimeout(() =>
      {
        if( ! this.rpDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "achDtInp2" )
    {
      this.achDtInv = false;
      setTimeout(() =>
      {
        if( ! this.achDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "achDtInp3" )
    {
      this.achDtInv = false;
      setTimeout(() =>
      {
        if( ! this.achDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "nprPayPDtInp" )
    {
      this.nprPPDtInv = false;
      setTimeout(() =>
      {
        if( ! this.nprPPDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.npr_form.controls['payroll_due_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "rpDtInp4" )
    {
      this.rpDtInv = false;
      setTimeout(() =>
      {
        if( ! this.rpDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.npr_form.controls['report_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "achDtInp5" )
    {
      this.achDtInv = false;
      setTimeout(() =>
      {
        if( ! this.achDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.npr_form.controls['ach_withdrawal_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
  }

  completeMonth( eleId )
  {
      var tempStr = this.dtValStr; 
      var compMGo = false;
      if( tempStr.length == 1 && (parseInt(tempStr) > 1 && parseInt(tempStr) < 10) )
      {
        this.dtValStr = "0" + this.dtValStr + "/";
        compMGo = true;
      }
      else if( tempStr.length == 2 && (parseInt(tempStr) >= 1 && parseInt(tempStr) <= 12) )
      {
        this.dtValStr = this.dtValStr + "/";
        compMGo = true;
      }

      if( eleId == "rpDtInp" && compMGo )
      {
        this.rpDtInv = true;
        setTimeout(() =>
        {
          this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "achDtInp" && compMGo )
      {
        this.achDtInv = true;
        setTimeout(() =>
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "rpDtInp1" && compMGo )
      {
        this.rpDtInv = true;
        setTimeout(() =>
        {
          this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "rpDtInp2" && compMGo )
      {
        this.rpDtInv = true;
        setTimeout(() =>
        {
          this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "achDtInp2" && compMGo )
      {
        this.achDtInv = true;
        setTimeout(() =>
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "achDtInp3" && compMGo )
      {
        this.achDtInv = true;
        setTimeout(() =>
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "nprPayPDtInp" && compMGo )
      {
        this.nprPPDtInv = true;
        setTimeout(() =>
        {
          this.npr_form.controls['payroll_due_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "rpDtInp4" && compMGo )
      {
        this.rpDtInv = true;
        setTimeout(() =>
        {
          this.npr_form.controls['report_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "achDtInp5" && compMGo )
      {
        this.achDtInv = true;
        setTimeout(() =>
        {
          this.npr_form.controls['ach_withdrawal_date'].setValue(this.dtValStr);
        }, 100);
      }
  }

  completeDate( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 1  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" && parseInt(tempStr) >= 4 && parseInt(tempStr) < 10 )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + "0" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "rpDtInp" )
          {
            this.rpDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
              this.corRevPremChgReptDt(1);
            }, 100);
          }
          else if( eleId == "achDtInp" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
              this.corRevPremChgReptDt(2);
            }, 100);
          }
          else if( eleId == "rpDtInp1" )
          {
            this.rpDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
              this.uplPremChgReptDt();
            }, 100);
          }
          else if( eleId == "rpDtInp2" )
          {
            this.rpDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
              this.corRevRepDateChgnd(this.invoice.metadata.invoice_type);
            }, 100);
          }
          else if( eleId == "achDtInp2" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
              this.pfCheckAchDate(2);
            }, 100);
          }
          else if( eleId == "achDtInp3" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
              this.corRevChkAchDate( this.invoice.metadata.invoice_type,2 );
            }, 100);
          }
          else if( eleId == "nprPayPDtInp" )
          {
            this.nprPPDtInv = true;
            setTimeout(() =>
            {
              this.npr_form.controls['payroll_due_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "rpDtInp4" )
          {
            this.rpDtInv = true;
            setTimeout(() =>
            {
              this.npr_form.controls['report_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "achDtInp5" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.npr_form.controls['ach_withdrawal_date'].setValue(this.dtValStr);
            }, 100);
          }
        }
      }

  }

  completeDt1( eleId )
  {
      var tempStr = this.dtValStr;
      var tmpNumb = "";

      if( tempStr != undefined && tempStr != null && tempStr.length == 3 )
      {
        tmpNumb = tempStr.charAt(2);
      }

      if( tmpNumb != "" && parseInt(tmpNumb) >= 4 && parseInt(tmpNumb) < 10 )
      {
        var assignDtFill = tempStr.charAt(0) + tempStr.charAt(1) + "/" + "0" + tmpNumb + "/";
        this.dtValStr = assignDtFill;

        if( eleId == "rpDtInp" )
        {
          this.rpDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
            this.corRevPremChgReptDt(1);
          }, 100);
        }
        else if( eleId == "achDtInp" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
            this.corRevPremChgReptDt(2);
          }, 100);
        }
        else if( eleId == "rpDtInp1" )
        {
          this.rpDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
            this.uplPremChgReptDt();
          }, 100);
        }
        else if( eleId == "rpDtInp2" )
        {
          this.rpDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
            this.corRevRepDateChgnd(this.invoice.metadata.invoice_type);
          }, 100);
        }
        else if( eleId == "achDtInp2" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
            this.pfCheckAchDate(2);
          }, 100);
        }
        else if( eleId == "achDtInp3" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
            this.corRevChkAchDate( this.invoice.metadata.invoice_type,2 );
          }, 100);
        }
        else if( eleId == "nprPayPDtInp" )
        {
          this.nprPPDtInv = true;
          setTimeout(() =>
          {
            this.npr_form.controls['payroll_due_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "rpDtInp4" )
        {
          this.rpDtInv = true;
          setTimeout(() =>
          {
            this.npr_form.controls['report_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "achDtInp5" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.npr_form.controls['ach_withdrawal_date'].setValue(this.dtValStr);
          }, 100);
        }
  }
  }

  completeDt2( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 2  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "rpDtInp" )
          {
            this.rpDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
              this.corRevPremChgReptDt(1);
            }, 100);
          }
          else if( eleId == "achDtInp" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
              this.corRevPremChgReptDt(2);
            }, 100);
          }
          else if( eleId == "rpDtInp1" )
          {
            this.rpDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
              this.uplPremChgReptDt();
            }, 100);
          }
          else if( eleId == "rpDtInp2" )
          {
            this.rpDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormA.controls['report_date'].setValue(this.dtValStr);
              this.corRevRepDateChgnd(this.invoice.metadata.invoice_type);
            }, 100);
          }
          else if( eleId == "achDtInp2" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
              this.pfCheckAchDate(2);
            }, 100);
          }
          else if( eleId == "achDtInp3" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.dtValStr);
              this.corRevChkAchDate( this.invoice.metadata.invoice_type,2 );
            }, 100);
          }
          else if( eleId == "nprPayPDtInp" )
          {
            this.nprPPDtInv = true;
            setTimeout(() =>
            {
              this.npr_form.controls['payroll_due_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "rpDtInp4" )
          {
            this.rpDtInv = true;
            setTimeout(() =>
            {
              this.npr_form.controls['report_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "achDtInp5" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.npr_form.controls['ach_withdrawal_date'].setValue(this.dtValStr);
            }, 100);
          }
        }
      }

  }

  pushSelect1( eleId )
  {
    if( this.validatedateInv(this.dtValStr) )
    {
        if( eleId == "rpDtInp" )
        {
          this.rpDtInv = false;
          setTimeout(() =>
          {
            this.corRevPremChgReptDt(1);
          }, 100);
        }
        else if( eleId == "achDtInp" )
        {
          this.achDtInv = false;
          setTimeout(() =>
          {
            this.corRevPremChgReptDt(2);
          }, 100);
        }
        else if( eleId == "rpDtInp1" )
        {
          this.rpDtInv = false;
          setTimeout(() =>
          {
            this.uplPremChgReptDt();
          }, 100);
        }
        else if( eleId == "rpDtInp2" )
        {
          this.rpDtInv = false;
          setTimeout(() =>
          {
            this.corRevRepDateChgnd(this.invoice.metadata.invoice_type);
          }, 100);
        }
        else if( eleId == "achDtInp2" )
        {
          this.achDtInv = false;
          setTimeout(() =>
          {
            this.pfCheckAchDate(2);
          }, 100);
        }
        else if( eleId == "achDtInp3" )
        {
          this.achDtInv = false;
          setTimeout(() =>
          {
            this.corRevChkAchDate( this.invoice.metadata.invoice_type,2 );
          }, 100);
        }
        else if( eleId == "nprPayPDtInp" )
        {
          this.nprPPDtInv = false;
        }
        else if( eleId == "rpDtInp4" )
        {
          this.rpDtInv = false;
        }
        else if( eleId == "achDtInp5" )
        {
          this.achDtInv = false;
        }
    }
  }

  setYear(ev)
  {
    var justBackSpaced = false;

    if( ev.inputType != undefined && ev.inputType != null )
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined  )
      {
        this.dtValStr = ev.srcElement.value;
      }
      if( ev.inputType.trim() == "deleteContentBackward" || ev.inputType.trim() == "deleteContentForward" )
      {
        this.dtValStr = ev.srcElement.value;
        justBackSpaced = true;
      }
    }
    else
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined )
      {
        if( ev.srcElement.value.length < this.chromePrevStr.length )
        {
          justBackSpaced = true;
        }
        this.dtValStr = ev.srcElement.value;
        this.chromePrevStr = ev.srcElement.value;
      }
    }

    if( ! justBackSpaced && (this.dtValStr.length == 4 && ! this.dtValStr.includes("/"))  )
    {
      // this.appendYr( ev.srcElement.id );
    }
    else if( ! justBackSpaced && (this.dtValStr.length == 1 || this.dtValStr.length == 2) )
    {
       // this.completeMonth( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 4 )
    {
       // this.completeDate( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 5 )
    {
       // this.completeDt2( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 3 && ! this.dtValStr.includes("/") )
    {
      // this.completeDt1( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.validatedateInv(this.dtValStr) )
    {
      this.pushSelect1( ev.srcElement.id );
    }
  }

  resetDtVal( dtFieldNum:number = 0 )
  {
    setTimeout(() =>
    {
      this.dtValStr = '';
    }, 50);
  }

  ngOnInit() {
    // Create Invoice
    this.invoice = new Invoice();
    this.invoice.premium_total = 0;
    this.invoice.metadata.admin_fee = 0;
    this.invoice.metadata.setup_fee = 0;
    this.invoice.metadata.credit_card_fee = 0;
    this.invoice.metadata.nsf_fee = 0;
    this.invoice.metadata.retry_payment_fee = 0;
    this.invoice.invoice_total = 0;

    this.invoice.ngFirst = false;
    this.invoice.ngCC = false;
    this.invoice.ngNPR = false;

    this.invoice.is_nsf = false;

    this.invoice.flags = [];

    this.spinnerService.show();

    this.invoiceNoteForm = this.fb.group({
      'notes': new FormControl('', Validators.required)
    });

    this.invoiceNoteFormPF = this.fb.group({
      'notes': new FormControl('', Validators.required)
    });

    this.invoiceNoteFormNPR = this.fb.group({
      'notes': new FormControl('', Validators.required)
    });

    this.premInvFormA = this.fb.group({
      'report_date': new FormControl('', Validators.required)
    });
    this.premInvFormB = this.fb.group({
      'ach_withdrawal_date': new FormControl('', Validators.required)
    });


    this.policy_fee_form = this.fb.group({
      'bill_to_type': new FormControl('', Validators.required),
      'bill_to_id': new FormControl('', Validators.required),
      'pol_holder_name': new FormControl('', Validators.required),
      'fein': new FormControl('', Validators.required),
      'policy_id': new FormControl('', Validators.required),
      'pf_pol_action_id': new FormControl('', Validators.required),
      'pfSelAgentEmail': new FormControl('', Validators.required)
    });

    this.serviceFeeForm = this.fb.group({
      'id': ['', ''],
      'uniqueIdentifier': ['', ''],
      'quantity': new FormControl('', Validators.required),
      'descSrvc': new FormControl('', Validators.required),
      'note': new FormControl('', Validators.required),
      'price': new FormControl('', Validators.required),
      'commissionable': false
    });

    this.npr_form = this.fb.group({
      'payroll_due_date': new FormControl('', Validators.required),
      'pol_holder_name': new FormControl('', Validators.required),
      'fein': new FormControl('', Validators.required),
      'policy_id': new FormControl('', Validators.required),
      'npr_pol_action_id': new FormControl('', Validators.required),
      'report_date': new FormControl('', Validators.required),
      'ach_withdrawal_date': new FormControl('', Validators.required),
    });

    this.new_line = new InvoiceDetail();
    this.new_line.calc();

    this.invoiceSubmitted = false;

    this.isFreshInvoice = false;
    this.currInvoiceSettingId = 0;

    this.docService.getInvoiceDescriptionsList().subscribe((res) => {
      this.descSrvcs = res;
    });

    // TODO load invoice on edit
    // Load URL parameters
    this.aroute.queryParams.subscribe(params => {
      if (params['invoice_type'] != undefined) {
        if(params['invoice_type'] == 'misc_fee')
        {
          this.invoice.metadata.invoice_type = 'policy_fee';
          this.miscType = 1;
        }
        else
        {
          this.invoice.metadata.invoice_type = params['invoice_type'];
        }
        

        if( this.invoice.metadata.invoice_type == "premium_invoice" || this.invoice.metadata.invoice_type == "payroll_reporting" )
        {

          this.in_check_date = params['check_date'].toString();
          this.in_payroll_end_date = params['payroll_end_date'].toString();

          var dtPremDts = this.in_check_date.split( "/" );
          if( dtPremDts[0].length == 1 )
          {
            dtPremDts[0] = "0" + dtPremDts[0]; 
          }
          if( dtPremDts[1].length == 1 )
          {
            dtPremDts[1] = "0" + dtPremDts[1]; 
          }
          this.in_check_date = dtPremDts[0] + "/" + dtPremDts[1] + "/" + dtPremDts[2];

          var dtPremDts = this.in_payroll_end_date.split( "/" );
          if( dtPremDts[0].length == 1 )
          {
            dtPremDts[0] = "0" + dtPremDts[0]; 
          }
          if( dtPremDts[1].length == 1 )
          {
            dtPremDts[1] = "0" + dtPremDts[1]; 
          }
          this.in_payroll_end_date = dtPremDts[0] + "/" + dtPremDts[1] + "/" + dtPremDts[2];

          if( params['payrollTotal'] != undefined && params['payrollTotal'] != null )
          {
            this.showPayrollTotal = params['payrollTotal'];
            this.payrollTotal = Number(params['payrollTotal'].replace(/[^0-9\.-]+/g,""));
            this.oriPT = this.payrollTotal;
            
            if( this.payrollTotal != 2 )
            {
              this.piPTMismatch = true;
            }
          }
        }

        if( (this.invoice.metadata.invoice_type == "policy_fee") )
        {
          // this.invoice.metadata.report_date = new Date(Date.parse(params['report_date']));
          // this.invoice.metadata.ach_withdrawal_date = new Date(Date.parse(params['ach_withdrawal_date']));

          this.in_report_date = params['report_date'].toString();
          this.in_ach_withdrawal_date = params['ach_withdrawal_date'].toString();

          var dtPFDts = this.in_report_date.split( "/" );
          if( dtPFDts[0].length == 1 )
          {
            dtPFDts[0] = "0" + dtPFDts[0]; 
          }
          if( dtPFDts[1].length == 1 )
          {
            dtPFDts[1] = "0" + dtPFDts[1]; 
          }
          this.in_report_date = dtPFDts[0] + "/" + dtPFDts[1] + "/" + dtPFDts[2];
          
          var dtPFDts = this.in_ach_withdrawal_date.split( "/" );
          if( dtPFDts[0].length == 1 )
          {
            dtPFDts[0] = "0" + dtPFDts[0]; 
          }
          if( dtPFDts[1].length == 1 )
          {
            dtPFDts[1] = "0" + dtPFDts[1]; 
          }
          this.in_ach_withdrawal_date = dtPFDts[0] + "/" + dtPFDts[1] + "/" + dtPFDts[2];

          // this.pf_backup_ach_withdrawal_date = this.invoice.metadata.ach_withdrawal_date;

          this.in_backup_ach_withdrawal_date = this.in_ach_withdrawal_date;
          this.pf_db_ach_dt = this.in_ach_withdrawal_date;
        }
        
        if( this.invoice.metadata.invoice_type == "premium_invoice" )
        {
          this.in_report_date = params['report_date'].toString();
          this.in_ach_withdrawal_date = params['ach_withdrawal_date'].toString();

          var dtPremDts = this.in_report_date.split( "/" );
          if( dtPremDts[0].length == 1 )
          {
            dtPremDts[0] = "0" + dtPremDts[0]; 
          }
          if( dtPremDts[1].length == 1 )
          {
            dtPremDts[1] = "0" + dtPremDts[1]; 
          }
          this.in_report_date = dtPremDts[0] + "/" + dtPremDts[1] + "/" + dtPremDts[2];

          var dtPremDts = this.in_ach_withdrawal_date.split( "/" );
          if( dtPremDts[0].length == 1 )
          {
            dtPremDts[0] = "0" + dtPremDts[0]; 
          }
          if( dtPremDts[1].length == 1 )
          {
            dtPremDts[1] = "0" + dtPremDts[1]; 
          }
          this.in_ach_withdrawal_date = dtPremDts[0] + "/" + dtPremDts[1] + "/" + dtPremDts[2];

        }
        if( this.invoice.metadata.invoice_type == "payroll_reporting" )
        {
          this.is_pay_reporting = 1;

          this.in_report_date = params['report_date'].toString();

          var dtPremDts = this.in_report_date.split( "/" );
          if( dtPremDts[0].length == 1 )
          {
            dtPremDts[0] = "0" + dtPremDts[0]; 
          }
          if( dtPremDts[1].length == 1 )
          {
            dtPremDts[1] = "0" + dtPremDts[1]; 
          }
          this.in_report_date = dtPremDts[0] + "/" + dtPremDts[1] + "/" + dtPremDts[2];
        }
        if (this.invoice.metadata.invoice_type == "npr")
        {
          this.payrollDtStr = params['payroll_post_date'];

          this.npr_form.controls['payroll_due_date'].setValue( params['payroll_post_date'] );

          this.in_report_date = params['report_date'].toString();
          this.in_ach_withdrawal_date = params['ach_withdrawal_date'].toString();

          var dtNPRDts = this.in_report_date.split( "/" );
          if( dtNPRDts[0].length == 1 )
          {
            dtNPRDts[0] = "0" + dtNPRDts[0]; 
          }
          if( dtNPRDts[1].length == 1 )
          {
            dtNPRDts[1] = "0" + dtNPRDts[1]; 
          }
          this.in_report_date = dtNPRDts[0] + "/" + dtNPRDts[1] + "/" + dtNPRDts[2];
          
          var dtNPRDts = this.in_ach_withdrawal_date.split( "/" );
          if( dtNPRDts[0].length == 1 )
          {
            dtNPRDts[0] = "0" + dtNPRDts[0]; 
          }
          if( dtNPRDts[1].length == 1 )
          {
            dtNPRDts[1] = "0" + dtNPRDts[1]; 
          }
          this.in_ach_withdrawal_date = dtNPRDts[0] + "/" + dtNPRDts[1] + "/" + dtNPRDts[2];
        }


        this.datesLoaded = true;
        this.save_dates_nsf = false;

        if (this.invoice.metadata.invoice_type == "premium_invoice" || this.invoice.metadata.invoice_type == "payroll_reporting" )
        {
          this.requiredCheckDate = params['check_date'];

          this.invPolPostObj.requiredCheckDate = this.requiredCheckDate.toString();
          this.invPolPostObj.clientId = this.id;
          this.policyService.getInvoicePolicy(this.invPolPostObj).subscribe(data => {
            // console.log(data);
            if (data != undefined && data != null && data.policyToConsider != undefined && data.policyToConsider != null) {
              this.isFreshInvoice = true;

              this.requiredPolicy = data.policyToConsider;

              if( this.isFreshInvoice && (this.invoice.metadata.invoice_type == "premium_invoice" || this.invoice.metadata.invoice_type == "payroll_reporting") )
              {
                if( this.client != undefined && this.client != null )
                {
                  if( this.client.fein != undefined && this.client.fein != null )
                  {
                    this.feinToDisp = this.client.fein;
                  }
                  if( this.client.agency_data != undefined && this.client.agency_data != null && this.client.agency_data.agency_name != undefined && this.client.agency_data.agency_name != null )
                  {
                    this.srAgencyName = this.client.agency_data.agency_name;
                  }
                  if( this.client.agency_data != undefined && this.client.agency_data != null && this.client.agency_data.agency_main_phone != undefined && this.client.agency_data.agency_main_phone != null )
                  {
                    this.srAgencyPhone = this.client.agency_data.agency_main_phone;
                  }
                }
              }

              if( (this.client != undefined && this.client != null) && this.isFreshInvoice )
              {
                if( this.client.enable_def_cc != undefined && this.client.enable_def_cc != null && this.client.enable_def_cc == 1 )
                {
                  if( this.invoice.metadata.invoice_type == "premium_invoice" || this.invoice.metadata.invoice_type == "payroll_reporting" )
                  {
                      this.invoice.ngCC = true;
                      this.onFlagChange(2);
                      this.default_credit_card = 1;
                  }
                }
              }
                      
              if (this.requiredPolicy.rpm_admin_fee != undefined && this.requiredPolicy.rpm_admin_fee != null ) {
                this.invoice.metadata.admin_fee = this.requiredPolicy.rpm_admin_fee;
              }

              if (data.ratesNeededArr != undefined && data.ratesNeededArr != null) {
                this.ratesNeededArr = data.ratesNeededArr;
                // console.log(data.ratesNeededArr);

                this.originalRatesArr = JSON.parse(JSON.stringify(this.ratesNeededArr));

                this.wagesCopyBtnShow = false;
                var firstCCFound = false;
                var secondCCFound = false;
                var rateRowIndex:number = -1;
                this.dataObjWagesCopy = {};

                for (const rate of this.ratesNeededArr) {
                  this.invoice.detail.push(new InvoiceDetail({
                    location: rate.location_name,
                    state: rate.state_id,
                    class_code: rate.class_code.class_code,
                    class_code_description: rate.class_code.class_code_description,
                    gross_wages: null,
                    overtime_wages: null,
                    overtime_premium: 0,
                    workers_comp_wages: 0,
                    net_rate: rate.net_rate,
                    premium: 0
                  }));

                  if( rate != undefined && rate != null && rate.class_code != undefined && rate.class_code != null && rate.class_code.class_code != undefined && rate.class_code.class_code != null )
                  {
                    ++rateRowIndex;
                    if( rate.class_code.class_code == "7431" )
                    {
                      firstCCFound = true;

                      if( rate.state_id != undefined && rate.state_id != null )
                      {
                        if( this.wagesCopyObjsArr != undefined )
                        {
                          if( this.wagesCopyObjsArr[rate.state_id] == undefined )
                          {
                            var dataObjWagesCopy:any = {};
                            dataObjWagesCopy.fromObj = {};
                            dataObjWagesCopy.toObj = {};
                            this.wagesCopyObjsArr[rate.state_id] = dataObjWagesCopy;
                            this.statesArr.push( rate.state_id );
                          }
                        }
                      }
                          
                      if( rate.state_id != undefined && rate.state_id != null && this.wagesCopyObjsArr[rate.state_id] != undefined && this.wagesCopyObjsArr[rate.state_id] != null )
                      {
                        var dataObjWagesCopy:any = this.wagesCopyObjsArr[rate.state_id];
                        dataObjWagesCopy.fromObj.ri = rateRowIndex;
                        dataObjWagesCopy.fromObj.item = {};
                        if( this.invoice != undefined && this.invoice.detail != undefined && typeof this.invoice.detail[rateRowIndex] !== undefined )
                        {
                          dataObjWagesCopy.fromObj.item = this.invoice.detail[rateRowIndex];
                        }
                        this.wagesCopyObjsArr[rate.state_id] = dataObjWagesCopy;
                      }
                    }
                    else if( rate.class_code.class_code == "7453" )
                    {
                      secondCCFound = true;

                      if( rate.state_id != undefined && rate.state_id != null )
                      {
                        if( this.wagesCopyObjsArr != undefined )
                        {
                          if( this.wagesCopyObjsArr[rate.state_id] == undefined )
                          {
                            var dataObjWagesCopy:any = {};
                            dataObjWagesCopy.fromObj = {};
                            dataObjWagesCopy.toObj = {};
                            this.wagesCopyObjsArr[rate.state_id] = dataObjWagesCopy;
                            this.statesArr.push( rate.state_id );
                          }
                        }
                      }
                          
                      if( rate.state_id != undefined && rate.state_id != null && this.wagesCopyObjsArr[rate.state_id] != undefined && this.wagesCopyObjsArr[rate.state_id] != null )
                      {
                        var dataObjWagesCopy:any = this.wagesCopyObjsArr[rate.state_id];
                        dataObjWagesCopy.toObj.ri = rateRowIndex;
                        dataObjWagesCopy.toObj.item = {};
                        if( this.invoice != undefined && this.invoice.detail != undefined && typeof this.invoice.detail[rateRowIndex] !== undefined )
                        {
                          dataObjWagesCopy.toObj.item = this.invoice.detail[rateRowIndex];
                        }
                        this.wagesCopyObjsArr[rate.state_id] = dataObjWagesCopy;
                      }
                    }
                  }
                }

                if( firstCCFound && secondCCFound )
                {
                  this.wagesCopyBtnShow = true;
                }

                // Update Totals
                this.invoice.calcTotals();

              }
            }
            this.spinnerService.hide();

            if (data != undefined && data != null && (data.policyToConsider == undefined || data.policyToConsider == null)) {
              alert("No matching policy found.");
            }

            this.invoiceNotePostObj = {};
            this.invoiceNotePostObj.client_id = this.id;
            this.invoiceNotePostObj.mode = -1;
            this.policyService.getInvoiceeNote(this.invoiceNotePostObj).subscribe(
              response => {
                if (response instanceof HttpErrorResponse) {
                  this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
                } else {
                  if (response != undefined && response != null && response.notes != undefined && response.notes != null) {
                    // this.invoiceNoteForm.controls['notes'].setValue(response.notes);
                    this.invNotesBk = this.sanitizer.bypassSecurityTrustHtml(response.notes);
                    this.notesInvSave = response.notes;
                  }
                }

              },
              error => {
                console.log(error);
                this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
              });

          });
        }
        if (this.invoice.metadata.invoice_type == "policy_fee")
        {
          // this.invPolPostObj = {};
          // this.invPolPostObj.clientId = this.id;
          this.isFreshInvoice = true;

          this.tc_types = [
            { label: 'ACH', value: 1 },
            { label: 'Paid by Check', value: 2 }
          ];

          this.bill_to_types = [
            { label: 'Select', value: '' },
            { label: 'Broker', value: 1 },
            { label: 'PR Company', value: 2 },
            { label: 'Company', value: 3 },
            { label: 'Agent', value: 4 }
          ];
          this.policy_fee_form.controls['bill_to_type'].setValue(3);

          this.PFPolicies.push({
            label: 'Select',
            value: ''
          });
          this.policy_fee_form.controls['policy_id'].setValue('');
          this.actionDates.push({
            label: 'Select',
            value: ''
          });
          this.policy_fee_form.controls['pf_pol_action_id'].setValue('');

          this.qnts = [
            { label: 'Select', value: '' },
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 },
            { label: '9', value: 9 },
            { label: '10', value: 10 }
          ];
          // this.descSrvcs = [
          //   { label: 'Select', value: '' },
          //   { label: 'One-Time Implementation', value: 'One-Time Implementation' },
          //   { label: 'Base Payroll Service Weekly', value: 'Base Payroll Service Weekly' },
          //   { label: 'Base Payroll Service Bi-Weekly', value: 'Base Payroll Service Bi-Weekly' },
          //   { label: 'Base Payroll Service Semi-Monthly', value: 'Base Payroll Service Semi-Monthly' },
          //   { label: ' Base Payroll Service Monthly', value: ' Base Payroll Service Monthly' },
          //   { label: 'Per Employee Paid', value: 'Per Employee Paid' },
          //   { label: 'Delivery-2 Day', value: 'Delivery-2 Day' },
          //   { label: 'Delivery-Overnight', value: 'Delivery-Overnight' },
          //   { label: 'W2 Delivery', value: 'W2 Delivery' },
          //   { label: 'Rush Implementation', value: 'Rush Implementation' },
          //   { label: 'Pay-Go Work Comp', value: 'Pay-Go Work Comp' },
          //   { label: 'ERMA Membership', value: 'ERMA Membership' },
          //   { label: 'Other', value: 'Other' }
          // ];

          this.clientService.getAllBrokers().subscribe(res => {
            this.brokers = res;
          });

          this.invoiceNotePostObj = {};
          this.invoiceNotePostObj.client_id = this.id;
          this.invoiceNotePostObj.mode = -1;
          this.policyService.getInvoiceeNote(this.invoiceNotePostObj).subscribe(
            response => {
              if (response instanceof HttpErrorResponse) {
                this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
              } else {
                if (response != undefined && response != null && response.notes != undefined && response.notes != null) {
                  // this.invoiceNoteFormPF.controls['notes'].setValue(response.notes);
                  this.invNotesBk = this.sanitizer.bypassSecurityTrustHtml(response.notes);
                  this.notesInvSave = response.notes;
                }
              }

            },
            error => {
              console.log(error);
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
            });


        }
        if (this.invoice.metadata.invoice_type == "npr")
        {
          this.isFreshInvoice = true;

          this.NPRPolicies.push({
            label: 'Select',
            value: ''
          });
          this.npr_form.controls['policy_id'].setValue('');
          this.NPRactionDates.push({
            label: 'Select',
            value: ''
          });
          this.npr_form.controls['npr_pol_action_id'].setValue('');

          this.invoiceNotePostObj = {};
          this.invoiceNotePostObj.client_id = this.id;
          this.invoiceNotePostObj.mode = -1;
          this.policyService.getInvoiceeNote(this.invoiceNotePostObj).subscribe(
            response => {
              if (response instanceof HttpErrorResponse) {
                this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
              } else {
                if (response != undefined && response != null && response.notes != undefined && response.notes != null) {
                  // this.invoiceNoteFormNPR.controls['notes'].setValue(response.notes);
                  this.invNotesBk = this.sanitizer.bypassSecurityTrustHtml(response.notes);
                  this.notesInvSave = response.notes;
                }
              }

            },
            error => {
              console.log(error);
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
            });


        }
      }
    });

    if (this.invoice_id)
    {
      this.spinnerService.show();

      this.invPolPostObj = {};
      this.invPolPostObj.invoiceSettingId = this.invoice_id;
      this.policyService.getSavedInvoice(this.invPolPostObj).subscribe(data => {
        // console.log(data);
        // alert();

        if (data != undefined && data != null && data.invoiceSetting != undefined && data.invoiceSetting != null) {
          this.isFreshInvoice = false;
          this.currInvoiceSettingId = data.invoiceSetting.id;

          if (data.invoiceSetting.invoice_type != undefined && data.invoiceSetting.invoice_type != null) {
            if (data.invoiceSetting.invoice_type == 1)
            {
              if (data.invoiceSetting.notes != undefined && data.invoiceSetting.notes != null) {
                // this.invoiceNoteForm.controls['notes'].setValue(data.invoiceSetting.notes);
                this.invNotesBk = this.sanitizer.bypassSecurityTrustHtml(data.invoiceSetting.notes);
                this.notesInvSave = data.invoiceSetting.notes;
              }

              this.prInvEditLoaded = true;
              if (data.invoiceSetting.sr_info_old != undefined && data.invoiceSetting.sr_info_old != null )
              {
                this.prInvData = data.invoiceSetting.sr_info_old;
              }
        
              if (data.invoiceSetting.servicing_agency != undefined && data.invoiceSetting.servicing_agency != null )
              {
                this.srAgencyName = data.invoiceSetting.servicing_agency;
              }
              if (data.invoiceSetting.sr_agency_phone != undefined && data.invoiceSetting.sr_agency_phone != null )
              {
                this.srAgencyPhone = data.invoiceSetting.sr_agency_phone;
              }
              
              if (data.invoiceSetting.fein != undefined && data.invoiceSetting.fein != null )
              {
                this.feinToDisp = data.invoiceSetting.fein;
              }

              if (data.invoiceSetting.rpm_admin_fee != undefined && data.invoiceSetting.rpm_admin_fee != null) {
                this.invoice.metadata.admin_fee = data.invoiceSetting.rpm_admin_fee;
                this.oriAdminFee = data.invoiceSetting.rpm_admin_fee;
              }
              if (data.invoiceSetting.has_first != undefined && data.invoiceSetting.has_first != null && data.invoiceSetting.has_first == 1) {
                this.invoice.ngFirst = true;
              }
              if (data.invoiceSetting.first_invoice != undefined && data.invoiceSetting.first_invoice != null && data.invoiceSetting.first_invoice > 0) {
                this.invoice.metadata.setup_fee = data.invoiceSetting.first_invoice;
                this.oriFirst = data.invoiceSetting.first_invoice;
              }
              if (data.invoiceSetting.has_credit_card != undefined && data.invoiceSetting.has_credit_card != null && data.invoiceSetting.has_credit_card == 1) {
                this.invoice.ngCC = true;
              }
              if (data.invoiceSetting.credit_card != undefined && data.invoiceSetting.credit_card != null && data.invoiceSetting.credit_card > 0) {
                this.invoice.metadata.credit_card_fee = data.invoiceSetting.credit_card;
                this.oricc = data.invoiceSetting.credit_card;
              }

              if (data.invoiceSetting.nsf_new != undefined && data.invoiceSetting.nsf_new != null && data.invoiceSetting.nsf_new == 1) {
                this.invoice.is_nsf = true;
                this.invoice.metadata.nsf_fee = 40;
                if (this.invoice.ngCC) {
                  this.invoice.metadata.nsf_fee = 0;
                }
                if (data.invoiceSetting.nsf_fee != undefined && data.invoiceSetting.nsf_fee != null && data.invoiceSetting.nsf_fee > 0) {
                  this.invoice.metadata.nsf_fee = data.invoiceSetting.nsf_fee;
                  this.orinsf = data.invoiceSetting.nsf_fee;
                }
                if (data.invoiceSetting.retry_payment_fee != undefined && data.invoiceSetting.retry_payment_fee != null && data.invoiceSetting.retry_payment_fee > 0) {
                  this.invoice.metadata.retry_payment_fee = data.invoiceSetting.retry_payment_fee;
                  this.orirp = data.invoiceSetting.retry_payment_fee;
                }
              }

              if (data.invoiceSetting.invoice_status != undefined && data.invoiceSetting.invoice_status != null) {
                this.invoiceStatus = data.invoiceSetting.invoice_status;
                if (this.invoiceStatus == 'Queue') {
                  this.isQueueStatus = true;
                }
              }

              if (data.invoiceSetting.is_void != undefined && data.invoiceSetting.is_void != null) {
                this.is_void = data.invoiceSetting.is_void;
              }
              if (data.invoiceSetting.is_nsf != undefined && data.invoiceSetting.is_nsf != null) {
                this.is_nsf = data.invoiceSetting.is_nsf;
              }
              if (data.invoiceSetting.is_revised != undefined && data.invoiceSetting.is_revised != null) {
                this.is_revised = data.invoiceSetting.is_revised;
              }

              if (data.invoiceSetting.custom_inv_number != undefined && data.invoiceSetting.custom_inv_number != null) {
                this.custom_inv_number = data.invoiceSetting.custom_inv_number;

                this.invSysNumber = this.id + '-' + data.invoiceSetting.custom_inv_number;
                if (data.invoiceSetting.custom_inv_number.charAt(0) == "N") {
                  this.save_dates_nsf = true;
                }
              }

              if (data.invoiceSetting.ori_cust_inv_number != undefined && data.invoiceSetting.ori_cust_inv_number != null) {
                this.ori_cust_inv_number = data.invoiceSetting.ori_cust_inv_number;
                if (data.invoiceSetting.ori_cust_inv_number != "") {
                  this.isCorRev = 1;
                }
              }
              if (data.invoiceSetting.ori_nsf_inv_number != undefined && data.invoiceSetting.ori_nsf_inv_number != null) {
                this.ori_nsf_inv_number = data.invoiceSetting.ori_nsf_inv_number;
              }
              if (data.invoiceSetting.ori_rev_inv_number != undefined && data.invoiceSetting.ori_rev_inv_number != null) {
                this.ori_rev_inv_number = data.invoiceSetting.ori_rev_inv_number;
                if (data.invoiceSetting.ori_rev_inv_number != "") {
                  this.isCorRev = 2;
                  this.invoice.is_revision = true;
                  if( data.invoiceSetting.originalInvTotal != undefined && data.invoiceSetting.originalInvTotal != null )
                  {
                    this.invoice.loadOriginalTotal = data.invoiceSetting.originalInvTotal;
                  }
                }
              }
              if (data.invoiceSetting.ori_inv_number != undefined && data.invoiceSetting.ori_inv_number != null) {
                this.ori_inv_number = data.invoiceSetting.ori_inv_number;
              }

              if (data.invoiceSetting.queue_manual_type != undefined && data.invoiceSetting.queue_manual_type != null) {
                this.queue_manual_type = data.invoiceSetting.queue_manual_type;
              }

              this.payrollTotal = 0;
              if( data.invoiceSetting.prem_payroll_total != undefined && data.invoiceSetting.prem_payroll_total != null )
              {
                  this.payrollTotal = data.invoiceSetting.prem_payroll_total;

                  var tv = parseFloat(data.invoiceSetting.prem_payroll_total.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                  // var tv = parseFloat(data.invoiceSetting.prem_payroll_total.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                  var parts = tv.toString().split(".");
                  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  var figure = parts.join(".");

                  this.showPayrollTotal = figure;
              }

              this.invoice.metadata.invoice_type = "premium_invoice";
              if( data.invoiceSetting.is_pay_reporting != undefined && data.invoiceSetting.is_pay_reporting != null )
              {
                this.is_pay_reporting = data.invoiceSetting.is_pay_reporting;
              }
              if( this.is_pay_reporting == 1 )
              {
                this.invoice.metadata.invoice_type = "payroll_reporting";
              }


              var dtArr4 = data.invoiceSetting.check_date.split(" "); 
              if( typeof dtArr4[0] !== 'undefined' )
              {
                var dtArr = dtArr4[0].split("-"); 
                if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
                {
                  var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                  this.in_check_date = nprPDD;
                }
              }

              var dtArr5 = data.invoiceSetting.payroll_end_date.split(" "); 
              if( typeof dtArr5[0] !== 'undefined' )
              {
                var dtArr = dtArr5[0].split("-"); 
                if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
                {
                  var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                  this.in_payroll_end_date = nprPDD;
                }
              }

              if( data.invoiceSetting.report_date != undefined && data.invoiceSetting.report_date != null && data.invoiceSetting.report_date != "" )
              {
                var dtArr3 = data.invoiceSetting.report_date.split(" "); 
                if( typeof dtArr3[0] !== 'undefined' )
                {
                  var dtArr = dtArr3[0].split("-"); 
                  if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
                  {
                    var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                    this.in_report_date = nprPDD;
                    this.db_report_date = nprPDD;
                  }
                }
              }

              if( this.is_pay_reporting == 0 && data.invoiceSetting.ach_withdrawal_date != undefined && data.invoiceSetting.ach_withdrawal_date != null && data.invoiceSetting.ach_withdrawal_date != "" )
              {
                var dtArr4 = data.invoiceSetting.ach_withdrawal_date.split(" "); 
                if( typeof dtArr4[0] !== 'undefined' )
                {
                  var dtArr = dtArr4[0].split("-"); 
                  if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
                  {
                    var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                    this.in_ach_withdrawal_date = nprPDD;
                    this.db_withdrawal_date = nprPDD;
                  }
                }
              }

            if( data.invoiceSetting.is_uploaded != undefined && data.invoiceSetting.is_uploaded != null && data.invoiceSetting.is_uploaded == 1 )
            {
              this.isUploadedInv = true;
            }
          
              if( this.isCorRev == 1 || this.isCorRev == 2 )
              {
                this.premInvFormA.controls['report_date'].setValue(this.in_report_date);
                if( this.is_pay_reporting == 0 )
                {
                  this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.in_ach_withdrawal_date);
                }
              }
              if( this.save_dates_nsf )
              {
                this.premInvFormA.controls['report_date'].setValue(this.in_report_date);
                if( this.is_pay_reporting == 0 )
                {
                  this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.in_ach_withdrawal_date);
                }
              }
              
              if( this.isUploadedInv && this.in_report_date != undefined && this.in_report_date != null && this.in_report_date != "" )
              {
                this.premInvFormA.controls['report_date'].setValue(this.in_report_date);
              }
              if( this.isUploadedInv && this.in_ach_withdrawal_date != undefined && this.in_ach_withdrawal_date != null && this.in_ach_withdrawal_date != "" )
              {
                this.premInvFormB.controls['ach_withdrawal_date'].setValue(this.in_ach_withdrawal_date);
              }
          
              this.datesLoaded = true;

              if (data != undefined && data != null && data.policyToConsider != undefined && data.policyToConsider != null) {
                this.requiredPolicy = data.policyToConsider;
              }

              if (data.ratesNeededArr != undefined && data.ratesNeededArr != null) {
                this.ratesNeededArr = data.ratesNeededArr;
                // console.log(data.ratesNeededArr);

                this.originalRatesArr = JSON.parse(JSON.stringify(this.ratesNeededArr));

                this.wagesCopyBtnShow = false;
                var firstCCFound = false;
                var secondCCFound = false;
                var rateRowIndex:number = -1;
                this.dataObjWagesCopy = {};
                
                for (const rate of this.ratesNeededArr) {
                  this.invoice.detail.push(new InvoiceDetail({
                    id: rate.id,
                    location: rate.location_name,
                    state: rate.state_id,
                    class_code: rate.class_code,
                    class_code_description: rate.class_code_description,
                    gross_wages: rate.gross_wages,
                    overtime_wages: rate.overtime_wages,
                    overtime_premium: 0,
                    workers_comp_wages: 0,
                    net_rate: rate.net_rate,
                    premium: 0,
                    gross_calculation:rate.gross_calculation,
                    overtime_calculation:rate.overtime_calculation
                  }));

                  if( rate != undefined && rate != null && rate.class_code != undefined && rate.class_code != null )
                  {
                    ++rateRowIndex;
                    if( rate.class_code == "7431" )
                    {
                      firstCCFound = true;

                      if( rate.state_id != undefined && rate.state_id != null )
                      {
                        if( this.wagesCopyObjsArr != undefined )
                        {
                          if( this.wagesCopyObjsArr[rate.state_id] == undefined )
                          {
                            var dataObjWagesCopy:any = {};
                            dataObjWagesCopy.fromObj = {};
                            dataObjWagesCopy.toObj = {};
                            this.wagesCopyObjsArr[rate.state_id] = dataObjWagesCopy;
                            this.statesArr.push( rate.state_id );
                          }
                        }
                      }
    
                      if( rate.state_id != undefined && rate.state_id != null && this.wagesCopyObjsArr[rate.state_id] != undefined && this.wagesCopyObjsArr[rate.state_id] != null )
                      {
                        var dataObjWagesCopy:any = this.wagesCopyObjsArr[rate.state_id];
                        dataObjWagesCopy.fromObj.ri = rateRowIndex;
                        dataObjWagesCopy.fromObj.item = {};
                        if( this.invoice != undefined && this.invoice.detail != undefined && typeof this.invoice.detail[rateRowIndex] !== undefined )
                        {
                          dataObjWagesCopy.fromObj.item = this.invoice.detail[rateRowIndex];
                        }
                        if( rate.state_id == "AZ" )
                        {
                          this.wagesCopyObjsArr[rate.state_id] = dataObjWagesCopy;
                        }
                      }
                    }
                    else if( rate.class_code == "7453" )
                    {
                      secondCCFound = true;

                      if( rate.state_id != undefined && rate.state_id != null )
                      {
                        if( this.wagesCopyObjsArr != undefined )
                        {
                          if( this.wagesCopyObjsArr[rate.state_id] == undefined )
                          {
                            var dataObjWagesCopy:any = {};
                            dataObjWagesCopy.fromObj = {};
                            dataObjWagesCopy.toObj = {};
                            this.wagesCopyObjsArr[rate.state_id] = dataObjWagesCopy;
                            this.statesArr.push( rate.state_id );
                          }
                        }
                      }
    
                      if( rate.state_id != undefined && rate.state_id != null && this.wagesCopyObjsArr[rate.state_id] != undefined && this.wagesCopyObjsArr[rate.state_id] != null )
                      {
                        var dataObjWagesCopy:any = this.wagesCopyObjsArr[rate.state_id];
                        dataObjWagesCopy.toObj.ri = rateRowIndex;
                        dataObjWagesCopy.toObj.item = {};
                        if( this.invoice != undefined && this.invoice.detail != undefined && typeof this.invoice.detail[rateRowIndex] !== undefined )
                        {
                          dataObjWagesCopy.toObj.item = this.invoice.detail[rateRowIndex];
                        }
                        this.wagesCopyObjsArr[rate.state_id] = dataObjWagesCopy;
                      }
                    }
                  }
                }

                if( firstCCFound && secondCCFound )
                {
                  this.wagesCopyBtnShow = true;
                }
                
                this.invoice.editLoading = true;
                // Update Totals
                if( this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf )
                {
                  this.invoice.is_rpf_editable = true;
                }
                var edit_retry_new = 0;
                if( data.invoiceSetting.edit_retry_new != undefined && data.invoiceSetting.edit_retry_new != null )
                {
                  edit_retry_new = data.invoiceSetting.edit_retry_new;
                }
                if( this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf && edit_retry_new == 0 )
                {
                  this.invoice.edit_retry_new = true;
                }
                this.invoice.calcTotals();
                if( this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf && edit_retry_new == 0 )
                {
                  this.orirp = this.invoice.metadata.retry_payment_fee;
                }
                if( data.invoiceSetting.prem_old_new != undefined && data.invoiceSetting.prem_old_new != null && data.invoiceSetting.prem_old_new == 0 )
                {
                  if( this.isCorRev > 0 || this.invoice.is_nsf )
                  {
                    this.payrollTotal = parseFloat((this.getTotalGW()).toFixed(2));
                    var tv = parseFloat(this.payrollTotal.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                    var parts = tv.toString().split(".");
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    var figure = parts.join(".");
                
                    this.showPayrollTotal = figure;
                  }
                }
                if( ((parseFloat((this.getTotalGW()).toFixed(2))) != this.payrollTotal) && (this.payrollTotal != 2) )
                {
                  this.piPTMismatch = true;
                }
                if( data.invoiceSetting.is_uploaded != undefined && data.invoiceSetting.is_uploaded != null && data.invoiceSetting.is_uploaded == 1 )
                {
                  this.piPTMismatch = false;
                }
              }

              this.oriPT = this.payrollTotal;

              this.spinnerService.hide();
            }
            else if (data.invoiceSetting.invoice_type == 2 || data.invoiceSetting.invoice_type == 5)
            {
              this.invoice.metadata.invoice_type = "policy_fee";
              this.pfData = data;
              this.pfLoaded = true;

              if (data.invoiceSetting.invoice_type == 5){
                this.miscType = 1;
              }

              this.tc_types = [
                { label: 'ACH', value: 1 },
                { label: 'Paid by Check', value: 2 }
              ];

              this.bill_to_types = [
                { label: 'Select', value: '' },
                { label: 'Broker', value: 1 },
                { label: 'PR Company', value: 2 },
                { label: 'Company', value: 3 },
                { label: 'Agent', value: 4 }
              ];
              this.policy_fee_form.controls['bill_to_type'].setValue('');

              this.actionDates.push({
                label: 'Select',
                value: ''
              });
              this.policy_fee_form.controls['pf_pol_action_id'].setValue('');

              this.qnts = [
                { label: 'Select', value: '' },
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 },
                { label: '6', value: 6 },
                { label: '7', value: 7 },
                { label: '8', value: 8 },
                { label: '9', value: 9 },
                { label: '10', value: 10 }
              ];
              // this.descSrvcs = [
              //   { label: 'Select', value: '' },
              //   { label: 'Monthly Processing Fee', value: 'Monthly Processing Fee' },
              //   { label: 'Initial Setup Fee', value: 'Initial Setup Fee' },
              //   { label: 'Admin Fee', value: 'Admin Fee' },
              //   { label: 'Audit Review Fee', value: 'Audit Review Fee' },
              //   { label: 'Renewal Expense Constant', value: 'Renewal Expense Constant' },
              //   { label: 'Blanket Waiver of Subrogation', value: 'Blanket Waiver of Subrogation' },
              //   { label: 'Waiver of Subrogation', value: 'Waiver of Subrogation' },
              //   { label: 'Down-Payment', value: 'Down-Payment' },
              //   { label: 'Deposit', value: 'Deposit' },
              //   { label: 'Down-Payment/Deposit', value: 'Down-Payment/Deposit' },
              //   { label: 'FIGA Charge', value: 'FIGA Charge' },
              //   { label: 'Other', value: 'Other' }
              // ];

              this.clientService.getAllBrokers().subscribe(res => {
                this.brokers = res;
              });
            }
            else if (data.invoiceSetting.invoice_type == 3)
            {
              this.invoice.metadata.invoice_type = "npr";

              // this.spinnerService.hide();
              // this.spinnerService.show();

              this.NPRPolicies = [];
              this.NPRPolicies.push({
                label: 'Select',
                value: ''
              });

              setTimeout(() =>
              {
                if (data.invoiceSetting.pol_holder_name != undefined && data.invoiceSetting.pol_holder_name != null)
                {
                  this.compNameToDisp = data.invoiceSetting.pol_holder_name;
                }
                if (data.invoiceSetting.pf_email != undefined && data.invoiceSetting.pf_email != null)
                {
                  this.clientEmailToDisp = data.invoiceSetting.pf_email;
                }
                if (data.invoiceSetting.fein != undefined && data.invoiceSetting.fein != null )
                {
                  this.feinToDisp = data.invoiceSetting.fein;
                }


                if (data.invoiceSetting.policy_id != undefined && data.invoiceSetting.policy_id != null)
                {
                  this.npr_form.controls['policy_id'].setValue(data.invoiceSetting.policy_id);
                  this.oriPolId = data.invoiceSetting.policy_id;

                  var pf_pol_action_id = '';
                  if (data.invoiceSetting.pf_pol_action_id != undefined && data.invoiceSetting.pf_pol_action_id != null)
                  {
                    pf_pol_action_id = data.invoiceSetting.pf_pol_action_id;
                    this.oriActionId = data.invoiceSetting.pf_pol_action_id;
                  }

                  var polNum = '';
                  if (data.invoiceSetting.pf_npr_change != undefined && data.invoiceSetting.pf_npr_change != null && data.invoiceSetting.pf_npr_change == 0 )
                  {
                    if (data.invoiceSetting.policy_number != undefined && data.invoiceSetting.policy_number != null && data.invoiceSetting.policy_number.trim() != '' )
                    {
                      polNum = data.invoiceSetting.policy_number.trim();
                    }
                  }

                  this.selIdNPRPF = data.trgAction;

                  this.setPolActions(2, pf_pol_action_id,data.invoiceSetting.policy_id,polNum);
                }

                // this.nprTopTableDisp = true;

                if (data.invoiceSetting.queue_manual_type != undefined && data.invoiceSetting.queue_manual_type != null) {
                  this.queue_manual_type = data.invoiceSetting.queue_manual_type;
                }

                if (data.invoiceSetting.carrier_name != undefined && data.invoiceSetting.carrier_name != null) {
                  this.nprCarrierName = data.invoiceSetting.carrier_name;
                }

                if (data.invoiceSetting.npr_payroll_due_date != undefined && data.invoiceSetting.npr_payroll_due_date != null)
                {
                  var dtArr1 = data.invoiceSetting.npr_payroll_due_date.split(" "); 
                  if( typeof dtArr1[0] !== 'undefined' )
                  {
                    var dtArr = dtArr1[0].split("-"); 
                    if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
                    {
                      var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                      this.npr_form.controls['payroll_due_date'].setValue(nprPDD);
                      this.db_payroll_due_date = nprPDD;
                    }
                  }
                }

                if (data.invoiceSetting.has_credit_card != undefined && data.invoiceSetting.has_credit_card != null && data.invoiceSetting.has_credit_card == 1)
                {
                  this.invoice.nprCC = true;
                  this.oriNPRCc = true;
                }

                if (data.invoiceSetting.credit_card != undefined && data.invoiceSetting.credit_card != null && data.invoiceSetting.credit_card > 0)
                {
                  this.invoice.metadata.credit_card_fee = data.invoiceSetting.credit_card;
                  this.oricc = data.invoiceSetting.credit_card;
                }
                
                if( data.invoiceSetting.nsf_new != undefined && data.invoiceSetting.nsf_new != null && data.invoiceSetting.nsf_new == 1 ) 
                {
                  this.invoice.is_nsf = true;
                  this.isCorRev = 3;
                  this.invoice.metadata.nsf_fee = 40;
                  if (this.invoice.nprCC)
                  {
                    this.invoice.metadata.nsf_fee = 0;
                  }

                  if (data.invoiceSetting.nsf_fee != undefined && data.invoiceSetting.nsf_fee != null && data.invoiceSetting.nsf_fee > 0)
                  {
                    this.invoice.metadata.nsf_fee = data.invoiceSetting.nsf_fee;
                    this.orinsf = data.invoiceSetting.nsf_fee;
                  }
                  if (data.invoiceSetting.retry_payment_fee != undefined && data.invoiceSetting.retry_payment_fee != null && data.invoiceSetting.retry_payment_fee > 0)
                  {
                    this.invoice.metadata.retry_payment_fee = data.invoiceSetting.retry_payment_fee;
                    this.orirp = data.invoiceSetting.retry_payment_fee;
                  }

                  var dtArr2 = data.invoiceSetting.report_date.split(" "); 
                  if( typeof dtArr2[0] !== 'undefined' )
                  {
                    var dtArr = dtArr2[0].split("-"); 
                    if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
                    {
                      var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                      this.npr_form.controls['report_date'].setValue(nprPDD);
                      this.in_report_date = nprPDD;
                      this.db_report_dt = nprPDD;
                    }
                  }

                  var dtArr3 = data.invoiceSetting.ach_withdrawal_date.split(" "); 
                  if( typeof dtArr3[0] !== 'undefined' )
                  {
                    var dtArr = dtArr3[0].split("-"); 
                    if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
                    {
                      var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                      this.npr_form.controls['ach_withdrawal_date'].setValue(nprPDD);
                      this.in_ach_withdrawal_date = nprPDD;
                      this.db_ach_dt = nprPDD;
                    }
                  }

                  this.datesLoaded = true;
                }
                if( data.invoiceSetting.nsf_new != undefined && data.invoiceSetting.nsf_new != null && data.invoiceSetting.nsf_new == 0 ) 
                {
                      /*
                      this.invoice.metadata.report_date = new Date(data.invoiceSetting.report_date);
                      this.invoice.metadata.ach_withdrawal_date = new Date(data.invoiceSetting.ach_withdrawal_date);
                      this.in_report_date = data.invoiceSetting.report_date.toString();
                      this.in_ach_withdrawal_date = data.invoiceSetting.ach_withdrawal_date.toString();
                      */

                      var dtArr2 = data.invoiceSetting.report_date.split(" "); 
                      if( typeof dtArr2[0] !== 'undefined' )
                      {
                        var dtArr = dtArr2[0].split("-"); 
                        if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
                        {
                          var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                          this.in_report_date = nprPDD;
                        }
                      }

                      var dtArr3 = data.invoiceSetting.ach_withdrawal_date.split(" "); 
                      if( typeof dtArr3[0] !== 'undefined' )
                      {
                        var dtArr = dtArr3[0].split("-"); 
                        if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
                        {
                          var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                          this.in_ach_withdrawal_date = nprPDD;
                        }
                      }

                      this.datesLoaded = true;
                }

                if( this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf )
                {
                  this.invoice.is_rpf_editable = true;
                }
    
                if (data.invoiceSetting.npr_invoice_total != undefined && data.invoiceSetting.npr_invoice_total != null)
                {
                  this.nprInvoiceTotal = data.invoiceSetting.npr_invoice_total;
                  this.nprOverallTotal += data.invoiceSetting.npr_invoice_total;

                  var edit_retry_new = 0;
                  if( data.invoiceSetting.edit_retry_new != undefined && data.invoiceSetting.edit_retry_new != null )
                  {
                    edit_retry_new = data.invoiceSetting.edit_retry_new;
                  }
                  if( this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf && edit_retry_new == 0 )
                  {
                    this.invoice.metadata.retry_payment_fee = this.nprInvoiceTotal;
                    this.orirp = this.invoice.metadata.retry_payment_fee;
                  }
                      
                  this.nprFlagChange(0);
                }
                
                if (data.invoiceSetting.invoice_status != undefined && data.invoiceSetting.invoice_status != null) {
                  this.invoiceStatus = data.invoiceSetting.invoice_status;
                  if (this.invoiceStatus == 'Queue') {
                    this.isQueueStatus = true;
                  }
                }

                if (data.invoiceSetting.custom_inv_number != undefined && data.invoiceSetting.custom_inv_number != null) {
                  this.custom_inv_number = data.invoiceSetting.custom_inv_number;

                  this.invSysNumber = this.id + '-' + data.invoiceSetting.custom_inv_number;
                }

                if (data.invoiceSetting.notes != undefined && data.invoiceSetting.notes != null) {
                  // this.invoiceNoteFormNPR.controls['notes'].setValue(data.invoiceSetting.notes);
                  this.invNotesBk = this.sanitizer.bypassSecurityTrustHtml(data.invoiceSetting.notes);
                  this.notesInvSave = data.invoiceSetting.notes;
                }
                this.spinnerService.hide();
              }, 6000);
            }
          }
        }
      });
    }

    this.spinnerService.show();
    // Load client
    this.clientService.getClient(this.id).subscribe(res => {
      this.client = res;

      //Newlly code
      if (this.invoice.metadata.invoice_type == "policy_fee" && this.isFreshInvoice)
      {
        this.pfRates = res.invoice_masters_data;
        if(this.pfRates.length != 0){
          this.newRatesCount = this.pfRates[(this.pfRates.length) - 1].uniqueIdentifier;

          for (var i = 0; i < this.pfRates.length; i++) {
              var obj = this.pfRates[i];
              this.pfInvoiceTotal += (obj.quantity * obj.price);
              this.pfInvoiceTotal = parseFloat(this.pfInvoiceTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
          }
          this.pfFlagChange(2);
        }
      }
      //Added

      this.clientService.getDispServerTime().subscribe(res =>
        {
          if( res != undefined && res != null && typeof res[0] !== 'undefined' )
          
          this.serverYr = res[0];
          console.log(this.serverYr);
        });
  
      if( (this.client != undefined && this.client != null) && this.invoice.metadata.invoice_type != "payroll_reporting" )
      {
        if( this.client.put_in_fax_q != undefined && this.client.put_in_fax_q != null )
        {
          this.fax_queued = this.client.put_in_fax_q;
        }
        this.text_queued = 0;
        if( this.client.text_q_place != undefined && this.client.text_q_place != null && this.client.text_q_place == 1 )
        {
          this.text_queued = 1;
        }
        if( this.client.primary_phone_status != undefined && this.client.primary_phone_status != null && this.client.primary_phone_status == 1 )
        {
          this.text_queued = 1;
        }
        if( this.text_queued == 0 )
        {
            this.spinnerService.show();
            this.clientService.getContactsClients(this.id).subscribe(res =>
            {
              var contactsInfo = res;
              if( contactsInfo != undefined && contactsInfo != null )
              {
                  contactsInfo.forEach((contactInfo) => {
                    if( contactInfo != undefined && contactInfo != null && contactInfo.phone_status == 1 )
                    {
                      this.text_queued = 1;
                    }
                  });
              }
              if( this.invoice.metadata.invoice_type == "premium_invoice" || this.invoice.metadata.invoice_type == "payroll_reporting" )
              {
                this.spinnerService.hide();
              }
            });
        }

        if( this.isFreshInvoice )
        {
          if( this.client.enable_def_cc != undefined && this.client.enable_def_cc != null && this.client.enable_def_cc == 1 )
          {
            if( this.invoice.metadata.invoice_type == "premium_invoice" || this.invoice.metadata.invoice_type == "payroll_reporting" )
            {
                this.invoice.ngCC = true;
                this.onFlagChange(2);
                this.default_credit_card = 1;
            }
            else if( this.invoice.metadata.invoice_type == "policy_fee" )
            {
                this.invoice.pfCC = true;
                this.pfFlagChange(2);
                this.default_credit_card = 1;
            }
            else if( this.invoice.metadata.invoice_type == "npr" )
            {
                this.invoice.nprCC = true;
                this.nprFlagChange(2);
                this.default_credit_card = 1;
            }
          }
        }
      }

      if( (this.client != undefined && this.client != null) && this.isFreshInvoice )
      {
        if( this.client.enable_def_cc != undefined && this.client.enable_def_cc != null && this.client.enable_def_cc == 1 )
        {
          if( this.invoice.metadata.invoice_type == "payroll_reporting" )
          {
              this.invoice.ngCC = true;
              this.onFlagChange(2);
              this.default_credit_card = 1;
          }
        }
      }
    
     // console.log(this.client.agency_data);
      // alert();
      // console.log(this.client.policies[this.client.policies.length-1]);

      if( this.isFreshInvoice && (this.invoice.metadata.invoice_type == "premium_invoice" || this.invoice.metadata.invoice_type == "payroll_reporting") )
      {
        if( this.client != undefined && this.client != null )
        {
          if( this.client.fein != undefined && this.client.fein != null )
          {
            this.feinToDisp = this.client.fein;
          }
          if( this.client.agency_data != undefined && this.client.agency_data != null && this.client.agency_data.agency_name != undefined && this.client.agency_data.agency_name != null )
          {
            this.srAgencyName = this.client.agency_data.agency_name;
          }
          if( this.client.agency_data != undefined && this.client.agency_data != null && this.client.agency_data.agency_main_phone != undefined && this.client.agency_data.agency_main_phone != null )
          {
            this.srAgencyPhone = this.client.agency_data.agency_main_phone;
          }
        }
      }

      if( ! this.isFreshInvoice && (this.invoice.metadata.invoice_type == "premium_invoice" || this.invoice.metadata.invoice_type == "payroll_reporting") )
      {
        setTimeout(() => {
          if( this.prInvEditLoaded && this.prInvData != undefined && this.prInvData != null && this.prInvData == 0 )
          {
            if( this.client != undefined && this.client != null && this.client.agency_data != undefined && this.client.agency_data != null && this.client.agency_data.agency_name != undefined && this.client.agency_data.agency_name != null )
            {
              this.srAgencyName = this.client.agency_data.agency_name;
            }
            if( this.client != undefined && this.client != null && this.client.agency_data != undefined && this.client.agency_data != null && this.client.agency_data.agency_main_phone != undefined && this.client.agency_data.agency_main_phone != null )
            {
              this.srAgencyPhone = this.client.agency_data.agency_main_phone;
            }
          }
        }, 2000);
      }

      if (this.isFreshInvoice && (this.invoice.metadata.invoice_type == "policy_fee" || this.invoice.metadata.invoice_type == "npr") && this.client != undefined && this.client != null)
      {
        if (this.client.policies != undefined && this.client.policies != null)
        {
          if (this.invoice.metadata.invoice_type == "policy_fee")
          {
            this.PFPolicies = [];
            this.PFPolicies.push({
              label: 'Select',
              value: ''
            });

            if( this.client.company_name != undefined && this.client.company_name != null )
            {
              this.compNameToDisp = this.client.company_name;
            }
            if( this.client.fein != undefined && this.client.fein != null )
            {
              this.feinToDisp = this.client.fein;
            }

            if( this.client.company_name != undefined && this.client.company_name != null )
            {
              this.pfShowName = this.client.company_name;
            }
            if( this.client.address != undefined && this.client.address != null )
            {
              this.pfShowAddr = this.client.address;
            }
            if( this.client.city != undefined && this.client.city != null )
            {
              this.pfShowCity = this.client.city;
            }
            if( this.client.state != undefined && this.client.state != null )
            {
              this.pfShowSt = this.client.state;
            }
            if( this.client.zip != undefined && this.client.zip != null )
            {
              this.pfShowZip = this.client.zip;
            }
            if( this.client.phone != undefined && this.client.phone != null )
            {
              this.pfShowPh = this.client.phone;
            }
            if( this.client.email != undefined && this.client.email != null )
            {
              this.pfShowEmail = this.client.email;
            }
            if( this.client.id != undefined && this.client.id != null )
            {
              this.pfBillToId = this.client.id;
            }
            
            this.PFAgentEmails = [];
            this.PFAgentEmails.push({
              label: 'Select',
              value: ''
            });

            this.fillPFAgentEmails( this.client.agency_data,this.client.agent,this.client.agent2 );
          }
          else if (this.invoice.metadata.invoice_type == "npr")
          {
            if( this.client.company_name != undefined && this.client.company_name != null )
            {
              this.compNameToDisp = this.client.company_name;
            }
            if( this.client.fein != undefined && this.client.fein != null )
            {
              this.feinToDisp = this.client.fein;
            }
            if( this.client.email != undefined && this.client.email != null )
            {
              this.clientEmailToDisp = this.client.email;
            }

            this.NPRPolicies = [];
            this.NPRPolicies.push({
              label: 'Select',
              value: ''
            });
          }
          var selectedPolicyId: any = '';
          for (let policy of this.client.policies)
          {
            if (policy != undefined && policy !== null && policy.id != undefined && policy.id !== null && policy.policy_number != undefined && policy.policy_number !== null)
            {
              if( policy.considerPolicy != undefined && policy.considerPolicy == 1 )
              {
                this.currPol = {};
                this.currPol.value = policy.id;
                this.currPol.label = policy.policy_number;
                if (this.invoice.metadata.invoice_type == "policy_fee")
                {
                  this.PFPolicies.push(this.currPol);
                }
                else if (this.invoice.metadata.invoice_type == "npr")
                {
                  this.NPRPolicies.push(this.currPol);
                }
                if (policy.effective_date != undefined && policy.effective_date != null)
                {
                  var todayDateObj1 = new Date();
                  var effDateObj = new Date(policy.effective_date);
                  if (todayDateObj1.getMonth() + 1 == effDateObj.getMonth() + 1)
                  {
                    selectedPolicyId = policy.id;
                  }
                }
              }
            }
          }

          // Policy Fee Select Default Policy.
          if (this.invoice.metadata.invoice_type == "policy_fee")
          {
            this.policy_fee_form.controls['policy_id'].setValue(selectedPolicyId);
            this.setPolActions(3, '',0);
          }
          else if (this.invoice.metadata.invoice_type == "npr")
          {

            this.requiredCheckDate = this.payrollDtStr;

            this.invPolPostObj.requiredCheckDate = this.requiredCheckDate.toString();
            this.invPolPostObj.clientId = this.id;
            this.policyService.getPFNPRPolicy(this.invPolPostObj).subscribe(data =>
            {
              if (data != undefined && data != null && (data.policyToConsider == undefined || data.policyToConsider == null))
              {
                alert("No matching policy found.");
              }
              else if (data != undefined && data != null && (data.policyToConsider == undefined || data.policyToConsider == null))
              {
                if(  (data.polFoundCancld != undefined && data.polFoundCancld != null) || (data.polEndDateCrossed != undefined && data.polEndDateCrossed != null) )
                {
                  alert(data.message);
                }
              }
              else if( data != undefined && data != null && data.trgAction != undefined && data.trgAction != null && data.trgAction.id != undefined && data.trgAction.id != null )
              {
                this.selIdNPRPF = data.trgAction;
                this.npr_form.controls['policy_id'].setValue('');
                this.setPolActions(3, '',0);
                this.nprTopTableDisp = true;
              }
            });
          }
        }
        this.spinnerService.hide();
      }
      else if (!this.isFreshInvoice && this.invoice.metadata.invoice_type == "policy_fee" && this.client != undefined && this.client != null)
      {
        this.spinnerService.hide();
        this.spinnerService.show();

        setTimeout(() =>
        {
          if (this.pfData != undefined && this.pfData != null && this.pfLoaded)
          {
            if (this.pfData.invoiceSetting.bill_to_type != undefined && this.pfData.invoiceSetting.bill_to_type != null)
            {
              this.policy_fee_form.controls['bill_to_type'].setValue(this.pfData.invoiceSetting.bill_to_type);
              this.oriBillToType = this.pfData.invoiceSetting.bill_to_type;
            }
            if (this.pfData.invoiceSetting.bill_to_type != undefined && this.pfData.invoiceSetting.bill_to_type != null && this.pfData.invoiceSetting.bill_to_id != undefined && this.pfData.invoiceSetting.bill_to_id != null)
            {
              this.policy_fee_form.controls['bill_to_id'].setValue(this.pfData.invoiceSetting.bill_to_id);
              this.oriBillToId = this.pfData.invoiceSetting.bill_to_id;

              this.pfShowName='';
              this.pfShowAddr='';
              this.pfShowCity='';
              this.pfShowSt='';
              this.pfShowZip=0;
              this.pfShowPh='';
              this.pfShowEmail='';
              this.pfBillToId=0;
          
              if (this.pfData.invoiceSetting.bill_to_type == 1 )
              {
                if (this.pfData.invoiceSetting.bill_to_id != undefined && this.pfData.invoiceSetting.bill_to_id != null)
                {
                  this.pfBillToId = this.pfData.invoiceSetting.bill_to_id;
                }
                if (this.pfData.invoiceSetting.pf_comp_name != undefined && this.pfData.invoiceSetting.pf_comp_name != null)
                {
                  this.pfShowName = this.pfData.invoiceSetting.pf_comp_name;
                }
                if (this.pfData.invoiceSetting.pf_address != undefined && this.pfData.invoiceSetting.pf_address != null)
                {
                  this.pfShowAddr = this.pfData.invoiceSetting.pf_address;
                }
                if (this.pfData.invoiceSetting.pf_city != undefined && this.pfData.invoiceSetting.pf_city != null)
                {
                  this.pfShowCity = this.pfData.invoiceSetting.pf_city;
                }
                if (this.pfData.invoiceSetting.pf_state != undefined && this.pfData.invoiceSetting.pf_state != null)
                {
                  this.pfShowSt = this.pfData.invoiceSetting.pf_state;
                }
                if (this.pfData.invoiceSetting.pf_zip != undefined && this.pfData.invoiceSetting.pf_zip != null)
                {
                  this.pfShowZip = this.pfData.invoiceSetting.pf_zip;
                }
                if (this.pfData.invoiceSetting.pf_phone != undefined && this.pfData.invoiceSetting.pf_phone != null)
                {
                  this.pfShowPh = this.pfData.invoiceSetting.pf_phone;
                }
                if (this.pfData.invoiceSetting.pf_email != undefined && this.pfData.invoiceSetting.pf_email != null)
                {
                  this.pfShowEmail = this.pfData.invoiceSetting.pf_email;
                }
              }
              else if (this.pfData.invoiceSetting.bill_to_type == 2 )
              {
                if (this.pfData.invoiceSetting.bill_to_id != undefined && this.pfData.invoiceSetting.bill_to_id != null)
                {
                  this.pfBillToId = this.pfData.invoiceSetting.bill_to_id;
                }
                if (this.pfData.invoiceSetting.pf_comp_name != undefined && this.pfData.invoiceSetting.pf_comp_name != null)
                {
                  this.pfShowName = this.pfData.invoiceSetting.pf_comp_name;
                }
                if (this.pfData.invoiceSetting.pf_phone != undefined && this.pfData.invoiceSetting.pf_phone != null)
                {
                  this.pfShowPh = this.pfData.invoiceSetting.pf_phone;
                }
                if (this.pfData.invoiceSetting.pf_email != undefined && this.pfData.invoiceSetting.pf_email != null)
                {
                  this.pfShowEmail = this.pfData.invoiceSetting.pf_email;
                }
              }
              else if (this.pfData.invoiceSetting.bill_to_type == 3 )
              {
                if (this.pfData.invoiceSetting.bill_to_id != undefined && this.pfData.invoiceSetting.bill_to_id != null)
                {
                  this.pfBillToId = this.pfData.invoiceSetting.bill_to_id;
                }
                if (this.pfData.invoiceSetting.pf_comp_name != undefined && this.pfData.invoiceSetting.pf_comp_name != null)
                {
                  this.pfShowName = this.pfData.invoiceSetting.pf_comp_name;
                }
                if (this.pfData.invoiceSetting.pf_address != undefined && this.pfData.invoiceSetting.pf_address != null)
                {
                  this.pfShowAddr = this.pfData.invoiceSetting.pf_address;
                }
                if (this.pfData.invoiceSetting.pf_city != undefined && this.pfData.invoiceSetting.pf_city != null)
                {
                  this.pfShowCity = this.pfData.invoiceSetting.pf_city;
                }
                if (this.pfData.invoiceSetting.pf_state != undefined && this.pfData.invoiceSetting.pf_state != null)
                {
                  this.pfShowSt = this.pfData.invoiceSetting.pf_state;
                }
                if (this.pfData.invoiceSetting.pf_zip != undefined && this.pfData.invoiceSetting.pf_zip != null)
                {
                  this.pfShowZip = this.pfData.invoiceSetting.pf_zip;
                }
                if (this.pfData.invoiceSetting.pf_phone != undefined && this.pfData.invoiceSetting.pf_phone != null)
                {
                  this.pfShowPh = this.pfData.invoiceSetting.pf_phone;
                }
                if (this.pfData.invoiceSetting.pf_email != undefined && this.pfData.invoiceSetting.pf_email != null)
                {
                  this.pfShowEmail = this.pfData.invoiceSetting.pf_email;
                }
              }
              else if (this.pfData.invoiceSetting.bill_to_type == 4 )
              {
                if (this.pfData.invoiceSetting.bill_to_id != undefined && this.pfData.invoiceSetting.bill_to_id != null)
                {
                  this.pfBillToId = this.pfData.invoiceSetting.bill_to_id;
                }
                if (this.pfData.invoiceSetting.pf_comp_name != undefined && this.pfData.invoiceSetting.pf_comp_name != null)
                {
                  this.pfShowName = this.pfData.invoiceSetting.pf_comp_name;
                }
                if (this.pfData.invoiceSetting.pf_address != undefined && this.pfData.invoiceSetting.pf_address != null)
                {
                  this.pfShowAddr = this.pfData.invoiceSetting.pf_address;
                }
                if (this.pfData.invoiceSetting.pf_city != undefined && this.pfData.invoiceSetting.pf_city != null)
                {
                  this.pfShowCity = this.pfData.invoiceSetting.pf_city;
                }
                if (this.pfData.invoiceSetting.pf_state != undefined && this.pfData.invoiceSetting.pf_state != null)
                {
                  this.pfShowSt = this.pfData.invoiceSetting.pf_state;
                }
                if (this.pfData.invoiceSetting.pf_zip != undefined && this.pfData.invoiceSetting.pf_zip != null)
                {
                  this.pfShowZip = this.pfData.invoiceSetting.pf_zip;
                }
                if (this.pfData.invoiceSetting.pf_phone != undefined && this.pfData.invoiceSetting.pf_phone != null)
                {
                  this.pfShowPh = this.pfData.invoiceSetting.pf_phone;
                }
                if (this.pfData.invoiceSetting.pf_email != undefined && this.pfData.invoiceSetting.pf_email != null)
                {
                  this.pfShowEmail = this.pfData.invoiceSetting.pf_email;
                  this.pfOriAgEmail = this.pfData.invoiceSetting.pf_email;
                }
              }
              this.PFAgentEmails = [];
              this.PFAgentEmails.push({
                label: 'Select',
                value: ''
              });
                this.fillPFAgentEmails( this.client.agency_data,this.client.agent,this.client.agent2 );
            }

            if (this.pfData.invoiceSetting.pol_holder_name != undefined && this.pfData.invoiceSetting.pol_holder_name != null)
            {
              this.compNameToDisp = this.pfData.invoiceSetting.pol_holder_name;
            }
            if( this.pfData.invoiceSetting.fein != undefined && this.pfData.invoiceSetting.fein != null )
            {
              this.feinToDisp = this.pfData.invoiceSetting.fein;
            }

            if (this.pfData.invoiceSetting.tc_type != undefined && this.pfData.invoiceSetting.tc_type != null) {
              this.tc_type = this.pfData.invoiceSetting.tc_type;
              this.oriTCType = this.pfData.invoiceSetting.tc_type;
            }
            if (this.pfData.invoiceSetting.carrier_name != undefined && this.pfData.invoiceSetting.carrier_name != null) {
              this.nprCarrierName = this.pfData.invoiceSetting.carrier_name;
            }

            if (this.pfData.invoiceSetting.policy_id != undefined && this.pfData.invoiceSetting.policy_id != null)
            {
              this.policy_fee_form.controls['policy_id'].setValue(this.pfData.invoiceSetting.policy_id);
              this.oriPolId = this.pfData.invoiceSetting.policy_id;

              var pf_pol_action_id = '';
              if (this.pfData.invoiceSetting.pf_pol_action_id != undefined && this.pfData.invoiceSetting.pf_pol_action_id != null)
              {
                pf_pol_action_id = this.pfData.invoiceSetting.pf_pol_action_id;
                this.oriActionId = this.pfData.invoiceSetting.pf_pol_action_id;
              }

              var polNum = '';
              if (this.pfData.invoiceSetting.pf_npr_change != undefined && this.pfData.invoiceSetting.pf_npr_change != null && this.pfData.invoiceSetting.pf_npr_change == 0 )
              {
                if (this.pfData.invoiceSetting.policy_number != undefined && this.pfData.invoiceSetting.policy_number != null && this.pfData.invoiceSetting.policy_number.trim() != '' )
                {
                  polNum = this.pfData.invoiceSetting.policy_number.trim();
                }
              }
              this.setPolActions(2, pf_pol_action_id,this.pfData.invoiceSetting.policy_id,polNum);
            }

            // this.invoice.metadata.report_date = new Date(this.pfData.invoiceSetting.report_date);
            // this.invoice.metadata.ach_withdrawal_date = new Date(this.pfData.invoiceSetting.ach_withdrawal_date);
            
            // this.in_report_date = this.pfData.invoiceSetting.report_date.toString();
            // this.in_ach_withdrawal_date = this.pfData.invoiceSetting.ach_withdrawal_date.toString();

            var dtArr2 = this.pfData.invoiceSetting.report_date.split(" "); 
            if( typeof dtArr2[0] !== 'undefined' )
            {
              var dtArr = dtArr2[0].split("-"); 
              if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
              {
                var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                this.in_report_date = nprPDD;
              }
            }

            var dtArr3 = this.pfData.invoiceSetting.ach_withdrawal_date.split(" "); 
            if( typeof dtArr3[0] !== 'undefined' )
            {
              var dtArr = dtArr3[0].split("-"); 
              if( typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined' )
              {
                var nprPDD = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
                this.in_ach_withdrawal_date = nprPDD;
              }
            }

            this.db_report_date = this.in_report_date;
            
            // this.pf_backup_ach_withdrawal_date = this.invoice.metadata.ach_withdrawal_date;
            
            this.in_backup_ach_withdrawal_date = this.in_ach_withdrawal_date;
            this.pf_db_ach_dt = this.in_ach_withdrawal_date;

            this.datesLoaded = true;

            if (this.pfData.invoiceSetting.pf_has_admin_fee != undefined && this.pfData.invoiceSetting.pf_has_admin_fee != null && this.pfData.invoiceSetting.pf_has_admin_fee == 1)
            {
              this.pfHasAdminFee = true;
              this.invoice.pfAdminFee = true;
              this.oriPFAfee = true;
            }
            if (this.pfData.invoiceSetting.rpm_admin_fee != undefined && this.pfData.invoiceSetting.rpm_admin_fee != null) {
              this.invoice.metadata.admin_fee = this.pfData.invoiceSetting.rpm_admin_fee;
              this.oriAdminFee = this.pfData.invoiceSetting.rpm_admin_fee;
            }

            if (this.pfData.invoiceSetting.has_credit_card != undefined && this.pfData.invoiceSetting.has_credit_card != null && this.pfData.invoiceSetting.has_credit_card == 1) {
              this.invoice.pfCC = true;
              this.oriPFCc = true;
            }
            if (this.pfData.invoiceSetting.credit_card != undefined && this.pfData.invoiceSetting.credit_card != null && this.pfData.invoiceSetting.credit_card > 0) {
              this.invoice.metadata.credit_card_fee = this.pfData.invoiceSetting.credit_card;
              this.oricc = this.pfData.invoiceSetting.credit_card;
            }

            if (this.pfData.invoiceSetting.nsf_new != undefined && this.pfData.invoiceSetting.nsf_new != null && this.pfData.invoiceSetting.nsf_new == 1) {
              this.invoice.is_nsf = true;
              this.invoice.metadata.nsf_fee = 40;
              if (this.invoice.pfCC) {
                this.invoice.metadata.nsf_fee = 0;
              }
              if (this.pfData.invoiceSetting.nsf_fee != undefined && this.pfData.invoiceSetting.nsf_fee != null && this.pfData.invoiceSetting.nsf_fee > 0) {
                this.invoice.metadata.nsf_fee = this.pfData.invoiceSetting.nsf_fee;
                this.orinsf = this.pfData.invoiceSetting.nsf_fee;
              }
              if (this.pfData.invoiceSetting.retry_payment_fee != undefined && this.pfData.invoiceSetting.retry_payment_fee != null && this.pfData.invoiceSetting.retry_payment_fee > 0) {
                this.invoice.metadata.retry_payment_fee = this.pfData.invoiceSetting.retry_payment_fee;
                this.orirp = this.pfData.invoiceSetting.retry_payment_fee;
              }
            }



            if (this.pfData.invoiceSetting.invoice_status != undefined && this.pfData.invoiceSetting.invoice_status != null) {
              this.invoiceStatus = this.pfData.invoiceSetting.invoice_status;
              if (this.invoiceStatus == 'Queue') {
                this.isQueueStatus = true;
              }
            }

            if (this.pfData.invoiceSetting.is_void != undefined && this.pfData.invoiceSetting.is_void != null) {
              this.is_void = this.pfData.invoiceSetting.is_void;
            }
            if (this.pfData.invoiceSetting.is_nsf != undefined && this.pfData.invoiceSetting.is_nsf != null) {
              this.is_nsf = this.pfData.invoiceSetting.is_nsf;
            }
            if (this.pfData.invoiceSetting.is_revised != undefined && this.pfData.invoiceSetting.is_revised != null) {
              this.is_revised = this.pfData.invoiceSetting.is_revised;
            }

            if (this.pfData.invoiceSetting.custom_inv_number != undefined && this.pfData.invoiceSetting.custom_inv_number != null) {
              this.custom_inv_number = this.pfData.invoiceSetting.custom_inv_number;

              this.invSysNumber = this.id + '-' + this.pfData.invoiceSetting.custom_inv_number;
            }


            if (this.pfData.invoiceSetting.ori_cust_inv_number != undefined && this.pfData.invoiceSetting.ori_cust_inv_number != null) {
              this.ori_cust_inv_number = this.pfData.invoiceSetting.ori_cust_inv_number;
              if (this.pfData.invoiceSetting.ori_cust_inv_number != "") {
                this.isCorRev = 1;
              }
            }
            if (this.pfData.invoiceSetting.ori_nsf_inv_number != undefined && this.pfData.invoiceSetting.ori_nsf_inv_number != null) {
              this.ori_nsf_inv_number = this.pfData.invoiceSetting.ori_nsf_inv_number;
              if (this.pfData.invoiceSetting.ori_nsf_inv_number != "") {
                this.isCorRev = 3;
              }
            }
            if (this.pfData.invoiceSetting.ori_rev_inv_number != undefined && this.pfData.invoiceSetting.ori_rev_inv_number != null) {
              this.ori_rev_inv_number = this.pfData.invoiceSetting.ori_rev_inv_number;
              if (this.pfData.invoiceSetting.ori_rev_inv_number != "") {
                this.isCorRev = 2;
              }
            }
            if (this.pfData.invoiceSetting.ori_inv_number != undefined && this.pfData.invoiceSetting.ori_inv_number != null) {
              this.ori_inv_number = this.pfData.invoiceSetting.ori_inv_number;
            }

            if( this.isCorRev > 0 )
            {
              this.premInvFormA.controls['report_date'].setValue( this.in_report_date );
              this.premInvFormB.controls['ach_withdrawal_date'].setValue( this.in_ach_withdrawal_date );
            }

            if (this.pfData.invoiceSetting.notes != undefined && this.pfData.invoiceSetting.notes != null) {
              // this.invoiceNoteFormPF.controls['notes'].setValue(this.pfData.invoiceSetting.notes);
              this.invNotesBk = this.sanitizer.bypassSecurityTrustHtml(this.pfData.invoiceSetting.notes);
              this.notesInvSave = this.pfData.invoiceSetting.notes;
            }

            if (this.pfData.invoiceSetting.queue_manual_type != undefined && this.pfData.invoiceSetting.queue_manual_type != null) {
              this.queue_manual_type = this.pfData.invoiceSetting.queue_manual_type;
            }

            if (this.pfData.ratesNeededArr != undefined && this.pfData.ratesNeededArr != null) {
              this.pfRatesNeededArr = this.pfData.ratesNeededArr;
              // console.log(this.pfRatesNeededArr);

              this.oriPFRatesArr = JSON.parse(JSON.stringify(this.pfRatesNeededArr));

              this.pfRates = [];
              this.pfRates = JSON.parse(JSON.stringify(this.pfRatesNeededArr));
              this.newRatesCount = this.pfRatesNeededArr.length - 1;
            }

            if( this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf )
            {
              this.invoice.is_rpf_editable = true;
            }

            if (this.pfData.pfInvoiceTotal != undefined && this.pfData.pfInvoiceTotal != null)
            {
              this.pfInvoiceTotal = this.pfData.pfInvoiceTotal;

              var edit_retry_new = 0;
              if( this.pfData.invoiceSetting.edit_retry_new != undefined && this.pfData.invoiceSetting.edit_retry_new != null )
              {
                edit_retry_new = this.pfData.invoiceSetting.edit_retry_new;
              }
              if( this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf && edit_retry_new == 0 )
              {
                this.invoice.metadata.retry_payment_fee = this.pfInvoiceTotal;
                this.orirp = this.invoice.metadata.retry_payment_fee;
                this.invoice.pf_edit_rnew = true;
              }

              this.pfFlagChange(0);

              if( this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf && edit_retry_new == 0 )
              {
                this.orirp = this.invoice.metadata.retry_payment_fee;
              }
            }
            this.spinnerService.hide();
          }
        }, 3000);



      }
    });

    // Load invoice types
    this.invoice_types = [
      { label: 'Premium Invoice', value: 'premium_invoice' },
      { label: 'Payroll Service Fee Invoice', value: 'policy_fee' },
      { label: 'NPR Invoice', value: 'npr' },
      { label: 'Payroll Reporting', value: 'payroll_reporting' }
    ];

    this.invoice_typess = [
      { label: 'Misc Billing Invoice', value: 'policy_fee' },
    ];

    // Load states
    this.clientService.getStates().subscribe(states => {
      this.states = [];
      this.states.push({
        label: '(select)',
        value: null
      });
      for (const state of states) {
        this.states.push({
          label: state.code,
          value: state.code
        });
      }
    });

    // Load class codes
    this.loadClassCodes();

  }

  loadClassCodes() {
    this.clientService.getClassCodes(this.new_line.state).subscribe(class_codes => {
      this.class_codes = [];
      this.class_codes.push({
        label: '(select)',
        value: null
      });
      for (const class_code of class_codes) {
        this.class_codes.push({
          label: class_code.class_code + ' - ' + class_code.class_code_description,
          value: JSON.stringify({
            class_code: class_code.class_code,
            class_code_description: class_code.class_code_description
          })
        });
      }
    });
  }

  isAdditionalFee() {
    if (this.invoice && this.invoice.flags) {
      return (
        this.invoice.flags.includes('first') ||
        this.invoice.flags.includes('nsf') ||
        this.invoice.flags.includes('credit_card')
      );
    }
  }

  priceFormateChange(typeForm) {
    if (typeForm == 1 && this.serviceFeeForm.value.price != "") {
      if (!isNaN(this.serviceFeeForm.value.price.replace(/,/g, ''))) {
        var newPrice = this.serviceFeeForm.value.price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

        this.serviceFeeForm.controls['price'].setValue(newPrice);
      }
    }
  }

  getTotalGW(){
    this.totalGW = 0.00;
    this.invoice.detail.forEach(item =>{
      if(item.gross_wages != null){
        this.totalGW = this.totalGW + item.gross_wages;
      }
    });
    return this.totalGW;
  }

  getTotalOW(){
    this.totalOW = 0.00;
    this.invoice.detail.forEach(item =>{
      if(item.overtime_wages != null){
        this.totalOW = this.totalOW + item.overtime_wages;
      }
    });
    return this.totalOW;
  }

  getTotalWW(){
    var totalWW = 0.00;
    this.invoice.detail.forEach(item =>{
      if(item.workers_comp_wages != null){
        totalWW = totalWW + item.workers_comp_wages;
      }
    });
    return totalWW;
  }

  getTotalPR(){
    var totalPR = 0.00;
    this.invoice.detail.forEach(item =>{
      if(item.premium != null){
        totalPR = totalPR + item.premium;
      }
    });
    return totalPR;
  }

  ccWagesCopy()
  {
    if( this.wagesCopyBtnShow && this.wagesCopyObjsArr != undefined )
    {
      for( var wcsi = 0; wcsi < this.statesArr.length; wcsi++ )
      {
        if( this.wagesCopyObjsArr[this.statesArr[wcsi]] != undefined )
        {
          var currWagesCopyObj:any = this.wagesCopyObjsArr[this.statesArr[wcsi]];
          if( currWagesCopyObj.toObj != undefined && currWagesCopyObj.toObj != null && currWagesCopyObj.fromObj != undefined && currWagesCopyObj.fromObj != null )
          {
            if( currWagesCopyObj.toObj.item != undefined && currWagesCopyObj.toObj.item != null && currWagesCopyObj.fromObj.item != undefined && currWagesCopyObj.fromObj.item != null )
            {
              if( currWagesCopyObj.fromObj.ri != undefined && currWagesCopyObj.fromObj.ri != null && currWagesCopyObj.toObj.ri != undefined && currWagesCopyObj.toObj.ri != null )
              {
                if( this.invoice.detail[currWagesCopyObj.fromObj.ri] != undefined && this.invoice.detail[currWagesCopyObj.fromObj.ri] != null && this.invoice.detail[currWagesCopyObj.toObj.ri] != undefined && this.invoice.detail[currWagesCopyObj.toObj.ri] != null )
                {
                  if( this.invoice.detail[currWagesCopyObj.fromObj.ri].gross_wages != undefined && this.invoice.detail[currWagesCopyObj.fromObj.ri].gross_wages != null )
                  {
                      // currWagesCopyObj.toObj.item.gross_wages = currWagesCopyObj.fromObj.item.gross_wages;
                      this.invoice.detail[currWagesCopyObj.toObj.ri].gross_wages = this.invoice.detail[currWagesCopyObj.fromObj.ri].gross_wages;
                      this.getTotalOW();
                      this.getTotalWW();
                      this.getTotalPR();
                  
                      currWagesCopyObj.toObj.item.calc();
                      this.invoice.calcTotals();
                  
                      this.piPTMismatch = false;
                      if( (this.payrollTotal != undefined && this.payrollTotal != null) && this.payrollTotal != 2 )
                      {
                        if( (parseFloat((this.getTotalGW()).toFixed(2))) != this.payrollTotal )
                        {
                          this.piPTMismatch = true;
                        }
                      }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  updateItem(item: InvoiceDetail, field: string, $event: Event, i) {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if( field == "gross_wages" && (this.invoice.metadata.invoice_type == 'premium_invoice' || this.invoice.metadata.invoice_type == 'payroll_reporting') )
    {
        item.gross_calculation = ""; 
        if( target.value != "" && target.value.includes("-") && (target.value.match(new RegExp("-", "g")) || []).length == 1 )
        {
          var givenCalclnArr = target.value.split( "-" );
          if( givenCalclnArr != undefined && givenCalclnArr != null && typeof givenCalclnArr !== undefined && typeof givenCalclnArr[0] !== undefined && typeof givenCalclnArr[1] !== undefined )
          {
            if( givenCalclnArr[0] != undefined && givenCalclnArr[0] != null && givenCalclnArr[0] != "" && givenCalclnArr[1] != undefined && givenCalclnArr[1] != null && givenCalclnArr[1] != "" )
            {
              item.gross_calculation = target.value;
              target.value = Number(givenCalclnArr[0].replace(/,/g, '')) - Number(givenCalclnArr[1].replace(/,/g, ''));
            }
          }
        }
        else if( target.value != "" && target.value.includes("+") )
        {
          var givenCalclnArr = target.value.split( "+" );
          if( givenCalclnArr != undefined && givenCalclnArr != null && typeof givenCalclnArr !== undefined && typeof givenCalclnArr[0] !== undefined && typeof givenCalclnArr[1] !== undefined )
          {
            if( givenCalclnArr[0] != undefined && givenCalclnArr[0] != null && givenCalclnArr[0] != "" && givenCalclnArr[1] != undefined && givenCalclnArr[1] != null && givenCalclnArr[1] != "" )
            {
              item.gross_calculation = target.value;
              target.value = Number(givenCalclnArr[0].replace(/,/g, '')) + Number(givenCalclnArr[1].replace(/,/g, ''));
            }
          }
        }
    }
    else if( field == "overtime_wages" && (this.invoice.metadata.invoice_type == 'premium_invoice' || this.invoice.metadata.invoice_type == 'payroll_reporting') )
    {
        item.overtime_calculation = ""; 
        if( target.value != "" && target.value.includes("-") && (target.value.match(new RegExp("-", "g")) || []).length == 1 )
        {
          var givenCalclnArr = target.value.split( "-" );
          if( givenCalclnArr != undefined && givenCalclnArr != null && typeof givenCalclnArr !== undefined && typeof givenCalclnArr[0] !== undefined && typeof givenCalclnArr[1] !== undefined )
          {
            if( givenCalclnArr[0] != undefined && givenCalclnArr[0] != null && givenCalclnArr[0] != "" && givenCalclnArr[1] != undefined && givenCalclnArr[1] != null && givenCalclnArr[1] != "" )
            {
              item.overtime_calculation = target.value;
              target.value = Number(givenCalclnArr[0].replace(/,/g, '')) - Number(givenCalclnArr[1].replace(/,/g, ''));
            }
          }
        }
        else if( target.value != "" && target.value.includes("+") )
        {
          var givenCalclnArr = target.value.split( "+" );
          if( givenCalclnArr != undefined && givenCalclnArr != null && typeof givenCalclnArr !== undefined && typeof givenCalclnArr[0] !== undefined && typeof givenCalclnArr[1] !== undefined )
          {
            if( givenCalclnArr[0] != undefined && givenCalclnArr[0] != null && givenCalclnArr[0] != "" && givenCalclnArr[1] != undefined && givenCalclnArr[1] != null && givenCalclnArr[1] != "" )
            {
              item.overtime_calculation = target.value;
              target.value = Number(givenCalclnArr[0].replace(/,/g, '')) + Number(givenCalclnArr[1].replace(/,/g, ''));
            }
          }
        }
    }
    
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    }
    else
    {
      if( field == "gross_wages" )
      {
        item.gross_calculation = ""; 
      }
      else if( field == "overtime_wages" )
      {
        item.overtime_calculation = ""; 
      }
    }
    // console.log(tarGetvalue);

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }
    
    switch (field) {
      case 'gross_wages':
        item.gross_wages = numeric;
        this.invoice.detail[i].gross_wages = numeric;
        this.getTotalGW();
        this.getTotalWW();
        this.getTotalPR();
        break;

      case 'overtime_wages':
        item.overtime_wages = numeric;
        this.invoice.detail[i].overtime_wages = numeric;
        this.getTotalOW();
        this.getTotalWW();
        this.getTotalPR();
        break;

      case 'net_rate':
        item.net_rate = numeric;
        break;
    }

    // Update Totals
    item.calc();
    this.invoice.calcTotals();

    if( field == "gross_wages" )
    {
      this.piPTMismatch = false;
      if( (this.payrollTotal != undefined && this.payrollTotal != null) && this.payrollTotal != 2 )
      {
        if( (parseFloat((this.getTotalGW()).toFixed(2))) != this.payrollTotal )
        {
          this.piPTMismatch = true;
        }
      }
    }

  }

  updateFee(type: string, $event: Event) {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");
  
      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.ccManualUpdate = false;
    this.invoice.nsfRpfManualUpd = false;

    switch (type) {
      case 'admin':
        this.invoice.metadata.admin_fee = numeric;
        break;

      case 'setup':
        this.invoice.metadata.setup_fee = numeric;
        break;

      case 'credit_card':
        this.invoice.metadata.credit_card_fee = numeric;
        this.invoice.ccManualUpdate = true;
        break;

      case 'nsf':
        this.invoice.metadata.nsf_fee = numeric;
        break;

      case 'retry_payment_fee':
        this.invoice.metadata.retry_payment_fee = numeric;
        this.invoice.nsfRpfManualUpd = true;
        break;
    }

    this.invoice.calcTotals();
    // Update Totals
  }

  pfNSFCorr()
  {
    this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;

    var ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
    this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

    var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    var parts = tv3.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
    var figure3 = parts.join(".");

    this.invoice.metadata.credit_card_fee = parseFloat(figure3);

    this.pfOverallTotal += parseFloat(figure3);
    this.pfOverallTotal = parseFloat(this.pfOverallTotal.toFixed(2));
  }

  setPFShowEmail()
  {
      this.pfShowEmail = this.policy_fee_form.value.pfSelAgentEmail;
  }

  fillPFAgentEmails( clAgencyDate,agent1Num,agent2Num )
  {
      if( clAgencyDate != undefined && clAgencyDate != null )
      {
        if( agent1Num != undefined && agent1Num != null && agent2Num != undefined && agent2Num != null )
        {
          if( (agent1Num == 1 || agent2Num == 1) && (clAgencyDate.contact_1_email != undefined && clAgencyDate.contact_1_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_1_email,
              value: clAgencyDate.contact_1_email
            });
          }
          if( (agent1Num == 2 || agent2Num == 2) && (clAgencyDate.contact_2_email != undefined && clAgencyDate.contact_2_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_2_email,
              value: clAgencyDate.contact_2_email
            });
          }
          if( (agent1Num == 3 || agent2Num == 3) && (clAgencyDate.contact_3_email != undefined && clAgencyDate.contact_3_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_3_email,
              value: clAgencyDate.contact_3_email
            });
          }
          if( (agent1Num == 4 || agent2Num == 4) && (clAgencyDate.contact_4_email != undefined && clAgencyDate.contact_4_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_4_email,
              value: clAgencyDate.contact_4_email
            });
          }
          if( (agent1Num == 5 || agent2Num == 5) && (clAgencyDate.contact_5_email != undefined && clAgencyDate.contact_5_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_5_email,
              value: clAgencyDate.contact_5_email
            });
          }
          if( (agent1Num == 6 || agent2Num == 6) && (clAgencyDate.contact_6_email != undefined && clAgencyDate.contact_6_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_6_email,
              value: clAgencyDate.contact_6_email
            });
          }
          if( (agent1Num == 7 || agent2Num == 7) && (clAgencyDate.contact_7_email != undefined && clAgencyDate.contact_7_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_7_email,
              value: clAgencyDate.contact_7_email
            });
          }
          if( (agent1Num == 8 || agent2Num == 8) && (clAgencyDate.contact_8_email != undefined && clAgencyDate.contact_8_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_8_email,
              value: clAgencyDate.contact_8_email
            });
          }
          if( (agent1Num == 9 || agent2Num == 9) && (clAgencyDate.contact_9_email != undefined && clAgencyDate.contact_9_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_9_email,
              value: clAgencyDate.contact_9_email
            });
          }
          if( (agent1Num == 10 || agent2Num == 10) && (clAgencyDate.contact_10_email != undefined && clAgencyDate.contact_10_email != null) )
          {
            this.PFAgentEmails.push({
              label: clAgencyDate.contact_10_email,
              value: clAgencyDate.contact_10_email
            });
          }

          if( (! this.isFreshInvoice) && this.pfData != undefined && this.pfData.invoiceSetting != undefined && this.pfData.invoiceSetting.nsf_new != undefined && this.pfData.invoiceSetting.nsf_new != null && this.pfData.invoiceSetting.nsf_new == 1 && this.pfData.invoiceSetting.bill_to_type == 4 )
          {
            var emailAgPresent = false;
            for( var pfNSF = 0;pfNSF < this.PFAgentEmails.length; pfNSF++ )
            {
              if( this.PFAgentEmails[pfNSF].value == this.pfShowEmail )
              {
                emailAgPresent = true;
              }
            }
            if( ! emailAgPresent )
            {
              this.PFAgentEmails.push({
                label: this.pfShowEmail,
                value: this.pfShowEmail
              });
            }
          }
        }
      }

      if( this.isFreshInvoice )
      {
        if( typeof this.PFAgentEmails[1] !== undefined )
        {
          // this.policy_fee_form.controls['pfSelAgentEmail'].setValue( this.PFAgentEmails[1].value );
          // this.pfOriAgEmail = this.PFAgentEmails[1].value;
        }
      }
      else
      {
        if( this.pfData.invoiceSetting.bill_to_type == 4 )
        {
          var emailAgPresent = false;
          for( var pfNSF = 0;pfNSF < this.PFAgentEmails.length; pfNSF++ )
          {
            if( this.PFAgentEmails[pfNSF].value == this.pfShowEmail )
            {
              emailAgPresent = true;
            }
          }
          if( ! emailAgPresent )
          {
            this.pfShowEmail = '';
          }
        
          this.policy_fee_form.controls['pfSelAgentEmail'].setValue( this.pfShowEmail );
        }
        else
        {
          if( typeof this.PFAgentEmails[1] !== undefined )
          {
            // this.policy_fee_form.controls['pfSelAgentEmail'].setValue( this.PFAgentEmails[1].value );
            // this.pfOriAgEmail = this.PFAgentEmails[1].value;
          }
        }
      }
  }

  updatedPFRPF($event: Event) {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.retry_payment_fee = numeric;

    if( this.invoice.is_rpf_editable )
    {
      this.pfOverallTotal = numeric;
    }
    if (this.invoice.is_nsf) {
      this.pfOverallTotal += parseFloat(this.invoice.metadata.nsf_fee.toFixed(2));
    }
    if( this.invoice.pfAdminFee )
    {
      // this.pfOverallTotal += this.invoice.metadata.admin_fee;
    }
    
    if (this.invoice.pfCC) {

      var ccTotal = 0;
      if( this.invoice.is_rpf_editable )
      {
        ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
      }
      if( this.invoice.pfAdminFee )
      {
        // ccTotal += this.invoice.metadata.admin_fee;
      }
      // console.log(ccTotal);

      this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

      var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv3.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure3 = parts.join(".");
  
      this.invoice.metadata.credit_card_fee = parseFloat(figure3);
        
      this.pfOverallTotal += parseFloat(figure3);
      this.pfOverallTotal = parseFloat(this.pfOverallTotal.toFixed(2));
    }
    this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }
  
  updatedPfRfee($event: Event)
  {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.admin_fee = numeric;

    if( this.invoice.is_rpf_editable )
    {
      this.pfOverallTotal = this.pfInvoiceTotal + this.invoice.metadata.nsf_fee + numeric;
      this.invoice.metadata.retry_payment_fee = this.pfInvoiceTotal + numeric;
    }
    else
    {
      this.pfOverallTotal = this.pfInvoiceTotal + numeric;
    }
    
    if( this.invoice.pfCC )
    {
      var ccTotal = 0;
      if( this.invoice.is_rpf_editable )
      {
        ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
      }
      else
      {
        ccTotal = this.pfInvoiceTotal + numeric;
      }
      
      console.log(ccTotal);

      this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

      var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv3.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure3 = parts.join(".");
  
      this.invoice.metadata.credit_card_fee = parseFloat(figure3);
        
      this.pfOverallTotal += parseFloat(figure3);
      this.pfOverallTotal = parseFloat(this.pfOverallTotal.toFixed(2));
    }
    this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }

  updatedPFNSF($event: Event) {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.nsf_fee = numeric;

    if( this.invoice.is_rpf_editable )
    {
      this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + numeric;
    }
    else
    {
      this.pfOverallTotal = this.pfInvoiceTotal + numeric;
    }
    if( this.invoice.pfAdminFee )
    {
      // this.pfOverallTotal += this.invoice.metadata.admin_fee;
    }
    
    if (this.invoice.pfCC) {

      var ccTotal = 0;
      if( this.invoice.is_rpf_editable )
      {
        ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
      }
      if( this.invoice.pfAdminFee )
      {
        // ccTotal += this.invoice.metadata.admin_fee;
      }
        // console.log(ccTotal);

      this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

      var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv3.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure3 = parts.join(".");
  
      this.invoice.metadata.credit_card_fee = parseFloat(figure3);
        
      this.pfOverallTotal += parseFloat(figure3);
      this.pfOverallTotal = parseFloat(this.pfOverallTotal.toFixed(2));
    }
    this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }

  updatedPFCC($event: Event) {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.credit_card_fee = numeric;

    var tv1 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    var parts = tv1.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
    var figure1 = parts.join(".");

    this.invoice.metadata.credit_card_fee = parseFloat(figure);

    if( this.invoice.is_rpf_editable )
    {
      this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.credit_card_fee;
    }
    else
    {
      this.pfOverallTotal = this.pfInvoiceTotal + this.invoice.metadata.credit_card_fee;
      if( this.invoice.pfAdminFee )
      {
        this.pfOverallTotal += this.invoice.metadata.admin_fee;
      }
    }

    if (this.invoice.is_nsf) {
      this.pfOverallTotal += parseFloat(this.invoice.metadata.nsf_fee.toFixed(2));
    }

    this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }

  resetPFAfee()
  {
    this.invoice.metadata.admin_fee = 0;

    if( ! this.invoice.pfAdminFee )
    {
      this.invoice.metadata.admin_fee = 0;

      if( this.invoice.is_rpf_editable )
      {
        this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
      }
      else
      {
        this.pfOverallTotal = this.pfInvoiceTotal;
      }
      
      if( this.invoice.pfCC )
      {
          var ccTotal = 0;
          if( this.invoice.is_rpf_editable )
          {
            ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
          }
          else
          {
            ccTotal = this.pfInvoiceTotal;
          }
          
          // console.log(ccTotal);
    
          this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;
    
          var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
          var parts = tv3.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
          var figure3 = parts.join(".");
      
          this.invoice.metadata.credit_card_fee = parseFloat(figure3);
            
          this.pfOverallTotal += parseFloat(figure3);
        }
        this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    }
  }

  openDscSrvDD(ev)
  {
    if( ev != undefined && ev != null && ev.relatedTarget != undefined && ev.relatedTarget != null )
    {
      ev.relatedTarget.click();
    }
  }

  pfFlagChange(chkType: number)
  {
    if (chkType == 2) {
      this.invoice.metadata.nsf_fee = 0;

      // Credit Card Fee
      if (this.invoice.pfCC)
      {
        var ccTotal = 0;
        if( this.pfInvoiceTotal != undefined && this.pfInvoiceTotal != null )
        {
          if( this.invoice.pfAdminFee )
          {
            ccTotal = this.pfInvoiceTotal + this.invoice.metadata.admin_fee;
          }
          else
          {
            ccTotal = this.pfInvoiceTotal;
          }
        }

        if( this.invoice.is_rpf_editable )
        {
          if( this.invoice.pfAdminFee )
          {
            ccTotal = this.invoice.metadata.retry_payment_fee;
          }
          else
          {
            ccTotal = this.invoice.metadata.retry_payment_fee;
          }
        }

        // console.log(ccTotal);

        this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

        var tv = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        var parts = tv.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{10})+(?!\d))/g, ",");;
        var figure = parts.join(".");

        this.invoice.metadata.credit_card_fee = parseFloat(figure);
        
      }
      else {
        this.invoice.metadata.credit_card_fee = 0;
        if (this.invoice.is_nsf) {
          this.invoice.metadata.nsf_fee = 40;
        }
      }

      if( this.invoice.is_rpf_editable )
      {
        this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + parseFloat(this.invoice.metadata.credit_card_fee.toFixed(2)) + this.invoice.metadata.nsf_fee;
      }
      else
      {
        this.pfOverallTotal = this.pfInvoiceTotal + parseFloat(this.invoice.metadata.credit_card_fee.toFixed(2)) + this.invoice.metadata.nsf_fee;
        if( this.invoice.pfAdminFee )
        {
          this.pfOverallTotal += this.invoice.metadata.admin_fee;
        }
      }

      this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      if (this.invoice.is_nsf && ! this.invoice.is_rpf_editable ) {
        this.invoice.metadata.retry_payment_fee = this.pfInvoiceTotal;
      }
    }
    else if (chkType == 0) {
      if (this.invoice.is_nsf) {
        if( this.invoice.is_rpf_editable )
        {
          this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee + this.invoice.metadata.credit_card_fee;
        }
        else
        {
          this.pfOverallTotal = this.pfInvoiceTotal + this.invoice.metadata.nsf_fee + this.invoice.metadata.credit_card_fee;
        }
      }
      else
      {
        if( this.invoice.is_rpf_editable )
        {
          this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.credit_card_fee;
        }
        else
        {
          this.pfOverallTotal = this.pfInvoiceTotal + this.invoice.metadata.credit_card_fee;
        }
        if( this.invoice.pfAdminFee )
        {
          this.pfOverallTotal += this.invoice.metadata.admin_fee;
        }
      }

      if( this.invoice.pf_edit_rnew && this.invoice.pfAdminFee )
      {
          this.invoice.pf_edit_rnew = false;
          this.invoice.metadata.retry_payment_fee += this.invoice.metadata.admin_fee;
          this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee + this.invoice.metadata.credit_card_fee;
          // this.pfNSFCorr();
      }
    }
  }

  updatedNPRpf($event: Event)
  {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, '')))
    {
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.retry_payment_fee = numeric;

    if( this.invoice.is_rpf_editable )
    {
      this.nprOverallTotal = this.invoice.metadata.nsf_fee + numeric;
    }
    else
    {
      this.nprOverallTotal = this.nprInvoiceTotal + numeric;
    }
    

    if (this.invoice.nprCC)
    {
      var ccTotal = 0;
      if( this.invoice.is_rpf_editable )
      {
        ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
      }
      this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;
    
      var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv3.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure3 = parts.join(".");
  
      this.invoice.metadata.credit_card_fee = parseFloat(figure3);
        
      this.nprOverallTotal += parseFloat(figure3);
    }
    this.nprOverallTotal = parseFloat(this.nprOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }

  updatedNPRNSF($event: Event)
  {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, '')))
    {
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.nsf_fee = numeric;

    if( this.invoice.is_rpf_editable )
    {
      this.nprOverallTotal = this.invoice.metadata.retry_payment_fee + numeric;
    }
    else
    {
      this.nprOverallTotal = this.nprInvoiceTotal + numeric;
    }
    

    if (this.invoice.nprCC)
    {
      var ccTotal = 0;
      if( this.invoice.is_rpf_editable )
      {
        ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
      }
      this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

      var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv3.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure3 = parts.join(".");
  
      this.invoice.metadata.credit_card_fee = parseFloat(figure3);
        
      this.nprOverallTotal += parseFloat(figure3);
    }
    this.nprOverallTotal = parseFloat(this.nprOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }

  updatedNPRCC($event: Event) {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }


    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.credit_card_fee = numeric;

    if( this.invoice.is_rpf_editable )
    {
      this.nprOverallTotal = this.invoice.metadata.retry_payment_fee + numeric;
    }
    else
    {
      this.nprOverallTotal = this.nprInvoiceTotal + numeric;
    }

    if (this.invoice.is_nsf)
    {
      this.nprOverallTotal += parseFloat(this.invoice.metadata.nsf_fee.toFixed(2));
    }
    this.nprOverallTotal = parseFloat(this.nprOverallTotal.toFixed(2));
    this.nprOverallTotal = parseFloat(this.nprOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }

  nprFlagChange(chkType: number)
  {
    if (chkType == 2)
    {
      this.invoice.metadata.nsf_fee = 0;

      // Credit Card Fee
      if (this.invoice.nprCC)
      {
        var ccTotal = 0;
        if (this.nprInvoiceTotal != undefined && this.nprInvoiceTotal != null)
        {
          ccTotal += this.nprInvoiceTotal;
        }

        if( this.invoice.is_rpf_editable )
        {
          ccTotal = this.invoice.metadata.retry_payment_fee;
        }
        
        console.log(ccTotal);

        this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

        var tv = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        var parts = tv.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
        var figure = parts.join(".");

        this.invoice.metadata.credit_card_fee = parseFloat(figure);
      }
      else
      {
        this.invoice.metadata.credit_card_fee = 0;
        if (this.invoice.is_nsf)
        {
          this.invoice.metadata.nsf_fee = 40;
        }
      }

      if( this.invoice.is_rpf_editable )
      {
        this.nprOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.credit_card_fee;
      }
      else
      {
        this.nprOverallTotal = this.nprInvoiceTotal + this.invoice.metadata.credit_card_fee;
      }
      
      if (this.invoice.is_nsf)
      {
        this.nprOverallTotal += parseFloat(this.invoice.metadata.nsf_fee.toFixed(2));
      }

      this.nprOverallTotal = parseFloat(this.nprOverallTotal.toFixed(2));
      // this.nprOverallTotal = parseFloat(this.nprOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      var tv = parseFloat(this.nprOverallTotal.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      this.nprOverallTotal = parseFloat(figure);
    }
    else if (chkType == 0)
    {

      if( this.invoice.is_rpf_editable )
      {
        this.nprOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.credit_card_fee;
      }
      else
      {
        this.nprOverallTotal = this.nprInvoiceTotal + this.invoice.metadata.credit_card_fee;
      }

      if (this.invoice.is_nsf)
      {
        this.nprOverallTotal += parseFloat(this.invoice.metadata.nsf_fee.toFixed(2));
      }
      this.nprOverallTotal = parseFloat(this.nprOverallTotal.toFixed(2));
      // this.nprOverallTotal = parseFloat(this.nprOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      var tv = parseFloat(this.nprOverallTotal.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      this.nprOverallTotal = parseFloat(figure);
    }
  }

  onFlagChange(chkType: number) {
    // Setup Fee

    if (chkType == 1) {
      if (this.invoice.ngFirst) {
        this.invoice.metadata.setup_fee = 100;
      } else {
        this.invoice.metadata.setup_fee = 0;
      }
    }
    if (chkType == 2) {
      // Credit Card Fee
      if (this.invoice.ngCC) {
        var ccTotal = 0;
        if (this.invoice.premium_total != undefined && this.invoice.premium_total != null) {
          ccTotal += this.invoice.premium_total;
        }
        if (this.invoice.metadata.admin_fee != undefined && this.invoice.metadata.admin_fee != null) {
          ccTotal += this.invoice.metadata.admin_fee;
        }
        if (this.invoice.ngFirst) {
          if (this.invoice.metadata.setup_fee != undefined && this.invoice.metadata.setup_fee != null) {
            ccTotal += this.invoice.metadata.setup_fee;
          }
        }

        if( this.invoice.is_rpf_editable )
        {
          ccTotal = this.invoice.metadata.retry_payment_fee;
        }

        if (this.invoice.is_nsf) {
          this.invoice.metadata.nsf_fee = 0;
        }
        // console.log(ccTotal);

        this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

        var tv = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        var parts = tv.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
        var figure = parts.join(".");
    
        this.invoice.metadata.credit_card_fee = parseFloat(figure);
      }
      else {
        this.invoice.metadata.credit_card_fee = 0;
        if (this.invoice.is_nsf) {
          this.invoice.metadata.nsf_fee = 40;
        }
      }
    }

    if (chkType == 3) {
      // NSF Fee
      if (this.invoice.ngNPR) {
        this.invoice.metadata.nsf_fee = 15;
      } else {
        this.invoice.metadata.nsf_fee = 0;
      }
    }

    // Update Totals
    this.invoice.calcTotals();
  }

  addLine() {
    if (this.new_line.class_code) {
      const class_code = JSON.parse(this.new_line.class_code);
      this.new_line.class_code_description = class_code.class_code_description;
      this.new_line.class_code = class_code.class_code;
    }
    this.invoice.detail.push(new InvoiceDetail(this.new_line));
    this.invoice.calcTotals();

    this.new_line.location = null;
    this.new_line.state = null;
    this.new_line.class_code = null;
    this.new_line.gross_wages = null;
    this.new_line.overtime_wages = null;
    this.new_line.net_rate = null;
    this.new_line.calc();
  }

  inRates(value: string) {
    for (const rate of this.client.activePolicy.rates) {
      if (rate.location_name === value) {
        return true;
      }
    }
    return false;
  }

  corRevPremChgReptDt(dateType: number = 0)
  {
    if (dateType == 1)
    {
      this.in_report_date = this.premInvFormA.value.report_date;
    }
    else if (dateType == 2)
    {
      this.in_ach_withdrawal_date = this.premInvFormB.value.ach_withdrawal_date;
    }
  }
  uplPremChgReptDt()
  {
    this.in_report_date = this.premInvFormA.value.report_date;
    if( this.in_ach_withdrawal_date == undefined || this.in_ach_withdrawal_date == null || this.in_ach_withdrawal_date == "" )
    {
      this.policyService.getUplAchDate( this.in_report_date ).subscribe(res =>
      {
          if( res != undefined && res != null && typeof res[0] != undefined )
          {
            this.in_ach_withdrawal_date = res[0];
            this.premInvFormB.controls['ach_withdrawal_date'].setValue( this.in_ach_withdrawal_date );
          }
      });
    }
  }

  corRevRepDateChgnd( invoice_type: any )
  {
    if( invoice_type == "policy_fee" )
    {
      this.in_report_date = this.premInvFormA.value.report_date;

      if( this.tc_type == 2 )
      {
        this.corRevChkAchDate( invoice_type,1 );
      }
    }
  }

  corRevChkAchDate( invoice_type: any,checkType:number )
  {
    if (invoice_type == "policy_fee")
    {
      this.spinnerService.hide();
      this.spinnerService.show();

      this.showAchAlert = false;

      var invoiceExists = false;

      this.invPolPostObj = {};
      if( checkType == 1 )
      {
          this.invPolPostObj.requiredCheckDate = this.in_report_date;
          this.invPolPostObj.PF_NPR = 20;
      }
      else if( checkType == 2 )
      {
          this.in_ach_withdrawal_date = this.premInvFormB.value.ach_withdrawal_date;
          this.invPolPostObj.requiredCheckDate = this.in_ach_withdrawal_date;
          this.invPolPostObj.PF_NPR = 2;
      }
      this.invPolPostObj.clientId = this.id;
      if (this.invoice_id != undefined && this.invoice_id != null && this.invoice_id)
      {
        this.invPolPostObj.currentInvSettingId = this.invoice_id;
      }
      this.policyService.checkPFNPRInvoice(this.invPolPostObj).subscribe(data => {
        this.spinnerService.hide();

        if (data != undefined && data != null && data.invoiceExists != undefined && data.invoiceExists != null) {
          this.pfAchInvalid = true;
          invoiceExists = true;
          this.showAchAlert = true;

          // this.invoice.metadata.ach_withdrawal_date = requiredCheckDate;
          if( checkType == 1 )
          {
            this.in_ach_withdrawal_date = data.plus10days;
          }
          this.premInvFormB.controls['ach_withdrawal_date'].setValue( this.in_ach_withdrawal_date );

          this.pfEditDate = this.in_ach_withdrawal_date;
        }
        else {
          this.pfAchInvalid = false;
          invoiceExists = false;

          // this.invoice.metadata.ach_withdrawal_date = requiredCheckDate;
          if( checkType == 1 )
          {
            this.in_ach_withdrawal_date = data.plus10days;
          }
          if( this.isCorRev > 0 )
          {
            this.premInvFormB.controls['ach_withdrawal_date'].setValue( this.in_ach_withdrawal_date );
          }
    
          this.pfEditDate = this.in_ach_withdrawal_date;
        }
      });
    }
  }

  pfCheckAchDate( checkType:number )
  {
    this.spinnerService.hide();
    this.spinnerService.show();

    this.showAchAlert = false;

    var invoiceExists = false;
    // this.requiredCheckDate = requiredCheckDate;

    this.invPolPostObj = {};
    if( checkType == 1 )
    {
        this.invPolPostObj.requiredCheckDate = this.in_report_date;
        this.invPolPostObj.PF_NPR = 20;
    }
    else if( checkType == 2 )
    {
        this.in_ach_withdrawal_date = this.premInvFormB.value.ach_withdrawal_date;
        this.invPolPostObj.requiredCheckDate = this.in_ach_withdrawal_date;
        this.invPolPostObj.PF_NPR = 2;
    }
    this.invPolPostObj.clientId = this.id;
    if (this.invoice_id != undefined && this.invoice_id != null && this.invoice_id) {
      this.invPolPostObj.currentInvSettingId = this.invoice_id;
    }
    this.policyService.checkPFNPRInvoice(this.invPolPostObj).subscribe(data => {
      this.spinnerService.hide();

      if (data != undefined && data != null && data.invoiceExists != undefined && data.invoiceExists != null) {
        this.pfAchInvalid = true;
        invoiceExists = true;
        this.showAchAlert = true;
        // alert( "Invoice exists with ACH Withdrawal date.Please change the date.\nInvoice will not be saved with this date." );
        this.pfShowAchDate = true;

        // this.invoice.metadata.ach_withdrawal_date = requiredCheckDate;
        if( checkType == 1 )
        {
          this.in_ach_withdrawal_date = data.plus10days;
        }
        this.premInvFormB.controls['ach_withdrawal_date'].setValue( this.in_ach_withdrawal_date );
        
        this.pfEditDate = this.in_ach_withdrawal_date;
      }
      else {
        this.pfAchInvalid = false;
        invoiceExists = false;

        // this.invoice.metadata.ach_withdrawal_date = requiredCheckDate;
        if( checkType == 1 )
        {
          this.in_ach_withdrawal_date = data.plus10days;
        }
        if( this.isCorRev > 0 )
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue( this.in_ach_withdrawal_date );
        }
        
        this.pfEditDate = this.in_ach_withdrawal_date;
      }
    });
  }

  pfChangeAchDate()
  {
    if( this.tc_type == 1 )
    {
      // this.invoice.metadata.ach_withdrawal_date = this.pf_backup_ach_withdrawal_date;
      this.in_ach_withdrawal_date = this.in_backup_ach_withdrawal_date;
      if( this.isCorRev > 0 )
      {
        this.premInvFormB.controls['ach_withdrawal_date'].setValue( this.in_ach_withdrawal_date );
      }
    }
    else if( this.tc_type == 2 )
    {
      if( ! this.pfShowAchDate )
      {
        if( this.isCorRev > 0 )
        {
          this.pfShowAchDate = true;
        }
        this.pfCheckAchDate( 1 );
      }
      else
      {
        // this.invoice.metadata.ach_withdrawal_date = this.pfEditDate;
        // this.in_ach_withdrawal_date = this.invoice.metadata.ach_withdrawal_date.toLocaleDateString('en-US');
        this.in_ach_withdrawal_date = this.pfEditDate;
        if( this.isCorRev > 0 )
        {
          this.premInvFormB.controls['ach_withdrawal_date'].setValue( this.in_ach_withdrawal_date );
        }
  
        if( this.pfAchInvalid )
        {
          // alert( "Invoice exists with ACH Withdrawal date.Please change the date.\nInvoice will not be saved with this date." );
          this.showAchAlert = true;
        }
      }
    }
  }

  revertQueue( queueRevertObj )
  {
    this.policyService.reverseQueue(queueRevertObj).subscribe
    (
      response =>
      {
        this.spinnerService.hide();
        if( response != undefined && response != null && response.reverted != undefined && response.reverted != null && response.reverted == 1 )
        {
          if( response != undefined && response != null && response.pdfExists != undefined && response.pdfExists != null && response.pdfExists == 0 )
          {
              this.showGenFailed = true;
          }
          else if( response != undefined && response != null && response.urlExists != undefined && response.urlExists != null && response.urlExists == 0 )
          {
              this.showGenFailed2 = true;
          }
          else
          {
            this.showGenFailed1 = true;
          }
        }
        else
        {
          this.showGenFailed1 = true;
        }
      },
      error =>
      {
        this.spinnerService.hide();
        this.showGenFailed1 = true;
      }
    );
  }
  invCrGoBack()
  {
    this.router.navigateByUrl('clients/list/' + this.id + '/invoice');
  }

  saveNPRInvoice(qmClicked: boolean) {
    this.invoiceSubmitted = true;

    if (qmClicked) {
      this.spinnerService.show();
    }
    else {
      if (this.fireIntimation) {
        this.policyService.toggleSidebarVisibility();
      }
    }

    this.invoicePostObj = {};

    this.invoicePostObj.invoice_settings = {};
    this.invoicePostObj.invoice_settings.is_report_generated = 0;
    this.invoicePostObj.invoice_settings.invoice_status = this.invoiceStatus;
    this.invoicePostObj.invoice_settings.invoice_type = 3;
    this.invoicePostObj.invoice_settings.client_id = this.id;
    this.invoicePostObj.invoice_settings.user_id = this.storage.get('app.auth').id;
    this.invoicePostObj.invoice_settings.notes = this.notesInvSave;

    this.invoicePostObj.invoice_settings.rpm_admin_fee = 0;
    // Credit Card Fee
    this.invoicePostObj.invoice_settings.has_credit_card = 0;
    this.invoicePostObj.invoice_settings.credit_card = 0;
    if (this.invoice.nprCC != undefined && this.invoice.nprCC != null && this.invoice.nprCC) {
      // this.invoicePostObj.invoice_settings.credit_card = this.invoice.metadata.credit_card_fee.toFixed(2);
      this.invoicePostObj.invoice_settings.credit_card = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.invoicePostObj.invoice_settings.has_credit_card = 1;
    }

    this.invoicePostObj.invoice_settings.edit_retry_new=1;
    
    this.invoicePostObj.invoice_settings.is_nsf = this.is_nsf;
    this.invoicePostObj.invoice_settings.nsf_new = 0;
    this.invoicePostObj.invoice_settings.nsf_fee = 0;
    this.invoicePostObj.invoice_settings.retry_payment_fee = 0;
    if (this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf)
    {
      this.invoicePostObj.invoice_settings.nsf_new = 1;
      // this.invoicePostObj.invoice_settings.nsf_fee = this.invoice.metadata.nsf_fee;
      this.invoicePostObj.invoice_settings.nsf_fee = parseFloat(this.invoice.metadata.nsf_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      // this.invoicePostObj.invoice_settings.retry_payment_fee = this.invoice.metadata.retry_payment_fee;
      this.invoicePostObj.invoice_settings.retry_payment_fee = parseFloat(this.invoice.metadata.retry_payment_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    }

    if( this.isCorRev != undefined && this.isCorRev != null && this.isCorRev == 0 )
    {
      this.invoicePostObj.invoice_settings.report_date = this.in_report_date;
      this.invoicePostObj.invoice_settings.ach_withdrawal_date = this.in_ach_withdrawal_date;
    }

    var pfForm = this.npr_form.value;
    if (pfForm != undefined && pfForm != null)
    {
      if( this.isCorRev != undefined && this.isCorRev != null && this.isCorRev == 3 )
      {
        if( this.npr_form.controls['report_date'].dirty === true )
        {
          this.invoicePostObj.invoice_settings.report_date = pfForm.report_date;
        }
        else
        {
          this.invoicePostObj.invoice_settings.report_date = pfForm.report_date;
        }

        if( this.npr_form.controls['ach_withdrawal_date'].dirty === true )
        {
          this.invoicePostObj.invoice_settings.ach_withdrawal_date = pfForm.ach_withdrawal_date;
        }
        else
        {
          this.invoicePostObj.invoice_settings.ach_withdrawal_date = pfForm.ach_withdrawal_date;
        }
      }
      
      // console.log(pfForm.payroll_due_date);
      if( this.npr_form.controls['payroll_due_date'].dirty === true )
      {
        this.invoicePostObj.invoice_settings.npr_payroll_due_date = pfForm.payroll_due_date;
      }
      else
      {
        this.invoicePostObj.invoice_settings.npr_payroll_due_date = pfForm.payroll_due_date;
      }
    

      this.invoicePostObj.invoice_settings.pol_holder_name = '';
      this.invoicePostObj.invoice_settings.fein = '';
      this.invoicePostObj.invoice_settings.pf_email = '';
      this.invoicePostObj.invoice_settings.policy_id = 0;
      this.invoicePostObj.invoice_settings.pf_pol_action_id = 0;
      this.invoicePostObj.invoice_settings.policy_number = '';
      this.invoicePostObj.invoice_settings.carrier_name = this.nprCarrierName;

      this.invoicePostObj.invoice_settings.sort_carrier = this.nprCarrierName;
      if( this.nprCarrierName == "Builders/National Bldrs (17)" )
      {
        this.invoicePostObj.invoice_settings.sort_carrier = "Builders/American Bldrs (22)";
      }
      else if( this.nprCarrierName == "Summit/BEIC" || this.nprCarrierName == "Summit/BFIC" || this.nprCarrierName == "Summit/RFIC" )
      {
        this.invoicePostObj.invoice_settings.sort_carrier = "Summit/BCIC";
      }

      if (this.client != undefined && this.client != null) {
        if (this.compNameToDisp != undefined && this.compNameToDisp != null) {
          this.invoicePostObj.invoice_settings.pol_holder_name = this.compNameToDisp;
        }
        if (this.feinToDisp != undefined && this.feinToDisp != null) {
          this.invoicePostObj.invoice_settings.fein = this.feinToDisp;
        }
        if (this.clientEmailToDisp != undefined && this.clientEmailToDisp != null) {
          this.invoicePostObj.invoice_settings.pf_email = this.clientEmailToDisp;
        }

        this.invoicePostObj.invoice_settings.pf_npr_change = 1;

        if (pfForm.policy_id != undefined && pfForm.policy_id != null)
        {
          if (this.client != undefined && this.client != null)
          {
            if (this.client.policies != undefined && this.client.policies != null)
            {
              for (let policy of this.client.policies)
              {
                for( let action of policy.actions_eff_date_change )
                {
                  if (action != undefined && action != null && action.id != undefined && action.id != null && action.id == pfForm.policy_id )
                  {
                    this.invoicePostObj.invoice_settings.policy_id = action.id;
                    if( action.policy_number != undefined && action.policy_number != null )
                    {
                      this.invoicePostObj.invoice_settings.policy_number = action.policy_number;
                    }
                  }
                }
              }
            }
          }
        }
        
        if (pfForm.npr_pol_action_id != undefined && pfForm.npr_pol_action_id) {
          this.invoicePostObj.invoice_settings.pf_pol_action_id = pfForm.npr_pol_action_id;
        }
      }
    }

    this.invoicePostObj.invoice_settings.custom_inv_number = this.custom_inv_number;
    this.invoicePostObj.invoice_settings.npr_invoice_total = this.nprInvoiceTotal;

    this.invoicePostObj.invoice_settings.queue_manual_type = this.queue_manual_type;

    this.invoicePostObj.invoice_settings.fax_queued = 0;
    this.invoicePostObj.invoice_settings.text_queued = 0;
    if( this.invoiceStatus == "Queue" )
    {
      this.invoicePostObj.invoice_settings.fax_queued = this.fax_queued;
      this.invoicePostObj.invoice_settings.text_queued = this.text_queued;
    }

    this.invoicePostObj.mode = 0;

    this.invoicePostObj.editInvoiceId = 0;
    if (!this.isFreshInvoice) {
      this.invoicePostObj.editInvoiceId = this.currInvoiceSettingId;
    }
    else
    {
      this.invoicePostObj.invoice_settings.default_credit_card = this.default_credit_card;
    }

    this.policyService.saveInvoice(this.invoicePostObj, 3).subscribe(
      response => {
        console.log(response);
        if (response instanceof HttpErrorResponse) {
          // this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
          alert("Could not save Invoice.");
        } else {
          // this.messageService.add({key:'top-corner', severity:'success', summary:'Success', detail:'Note updated successfully'});
          // alert( "Invoice Notes Saved Successfully." );
          if (qmClicked) {
            if (response != undefined && response != null && response.invoiceSetting != undefined && response.invoiceSetting != null) {
              if (response.invoiceSetting.id != undefined && response.invoiceSetting.id != null) {
                this.invoice_id = response.invoiceSetting.id
              }
            }
            
            var genFailedRevert:boolean=false;
            
            // New : PDF Generating Service called.
            var invoicePDFObj:any={};
            invoicePDFObj.invoiceId = this.invoice_id;
            invoicePDFObj.clientId = this.id;
            invoicePDFObj.invoiceType=3;
            this.policyService.genInvoicePDF(invoicePDFObj).subscribe
            (
              response =>
              {
                console.log(response);
                if (response instanceof HttpErrorResponse)
                {
                  genFailedRevert = true;
                  // this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
                  // alert("Could not save gen Invoice pdf.");
                }
                else
                {
                  // Nothing to do right now.
                }

                if( response != undefined && response != null && response.fileNotGenerated != undefined && response.fileNotGenerated != null && response.fileNotGenerated == 1 )
                {
                  genFailedRevert = true;
                }
    
                if( ! genFailedRevert )
                {
                  this.statusBeingUpdated = false;

                  // Submitted status remains false for Queue/Manual.
                  // this.invoiceSubmitted = false;
      
                  this.isQueueStatus = true;
                  this.spinnerService.hide();
      
                  // Client asked to stop alert.
                  // alert( "Invoice Saved and Set to Queue." );
      
                  window.open(this.configServiceService.getApiUrl() + '/nprpdf?download=pdf&invoiceId=' + this.invoice_id + '&id=' + this.id + '&up=1', 'blank');
                  this.router.navigateByUrl('clients/list/' + this.id + '/invoice');
                }
                else
                {
                    this.statusBeingUpdated = false;

                    var queueRevertObj:any={};
                    queueRevertObj.invoiceId = this.invoice_id;
                    queueRevertObj.clientId = this.id;
                    queueRevertObj.invoiceType=1;
                    this.revertQueue( queueRevertObj );
                }
              },
              error =>
              {
                console.log(error);
                this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
                // alert("Could not gen Invoice pdf.");

                this.statusBeingUpdated = false;

                // Submitted status remains false for Queue/Manual.
                // this.invoiceSubmitted = false;
    
                // this.isQueueStatus = true;
                // this.spinnerService.hide();
    
                // Client asked to stop alert.
                // alert( "Invoice Saved and Set to Queue." );
    
                // window.open(this.configServiceService.getApiUrl() + '/nprpdf?download=pdf&invoiceId=' + this.invoice_id + '&id=' + this.id + '&up=1', 'blank');
                // this.router.navigateByUrl('clients/list/' + this.id + '/invoice');
                var queueRevertObj:any={};
                queueRevertObj.invoiceId = this.invoice_id;
                queueRevertObj.clientId = this.id;
                queueRevertObj.invoiceType=1;
                this.revertQueue( queueRevertObj );
              });
              // End: PDF Generating Service called.
          }
          else {
            alert("Invoice saved as Incomplete.");
            if (this.fireIntimation) {
              this.policyService.toggleSidebarVisibility();
            }
          }
        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        alert("Could not save Invoice.");
      });

  }

  savePFInvoice(qmClicked: boolean) {
    this.invoiceSubmitted = true;

    if (qmClicked) {
      this.spinnerService.show();
    }
    else {
      if (this.fireIntimation) {
        this.policyService.toggleSidebarVisibility();
      }
    }

    this.invoicePostObj = {};

    this.invoicePostObj.invoice_settings = {};
    this.invoicePostObj.invoice_settings.is_report_generated = 0;
    this.invoicePostObj.invoice_settings.invoice_status = this.invoiceStatus;
    if(this.miscType == 0)
    {
      this.invoicePostObj.invoice_settings.invoice_type = 2;
    }
    else
    {
      this.invoicePostObj.invoice_settings.invoice_type = 5;
    }
    this.invoicePostObj.invoice_settings.client_id = this.id;
    this.invoicePostObj.invoice_settings.user_id = this.storage.get('app.auth').id;
    this.invoicePostObj.invoice_settings.notes = this.notesInvSave;
    // this.invoicePostObj.invoice_settings.notes = "Notes Check";

    this.invoicePostObj.invoice_settings.pf_has_admin_fee = 0;
    this.invoicePostObj.invoice_settings.rpm_admin_fee = 0;
    if( this.invoice.pfAdminFee != undefined && this.invoice.pfAdminFee != null && this.invoice.pfAdminFee )
    {
      this.invoicePostObj.invoice_settings.pf_has_admin_fee = 1;
      if (this.invoice.metadata.admin_fee != undefined && this.invoice.metadata.admin_fee != null) {
        // this.invoicePostObj.invoice_settings.rpm_admin_fee = this.invoice.metadata.admin_fee;
        this.invoicePostObj.invoice_settings.rpm_admin_fee = parseFloat(this.invoice.metadata.admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      }
    }

    // Credit Card Fee
    this.invoicePostObj.invoice_settings.has_credit_card = 0;
    this.invoicePostObj.invoice_settings.credit_card = 0;
    if (this.invoice.pfCC != undefined && this.invoice.pfCC != null && this.invoice.pfCC) {
      // this.invoicePostObj.invoice_settings.credit_card = this.invoice.metadata.credit_card_fee.toFixed(2);
      this.invoicePostObj.invoice_settings.credit_card = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.invoicePostObj.invoice_settings.has_credit_card = 1;
    }

    this.invoicePostObj.invoice_settings.edit_retry_new=1;

    this.invoicePostObj.invoice_settings.is_void = this.is_void;
    this.invoicePostObj.invoice_settings.is_nsf = this.is_nsf;
    this.invoicePostObj.invoice_settings.is_revised = this.is_revised;

    this.invoicePostObj.invoice_settings.ori_cust_inv_number = this.ori_cust_inv_number;
    this.invoicePostObj.invoice_settings.ori_nsf_inv_number = this.ori_nsf_inv_number;
    this.invoicePostObj.invoice_settings.ori_rev_inv_number = this.ori_rev_inv_number;
    this.invoicePostObj.invoice_settings.ori_inv_number = this.ori_inv_number;

    this.invoicePostObj.invoice_settings.nsf_new = 0;
    this.invoicePostObj.invoice_settings.nsf_fee = 0;
    this.invoicePostObj.invoice_settings.retry_payment_fee = 0;
    if (this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf) {
      this.invoicePostObj.invoice_settings.nsf_new = 1;
      // this.invoicePostObj.invoice_settings.nsf_fee = this.invoice.metadata.nsf_fee;
      this.invoicePostObj.invoice_settings.nsf_fee = parseFloat(this.invoice.metadata.nsf_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      // this.invoicePostObj.invoice_settings.retry_payment_fee = this.invoice.metadata.retry_payment_fee;
      this.invoicePostObj.invoice_settings.retry_payment_fee = parseFloat(this.invoice.metadata.retry_payment_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    }


    this.invoicePostObj.invoice_settings.report_date = this.in_report_date;
    if (this.pfShowAchDate != undefined && this.pfShowAchDate != null && this.pfShowAchDate) {
      this.invoicePostObj.invoice_settings.ach_withdrawal_date = this.in_ach_withdrawal_date;
    }
    else {
      this.invoicePostObj.invoice_settings.ach_withdrawal_date = this.in_ach_withdrawal_date;
    }

    var pfForm = this.policy_fee_form.value;
    if (pfForm != undefined && pfForm != null)
    {
      this.invoicePostObj.invoice_settings.bill_to_type = 0;
      if (pfForm.bill_to_type != undefined && pfForm.bill_to_type != null && pfForm.bill_to_type != '')
      {
        this.invoicePostObj.invoice_settings.bill_to_type = pfForm.bill_to_type;

        this.invoicePostObj.invoice_settings.bill_to_id = 0;
        this.invoicePostObj.invoice_settings.pf_comp_name = '';
        this.invoicePostObj.invoice_settings.pf_address = '';
        this.invoicePostObj.invoice_settings.pf_city = '';
        this.invoicePostObj.invoice_settings.pf_state = '';
        this.invoicePostObj.invoice_settings.pf_zip = '';
        this.invoicePostObj.invoice_settings.pf_phone = '';
        this.invoicePostObj.invoice_settings.pf_email = '';

        if( pfForm.bill_to_type == 1 )
        {
          if(this.pfBillToId != undefined && this.pfBillToId != null)
          {
            this.invoicePostObj.invoice_settings.bill_to_id = this.pfBillToId;
          }
          if (this.pfShowName != undefined && this.pfShowName != null)
          {
            this.invoicePostObj.invoice_settings.pf_comp_name = this.pfShowName;
          }
          if (this.pfShowAddr != undefined && this.pfShowAddr != null)
          {
            this.invoicePostObj.invoice_settings.pf_address = this.pfShowAddr;
          }
          if (this.pfShowCity != undefined && this.pfShowCity != null)
          {
            this.invoicePostObj.invoice_settings.pf_city = this.pfShowCity;
          }
          if (this.pfShowSt != undefined && this.pfShowSt != null)
          {
            this.invoicePostObj.invoice_settings.pf_state = this.pfShowSt;
          }
          if (this.pfShowZip != undefined && this.pfShowZip != null)
          {
            this.invoicePostObj.invoice_settings.pf_zip = this.pfShowZip;
          }
          if (this.pfShowPh != undefined && this.pfShowPh != null)
          {
            this.invoicePostObj.invoice_settings.pf_phone = this.pfShowPh;
          }
          if (this.pfShowEmail != undefined && this.pfShowEmail != null)
          {
            this.invoicePostObj.invoice_settings.pf_email = this.pfShowEmail;
          }
        }
        else if( pfForm.bill_to_type == 2 )
        {
          if(this.pfBillToId != undefined && this.pfBillToId != null)
          {
            this.invoicePostObj.invoice_settings.bill_to_id = this.pfBillToId;
          }
          if (this.pfShowName != undefined && this.pfShowName != null)
          {
            this.invoicePostObj.invoice_settings.pf_comp_name = this.pfShowName;
          }
          if (this.pfShowPh != undefined && this.pfShowPh != null)
          {
            this.invoicePostObj.invoice_settings.pf_phone = this.pfShowPh;
          }
          if (this.pfShowEmail != undefined && this.pfShowEmail != null)
          {
            this.invoicePostObj.invoice_settings.pf_email = this.pfShowEmail;
          }
        }
        else if( pfForm.bill_to_type == 3 )
        {
          if(this.pfBillToId != undefined && this.pfBillToId != null)
          {
            this.invoicePostObj.invoice_settings.bill_to_id = this.pfBillToId;
          }
          if (this.pfShowName != undefined && this.pfShowName != null)
          {
            this.invoicePostObj.invoice_settings.pf_comp_name = this.pfShowName;
          }
          if (this.pfShowAddr != undefined && this.pfShowAddr != null)
          {
            this.invoicePostObj.invoice_settings.pf_address = this.pfShowAddr;
          }
          if (this.pfShowCity != undefined && this.pfShowCity != null)
          {
            this.invoicePostObj.invoice_settings.pf_city = this.pfShowCity;
          }
          if (this.pfShowSt != undefined && this.pfShowSt != null)
          {
            this.invoicePostObj.invoice_settings.pf_state = this.pfShowSt;
          }
          if (this.pfShowZip != undefined && this.pfShowZip != null)
          {
            this.invoicePostObj.invoice_settings.pf_zip = this.pfShowZip;
          }
          if (this.pfShowPh != undefined && this.pfShowPh != null)
          {
            this.invoicePostObj.invoice_settings.pf_phone = this.pfShowPh;
          }
          if (this.pfShowEmail != undefined && this.pfShowEmail != null)
          {
            this.invoicePostObj.invoice_settings.pf_email = this.pfShowEmail;
          }
        }
        else if( pfForm.bill_to_type == 4 )
        {
          if(this.pfBillToId != undefined && this.pfBillToId != null)
          {
            this.invoicePostObj.invoice_settings.bill_to_id = this.pfBillToId;
          }
          if (this.pfShowName != undefined && this.pfShowName != null)
          {
            this.invoicePostObj.invoice_settings.pf_comp_name = this.pfShowName;
          }
          if (this.pfShowAddr != undefined && this.pfShowAddr != null)
          {
            this.invoicePostObj.invoice_settings.pf_address = this.pfShowAddr;
          }
          if (this.pfShowCity != undefined && this.pfShowCity != null)
          {
            this.invoicePostObj.invoice_settings.pf_city = this.pfShowCity;
          }
          if (this.pfShowSt != undefined && this.pfShowSt != null)
          {
            this.invoicePostObj.invoice_settings.pf_state = this.pfShowSt;
          }
          if (this.pfShowZip != undefined && this.pfShowZip != null)
          {
            this.invoicePostObj.invoice_settings.pf_zip = this.pfShowZip;
          }
          if (this.pfShowPh != undefined && this.pfShowPh != null)
          {
            this.invoicePostObj.invoice_settings.pf_phone = this.pfShowPh;
          }
          if (this.pfShowEmail != undefined && this.pfShowEmail != null)
          {
            this.invoicePostObj.invoice_settings.pf_email = this.pfShowEmail;
          }
        }
      }

      this.invoicePostObj.invoice_settings.pol_holder_name = '';
      this.invoicePostObj.invoice_settings.fein = '';
      this.invoicePostObj.invoice_settings.policy_id = 0;
      this.invoicePostObj.invoice_settings.pf_pol_action_id = 0;
      this.invoicePostObj.invoice_settings.policy_number = '';
      this.invoicePostObj.invoice_settings.carrier_name = this.nprCarrierName;

      this.invoicePostObj.invoice_settings.sort_carrier = this.nprCarrierName;
      if( this.nprCarrierName == "Builders/National Bldrs (17)" )
      {
        this.invoicePostObj.invoice_settings.sort_carrier = "Builders/American Bldrs (22)";
      }
      else if( this.nprCarrierName == "Summit/BEIC" || this.nprCarrierName == "Summit/BFIC" || this.nprCarrierName == "Summit/RFIC" )
      {
        this.invoicePostObj.invoice_settings.sort_carrier = "Summit/BCIC";
      }
      
      if (this.client != undefined && this.client != null) {
        if (this.compNameToDisp != undefined && this.compNameToDisp != null) {
          this.invoicePostObj.invoice_settings.pol_holder_name = this.compNameToDisp;
        }
        if (this.feinToDisp != undefined && this.feinToDisp != null) {
          this.invoicePostObj.invoice_settings.fein = this.feinToDisp;
        }

        this.invoicePostObj.invoice_settings.pf_npr_change = 1;

        if (pfForm.policy_id != undefined && pfForm.policy_id != null)
        {
          if (this.client != undefined && this.client != null)
          {
            if (this.client.policies != undefined && this.client.policies != null)
            {
              for (let policy of this.client.policies)
              {
                for( let action of policy.actions_eff_date_change )
                {
                  if (action != undefined && action != null && action.id != undefined && action.id != null && action.id == pfForm.policy_id )
                  {
                    this.invoicePostObj.invoice_settings.policy_id = action.id;
                    if( action.policy_number != undefined && action.policy_number != null )
                    {
                      this.invoicePostObj.invoice_settings.policy_number = action.policy_number;
                    }
                  }
                }
              }
            }
          }
        }

        if (pfForm.pf_pol_action_id != undefined && pfForm.pf_pol_action_id) {
          this.invoicePostObj.invoice_settings.pf_pol_action_id = pfForm.pf_pol_action_id;
        }
      }
    }

    this.invoicePostObj.invoice_settings.custom_inv_number = this.custom_inv_number;

    this.invoicePostObj.invoice_settings.tc_type = 0;
    if (this.tc_type != undefined && this.tc_type != null) {
      this.invoicePostObj.invoice_settings.tc_type = this.tc_type;
    }

    this.invoicePostObj.invoice_settings.queue_manual_type = this.queue_manual_type;

    this.invoicePostObj.invoice_settings.fax_queued = 0;
    this.invoicePostObj.invoice_settings.text_queued = 0;
    if( this.invoiceStatus == "Queue" )
    {
      this.invoicePostObj.invoice_settings.fax_queued = this.fax_queued;
      this.invoicePostObj.invoice_settings.text_queued = this.text_queued;
    }

    this.invoicePostObj.rates = [];
    if (this.pfRates != undefined && this.pfRates != null) {
      this.invoicePostObj.rates = this.pfRates;
      // console.log(this.invoicePostObj.rates);
    }

    this.invoicePostObj.mode = 0;

    this.invoicePostObj.editInvoiceId = 0;
    if (!this.isFreshInvoice) {
      this.invoicePostObj.editInvoiceId = this.currInvoiceSettingId;
    }
    else
    {
      this.invoicePostObj.invoice_settings.default_credit_card = this.default_credit_card;
    }

    this.policyService.saveInvoice(this.invoicePostObj, 2).subscribe(
      response => {
        console.log(response);
        if (response instanceof HttpErrorResponse) {
          // this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
          alert("Could not save Invoice.");
        } else {
          // this.messageService.add({key:'top-corner', severity:'success', summary:'Success', detail:'Note updated successfully'});
          // alert( "Invoice Notes Saved Successfully." );
          if (qmClicked) {
            if (response != undefined && response != null && response.invoiceSetting != undefined && response.invoiceSetting != null) {
              if (response.invoiceSetting.id != undefined && response.invoiceSetting.id != null) {
                this.invoice_id = response.invoiceSetting.id
              }
            }

            var genFailedRevert:boolean=false;

            // New : PDF Generating Service called.
            var invoicePDFObj:any={};
            invoicePDFObj.invoiceId = this.invoice_id;
            invoicePDFObj.clientId = this.id;
            invoicePDFObj.invoiceType=2;
            this.policyService.genInvoicePDF(invoicePDFObj).subscribe
            (
              response =>
              {
                console.log(response);
                if (response instanceof HttpErrorResponse)
                {
                  genFailedRevert = true;
                  // this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
                  // alert("Could not save gen Invoice pdf.");
                }
                else
                {
                  // Nothing to do right now.
                }

                if( response != undefined && response != null && response.fileNotGenerated != undefined && response.fileNotGenerated != null && response.fileNotGenerated == 1 )
                {
                  genFailedRevert = true;
                }
    
                if( ! genFailedRevert )
                {
                    this.statusBeingUpdated = false;

                    // Submitted status remains false for Queue/Manual.
                    // this.invoiceSubmitted = false;
        
                    this.isQueueStatus = true;
                    this.spinnerService.hide();
        
                    // Client asked to stop alert.
                    // alert( "Invoice Saved and Set to Queue." );
        
                    window.open(this.configServiceService.getApiUrl() + '/pfpdf?download=pdf&invoiceId=' + this.invoice_id + '&id=' + this.id + '&up=1', 'blank');
                    this.router.navigateByUrl('clients/list/' + this.id + '/invoice');
                }
                else
                {
                    this.statusBeingUpdated = false;

                    var queueRevertObj:any={};
                    queueRevertObj.invoiceId = this.invoice_id;
                    queueRevertObj.clientId = this.id;
                    queueRevertObj.invoiceType=1;
                    this.revertQueue( queueRevertObj );
                }
              },
              error =>
              {
                console.log(error);
                this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
                // alert("Could not gen Invoice pdf.");

                this.statusBeingUpdated = false;

                // Submitted status remains false for Queue/Manual.
                // this.invoiceSubmitted = false;
    
                // this.isQueueStatus = true;
                // this.spinnerService.hide();
    
                // Client asked to stop alert.
                // alert( "Invoice Saved and Set to Queue." );
    
                // window.open(this.configServiceService.getApiUrl() + '/pfpdf?download=pdf&invoiceId=' + this.invoice_id + '&id=' + this.id + '&up=1', 'blank');
                // this.router.navigateByUrl('clients/list/' + this.id + '/invoice');

                var queueRevertObj:any={};
                queueRevertObj.invoiceId = this.invoice_id;
                queueRevertObj.clientId = this.id;
                queueRevertObj.invoiceType=1;
                this.revertQueue( queueRevertObj );
               });
              // End: PDF Generating Service called.
          }
          else {
            alert("Invoice saved as Incomplete.");
            if (this.fireIntimation) {
              this.policyService.toggleSidebarVisibility();
            }
          }
        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        alert("Could not save Invoice.");
      });

  }

  saveInvoice(qmClicked: boolean) {
    this.invoiceSubmitted = true;

    if (qmClicked) {
      this.spinnerService.show();
    }
    else {
      if (this.fireIntimation) {
        this.policyService.toggleSidebarVisibility();
      }
    }

    this.invoicePostObj = {};

    this.invoicePostObj.invoice_settings = {};
    this.invoicePostObj.invoice_settings.is_report_generated = 0;
    this.invoicePostObj.invoice_settings.invoice_status = this.invoiceStatus;
    this.invoicePostObj.invoice_settings.invoice_type = 1;
    this.invoicePostObj.invoice_settings.client_id = this.id;
    this.invoicePostObj.invoice_settings.user_id = this.storage.get('app.auth').id;
    this.invoicePostObj.invoice_settings.notes = this.notesInvSave;
    // this.invoicePostObj.invoice_settings.notes = "Notes Check"; 

    this.invoicePostObj.invoice_settings.check_date = this.in_check_date;
    this.invoicePostObj.invoice_settings.payroll_end_date = this.in_payroll_end_date;

    if (this.save_dates_nsf != undefined && this.save_dates_nsf != null && this.save_dates_nsf)
    {
      this.invoicePostObj.invoice_settings.report_date = this.in_report_date;
      this.invoicePostObj.invoice_settings.ach_withdrawal_date = this.in_ach_withdrawal_date;
    }
    else
    {
      this.invoicePostObj.invoice_settings.report_date = this.in_report_date;
      this.invoicePostObj.invoice_settings.ach_withdrawal_date = this.in_ach_withdrawal_date;
    }

    // console.log('Dileep: '+this.requiredPolicy);
    this.invoicePostObj.invoice_settings.policy_id = this.requiredPolicy.id;
    this.invoicePostObj.invoice_settings.effective_date = this.requiredPolicy.effective_date.toString();
    this.invoicePostObj.invoice_settings.servicing_agency = this.srAgencyName;
    this.invoicePostObj.invoice_settings.sr_agency_phone = this.srAgencyPhone;
    this.invoicePostObj.invoice_settings.fein = this.feinToDisp;
    this.invoicePostObj.invoice_settings.policy_number = this.requiredPolicy.policy_number;
    this.invoicePostObj.invoice_settings.carrier_name = this.requiredPolicy.carrier.carrier_name;

    this.invoicePostObj.invoice_settings.sort_carrier = this.requiredPolicy.carrier.carrier_name;
    if( this.requiredPolicy.carrier.carrier_name == "Builders/National Bldrs (17)" )
    {
      this.invoicePostObj.invoice_settings.sort_carrier = "Builders/American Bldrs (22)";
    }
    else if( this.requiredPolicy.carrier.carrier_name == "Summit/BEIC" || this.requiredPolicy.carrier.carrier_name == "Summit/BFIC" || this.requiredPolicy.carrier.carrier_name == "Summit/RFIC" )
    {
      this.invoicePostObj.invoice_settings.sort_carrier = "Summit/BCIC";
    }

    this.invoicePostObj.invoice_settings.rpm_admin_fee = 0;
    if (this.invoice.metadata.admin_fee != undefined && this.invoice.metadata.admin_fee != null) {
      // this.invoicePostObj.invoice_settings.rpm_admin_fee = this.invoice.metadata.admin_fee;
      this.invoicePostObj.invoice_settings.rpm_admin_fee = parseFloat(this.invoice.metadata.admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    }

    this.invoicePostObj.invoice_settings.has_first = 0;
    this.invoicePostObj.invoice_settings.first_invoice = 0;
    if (this.invoice.ngFirst != undefined && this.invoice.ngFirst != null && this.invoice.ngFirst) {
      this.invoicePostObj.invoice_settings.has_first = 1;
      // this.invoicePostObj.invoice_settings.first_invoice = this.invoice.metadata.setup_fee;
      this.invoicePostObj.invoice_settings.first_invoice = parseFloat(this.invoice.metadata.setup_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    }

    // Credit Card Fee
    this.invoicePostObj.invoice_settings.has_credit_card = 0;
    this.invoicePostObj.invoice_settings.credit_card = 0;
    if (this.invoice.ngCC != undefined && this.invoice.ngCC != null && this.invoice.ngCC) {
      // this.invoicePostObj.invoice_settings.credit_card = this.invoice.metadata.credit_card_fee;
      this.invoicePostObj.invoice_settings.credit_card = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      this.invoicePostObj.invoice_settings.has_credit_card = 1;
    }

    this.invoicePostObj.invoice_settings.is_void = this.is_void;
    this.invoicePostObj.invoice_settings.is_nsf = this.is_nsf;
    this.invoicePostObj.invoice_settings.is_revised = this.is_revised;

    this.invoicePostObj.invoice_settings.custom_inv_number = this.custom_inv_number;

    this.invoicePostObj.invoice_settings.ori_cust_inv_number = this.ori_cust_inv_number;
    this.invoicePostObj.invoice_settings.ori_nsf_inv_number = this.ori_nsf_inv_number;
    this.invoicePostObj.invoice_settings.ori_rev_inv_number = this.ori_rev_inv_number;
    this.invoicePostObj.invoice_settings.ori_inv_number = this.ori_inv_number;

    this.invoicePostObj.invoice_settings.nsf_new = 0;
    this.invoicePostObj.invoice_settings.nsf_fee = 0;
    this.invoicePostObj.invoice_settings.retry_payment_fee = 0;
    if (this.invoice.is_nsf != undefined && this.invoice.is_nsf != null && this.invoice.is_nsf) {
      this.invoicePostObj.invoice_settings.nsf_new = 1;
      // this.invoicePostObj.invoice_settings.nsf_fee = this.invoice.metadata.nsf_fee;
      this.invoicePostObj.invoice_settings.nsf_fee = parseFloat(this.invoice.metadata.nsf_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      // this.invoicePostObj.invoice_settings.retry_payment_fee = this.invoice.metadata.retry_payment_fee;
      // console.log( this.invoice.metadata.retry_payment_fee );
      this.invoicePostObj.invoice_settings.retry_payment_fee = parseFloat(this.invoice.metadata.retry_payment_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    }

    this.invoicePostObj.invoice_settings.queue_manual_type = this.queue_manual_type;

    this.invoicePostObj.invoice_settings.fax_queued = 0;
    this.invoicePostObj.invoice_settings.text_queued = 0;
    if( this.invoiceStatus == "Queue" )
    {
      this.invoicePostObj.invoice_settings.fax_queued = this.fax_queued;
      this.invoicePostObj.invoice_settings.text_queued = this.text_queued;
    }

    this.invoicePostObj.invoice_settings.is_pay_reporting = this.is_pay_reporting;

    this.invoicePostObj.invoices = [];
    if (this.invoice.detail != undefined && this.invoice.detail != null)
    {
      this.invoicePostObj.invoices = this.invoice.detail;
    }

    this.invoicePostObj.mode = 0;

    this.invoicePostObj.invoice_settings.sr_info_old = 0;
    this.invoicePostObj.invoice_settings.edit_retry_new=1;

    this.invoicePostObj.invoice_settings.prem_payroll_total=this.payrollTotal;
    this.invoicePostObj.invoice_settings.prem_old_new=1;

    this.invoicePostObj.editInvoiceId = 0;
    if (!this.isFreshInvoice) {
      this.invoicePostObj.editInvoiceId = this.currInvoiceSettingId;
    }
    else
    {
      this.invoicePostObj.invoice_settings.default_credit_card = this.default_credit_card;
      this.invoicePostObj.invoice_settings.sr_info_old = 1;
    }

    this.policyService.saveInvoice(this.invoicePostObj, 1).subscribe(
      response => {
        // console.log(response);
        if (response instanceof HttpErrorResponse) {
          // this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
          alert("Could not save Invoice.");
        } else {
          // this.messageService.add({key:'top-corner', severity:'success', summary:'Success', detail:'Note updated successfully'});
          // alert( "Invoice Notes Saved Successfully." );
          if (qmClicked)
          {
            if (response != undefined && response != null && response.invoiceSetting != undefined && response.invoiceSetting != null)
            {
              if (response.invoiceSetting.id != undefined && response.invoiceSetting.id != null)
              {
                this.invoice_id = response.invoiceSetting.id
              }
            }

            var genFailedRevert:boolean=false;

            // New : PDF Generating Service called.
            var invoicePDFObj:any={};
            invoicePDFObj.invoiceId = this.invoice_id;
            invoicePDFObj.clientId = this.id;
            invoicePDFObj.invoiceType=1;
            this.policyService.genInvoicePDF(invoicePDFObj).subscribe
            (
              response =>
              {
                // console.log(response);
                if (response instanceof HttpErrorResponse)
                {
                  genFailedRevert = true;
                  // this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
                  // alert("Could not save gen Invoice pdf.");
                }
                else
                {
                  // Nothing to do right now.
                }

                if( response != undefined && response != null && response.fileNotGenerated != undefined && response.fileNotGenerated != null && response.fileNotGenerated == 1 )
                {
                  genFailedRevert = true;
                }
    
                if( ! genFailedRevert )
                {
                    this.statusBeingUpdated = false;
                    // this.invoiceSubmitted = false;
                    this.isQueueStatus = true;
                    this.spinnerService.hide();
                    // alert( "Invoice Saved and Set to Queue." );
                    window.open(this.configServiceService.getApiUrl() + '/pdfview?download=pdf&invoiceId=' + this.invoice_id + '&id=' + this.id + '&up=0', 'blank');
                    this.router.navigateByUrl('clients/list/' + this.id + '/invoice');
                }
                else
                {
                    this.statusBeingUpdated = false;

                    var queueRevertObj:any={};
                    queueRevertObj.invoiceId = this.invoice_id;
                    queueRevertObj.clientId = this.id;
                    queueRevertObj.invoiceType=1;
                    this.revertQueue( queueRevertObj );
                }
            },
              error =>
              {
                console.log(error);
                this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
                // alert("Could not gen Invoice pdf.");

                this.statusBeingUpdated = false;
                // this.invoiceSubmitted = false;
                // this.isQueueStatus = true;
                // this.spinnerService.hide();
                // alert( "Invoice Saved and Set to Queue." );
                // window.open(this.configServiceService.getApiUrl() + '/pdfview?download=pdf&invoiceId=' + this.invoice_id + '&id=' + this.id + '&up=0', 'blank');
                // this.router.navigateByUrl('clients/list/' + this.id + '/invoice');
                
                var queueRevertObj:any={};
                queueRevertObj.invoiceId = this.invoice_id;
                queueRevertObj.clientId = this.id;
                queueRevertObj.invoiceType=1;
                this.revertQueue( queueRevertObj );
              });
              // End: PDF Generating Service called.
          }
          else
          {
            alert("Invoice saved as Incomplete.");
            if (this.fireIntimation)
            {
              this.policyService.toggleSidebarVisibility();
            }
          }

          /*
          // Display message for 2 seconds
          this.msgs.push({severity: 'success', summary: 'Invoice Saved', detail: 'A new invoice was added to the current batch'});
          setTimeout(() => {
            this.msgs = [];
            this.router.navigate(['clients/list/' + this.id + '/invoice']);
          }, 2000);
          */

        }

      },
      error => {
        this.spinnerService.hide();
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        alert("Could not save Invoice.");
      });

  }

  updateStatus(newStatus: string, queue_manual_type: number) {
    var validateGO = 0;
    if(this.invoice.detail){
      var i = 0;
      for (let currInvDetail of this.invoice.detail)
      {
        if (currInvDetail != undefined && currInvDetail !== null && (currInvDetail.gross_wages != undefined && currInvDetail.gross_wages != null && currInvDetail.gross_wages > 0))
        {
          validateGO = 1;
          this.invoice.detail[i].gross_wages = parseFloat(currInvDetail.gross_wages.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        }
        if (currInvDetail != undefined && currInvDetail !== null && (currInvDetail.overtime_wages != undefined && currInvDetail.overtime_wages != null && currInvDetail.overtime_wages > 0))
        {
          validateGO = 1;
          this.invoice.detail[i].overtime_wages = parseFloat(currInvDetail.overtime_wages.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        }
        i++;
      }
    }

    if(validateGO != 1)
    {
      alert("Please fill at least one wage.");
    }
    // console.log(this.in_report_date);
    else if( this.in_report_date == undefined || this.in_report_date == null || this.in_report_date == "" )
    {
      alert("Please select report date.");
      // this.formValid = false;
    }
    else if( this.in_ach_withdrawal_date == undefined || this.in_ach_withdrawal_date == null || this.in_ach_withdrawal_date == "" )
    {
      alert("Please select ach withdrawal date.");
      // this.formValid = false;
    }
    else
    {
      if (this.invoice_id != undefined && this.invoice_id != null && this.invoice_id > 0) {
        this.premiumValid = false;
        this.checkFormValid();
        if (this.premiumValid) {
          this.statusBeingUpdated = true;
          this.queue_manual_type = queue_manual_type;

          this.invoiceStatus = newStatus;
          this.isFreshInvoice = false;
          this.currInvoiceSettingId = this.invoice_id;

          this.invoiceStatus = newStatus;
          this.saveInvoice(true);
        }
        else {
          alert("Please fill all the required Invoice details1.");
        }
      }
      else {
        this.premiumValid = false;
        this.checkFormValid();
        if (this.premiumValid) {
          this.statusBeingUpdated = true;
          this.queue_manual_type = queue_manual_type;

          this.invoiceStatus = newStatus;
          this.saveInvoice(true);
        }
        else {
          alert("Please fill all the required Invoice details2.");
        }
      }
    }
  }

  savePRInvoice(newStatus: string, queue_manual_type: number)
  {
    var validateGO = 0;
    if(this.invoice.detail){
      var i = 0;
      for (let currInvDetail of this.invoice.detail)
      {
        if (currInvDetail != undefined && currInvDetail !== null && (currInvDetail.gross_wages != undefined && currInvDetail.gross_wages != null && currInvDetail.gross_wages > 0))
        {
          validateGO = 1;
          this.invoice.detail[i].gross_wages = parseFloat(currInvDetail.gross_wages.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        }
        if (currInvDetail != undefined && currInvDetail !== null && (currInvDetail.overtime_wages != undefined && currInvDetail.overtime_wages != null && currInvDetail.overtime_wages > 0))
        {
          validateGO = 1;
          this.invoice.detail[i].overtime_wages = parseFloat(currInvDetail.overtime_wages.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
        }
        i++;
      }
    }

    if(validateGO != 1)
    {
      alert("Please fill at least one wage.");
    }
    // console.log(this.in_report_date);
    else if( this.in_report_date == undefined || this.in_report_date == null || this.in_report_date == "" )
    {
      alert("Please select report date.");
      // this.formValid = false;
    }
    else
    {
      if (this.invoice_id != undefined && this.invoice_id != null && this.invoice_id > 0) {
        this.premiumValid = false;
        this.checkFormValid();
        if (this.premiumValid) {
          this.statusBeingUpdated = true;
          this.queue_manual_type = 0;

          this.invoiceStatus = newStatus;
          this.isFreshInvoice = false;
          this.currInvoiceSettingId = this.invoice_id;

          this.invoiceStatus = newStatus;
          this.saveInvoice(true);
        }
        else {
          alert("Please fill all the required Invoice details3.");
        }
      }
      else {
        this.premiumValid = false;
        this.checkFormValid();
        if (this.premiumValid) {
          this.statusBeingUpdated = true;
          this.queue_manual_type = 0;

          this.invoiceStatus = newStatus;
          this.saveInvoice(true);
        }
        else {
          alert("Please fill all the required Invoice details4.");
        }
      }
    }
  }

  checkPDF() {
    this.policyService.getInvoicePDF(this.id).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
        } else {
        }
      },
      error => {
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
      });

  }

  updatePFStatus(newStatus: string, queue_manual_type: number) {
    if (this.invoice_id != undefined && this.invoice_id != null && this.invoice_id > 0) {
      this.checkPFForm();
      if (this.pfFormValid) {

        var ensurePFRates = false;
        if( this.pfRates == undefined || this.pfRates == null || ( ! (this.pfRates instanceof Array) ) || this.pfRates.length == 0 )
        {
          alert( "Please add atleast one service charge." );
        }
        else
        {
          this.statusBeingUpdated = true;
          this.queue_manual_type = queue_manual_type;
  
          this.invoiceStatus = newStatus;
          this.isFreshInvoice = false;
          this.currInvoiceSettingId = this.invoice_id;
  
          this.invoiceStatus = newStatus;
          this.savePFInvoice(true);
        }
      }
      else {
        alert("Please fill all the required Invoice details5.");
      }
    }
    else {
      this.checkPFForm();
      if (this.pfFormValid) {

        var ensurePFRates = false;
        // console.log( this.pfRates instanceof Array );
        if( this.pfRates == undefined || this.pfRates == null || ( ! (this.pfRates instanceof Array) ) || this.pfRates.length == 0 )
        {
          alert( "Please add atleast one service charge." );
        }
        else
        {
          this.statusBeingUpdated = true;
          this.queue_manual_type = queue_manual_type;
  
          this.invoiceStatus = newStatus;
          this.savePFInvoice(true);
        }
      }
      else {
        alert("Please fill all the required Invoice details6.");
      }
    }
  }

  updateNPRStatus(newStatus: string, queue_manual_type: number) {
    if (this.invoice_id != undefined && this.invoice_id != null && this.invoice_id > 0) {
      this.checkNPRForm();
      if (this.nprFormValid) {
        this.statusBeingUpdated = true;
        this.queue_manual_type = queue_manual_type;

        this.invoiceStatus = newStatus;
        this.isFreshInvoice = false;
        this.currInvoiceSettingId = this.invoice_id;

        this.invoiceStatus = newStatus;
        this.saveNPRInvoice(true);
      }
      else {
        alert("Please fill all the required Invoice details7.");
      }
    }
    else {
      this.checkNPRForm();
      if (this.nprFormValid) {
        this.statusBeingUpdated = true;
        this.queue_manual_type = queue_manual_type;

        this.invoiceStatus = newStatus;
        this.saveNPRInvoice(true);
      }
      else {
        alert("Please fill all the required Invoice details8.");
      }
    }
  }

}
