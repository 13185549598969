import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Client } from '../client';
import { Contact } from '../contact';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../client.service';
import { Location } from '@angular/common';
import { SpinnerService } from '../../core';
import { HttpErrorResponse } from '@angular/common/http';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

@Component({
  selector: 'app-view-client',
  templateUrl: './view-client.component.html',
  styleUrls: ['./view-client.component.css']
})
export class ViewClientComponent implements OnInit {
  @ViewChild('documentsList', { static: true }) documentsList;
  id: number;
  client: Client;
  contacts: any = [];
  documents: any = [];

  siaName:string='';
  amName:string='';
  siaRole:string='';
  siaPhone:string='';

  ag2Name:string='';
  ag2Email:string='';
  ag2Role:string='';
  ag2Phone:string='';

  uploadedFile:any=null;
  uploadedFileName:any=null;
  uploadInProgress:boolean = false;
  showSelFile:boolean = false;
  invUplSuccess:boolean = false;
  invUplFail:boolean = false;
  invMsgNotUpl:boolean = false;

  uplInvoiceInfo: any = {};
  invUplRetMsg:String="";

  constructor(aroute: ActivatedRoute, private router: Router,
    private clientService: ClientService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    public location: Location,private spinnerService: SpinnerService ) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
   }

  ngOnInit() {
    this.refresh();
  }

  viewDocument(document:any){
    window.open(document.cd_path);
  }

  deleteDocument(document:any){
    this.spinnerService.show();
    this.clientService.deleteDocument(document.id).subscribe(res => {
      this.clientService.getDocuments(this.id).subscribe(resData => {
        this.spinnerService.hide();
        this.documents = resData;
      });
    });
  }


  refresh() {
    this.spinnerService.show();
    // Load client
    // alert( this.id );
   
    this.clientService.getOnlyClientData(this.id).subscribe(res => {
      this.client = res;
      this.documents = this.client.documents_data;

      if( this.client != undefined && this.client != null )
      {
        if( this.client.agency_data != undefined && this.client.agency_data != null )
        {
            if( this.client.agent != undefined && this.client.agent != null )
            {
                    if( this.client.agent == 1 || this.client.agent == 0)
                    {
                      this.siaName = this.client.agency_data.contact_1_name;
                    }
                    else if( this.client.agent == 2 )
                    {
                      this.siaName = this.client.agency_data.contact_2_name;
                    }
                    else if( this.client.agent == 3 )
                    {
                      this.siaName = this.client.agency_data.contact_3_name;
                    }
                    else if( this.client.agent == 4 )
                    {
                      this.siaName = this.client.agency_data.contact_4_name;
                    }
                    else if( this.client.agent == 5 )
                    {
                      this.siaName = this.client.agency_data.contact_5_name;
                    }
                    else if( this.client.agent == 6 )
                    {
                      this.siaName = this.client.agency_data.contact_6_name;
                    }
                    else if( this.client.agent == 7 )
                    {
                      this.siaName = this.client.agency_data.contact_7_name;
                    }
                    else if( this.client.agent == 8 )
                    {
                      this.siaName = this.client.agency_data.contact_8_name;
                    }
                    else if( this.client.agent == 9 )
                    {
                      this.siaName = this.client.agency_data.contact_9_name;
                    }
                    else if( this.client.agent == 10 )
                    {
                      this.siaName = this.client.agency_data.contact_10_name;
                    }
            }

            if( this.client.agent != undefined && this.client.agent != null )
            {
                    if( this.client.agent == 1 || this.client.agent == 0)
                    {
                      this.amName = this.client.agency_data.contact_1_email;
                    }
                    else if( this.client.agent == 2 )
                    {
                      this.amName = this.client.agency_data.contact_2_email;
                    }
                    else if( this.client.agent == 3 )
                    {
                      this.amName = this.client.agency_data.contact_3_email;
                    }
                    else if( this.client.agent == 4 )
                    {
                      this.amName = this.client.agency_data.contact_4_email;
                    }
                    else if( this.client.agent == 5 )
                    {
                      this.amName = this.client.agency_data.contact_5_email;
                    }
                    else if( this.client.agent == 6 )
                    {
                      this.amName = this.client.agency_data.contact_6_email;
                    }
                    else if( this.client.agent == 7 )
                    {
                      this.amName = this.client.agency_data.contact_7_email;
                    }
                    else if( this.client.agent == 8 )
                    {
                      this.amName = this.client.agency_data.contact_8_email;
                    }
                    else if( this.client.agent == 9 )
                    {
                      this.amName = this.client.agency_data.contact_9_email;
                    }
                    else if( this.client.agent == 10 )
                    {
                      this.amName = this.client.agency_data.contact_10_email;
                    }
              }

              if( this.client.agent != undefined && this.client.agent != null )
              {
                      if( this.client.agent == 1 || this.client.agent == 0)
                      {
                        this.siaRole = this.client.agency_data.contact_1_role;
                      }
                      else if( this.client.agent == 2 )
                      {
                        this.siaRole = this.client.agency_data.contact_2_role;
                      }
                      else if( this.client.agent == 3 )
                      {
                        this.siaRole = this.client.agency_data.contact_3_role;
                      }
                      else if( this.client.agent == 4 )
                      {
                        this.siaRole = this.client.agency_data.contact_4_role;
                      }
                      else if( this.client.agent == 5 )
                      {
                        this.siaRole = this.client.agency_data.contact_5_role;
                      }
                      else if( this.client.agent == 6 )
                      {
                        this.siaRole = this.client.agency_data.contact_6_role;
                      }
                      else if( this.client.agent == 7 )
                      {
                        this.siaRole = this.client.agency_data.contact_7_role;
                      }
                      else if( this.client.agent == 8 )
                      {
                        this.siaRole = this.client.agency_data.contact_8_role;
                      }
                      else if( this.client.agent == 9 )
                      {
                        this.siaRole = this.client.agency_data.contact_9_role;
                      }
                      else if( this.client.agent == 10 )
                      {
                        this.siaRole = this.client.agency_data.contact_10_role;
                      }
              }
  
              if( this.client.agent != undefined && this.client.agent != null )
              {
                      if( this.client.agent == 1 || this.client.agent == 0)
                      {
                        this.siaPhone = this.client.agency_data.contact_1_phone;
                      }
                      else if( this.client.agent == 2 )
                      {
                        this.siaPhone = this.client.agency_data.contact_2_phone;
                      }
                      else if( this.client.agent == 3 )
                      {
                        this.siaPhone = this.client.agency_data.contact_3_phone;
                      }
                      else if( this.client.agent == 4 )
                      {
                        this.siaPhone = this.client.agency_data.contact_4_phone;
                      }
                      else if( this.client.agent == 5 )
                      {
                        this.siaPhone = this.client.agency_data.contact_5_phone;
                      }
                      else if( this.client.agent == 6 )
                      {
                        this.siaPhone = this.client.agency_data.contact_6_phone;
                      }
                      else if( this.client.agent == 7 )
                      {
                        this.siaPhone = this.client.agency_data.contact_7_phone;
                      }
                      else if( this.client.agent == 8 )
                      {
                        this.siaPhone = this.client.agency_data.contact_8_phone;
                      }
                      else if( this.client.agent == 9 )
                      {
                        this.siaPhone = this.client.agency_data.contact_9_phone;
                      }
                      else if( this.client.agent == 10 )
                      {
                        this.siaPhone = this.client.agency_data.contact_10_phone;
                      }
                }

                if( this.client.agent2 != undefined && this.client.agent2 != null && this.client.agent2 )
                {
                        if( this.client.agent2 == 1 )
                        {
                          this.ag2Name = this.client.agency_data.contact_1_name;
                        }
                        else if( this.client.agent2 == 2 )
                        {
                          this.ag2Name = this.client.agency_data.contact_2_name;
                        }
                        else if( this.client.agent2 == 3 )
                        {
                          this.ag2Name = this.client.agency_data.contact_3_name;
                        }
                        else if( this.client.agent2 == 4 )
                        {
                          this.ag2Name = this.client.agency_data.contact_4_name;
                        }
                        else if( this.client.agent2 == 5 )
                        {
                          this.ag2Name = this.client.agency_data.contact_5_name;
                        }
                        else if( this.client.agent2 == 6 )
                        {
                          this.ag2Name = this.client.agency_data.contact_6_name;
                        }
                        else if( this.client.agent2 == 7 )
                        {
                          this.ag2Name = this.client.agency_data.contact_7_name;
                        }
                        else if( this.client.agent2 == 8 )
                        {
                          this.ag2Name = this.client.agency_data.contact_8_name;
                        }
                        else if( this.client.agent2 == 9 )
                        {
                          this.ag2Name = this.client.agency_data.contact_9_name;
                        }
                        else if( this.client.agent2 == 10 )
                        {
                          this.ag2Name = this.client.agency_data.contact_10_name;
                        }
                }
    
                if( this.client.agent2 != undefined && this.client.agent2 != null && this.client.agent2 )
                {
                        if( this.client.agent2 == 1 )
                        {
                          this.ag2Email = this.client.agency_data.contact_1_email;
                        }
                        else if( this.client.agent2 == 2 )
                        {
                          this.ag2Email = this.client.agency_data.contact_2_email;
                        }
                        else if( this.client.agent2 == 3 )
                        {
                          this.ag2Email = this.client.agency_data.contact_3_email;
                        }
                        else if( this.client.agent2 == 4 )
                        {
                          this.ag2Email = this.client.agency_data.contact_4_email;
                        }
                        else if( this.client.agent2 == 5 )
                        {
                          this.ag2Email = this.client.agency_data.contact_5_email;
                        }
                        else if( this.client.agent2 == 6 )
                        {
                          this.ag2Email = this.client.agency_data.contact_6_email;
                        }
                        else if( this.client.agent2 == 7 )
                        {
                          this.ag2Email = this.client.agency_data.contact_7_email;
                        }
                        else if( this.client.agent2 == 8 )
                        {
                          this.ag2Email = this.client.agency_data.contact_8_email;
                        }
                        else if( this.client.agent2 == 9 )
                        {
                          this.ag2Email = this.client.agency_data.contact_9_email;
                        }
                        else if( this.client.agent2 == 10 )
                        {
                          this.ag2Email = this.client.agency_data.contact_10_email;
                        }
                  }
    
                  if( this.client.agent2 != undefined && this.client.agent2 != null && this.client.agent2 )
                  {
                          if( this.client.agent2 == 1 )
                          {
                            this.ag2Role = this.client.agency_data.contact_1_role;
                          }
                          else if( this.client.agent2 == 2 )
                          {
                            this.ag2Role = this.client.agency_data.contact_2_role;
                          }
                          else if( this.client.agent2 == 3 )
                          {
                            this.ag2Role = this.client.agency_data.contact_3_role;
                          }
                          else if( this.client.agent2 == 4 )
                          {
                            this.ag2Role = this.client.agency_data.contact_4_role;
                          }
                          else if( this.client.agent2 == 5 )
                          {
                            this.ag2Role = this.client.agency_data.contact_5_role;
                          }
                          else if( this.client.agent2 == 6 )
                          {
                            this.ag2Role = this.client.agency_data.contact_6_role;
                          }
                          else if( this.client.agent2 == 7 )
                          {
                            this.ag2Role = this.client.agency_data.contact_7_role;
                          }
                          else if( this.client.agent2 == 8 )
                          {
                            this.ag2Role = this.client.agency_data.contact_8_role;
                          }
                          else if( this.client.agent2 == 9 )
                          {
                            this.ag2Role = this.client.agency_data.contact_9_role;
                          }
                          else if( this.client.agent2 == 10 )
                          {
                            this.ag2Role = this.client.agency_data.contact_10_role;
                          }
                  }
      
                  if( this.client.agent2 != undefined && this.client.agent2 != null && this.client.agent2 )
                  {
                          if( this.client.agent2 == 1 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_1_phone;
                          }
                          else if( this.client.agent2 == 2 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_2_phone;
                          }
                          else if( this.client.agent2 == 3 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_3_phone;
                          }
                          else if( this.client.agent2 == 4 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_4_phone;
                          }
                          else if( this.client.agent2 == 5 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_5_phone;
                          }
                          else if( this.client.agent2 == 6 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_6_phone;
                          }
                          else if( this.client.agent2 == 7 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_7_phone;
                          }
                          else if( this.client.agent2 == 8 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_8_phone;
                          }
                          else if( this.client.agent2 == 9 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_9_phone;
                          }
                          else if( this.client.agent2 == 10 )
                          {
                            this.ag2Phone = this.client.agency_data.contact_10_phone;
                          }
                    }
                    
        }
      }
      this.spinnerService.hide();
      // console.log(this.client);
    });
    this.clientService.getContactsFull(this.id).subscribe(res => {
      this.contacts = res;
    });
  }

  uplInvoice()
  {
    this.uploadInProgress = true;

    this.showSelFile = false;
    this.invUplSuccess = false;
    this.invUplFail = false;
    this.invMsgNotUpl = false;
    this.invUplRetMsg='';

    var goAhead = false;
    if( this.uploadedFile != undefined && this.uploadedFile != null )
    {
      goAhead = true;
    }

    if( goAhead )
    {
      this.showSelFile = false;
      this.invUplSuccess = false;
      this.invUplFail = false;
      this.invMsgNotUpl = false;
      this.invUplRetMsg='';
      this.spinnerService.show();

      this.uplInvoiceInfo = {};
      this.uplInvoiceInfo.uploadedFile = this.uploadedFile;
      this.uplInvoiceInfo.uploadedFileName = this.uploadedFileName;
      this.uplInvoiceInfo.client_id = this.id;
      this.uplInvoiceInfo.user_id = this.storage.get('app.auth').id;

      this.clientService.uploadDocumentHandler(this.uplInvoiceInfo).subscribe(response =>
      {
            this.spinnerService.hide();
            if( response instanceof HttpErrorResponse )
            {
              alert( "Could not be uploaded." );
            }
            else
            {
              if( response.output == '-1' || response.output == '-2' )
              {
                this.invUplFail = true;
              }
              if( response.output == '-10' )
              {
                this.invMsgNotUpl = true;
                this.invUplRetMsg = response.message;
              }

              this.uplInvoiceInfo = {};
              this.uploadedFile = null;
              this.uploadedFileName = null;
              document.getElementsByTagName('form')[0].reset();
              this.uploadInProgress = false;
              
              if( response.output == '3' )
              {
                this.invUplSuccess = true;
                this.spinnerService.show();
                this.clientService.getDocuments(this.id).subscribe(resData => {
                  this.spinnerService.hide();
                  this.documents = resData;
                });
              }
        }
      }
      ,
      error => {
        this.uplInvoiceInfo = {};
        this.uploadedFile = null;
        this.uploadedFileName = null;
        document.getElementsByTagName('form')[0].reset();
        this.uploadInProgress = false;
        this.invUplFail = true;
      });
    }
    else
    {
        this.uploadInProgress = false;
        this.showSelFile = true;
    }
  }

  onFileChange(event)
  {
    this.showSelFile = false;

    const reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        this.uploadedFile = reader.result;
        var filess = event.target.files;
        this.uploadedFileName = filess[0].name;
      };
    }
  }

  goToViewPage(pageName, id) {
    this.router.navigate(['/'+pageName+'/', id]);
  }
}
