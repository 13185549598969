import { Component, OnInit } from '@angular/core';
import { DocumentTemplate } from '../document-template';
import {ConfirmationService, Message} from 'primeng/api';
import {DocumentService} from '../document.service';
import { MenuItem } from "primeng/api";
import { SpinnerService } from '../../core';

import { SafePipe } from '../../safe.pipe';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})

export class TemplatesComponent implements OnInit {
  templates: DocumentTemplate[];
  msgs: Message[] = [];
  public items: MenuItem[];

  constructor(private docService: DocumentService, 
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.loadTemplates();
    this.items = [
      {
        label: 'Settings',
        icon: 'fa-gear',
        routerLink: [ '/settings' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Create Template',
        icon: 'fa-file-text-o',
        routerLink: [ '/settings/templates/create' ],
        routerLinkActiveOptions: { exact: true }
      }
    ];
  }

  loadTemplates() {
    this.docService.getTemplates().subscribe((res) => {
      this.templates = res;
      this.spinnerService.hide();
    });
  }

  deleteTemplate(id: number) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this template?',
      accept: () => {
        this.docService.deleteTemplate(id).subscribe(res => {
          // Display message for 2 seconds
          this.msgs.push({severity: 'success', summary: 'Template Deleted', detail: res.message});
          setTimeout(() => {
            this.msgs = [];
            this.loadTemplates();
          }, 2000);
        });
      }
    });
  }

}
