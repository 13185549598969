import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BrokersService } from '../brokers.service';
import { ClientService } from '../client.service';
import { SpinnerService } from '../../core';
import { emailValidator, phoneNumberValidator, zipCodeValidator } from '../../shared';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Message } from 'primeng/components/common/api';


@Component({
  selector: 'app-brokers',
  templateUrl: './brokers.component.html',
  styleUrls: ['./brokers.component.css']
})
export class BrokersComponent implements OnInit {
  msgs: Message[] = [];

  brokersForm: FormGroup;
  editBroker = false;
  btnShow:string='none';
  allObject: any;
  allObjectCount: any;
  usDateValue: any;
  formattedNumber: any;
  area: any;
  front: any;
  end: any;
  c: any;
  states = [];
  redError = 'red';
  broker_nameError = 'none';
  broker_web_siteError = 'none';
  broker_main_phoneError = 'none';
  broker_commission_percentageError = 'none';
  physical_addressError = 'none';
  physical_cityError = 'none';
  physical_stateError = 'none';
  physical_zipError = 'none';
  mailing_addressError = 'none';
  mailing_cityError = 'none';
  mailing_stateError = 'none';
  mailing_zipError = 'none';
  contact_1_nameError = 'none';
  contact_1_roleError = 'none';
  contact_1_phoneError = 'none';
  contact_2_phoneError = 'none';
  contact_3_phoneError = 'none';
  contact_4_phoneError = 'none';
  contact_1_emailError = 'none';
  contact_2_emailError = 'none';
  contact_3_emailError = 'none';
  contact_4_emailError = 'none';
  broker_notesError = 'none';
  broker_main_phoneError_message = "";
  broker_commission_percentageError_message = "";
  physical_zipError_message = "";
  mailing_zipError_message = "";
  contact_1_phoneError_message = "";
  contact_2_phoneError_message = "";
  contact_3_phoneError_message = "";
  contact_4_phoneError_message = "";
  contact_1_emailError_message = "";
  contact_2_emailError_message = "";
  contact_3_emailError_message = "";
  contact_4_emailError_message = "";
  skip: any;
  take: any;
  searchVal: any;
  filteredStates: any[];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private brokersService: BrokersService,
    private spinnerService: SpinnerService
  ) { }

  filterStates(event) {
    this.filteredStates = [];
    for (let i = 0; i < this.states.length; i++) {
      let s = this.states[i].value;
      if (s.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredStates.push(s);
      }
    }
  }
  scrollTo(className: string): void {
    setTimeout(function () {
      const elementList = document.querySelectorAll('.' + className);
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }
  ngOnInit() {
    this.brokersForm = this.formBuilder.group({
      broker_name: ['', Validators.required],
      broker_web_site: '',
      broker_main_phone: '',
      broker_commission_percentage: '',
      physical_address: '',
      physical_city: '',
      physical_state: '',
      physical_zip: '',
      mailing_address: '',
      mailing_city: '',
      mailing_state: '',
      mailing_zip: '',
      contact_1_name: '',
      contact_1_role: '',
      contact_1_phone: '',
      contact_1_email: '',
      contact_2_name: '',
      contact_2_role: '',
      contact_2_phone: '',
      contact_2_email: '',
      contact_3_name: '',
      contact_3_role: '',
      contact_3_phone: '',
      contact_3_email: '',
      contact_4_name: '',
      contact_4_role: '',
      contact_4_phone: '',
      contact_4_email: '',
      broker_notes: ''
    });

    this.clientService.getStates().subscribe(res => {
      this.states = res;
      this.states.unshift({ label: 'Select', value: '' });
    });
    this.skip = 0;
    this.take = 12;
    this.searchVal = '';
    this.getAll();
  }
  getAll() {
    //Get All Agenceies
    this.brokersService.getAll(this.skip, this.take, this.searchVal).subscribe(response => {
      this.allObject = response[1];
      this.allObjectCount = response[0];
      this.spinnerService.hide();
    });
  }
  loadData(event) {
    this.skip = event.first;
    this.take = event.rows;
    this.getAll();
  }
  search(event) {
    this.searchVal = event.target.value;
    this.skip = 0;
    this.take = 12;
    this.getAll();
  }

  feleFocus()
  {
    this.btnShow = 'block';
  }


  // Hide All Errors in the popup 
  hideAllErrors() {

    this.btnShow='none';

    
    this.broker_nameError = 'none';
    this.broker_web_siteError = 'none';
    this.broker_main_phoneError = 'none';
    this.broker_commission_percentageError = 'none';
    this.physical_addressError = 'none';
    this.physical_cityError = 'none';
    this.physical_stateError = 'none';
    this.physical_zipError = 'none';
    this.mailing_addressError = 'none';
    this.mailing_cityError = 'none';
    this.mailing_stateError = 'none';
    this.mailing_zipError = 'none';
    this.contact_1_nameError = 'none';
    this.contact_1_roleError = 'none';
    this.contact_1_phoneError = 'none';
    this.contact_2_phoneError = 'none';
    this.contact_3_phoneError = 'none';
    this.contact_4_phoneError = 'none';
    this.contact_1_emailError = 'none';
    this.contact_2_emailError = 'none';
    this.contact_3_emailError = 'none';
    this.contact_4_emailError = 'none';
    this.contact_1_phoneError_message = "";
    this.contact_2_phoneError_message = "";
    this.contact_3_phoneError_message = "";
    this.contact_4_phoneError_message = "";
    this.contact_1_emailError_message = "";
    this.contact_2_emailError_message = "";
    this.contact_3_emailError_message = "";
    this.contact_4_emailError_message = "";
    this.broker_notesError = 'none';
    this.broker_main_phoneError_message = "";
    this.broker_commission_percentageError_message = "";
    this.physical_zipError_message = "";
    this.mailing_zipError_message = "";
  }
  submitForm() {
    if (this.brokersForm.valid) {
      this.broker_nameError = 'none';
      var validateFlag = 0;
      if (this.brokersForm.value.broker_main_phone == '' || this.brokersForm.value.broker_main_phone == null) {
        // this.broker_main_phoneError = 'block';
        // this.broker_main_phoneError_message = 'Required';
      } else {
      }

      if (this.brokersForm.value.physical_zip == '' || this.brokersForm.value.physical_zip == null) {
        // this.physical_zipError = 'block';
        // this.physical_zipError_message = 'Required.';
      } else {
        const pattern = /^[0-9=]*$/;
        if (!this.brokersForm.value.physical_zip.match(pattern)) {
          this.physical_zipError = 'block';
          this.physical_zipError_message = 'Zip Code is not valid.';
          validateFlag = 1;
        } else {
          this.physical_zipError = 'none';
        }
      }

      if (this.brokersForm.value.mailing_zip == '' || this.brokersForm.value.mailing_zip == null) {
        // this.mailing_zipError = 'block';
        // this.mailing_zipError_message = 'Required.';
      } else {
        const pattern = /^[0-9=]*$/;
        if (!this.brokersForm.value.mailing_zip.match(pattern)) {
          this.mailing_zipError = 'block';
          this.mailing_zipError_message = 'Zip Code is not valid.';
          validateFlag = 1;
        } else {
          this.mailing_zipError = 'none';
        }
      }

      if (this.brokersForm.value.contact_1_phone == '' || this.brokersForm.value.contact_1_phone == null) {
        // this.contact_1_phoneError = 'block';
        // this.contact_1_phoneError_message = 'Required';
      } else {
      }

      if (this.brokersForm.value.contact_1_email == '' || this.brokersForm.value.contact_1_email == null) {
        // this.contact_1_emailError = 'block';
        // this.contact_1_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.brokersForm.value.contact_1_email)) {
          this.contact_1_emailError = 'block';
          this.contact_1_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_1_emailError = 'none';
        }
      }

      if (this.brokersForm.value.contact_2_phone == '' || this.brokersForm.value.contact_2_phone == null) {
        // this.contact_2_phoneError = 'block';
        // this.contact_2_phoneError_message = 'Required';
      } else {
      }

      if (this.brokersForm.value.contact_2_email == '' || this.brokersForm.value.contact_2_email == null) {
        // this.contact_2_emailError = 'block';
        // this.contact_2_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.brokersForm.value.contact_2_email)) {
          this.contact_2_emailError = 'block';
          this.contact_2_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_2_emailError = 'none';
        }
      }

      if (this.brokersForm.value.contact_3_phone == '' || this.brokersForm.value.contact_3_phone == null) {
        // this.contact_3_phoneError = 'block';
        // this.contact_3_phoneError_message = 'Required';
      } else {
      }

      if (this.brokersForm.value.contact_3_email == '' || this.brokersForm.value.contact_3_email == null) {
        // this.contact_3_emailError = 'block';
        // this.contact_3_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.brokersForm.value.contact_3_email)) {
          this.contact_3_emailError = 'block';
          this.contact_3_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_3_emailError = 'none';
        }
      }

      if (this.brokersForm.value.contact_4_phone == '' || this.brokersForm.value.contact_4_phone == null) {
        // this.contact_4_phoneError = 'block';
        // this.contact_4_phoneError_message = 'Required';
      } else {
      }

      if (this.brokersForm.value.contact_4_email == '' || this.brokersForm.value.contact_4_email == null) {
        // this.contact_4_emailError = 'block';
        // this.contact_4_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.brokersForm.value.contact_4_email)) {
          this.contact_4_emailError = 'block';
          this.contact_4_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_4_emailError = 'none';
        }
      }
      
      if(validateFlag == 0){
        var formObject = this.brokersForm.value;
        // console.log(agencyFormObject);
        this.brokersService.add(formObject).subscribe(response => {
          this.brokersForm.reset();
          this.editBroker = false;
          this.skip = 0;
          this.take = 12;
          this.searchVal = '';
          this.getAll();
        });
      }
    } else {
      if (this.brokersForm.value.broker_name == '' || this.brokersForm.value.broker_name == null) {
        this.broker_nameError = 'block';
      } else {
        this.broker_nameError = 'none';
      }
      // if (this.brokersForm.value.broker_web_site == '' || this.brokersForm.value.broker_web_site == null) {
      //   this.broker_web_siteError = 'block';
      // } else {
      //   this.broker_web_siteError = 'none';
      // }
      // if (this.brokersForm.value.broker_main_phone == '' || this.brokersForm.value.broker_main_phone == null) {
      //   this.broker_main_phoneError = 'block';
      //   this.broker_main_phoneError_message = 'Required';
      // } else {
      //   const pattern1 = /^\d{10}$/;
      //   const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      //   if (!this.brokersForm.value.broker_main_phone.match(pattern1) && !this.brokersForm.value.broker_main_phone.match(pattern2)) {
      //     this.broker_main_phoneError = 'block';
      //     this.broker_main_phoneError_message = 'Format allow (xxx) xxx-xxxx';
      //   } else {
      //     this.broker_main_phoneError = 'none';
      //   }
      // }

      // if (this.brokersForm.value.physical_address == '' || this.brokersForm.value.physical_address == null) {
      //   this.physical_addressError = 'block';
      // } else {
      //   this.physical_addressError = 'none';
      // }

      // if (this.brokersForm.value.physical_city == '' || this.brokersForm.value.physical_city == null) {
      //   this.physical_cityError = 'block';
      // } else {
      //   this.physical_cityError = 'none';
      // }
      // if (this.brokersForm.value.physical_state == '' || this.brokersForm.value.physical_state == null) {
      //   this.physical_stateError = 'block';
      // } else {
      //   this.physical_stateError = 'none';
      // }
      // if (this.brokersForm.value.physical_zip == '' || this.brokersForm.value.physical_zip == null) {
      //   this.physical_zipError = 'block';
      //   this.physical_zipError_message = 'Required.';
      // } else {
      //   const pattern = /^[0-9=]*$/;
      //   if (!this.brokersForm.value.physical_zip.match(pattern)) {
      //     this.physical_zipError = 'block';
      //     this.physical_zipError_message = 'Zip Code is not valid.';
      //   } else {
      //     this.physical_zipError = 'none';
      //   }
      // }

      // if (this.brokersForm.value.mailing_address == '' || this.brokersForm.value.mailing_address == null) {
      //   this.mailing_addressError = 'block';
      // } else {
      //   this.mailing_addressError = 'none';
      // }

      // if (this.brokersForm.value.mailing_city == '' || this.brokersForm.value.mailing_city == null) {
      //   this.mailing_cityError = 'block';
      // } else {
      //   this.mailing_cityError = 'none';
      // }
      // if (this.brokersForm.value.mailing_state == '' || this.brokersForm.value.mailing_state == null) {
      //   this.mailing_stateError = 'block';
      // } else {
      //   this.mailing_stateError = 'none';
      // }
      // if (this.brokersForm.value.mailing_zip == '' || this.brokersForm.value.mailing_zip == null) {
      //   this.mailing_zipError = 'block';
      //   this.mailing_zipError_message = 'Required.';
      // } else {
      //   const pattern = /^[0-9=]*$/;
      //   if (!this.brokersForm.value.mailing_zip.match(pattern)) {
      //     this.mailing_zipError = 'block';
      //     this.mailing_zipError_message = 'Zip Code is not valid.';
      //   } else {
      //     this.mailing_zipError = 'none';
      //   }
      // }

      // if (this.brokersForm.value.contact_1_name == '' || this.brokersForm.value.contact_1_name == null) {
      //   this.contact_1_nameError = 'block';
      // } else {
      //   this.contact_1_nameError = 'none';
      // }

      // if (this.brokersForm.value.contact_1_role == '' || this.brokersForm.value.contact_1_role == null) {
      //   this.contact_1_roleError = 'block';
      // } else {
      //   this.contact_1_roleError = 'none';
      // }

      // if (this.brokersForm.value.contact_1_phone == '' || this.brokersForm.value.contact_1_phone == null) {
      //   this.contact_1_phoneError = 'block';
      //   this.contact_1_phoneError_message = 'Required';
      // } else {
      //   const pattern1 = /^\d{10}$/;
      //   const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      //   if (!this.brokersForm.value.contact_1_phone.match(pattern1) && !this.brokersForm.value.contact_1_phone.match(pattern2)) {
      //     this.contact_1_phoneError = 'block';
      //     this.contact_1_phoneError_message = 'Format allow (xxx) xxx-xxxx';
      //   } else {
      //     this.contact_1_phoneError = 'none';
      //   }
      // }

      // if (this.brokersForm.value.contact_1_email == '' || this.brokersForm.value.contact_1_email == null) {
      //   this.contact_1_emailError = 'block';
      //   this.contact_1_emailError_message = 'Required';
      // } else {
      //   const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //   if (!EMAIL_REGEXP.test(this.brokersForm.value.contact_1_email)) {
      //     this.contact_1_emailError = 'block';
      //     this.contact_1_emailError_message = 'Email Format is not valid.';
      //   } else {
      //     this.contact_1_emailError = 'none';
      //   }
      // }

      // if (this.brokersForm.value.broker_notes == '' || this.brokersForm.value.broker_notes == null) {
      //   this.broker_notesError = 'block';
      // } else {
      //   this.broker_notesError = 'none';
      // }
    }
  }
  usFormatteddate(phonenumbrerlabel) {
    if (phonenumbrerlabel == 1) {
      this.usDateValue = this.brokersForm.value.broker_main_phone;
    } else if (phonenumbrerlabel == 2) {
      this.usDateValue = this.brokersForm.value.contact_1_phone;
    } else if (phonenumbrerlabel == 3) {
      this.usDateValue = this.brokersForm.value.contact_2_phone;
    } else if (phonenumbrerlabel == 4) {
      this.usDateValue = this.brokersForm.value.contact_3_phone;
    } else if (phonenumbrerlabel == 5) {
      this.usDateValue = this.brokersForm.value.contact_4_phone;
    }

    this.formattedNumber = String(this.usDateValue.replace(/[- )(]/g, ''));
    //  this.c = (this.formattedNumber[0] == '1') ? '1 ' : '';
    //   this.formattedNumber = this.formattedNumber[0] == '1' ? this.formattedNumber.slice(1) : this.formattedNumber;

    // # (###) ###-#### as c (area) front-end
    this.area = this.formattedNumber.substring(0, 3);
    this.front = this.formattedNumber.substring(3, 6);
    this.end = this.formattedNumber.substring(6);

    if (this.front) {
      this.formattedNumber = ("(" + this.area + ") " + this.front);
    }
    if (this.end) {
      this.formattedNumber += ("-" + this.end);
    }

    // New Code for Ext Cleaned
    if( this.formattedNumber != undefined && this.formattedNumber != null )
    {
      var pContent = this.formattedNumber.toLowerCase().split( "ext" );
      var finalNum = "";
      if( typeof pContent !== 'undefined' && pContent.length )
      {
        if( typeof pContent[0] !== 'undefined' )
        {
          finalNum = pContent[0];
        }
        if( typeof pContent[1] !== 'undefined' )
        {
          finalNum += " Ext " + pContent[1];
        }
        this.formattedNumber = finalNum;
      }
    }
    
    if (phonenumbrerlabel == 1) {
      this.brokersForm.controls['broker_main_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 2) {
      this.brokersForm.controls['contact_1_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 3) {
      this.brokersForm.controls['contact_2_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 4) {
      this.brokersForm.controls['contact_3_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 5) {
      this.brokersForm.controls['contact_4_phone'].setValue(this.formattedNumber);
    }
  }

  percentageCommission(event: any) {
    var currentValue = event.target.value;
    var trimmedsubcontval = this.trimgivennumber(currentValue);
    if(trimmedsubcontval != "" && trimmedsubcontval <= 100 && trimmedsubcontval > 0){
      this.brokersForm.patchValue({
        broker_commission_percentage: trimmedsubcontval
      });
    }else{
      this.brokersForm.patchValue({
        broker_commission_percentage: ''
      });
    }
  }

  getpercentageCommission(event: any){
    var percentageCommission = event.target.value;
    if(percentageCommission != "" && !percentageCommission.includes("%")){
      if(percentageCommission > 100){
        this.brokersForm.patchValue({
          broker_commission_percentage: ''
        });
      }else{
        this.brokersForm.patchValue({
          broker_commission_percentage: percentageCommission+'%'
        });
      }
    }
  }

  trimgivennumber(num: any) {
    var trimmedcharacters = num.replace(/\D/g, '');
    var trimmedgrossval = trimmedcharacters.replace(/\$|,/g, "");
    return trimmedgrossval;
  }
}
