import { InvoiceDetail } from './invoice-detail';
import { InvoiceMetadata } from './invoice-metadata';

export class Invoice {
  id: number;
  flags: string[];
  metadata: InvoiceMetadata;
  detail: InvoiceDetail[];
  premium_total: number;
  invoice_total: number;
  withdraw_amount: number;
  created_at: Date;
  updated_at: Date;

  ngFirst:boolean=false;
  ngCC:boolean=false;
  ngNPR:boolean=false;

  pfAdminFee:boolean=false;

  pfCC:boolean=false;
  nprCC:boolean=false;

  is_nsf:boolean=false;
  
  editLoading:boolean=false;

  ccManualUpdate:boolean=false;
  nsfRpfManualUpd:boolean=false;

  loadOriginalTotal:number=0;
  revOriginalTotal:number=0;
  is_revision:boolean=false;

  edit_retry_new = false;
  is_rpf_editable = false;

  pf_edit_rnew = false;

  constructor(data: any = {}) {
    // Set normal fields
    this.id = data.id;
    this.flags = data.flags;
    this.premium_total = data.premium_total;
    this.invoice_total = data.invoice_total;
    this.withdraw_amount = data.withdraw_amount;

    // Parse date fields
    this.created_at = new Date(Date.parse(data.created_at));
    this.updated_at = new Date(Date.parse(data.updated_at));

    // Parse other fields
    this.metadata = new InvoiceMetadata(data.metadata);
    this.detail = [];
  }

  setRPF()
  {
    this.metadata.retry_payment_fee = 0;
    if( this.is_nsf )
    {
      this.metadata.retry_payment_fee = (
        this.premium_total +
        this.metadata.admin_fee +
        this.metadata.setup_fee
      );
    }
  }

  calcTotals()
  {
    // Premium Total
    let premium_total = 0;
    for(const item of this.detail) {
      item.calc();
      premium_total += item.premium;
    }
    this.premium_total = premium_total;
    this.premium_total = Math.round(parseFloat((this.premium_total * Math.pow(10, 2)).toFixed(2))) / Math.pow(10, 2);

    var ccTotal = 0;
    if( ! this.editLoading && ! this.ccManualUpdate )
    {
        this.metadata.credit_card_fee = 0;
        if( this.ngCC )
        {
            if( this.premium_total != undefined && this.premium_total != null )
            {
              ccTotal += this.premium_total;
            }
            if( this.metadata.admin_fee != undefined && this.metadata.admin_fee != null )
            {
              ccTotal += this.metadata.admin_fee;
            }
            if( this.ngFirst )
            {
              if( this.metadata.setup_fee != undefined && this.metadata.setup_fee != null )
              {
                ccTotal += this.metadata.setup_fee;
              }
            }
            if( this.is_rpf_editable )
            {
              ccTotal = this.metadata.retry_payment_fee;
            }
            if( this.is_nsf )
            {
              if( this.metadata.nsf_fee != undefined && this.metadata.nsf_fee != null )
              {
                ccTotal += this.metadata.nsf_fee;
              }
            }
            this.metadata.credit_card_fee = ccTotal * 0.0425;

            var tv = parseFloat(this.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            var parts = tv.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");
            var figure = parts.join(".");
        
            this.metadata.credit_card_fee = parseFloat(figure);
                
        }
    }
    else
    {
        this.editLoading = false;
        if( this.ccManualUpdate )
        {
          this.ccManualUpdate = false;
        }
    }
  

    // Invoice Total
    // Earlier. Now being changed.
    // this.invoice_total = this.premium_total + this.metadata.admin_fee;
    this.invoice_total = (
      this.premium_total +
      this.metadata.admin_fee +
      this.metadata.setup_fee +
      this.metadata.credit_card_fee +
      this.metadata.nsf_fee
      );

    if( this.is_revision && ! this.is_rpf_editable )
    {
        this.invoice_total = (
          (
          this.premium_total +
          this.metadata.admin_fee +
          this.metadata.setup_fee +
          this.metadata.credit_card_fee +
          this.metadata.nsf_fee ) - this.loadOriginalTotal
        );
    }
    else if( this.is_revision && this.is_rpf_editable )
    {
      if( this.edit_retry_new )
      {
        this.edit_retry_new=false;
        this.setRPF();
      }
      this.invoice_total = (
        ( this.metadata.retry_payment_fee +
        this.metadata.credit_card_fee +
        this.metadata.nsf_fee ) - this.loadOriginalTotal
      );
    }
    else if( ! this.is_revision && this.is_rpf_editable )
    {
      if( this.edit_retry_new )
      {
        this.edit_retry_new=false;
        this.setRPF();
      }
      this.invoice_total = (
        this.metadata.retry_payment_fee +
        this.metadata.credit_card_fee +
        this.metadata.nsf_fee
      );
    }

    // Withdraw Amount
    this.withdraw_amount = (
      this.premium_total +
      this.metadata.admin_fee +
      this.metadata.setup_fee +
      this.metadata.credit_card_fee +
      this.metadata.nsf_fee
    );

    if( this.nsfRpfManualUpd )
    {
      this.nsfRpfManualUpd = false;
    }
  }
}
