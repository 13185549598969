import { Injectable, Inject } from '@angular/core';

@Injectable()
export class ConfigServiceService {

  private apiUrl = '/api';

  getApiUrl() {
	var environment:string;
	environment = window.location.hostname;
	switch (environment) {
		case '35.167.8.208':
			this.apiUrl = 'http://35.167.8.208/api';
			break;
		case 'ipaapplication.com':
			this.apiUrl = 'https://ipaapplication.com/api';
			break;
		case 'www.ipaapplication.com':
			this.apiUrl = 'https://www.ipaapplication.com/api';
			break;
		default:
			this.apiUrl = 'http://ipa.local/api';
	}
    return this.apiUrl;
  } 

}
