import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Stats } from '../stats';
import { ClientService } from '../client.service';
import { SpinnerService } from '../../core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  stats: Stats;
  isLogged:any;
  homeClients:any = [];
  worklists:any = [];
  selectedUser:any = 0;
  notexTextVisible = false;
  notexText = "";

  constructor(private router: Router, 
    private clientService: ClientService,
    private spinnerService: SpinnerService,
    private dataService: DataService) { }

  ngOnInit() {
    this.dataService.currentMessage.subscribe(isLogged => setTimeout(() => this.isLogged = isLogged,0));
    this.isLogged = '1';
    this.dataService.changeMessage(this.isLogged);
    
    this.clientService.getStats().subscribe(
      (res) => {
        this.stats = res;
        localStorage.setItem('new_clients_count', res.new.toString());
        localStorage.setItem('prospect_clients_count', res.prospect.toString());
        localStorage.setItem('new_aclients_count', res.active.toString());
        localStorage.setItem('new_iclients_count', res.inactive.toString());
        localStorage.setItem('new_rclients_count', res.relations.toString());
        this.spinnerService.hide();
      }
    );
  }

  search(q) {
    this.router.navigateByUrl('/clients/list?q=' + q);
  }

}
