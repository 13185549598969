import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../../settings/document.service';
import { DocumentQueue } from '../../settings/document-queue';
import { ConfirmationService } from 'primeng/api';
import { SpinnerService } from '../../core';
import { ConfigServiceService } from '../../config-service.service';
import { DocumentTemplate } from '../../settings/document-template';

@Component({
  selector: 'app-dashboard-fax',
  templateUrl: './dashboardfax.component.html',
  styleUrls: ['./dashboardfax.component.css']
})
export class DashboardFaxComponent implements OnInit {

  queues = [];
  queueSettingIds = [];
  allQueuesCount = 0;
  skip = 0;
  take = 150;
  sortField = 'company_name';
  sortOrder = 1;
  showInvoiceAlert:boolean = false;
  singleMailNoTempl:boolean = false;
  singleClientMsg:boolean = false;
  seInvsList:boolean = false;
  selectedAll = false;

  mqSngleEm: any = {};
  uploadedFile:any=null;

  emailTempls: DocumentTemplate[];
  templsLoaded:boolean=false;

  showTmpls: any = [];
  selTemplateId:any = '';

  faxCronDt:any='';

  filterDates: any;
  selectedDates = [];
  first = 0;
  selectedCarriers = [];
  
  constructor(
    private documentService: DocumentService, 
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService
    ,private configServiceService: ConfigServiceService
  ) { }

  ngOnInit()
  {
    this.documentService.getFaxCronTme().subscribe(res =>
      {
          if( res != undefined && res != null )
          {
            if( typeof res[0] !== 'undefined' )
            {
              this.faxCronDt = res[0];
            }
          }
      }); 
  }

  tmplSelected( seletecTpml )
  {
    this.selTemplateId = seletecTpml.value;
  }
  
  filterQueueByDates( mode:number )
  {
    if( mode == 0 )
    {
      this.selectedDates = [];
    }
    this.skip = 0;
    this.take = 150;
    this.sortField = this.sortField;
    this.sortOrder = this.sortOrder;
    this.first = 0;
    this.documentService.getFaxQueue(this.skip, this.take, this.sortField, this.sortOrder, 2, 0,this.selectedCarriers,this.selectedDates).subscribe(response => {
      this.getAllQueues(response);
    });
  }
  
  getAllQueues(response){
    this.queues = response.invoiceSetting;
    this.allQueuesCount = response.invoiceSettingsCount;
    if( this.skip == 0 )
    {
      this.filterDates = response.achDatesArr;
    }

    this.selectedAll = false;
    if( this.queueSettingIds.length )
    {
      var idsArr = [];
      for( var i = 0; i < this.queueSettingIds.length;i++ )
      {
        idsArr.push(this.queueSettingIds[i].id)
      }

      var key = 0;
      var selectedIts = 0;
      this.queues.forEach((queueClientinfo) =>
      {
        if( idsArr.includes(this.queues[key].id) )
        {
          this.queues[key].selected_checkbox = true;
          selectedIts++;
        }
        key++;
      });
      if( key == selectedIts )
      {
        this.selectedAll = true;
      }
    }

    this.spinnerService.hide();
  }

  paginate(event) {
      this.skip = event.first;
      this.take = event.rows;
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.documentService.getFaxQueue(this.skip, this.take, this.sortField, this.sortOrder, 2, 0,this.selectedCarriers,this.selectedDates).subscribe(response => {
        this.getAllQueues(response);
      });
  }

  checkUncheckAll(e)
  {
    console.log(this.queueSettingIds);

    // this.queueSettingIds = [];

    if(e.target.checked == true){
      this.selectedAll = true;
      var key = 0;
      this.queues.forEach((queueClientinfo) => {
        this.queues[key].selected_checkbox = true;
        var objectClient = {};
        objectClient['id'] = queueClientinfo.id;
        objectClient['clientId'] = queueClientinfo.clientId;
        objectClient['invType'] = queueClientinfo.invoice_type;
        objectClient['email'] = queueClientinfo.email;
        objectClient['company_name'] = queueClientinfo.company_name;
        objectClient['pfInvoiceTotal'] = queueClientinfo.pfInvoiceTotal;
        objectClient['check_date_formate'] = queueClientinfo.check_date_formate;
        this.queueSettingIds.push(objectClient);
        key++;
      });
    }else{
      this.selectedAll = false;
      var key = 0;
      this.queues.forEach((queueClientinfo) => {
        for( var em = 0;em < this.queueSettingIds.length;em++ )
        {
          if( this.queueSettingIds[em].id == queueClientinfo.id )
          {
            this.queueSettingIds.splice(em, 1);
          }
        }
        this.queues[key].selected_checkbox = false;
        key++;
      });
    }
  }

  checkBoxEvent(id, e, i)
  {

    if( i >= this.take )
    {
      i = i % this.take;
    }

    if(e.target.checked == true){
      this.queues[i].selected_checkbox = true;
      var objectClient = {};
      objectClient['id'] = id;
      var queueClientinfo = this.queues.filter(item => item.id == id);
      objectClient['clientId'] = queueClientinfo[0].clientId;
      objectClient['invType'] = queueClientinfo[0].invoice_type;
      objectClient['email'] = queueClientinfo[0].email;
      objectClient['company_name'] = queueClientinfo[0].company_name;
      objectClient['pfInvoiceTotal'] = queueClientinfo[0].pfInvoiceTotal;
      objectClient['check_date_formate'] = queueClientinfo[0].check_date_formate;
      this.queueSettingIds.push(objectClient);

      this.selectedAll = this.queues.every(function(item:any) {
        return item.selected_checkbox == true;
      });

    }else{
      this.selectedAll = false;
      this.queues[i].selected_checkbox = false;
      this.queueSettingIds = this.queueSettingIds.filter(item => item.id != id);
    }
  }

  openPDF(invSettingId: number,invType:number,clientId:number)
  { 

    if( invType == 1 )
    {
      window.open(this.configServiceService.getApiUrl() + '/pdfview?download=pdf&invoiceId=' + invSettingId + '&id=' + clientId + '&up=0', '_blank');
    }
    else if( invType == 2 )
    {
      window.open(this.configServiceService.getApiUrl() + '/pfpdf?download=pdf&invoiceId=' + invSettingId + '&id=' + clientId + '&up=0', '_blank');
    }
    else if( invType == 3 )
    {
      window.open(this.configServiceService.getApiUrl() + '/nprpdf?download=pdf&invoiceId=' + invSettingId + '&id=' + clientId + '&up=0', '_blank');
    }

  }


  setDelivrdInvs()
  {
    if(this.queueSettingIds.length != 0)
    {
      this.confirmationService.confirm
      ({
          message: 'Are you sure you want to set the invoices as delivered ?',
          accept: () =>
          {
            this.documentService.createMQDel(this.queueSettingIds).subscribe(response =>
            {
              this.spinnerService.hide();
              if(response.output == '0')
              {
              }
              else
              {
                this.documentService.getQueues(this.skip, this.take, this.sortField, this.sortOrder, 2).subscribe(response => {
                  this.queueSettingIds = [];
                  this.getAllQueues(response);
                });
              }
            });
          },
          reject: () => {}
      });
    }
    else
    {
      alert('Please select invoices');
    }
  }

  closeInvsPopUp()
  {
    this.seInvsList = false;
    this.uploadedFile = null;
    document.getElementsByTagName('form')[0].reset();
  }

  onFileChange(event)
  {
    const reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        this.uploadedFile = reader.result;
      
        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };
    }
  }

  triggerSendSngle()
  {
    var tmplSelected = true;
    if( this.selTemplateId == "" )
    {
      tmplSelected = false;
    }

    if( tmplSelected )
    {
          this.seInvsList = false;
          this.spinnerService.show();

          this.mqSngleEm = {};
          this.mqSngleEm.qSIds = this.queueSettingIds;
          this.mqSngleEm.uploadedFile = this.uploadedFile;
          this.mqSngleEm.selTemplateId = this.selTemplateId;
          
          this.documentService.sendSingleMail(this.mqSngleEm).subscribe(response =>
          {
              this.spinnerService.hide();
              if( response.output == '0' )
              {
                this.singleMailNoTempl = true;
              }
              else if( response.output == '-1' || response.output == '-2' )
              {
                alert( response.message );
              }
              else
              {
                this.mqSngleEm = {};
                this.uploadedFile = null;
                document.getElementsByTagName('form')[0].reset();
                
                this.documentService.getQueues(this.skip, this.take, this.sortField, this.sortOrder, 2).subscribe(response =>
                {
                  this.queueSettingIds = [];
                  this.getAllQueues(response);
                });
              }
          });
    }
    else
    {
      alert( "Please select a template." );
    }
  }

  sendSingleMail()
  {
    if( this.queueSettingIds.length != 0 )
    {
      var goAheadSngMail = false;
      var emailNotAv = false;
      var infoNotAv = false;

      if( this.queueSettingIds != undefined && this.queueSettingIds != null && this.queueSettingIds.length > 0 )
      {
        goAheadSngMail = true;
      }

      var clIdsArr = [];
      var firstInv = true;
      this.queueSettingIds.forEach((queueClientinfo) =>
      {
        if( queueClientinfo != undefined && queueClientinfo != null && queueClientinfo.email != undefined && queueClientinfo.email != null && queueClientinfo.email != "" )
        {
          if( ! firstInv && ! clIdsArr.includes(queueClientinfo.email) )
          {
            goAheadSngMail = false;
          }
          if( firstInv  )
          {
            clIdsArr.push( queueClientinfo.email );
            firstInv = false;
          }
        }
        else if( queueClientinfo == undefined || queueClientinfo == null )
        {
          goAheadSngMail = false;
          infoNotAv = true;
        }
        else if( (queueClientinfo != undefined && queueClientinfo != null) && (queueClientinfo.email == undefined || queueClientinfo.email == null || queueClientinfo.email == "") )
        {
          goAheadSngMail = false;
          emailNotAv = true;
        }
      });

      if( goAheadSngMail )
      {
        this.seInvsList = true;
      }
      else
      {
        if( emailNotAv )
        {
          alert( "One or more clients do not have email, email not to be sent." );
        }
        else if( infoNotAv )
        {
          alert( "Information not availalbe for one or more clients.\nEmail not to be sent." );
        }
        else
        {
          this.singleClientMsg = true;
        }
      }
    }
    else
    {
      alert('Please select atlease one invoice.');
    }
  }


  sendInvoicePdf(){
    if(this.queueSettingIds.length != 0){
      this.confirmationService.confirm
      ({
          message: 'Are you sure you want to send fax for selected invoices?',
          accept: () => {
            this.documentService.FaxInvoices(this.queueSettingIds).subscribe(response => {
              this.spinnerService.hide();
              if(response.output == '0'){
                this.showInvoiceAlert = true;
              }else{
                this.documentService.getFaxQueue(this.skip, this.take, this.sortField, this.sortOrder, 2, 0,this.selectedCarriers,this.selectedDates).subscribe(response => {
                  this.queueSettingIds = [];
                  this.getAllQueues(response);
                });
              }
            });
          },
          reject: () => {}
      });
    }else{
      alert('Please select invoices');
    }
  }

  remFromFQ(){
    if(this.queueSettingIds.length != 0){
      this.confirmationService.confirm
      ({
          message: 'Are you sure you want to remove selected invoices from Fax Queue ?',
          accept: () => {
            this.documentService.removeFromFax(1, this.queueSettingIds).subscribe(response => {
              this.spinnerService.hide();
              this.documentService.getFaxQueue(this.skip, this.take, this.sortField, this.sortOrder, 2, 0,this.selectedCarriers,this.selectedDates).subscribe(response => {
                this.queueSettingIds = [];
                this.getAllQueues(response);
              });
            });
          },
          reject: () => {}
      });
    }else{
      alert('Please select invoices');
    }
  }

}
