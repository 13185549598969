import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardManualComponent } from './dashboardmanual/dashboardmanual.component';
import { DashboardFaxComponent } from './dashboardfax/dashboardfax.component';
import { DashboardTextComponent } from './dashboardtext/dashboardtext.component';
import { DashboardPrintComponent } from './dashboardprint/dashboardprint.component';
import { ViewComponent } from './view/view.component';

const routes: Routes = [
  {path: 'queues', component: DashboardComponent},
  {path: 'queues/manual', component: DashboardManualComponent},
  {path: 'queues/print', component: DashboardPrintComponent},
  {path: 'queues/fax', component: DashboardFaxComponent},
  {path: 'queues/text', component: DashboardTextComponent},
  {path: 'queues/view/:id', component: ViewComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QueueRoutingModule { }
