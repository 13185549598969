import {Component, OnInit, ViewChild, Inject} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SelectItem } from "primeng/api";
import { ProspectService } from '../prospect.service';
import { Prospect } from "../prospect";
import { CompletedActivity } from '../completedactivity';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { HttpErrorResponse } from '@angular/common/http';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SpinnerService } from '../../core';
import { ClientService } from '../client.service';
import { Client } from "../client";

@Component({
  selector: 'client-prospect',
  templateUrl: './prospect-activity.component.html',
  styleUrls: ['./prospect-activity.component.css']
})
export class ClientProspectComponent implements OnInit {
	msgs: Message[] = [];
	activityTypes: SelectItem[];
	// selectedActivityType: string;
	private _filter:any = ['prospectsvcagreement','prospect8865','prospect8821','prospectnatpayform','prospectdigitalsign','prospectpoa','prospectvoidcheck','prospectcall','prospectemail','prospectmisc'];
	@ViewChild('activityList') activityList;

	activities: any = [];
	newActivity:any = {};
	completedActivity:any = {};
	completedActivities: any = [];
	
	client: Client;
	is_new:number = -1;
	is_prospect:number = -1;
	
	activityform: FormGroup;
	submitted: boolean = false;

	showActivityNew: boolean = false;
	showActivityUpdate: boolean = false;

	clientId: any;

	// loggedUserRole:any;

	addUpdMode: number;

	addUpdPopUpBgClr="#ffffff";
	headerNoteCust='Note';

	viewDispVar:string='none';
	showPlus:boolean=true;

	completedActivitiesStr: string[] = [];

	constructor(private fb: FormBuilder, private prospectService:ProspectService,
		aroute:ActivatedRoute, private messageService: MessageService,
		@Inject(LOCAL_STORAGE) private storage: WebStorageService,
		private spinnerService: SpinnerService,private clientService:ClientService) { 
		aroute.params.subscribe(params => {
			this.clientId = params['id'];
		});
	}

	openCloseView()
	{
		if( this.showPlus )
		{
			this.showPlus = false;
			this.viewDispVar = 'block';
		}
		else
		{
			this.showPlus = true;
			this.viewDispVar = 'none';
		}
	}

	loadASetup(id: number)
	{
		this.spinnerService.show();
		this.showActivityNew=false;
		// Load setup
		this.prospectService.getSetup(id).subscribe(res =>
		{
			this.spinnerService.hide();
			
			this.newActivity = res;

			if( this.newActivity != undefined && this.newActivity.type.trim() != "" && this.newActivity.description.trim() !="" )
			{
				this.submitted=true;
			}
			this.addUpdMode = 2;
			this.showActivityUpdate=true;
		});
	}

	compAcClick( checked,type )
	{
		if( checked != undefined && checked != null && type != undefined && type != null )
		{
			if( checked )
			{
				this.spinnerService.show();
				this.completedActivity.client_id = this.clientId;	  
				this.completedActivity.user_id = this.storage.get('app.auth').id;	  
				this.completedActivity.type = type;
				/****** Call Save CompletedActivity Service ******/
				this.prospectService.saveCompActivity(this.completedActivity).subscribe(
				response =>
				{
					this.spinnerService.hide();				
					if(response instanceof HttpErrorResponse)
					{
						this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
						this.completedActivity = {};
					}
					else
					{
						this.messageService.add({key:'top-corner', severity:'success', summary:'Success', detail:'Activity marked completed'});
						this.completedActivity = {};
						this.getSavedCompleted();
					}
				},
				error =>
				{
					this.spinnerService.hide();				
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:'We faced an issue'});
				});		
				/**************************************/
			}
			else
			{
				this.completedActivity = {};
				for( var ca = 0; ca < this.completedActivities.length; ca++ )
				{
					if( this.completedActivities[ca].type == type )
					{
						this.completedActivity = this.completedActivities[ca];
					}
				}
				if( this.completedActivity != undefined && this.completedActivity.type != undefined )
				{
					this.spinnerService.show();
					
					this.prospectService.deleteCompletedActivity(this.completedActivity).subscribe(
						response =>
						{
							this.spinnerService.hide();				
							if(response instanceof HttpErrorResponse)
							{
								this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
								this.completedActivity = {};
							}
							else
							{
								this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Completed activity deleted'});
								this.completedActivity = {};
								this.getSavedCompleted();
							}
						},
						error =>
						{
							this.spinnerService.hide();				
							console.log(error);
							this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.message});
						});
				}
			}
		}
	}

	ngOnInit()
	{
		this.activities = [];
		
		this.newActivity = {};
		this.newActivity.type = '';
		this.newActivity.description = '';

		this.activityform = this.fb.group({
			'description': new FormControl('', Validators.required),
			'type': new FormControl('', Validators.required)
		});

		this.activityTypes = [
			{ label: 'Svc Agreement', icon: 'fa fa-asterisk', value: 'prospectsvcagreement' },
			{ label: '8865', icon: 'fa fa-phone', value: 'prospect8865' },
			{ label: '8821', icon: 'fa fa-envelope', value: 'prospect8821' },
			{ label: 'NatPay Form', icon: 'fa fa-clipboard', value: 'prospectnatpayform' },
			{ label: 'Digital Sign', icon: 'fa fa-asterisk', value: 'prospectdigitalsign' },
			{ label: 'POA', icon: 'fa fa-asterisk', value: 'prospectpoa' },
			{ label: 'Void Check', icon: 'fa fa-asterisk', value: 'prospectvoidcheck' },
			{ label: 'Call', icon: 'fa fa-asterisk', value: 'prospectcall' },
			{ label: 'Email', icon: 'fa fa-asterisk', value: 'prospectemail' },
			{ label: 'Misc', icon: 'fa fa-asterisk', value: 'prospectmisc' }
		];

		this.refresh();
		this.getSavedCompleted();
	}
	

	refresh()
	{
		this.clientService.getClientNameOnly(this.clientId).subscribe(res =>
		{
			this.client = res;
			if( this.client != undefined && this.client != null && this.client.is_new != undefined && this.client.is_new != null )
			{
				this.is_new = this.client.is_new;
			}
			// if( this.client != undefined && this.client != null && this.client.is_prospect != undefined && this.client.is_prospect != null )
			// {
			// 	this.is_prospect = this.client.is_prospect;
			// }
		});
				
		this.activities = [];
		this.prospectService.getSetUpLog(this.clientId, this._filter).subscribe(
			response => {				
				if(response instanceof HttpErrorResponse) {
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
				} else {
					this.activities = <Prospect[]> response;
				}
			},
			error => {
				this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.statusText});
		});		
	}

	getSavedCompleted()
	{
		this.spinnerService.show();
		this.prospectService.getCompletedActivities(this.clientId).subscribe(
			response => {		
				this.spinnerService.hide();		
				if(response instanceof HttpErrorResponse) {
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
				} else {
					this.completedActivities = <CompletedActivity[]> response;
					this.completedActivitiesStr = [];
					for( var ca = 0; ca < this.completedActivities.length; ca++ )
					{
						this.completedActivitiesStr.push( this.completedActivities[ca].type );
					}
				}
			},
			error => {
				this.spinnerService.hide();		
				this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.statusText});
		});		
	}

	rpmResetForm()
	{
		this.newActivity.type = '';
		this.newActivity.description = '';

		this.submitted=false;
		this.addUpdMode = 1;
		this.showActivityNew=true;
	}

	enableSubmit(  cntrl:number=0 )
	{
		if( this.newActivity.type.trim() != "" && this.newActivity.description.trim() !="" )
		{
            this.submitted = true;
		}
		else
		{
			this.submitted = false;
		}
		
		if( cntrl == 1 )
		{
			this.addUpdPopUpBgClr="#ffffff";
			if( this.newActivity.type.trim() != "" )
			{
				if( this.newActivity.type.trim() == "prospectsvcagreement" )
				{
					this.addUpdPopUpBgClr="#F5B7B1";
				}
				else if( this.newActivity.type.trim() == "prospect8865" )
				{
					this.addUpdPopUpBgClr="#A3E4D7";
				}
				else if( this.newActivity.type.trim() == "prospect8821" )
				{
					this.addUpdPopUpBgClr="#FAD7A0";
				}
				else if( this.newActivity.type.trim() == "prospectnatpayform" )
				{
					this.addUpdPopUpBgClr="#AED6F1";
				}
				else if( this.newActivity.type.trim() == "prospectdigitalsign" )
				{
					this.addUpdPopUpBgClr="#A2D9CE";
				}
				else if( this.newActivity.type.trim() == "prospectpoa" )
				{
					this.addUpdPopUpBgClr="#F9E79F";
				}
				else if( this.newActivity.type.trim() == "prospectvoidcheck" )
				{
					this.addUpdPopUpBgClr="#E5E7E9";
				}
				else if( this.newActivity.type.trim() == "prospectcall" )
				{
					this.addUpdPopUpBgClr="#D7BDE2";
				}
				else if( this.newActivity.type.trim() == "prospectemail" )
				{
					this.addUpdPopUpBgClr="#E6B0AA";
				}
				else if( this.newActivity.type.trim() == "prospectmisc" )
				{
					this.addUpdPopUpBgClr="#A9CCE3";
				}
			}
		}

		// console.log('enableSubmit');
        // console.log(this.activityform.touched);
        // console.log(this.activityform.valid);
        // console.log(this.submitted);
    }

	set filter(value) {
		this._filter = value;
		/* this.activityList.filterBy = 'type';
		this.activityList.filter = ''; 
		this.activityList.filter(this._filter.join(' '));*/

		this.refresh();
	}

	get filter() {
		return this._filter;
	}

	onSubmit(value: string) {
		this.saveActivity();
		this.submitted = true;
	}

	callFormSave()
	{
		 if( this.addUpdMode == 1 )
		 {
			this.saveActivity();
		 }
		 else if( this.addUpdMode == 2 )
		 {
			this.updateActivity();
		 }
	}

	saveActivity()
	{  
		if( this.submitted )
		{
			this.submitted=false;
			this.newActivity.client_id = this.clientId;	  
			this.newActivity.user_id = this.storage.get('app.auth').id;	  
			/****** Call Save Setup Service ******/
			this.prospectService.saveSetup(this.newActivity).subscribe(
			response =>
			{				
				if(response instanceof HttpErrorResponse)
				{
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
					this.showActivityNew=false;
					this.newActivity.type = '';
					this.newActivity.description = '';
				}
				else
				{
					this.messageService.add({key:'top-corner', severity:'success', summary:'Success', detail:'Setup Activity added successfully'});
					this.activities.push(response.setup);
					this.showActivityNew=false;
					this.newActivity.type = '';
					this.newActivity.description = '';
					this.refresh();
				}
			},
			error =>
			{
				this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:'Something went wrong'});
			});		
			/**************************************/
		}
	}

	updateActivity()
	{
		if( this.submitted )
		{
			this.submitted=false;

			this.prospectService.updateSetupData(this.newActivity.id,this.newActivity).subscribe(
			response =>
			{
				if(response instanceof HttpErrorResponse)
				{
					this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
					this.showActivityUpdate=false;
					this.newActivity.type = '';
					this.newActivity.description = '';
				}
				else
				{
					this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Setup Activity updated successfully'});
					this.showActivityUpdate=false;
					this.newActivity.type = '';
					this.newActivity.description = '';
					this.refresh();
	  			}
		  },
		  error =>
		  {
			console.log(error);
			this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.message});
		  });
		}
	}

	deleteActivity()
	{
		this.submitted=false;

		var r = confirm("Are you sure you want to delete setup ?");
		if( ! r )
		{
			this.submitted=true;
			return false;
		}
		this.prospectService.deleteSetup(this.newActivity).subscribe(
		response =>
		{
			if(response instanceof HttpErrorResponse)
			{
			this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
			this.showActivityUpdate=false;
			this.newActivity.type = '';
			this.newActivity.description = '';
			}
			else
			{
			this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Setup deleted successfully'});
			this.showActivityUpdate=false;
			this.newActivity.type = '';
			this.newActivity.description = '';
			this.refresh();
			}
		},
		error =>
		{
			console.log(error);
			this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.message});
		});
	}
}