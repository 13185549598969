import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../config-service.service';
import { AuthService } from '../auth/auth.service';
import { Client } from './client';
import { Worklist } from '../worklist/worklist';
import { ClientChangeResponse } from './client-change-response';
import { Observable } from 'rxjs';
import { Stats } from './stats';
import { State } from './state';
import { ClassCode } from './class-code';
import { SpinnerService } from '../core';
import 'rxjs/add/operator/map'

@Injectable()
export class ClientService {

  readonly apiUrl;
  public selectedClient: Client;

  constructor(private http: HttpClient,
    private auth: AuthService,
    config: ConfigServiceService,
    private spinnerService: SpinnerService) {
    this.apiUrl = config.getApiUrl();
  }

  getQueues(skip, take, sortField, sortOrder, qm,getPrintQueue=0, carrierData=[])
  {
    this.spinnerService.show();
    const token = this.auth.getToken();
    var id = 'skip=' + skip + '&take=' +take+'&sortField=' +sortField+'&sortOrder=' +sortOrder+'&qm=' +qm+'&getPrintQueue='+getPrintQueue+'&carrierData='+carrierData;
    return this.http.get<any>(this.apiUrl + '/incomplete-invoices?'+id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
  getClients(q: string = '', filter: string = '', skip, take, d) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Client[]>(this.apiUrl + '/clients?q=' + q + '&filter=' + filter + '&skip=' + skip +'&take=' + take + '&d=' + d, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getClientsEmail(skip, take, sortField, sortOrder) {

    this.spinnerService.show();

    const token = this.auth.getToken();
    var id = 'skip=' + skip + '&take=' +take+'&sortField=' +sortField+'&sortOrder=' +sortOrder;
    return this.http.get<any>(this.apiUrl + '/get-clients-send-email?'+id, {

      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getPayrollPlatformList(){ 
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/PayrollPlatformlist', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }); 
  }



  getOnlyClientData( clientId:number )
  {
    const token = this.auth.getToken();
    return this.http.get<Client>(this.apiUrl + '/client-data?clientId='+clientId, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
  getClientNameOnly( clientId:number )
  {
    const token = this.auth.getToken();
    return this.http.get<Client>(this.apiUrl + '/client-name?clientId='+clientId, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getClientPolicies( clientId:number )
  {
    const token = this.auth.getToken();
    return this.http.get<Client>(this.apiUrl + '/client-policies-get?clientId='+clientId, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getClient(id: number) {
    return new Observable<any>((observer) => {
      //if (!this.selectedClient || this.selectedClient.id !== id) {

      // Clear Client
      this.selectedClient = null;

      // Load Client
      const token = this.auth.getToken();
      this.http.get<any>(this.apiUrl + '/clients/' + id, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).subscribe(res => {
        this.selectedClient = res;

        observer.next(this.selectedClient);
        observer.complete();
      });

      /*} else {
        // Return cached object

        observer.next(this.selectedClient);
        observer.complete();
      }*/
    });
  }

  deleteClient(clientId:any) {
    return new Observable<any>((observer) => {
      //if (!this.selectedClient || this.selectedClient.id !== id) {

      // Clear Client
      this.selectedClient = null;
      let loggedUserId = localStorage.getItem('userId');

      // Load Client
      const token = this.auth.getToken();
      this.http.get<any>(this.apiUrl + '/remove-client?clientId='+clientId+'&u='+loggedUserId, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).subscribe(res => {
        this.selectedClient = res;

        observer.next(this.selectedClient);
        observer.complete();
      });

      /*} else {
        // Return cached object

        observer.next(this.selectedClient);
        observer.complete();
      }*/
    });
  }

  getHomePageClients(selectedUser:any, columnvalue:any, orderValue:any, sd:any, ed:any) {
    return new Observable<any>((observer) => {
      //if (!this.selectedClient || this.selectedClient.id !== id) {

      // Clear Client
      this.selectedClient = null;

      // Load Client
      const token = this.auth.getToken();
      this.http.get<any>(this.apiUrl + '/home-clients?userId='+selectedUser+'&columnvalue='+columnvalue+'&orderValue='+orderValue+'&sd='+sd+'&ed='+ed, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).subscribe(res => {
        this.selectedClient = res;

        observer.next(this.selectedClient);
        observer.complete();
      });

      /*} else {
        // Return cached object

        observer.next(this.selectedClient);
        observer.complete();
      }*/
    });
  }

  updateNextACDate(nextAnticipatedCDDate:any, currentUpdateNextACDateId:any) {
    return new Observable<any>((observer) => {
      //if (!this.selectedClient || this.selectedClient.id !== id) {

      // Clear Client
      this.selectedClient = null;

      // Load Client
      const token = this.auth.getToken();
      this.http.get<any>(this.apiUrl + '/update-next-acdate?date='+nextAnticipatedCDDate+'&id='+currentUpdateNextACDateId, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).subscribe(res => {
        this.selectedClient = res;

        observer.next(this.selectedClient);
        observer.complete();
      });

      /*} else {
        // Return cached object

        observer.next(this.selectedClient);
        observer.complete();
      }*/
    });
  }

  getStats() {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Stats>(this.apiUrl + '/clients/stats', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  saveClientData(id: number, data: Client) {

    const token = this.auth.getToken();
    return this.http.put<ClientChangeResponse>(this.apiUrl + '/clients/' + id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  sendMailsToClinets(data) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<any>(this.apiUrl + '/send-mails-to-clinets', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  saveClientDataa(data) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<any>(this.apiUrl + '/clients', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getPayrollCompanies() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/payrollcompanies', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getPayrollCompaniesClients() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/payrollcompaniesclients', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getContacts(client_id) {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/contacts?id=' + client_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getContactsClients(client_id) {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/contacts?client_id=' + client_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getASCompanies(client_id) {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/associated-companies?client_id=' + client_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getMethodOfContacts(client_id) {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/method-of-contacts?client_id=' + client_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getActivityLog(client_id: Number) {
    var filter = "";
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/activities?client_id=' + client_id + '&filter='+filter, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }


  uploadInvoiceFile( mqSngleEmPostObj:any )
  {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<ClientChangeResponse>(this.apiUrl + '/upload-invoice-file', mqSngleEmPostObj, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  uploadDocumentHandler( mqSngleEmPostObj:any )
  {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<ClientChangeResponse>(this.apiUrl + '/document-handler', mqSngleEmPostObj, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
  getDispServerTime()
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/srvrDispTime', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getTodayDateRpm()
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/todayDateRpm', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
  getUserEmail( userId )
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/loggedInEmailGet?userId='+userId, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getPayrollReps(payroll_company_id) {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/payrollreps?id=' + payroll_company_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getContactsFull(client_id) {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/contacts?full=1&id=' + client_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getWorkList() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/worklist', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getAgencyBrokersList() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/agencybrokerlists', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getCarriers() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/carriers', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getCarriersByName() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/carriersByName', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getStates() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/states', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getClassCodes(state: string) {
    const token = this.auth.getToken();
    return this.http.get<ClassCode[]>(this.apiUrl + '/class-codes?state=' + state, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getUsers() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/users', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getWorklistsForPayroll()
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/worklists-for-payroll', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  getWorklist(id: number): Observable<any> {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Worklist>(this.apiUrl + '/worklists/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).map(res => {
      return new Worklist(res);
    });
  }
  getPayrollPlatform( payroll_platform_id )
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/get-platform?id=' + payroll_platform_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  getPCompPlatforms( payroll_company_id )
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/get-payroll-platforms?id=' + payroll_company_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getAllAgencies() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/agencies', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getAgencyAgents(agency_id) {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/agencyAgents?id=' + agency_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }


  getAllBrokers() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/brokers', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getCarrierSet(): Observable<any> {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/getCarrierSet', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  deleteDocument(id:any)
  {
    const token = this.auth.getToken();
    return this.http.delete<any>(this.apiUrl + '/document-handler/delete?id=' +id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getDocuments(client_id) {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/document-handler?client_id=' + client_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
}
