import { Component, OnInit } from '@angular/core';
import { MenuItem } from "primeng/api";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'worklist-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css']
})
export class MenubarComponent implements OnInit {

  public items: MenuItem[];
  public q: string;

  constructor(private aroute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.items = [
      {
        label: 'Settings',
        icon: 'fa-gear',
        routerLink: ['/settings'],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Create Worklist',
        icon: 'fa-user',
        routerLink: ['/settings/worklists/create'],
        routerLinkActiveOptions: { exact: true }
      }
    ];

    // Initial Load
    this.aroute.queryParams.subscribe(params => {
      this.q = params['q'];
    });

    // Navigation Change
    this.router.events
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/settings/worklists')) {
          this.aroute.queryParams.subscribe(params => {
            this.q = params['q'];
          });
        }
      });
  }

  search(q) {
    this.router.navigateByUrl('/settings/worklists?q=' + q);
  }
}
