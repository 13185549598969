import { Component, OnInit, Inject } from '@angular/core';
import { Location } from "@angular/common";
import { Client } from "../client";
import { ActivatedRoute, Router, RouterEvent, NavigationStart, NavigationEnd, NavigationError } from "@angular/router";
import { ClientService } from "../client.service";
import { PolicyService } from '../policy.service';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SpinnerService } from '../../core';
import { ConfirmationService } from 'primeng/components/common/api';
import { ConfigServiceService } from '../../config-service.service';

declare var $: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  id: number;
  client: Client;
  invoice_types: SelectItem[];
  invoice_type: string;

  check_date: string;
  payroll_end_date: string;
  report_date: string;
  ach_withdrawal_date: string;
  payroll_post_date: string;

  invoiceNotePostObj: any = {};

  invoiceNoteForm: FormGroup;
  invoiceForm: FormGroup;
  invAddUpd: number = 0;

  invoicesList: any = [];
  pfList: any = [];
  mbList: any = [];

  msgs: Message[] = [];
  isLoading: number = 0;
  shouldNavigate: boolean = false;

  requiredCheckDate: Date;
  invPolPostObj: any = {};

  redError = 'red';
  chkdt_nameError = 'none';
  prdt_nameError = 'none';
  rptdt_nameError = 'none';
  achwdt_nameError = 'none';
  payroll_post_date_Error = 'none';
  ptError = 'none';

  corrInProgress: boolean = false;
  voidInProgress: boolean = false;
  delInProgress: boolean = false;

  invActionPostObj: any = {};

  invCorsArr: string[];
  invNSFsArr: string[];
  invREVsArr: string[];

  beingAutoSaved: boolean = false;

  skip = 0;
  take = 12;
  allInvoicesCount = 0;

  skipPF = 0;
  takePF = 6;
  pfInvoicesCount = 0;
  mbInvoicesCount = 0;

  loggedUserRole: any;

  editorDisp: boolean = true;
  submitted: boolean = false;
  showSavedMsg:boolean = false;

  dtValStr:string='';
  serverYr:string='';

  lenDtStr:number=0;
  chromePrevStr:string='';
  
  holdInProgress:boolean=false;

  payrollTotal=null;

  popupInvNotes:boolean=false;
  currInvNotes:String='';

  loggedInEmail:String='';
  superDeletorIds:any=[];
  userEmailLoaded:boolean=false;

  ckDtInv:boolean = false;
  pedDtInv:boolean = false;
  rpdtInv:boolean = false;
  achDtInv:boolean = false;
  ppostdt:boolean = false;

  invNotUnHold:boolean = false;
  notActivatedField:string = "check date";

  invDelNotAllowed:boolean = false;

  pfColInProgress:boolean = false;

  invCancldCrsd:boolean = false;
  invCnCrMsg:String='';

  actionsGoStop:boolean = false;

  invNotesBk:string='';

  holdInvoiceId:number = 0;
  holdActionType:number = 0;
  holdInvoiceType:number = 0;
  holdConfirmation:boolean = false;
  holdHeader:string="Hold Invoice";
  holdMsg:string='';
  reason_held:string='';
  reasonValdn:boolean = false;
  
  constructor(public aroute: ActivatedRoute, private router: Router, public location: Location,
    private clientService: ClientService, private fb: FormBuilder, private policyService: PolicyService
    , private messageService: MessageService, @Inject(LOCAL_STORAGE) private storage: WebStorageService
    , private spinnerService: SpinnerService, private confirmationService: ConfirmationService
    , private configServiceService: ConfigServiceService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });

    // this.reloadLstFired = false;
    this.policyService.sidebarVisibilityChange.subscribe(value => {
      if (value) {
        this.beingAutoSaved = true;
      }
      else {
        // console.log( "False fired: " + this.beingAutoSaved);
        if (this.beingAutoSaved) {
          this.beingAutoSaved = false;
        }
      }

    });
  }

  resetInvGenForm()
  {
    this.check_date = null;
    this.payroll_end_date = null;
    this.report_date = null;
    this.ach_withdrawal_date = null;
    this.payroll_post_date = null;

    this.chkdt_nameError = 'none';
    this.prdt_nameError = 'none';
    this.rptdt_nameError = 'none';
    this.achwdt_nameError = 'none';
    this.payroll_post_date_Error = 'none';
    this.ptError = 'none';
  }

  reloadList() {
    location.reload();
  }

  ngAfterViewInit()
  {
      $(document).ready(function()
      {
          setTimeout(() => {

              if( this.invNotesBk != undefined && this.invNotesBk != null )
              {
                $('#notesDisp').html(this.invNotesBk);
              }
      
          }, 3000);
              
      }.bind(this));
  }

  editorLoadedCl()
  {
      // console.log('Loaded...');
      var pickerSpans:any = null;
      pickerSpans = document.querySelectorAll('[data-value="#facccc"]');
      if( pickerSpans != undefined && pickerSpans != null )
      {
          for( var el = 0;el < pickerSpans.length;el++ )
          {
              if( pickerSpans[el] )
              {
                  pickerSpans[el].style.backgroundColor = "#fa00cc";
                  pickerSpans[el].setAttribute('data-value','#fa00cc');
              }
          }
      }

      var pickerSelects:any = null;
      pickerSelects = document.querySelectorAll('[value="#facccc"]');
      if( pickerSelects != undefined && pickerSelects != null )
      {
          for( var op = 0;op < pickerSelects.length;op++ )
          {
              if( pickerSelects[op] )
              {
                  pickerSelects[op].setAttribute('value','#fa00cc');
              }
          }
      }
  }


  validatedateInv(inputText)
  {
    var isDateValid = true;
    var dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  // Match the date format through regular expression
  if(inputText.match(dateformat))
  {
  //Test which seperator is used '/' or '-'
  var opera1 = inputText.split('/');
  var opera2 = inputText.split('-');
  var lopera1 = opera1.length;
  var lopera2 = opera2.length;
  // Extract the string into month, date and year
  if (lopera1>1)
  {
  var pdate = inputText.split('/');
  }
  else if (lopera2>1)
  {
  var pdate = inputText.split('-');
  }
  var mm  = parseInt(pdate[0]);
  var dd = parseInt(pdate[1]);
  var yy = parseInt(pdate[2]);
  // Create list of days of a month [assume there is no leap year by default]
  var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
  if (mm==1 || mm>2)
  {
  if (dd>ListofDays[mm-1])
  {
  isDateValid = false;
  }
  }
  if (mm==2)
  {
  var lyear = false;
  if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
  {
  lyear = true;
  }
  if ((lyear==false) && (dd>=29))
  {
    isDateValid = false;
  }
  if ((lyear==true) && (dd>29))
  {
    isDateValid = false;
  }
  }
  }
  else
  {
    isDateValid = false;
  }

  return isDateValid;
  }
  
  appendYr( eleId )
  {
      var isValidDate = true;
      var checkingDt = this.dtValStr;
      var dtGoAhead = false;

      if( checkingDt.length == 4 && ! checkingDt.includes('/') )
      {
        checkingDt = checkingDt.substring(0,2) + "/" + checkingDt.substring(2,4) + "/";
        if( this.validatedateInv(checkingDt) )
        {
          this.dtValStr = checkingDt;
          dtGoAhead = true;
        } 
      }
      else if( checkingDt.length == 5 )
      {
        var dtArr = this.dtValStr.split("/");
        if( dtArr != undefined && dtArr != null && typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' )
        {
          checkingDt += "/";
          if( this.validatedateInv(checkingDt) )
          {
            this.dtValStr += "/";
            dtGoAhead = true;
          }
        }
      }

      if( dtGoAhead )
      {
        if( eleId == "chkDt" )
        {
          this.ckDtInv = true;
          this.check_date = this.dtValStr;
          setTimeout(() =>
          {
            this.ckDtInv = false;
          }, 50);
        }
        else if( eleId == "rptDt" )
        {
          this.rpdtInv = true;
          this.report_date = this.dtValStr;
          setTimeout(() =>
          {
            this.rpdtInv = false;
          }, 50);
        }
        else if( eleId == "prEndDt" )
        {
          this.pedDtInv = true;
          this.payroll_end_date = this.dtValStr;
          setTimeout(() =>
          {
            this.pedDtInv = false;
          }, 50);
        }
        else if( eleId == "achdDt" )
        {
          this.achDtInv = true;
          this.ach_withdrawal_date = this.dtValStr;
          setTimeout(() =>
          {
            this.achDtInv = false;
          }, 50);
        }
        else if( eleId == "payPostdDt" )
        {
          this.ppostdt = true;
          this.payroll_post_date = this.dtValStr;
          setTimeout(() =>
          {
            this.ppostdt = false;
          }, 50);
        }
      }
  }

  fillDtTab( eleId )
  {
    var goFillField = false;

    var chkTempStr = this.dtValStr;
    if( chkTempStr.length == 8 && chkTempStr.includes("/") && chkTempStr.charAt(2)=="/" && chkTempStr.charAt(5)=="/" )
    {
        var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
            if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[2].length == 2 )
              {
                var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                if( this.validatedateInv(checkingDt1) )
                {
                  this.dtValStr = checkingDt1;
                  goFillField = true;
                }
              }
            }
        }
    }

    if( ! goFillField && chkTempStr != "" )
    {
	      var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
          if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[0] != "" && tempStrArr[1] != "" && tempStrArr[2] != "" )
              {
                if( tempStrArr[0].length == 1 )
                {
                  tempStrArr[0] = "0" + tempStrArr[0];
                }
                if( tempStrArr[1].length == 1 )
                {
                  tempStrArr[1] = "0" + tempStrArr[1];
                }
                
                if( tempStrArr[2].length == 2 )
                {
                  var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                  if( this.validatedateInv(checkingDt1) )
                  {
                    this.dtValStr = checkingDt1;
                    goFillField = true;
                  }
                }
                else if( tempStrArr[2].length == 4 )
                {
                  var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + tempStrArr[2];
                  if( this.validatedateInv(checkingDt1) )
                  {
                    this.dtValStr = checkingDt1;
                    goFillField = true;
                  }
                }
              }
            }
        }
    }

    if( goFillField )
    {
      if( eleId == "chkDt" )
      {
        this.ckDtInv = false;
        this.check_date = this.dtValStr;
      }
      else if( eleId == "rptDt" )
      {
        this.rpdtInv = false;
        this.report_date = this.dtValStr;
      }
      else if( eleId == "prEndDt" )
      {
        this.pedDtInv = false;
        this.payroll_end_date = this.dtValStr;
      }
      else if( eleId == "achdDt" )
      {
        this.achDtInv = false;
        this.ach_withdrawal_date = this.dtValStr;
      }
      else if( eleId == "payPostdDt" )
      {
        this.ppostdt = false;
        this.payroll_post_date = this.dtValStr;
      }
    }
  }

  outOfDtField( event,eleId )
  {
    this.fillDtTab( eleId );

    if( eleId == "chkDt" )
    {
      this.ckDtInv = false;
      setTimeout(() =>
      {
        if( ! this.ckDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.check_date = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "prEndDt" )
    {
      this.pedDtInv = false;
      setTimeout(() =>
      {
        if( ! this.pedDtInv &&  this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.payroll_end_date = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "rptDt" )
    {
      this.rpdtInv = false;
      setTimeout(() =>
      {
        if( ! this.rpdtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.report_date = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "achdDt" )
    {
      this.achDtInv = false;
      setTimeout(() =>
      {
        if( ! this.achDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.ach_withdrawal_date = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "payPostdDt" )
    {
      this.ppostdt = false;
      setTimeout(() =>
      {
        if( ! this.ppostdt && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.payroll_post_date = this.dtValStr;
        }
      }, 15);
    }
  }

  completeMonth( eleId )
  {
      var tempStr = this.dtValStr; 
      var compMGo = false;
      if( tempStr.length == 1 && (parseInt(tempStr) > 1 && parseInt(tempStr) < 10) )
      {
        this.dtValStr = "0" + this.dtValStr + "/";
        compMGo = true;
      }
      else if( tempStr.length == 2 && (parseInt(tempStr) >= 1 && parseInt(tempStr) <= 12) )
      {
        this.dtValStr = this.dtValStr + "/";
        compMGo = true;
      }

      if( eleId == "chkDt" && compMGo )
      {
        this.ckDtInv = true;
        setTimeout(() =>
        {
         this.check_date = this.dtValStr;
        }, 100);
      }
      else if( eleId == "rptDt" && compMGo )
      {
        this.rpdtInv = true;
        setTimeout(() =>
        {
         this.report_date = this.dtValStr;
        }, 100);
      }
      else if( eleId == "prEndDt" && compMGo )
      {
        this.pedDtInv = true;
        setTimeout(() =>
        {
         this.payroll_end_date = this.dtValStr;
        }, 100);
      }
      else if( eleId == "achdDt" && compMGo )
      {
        this.achDtInv = true;
        setTimeout(() =>
        {
         this.ach_withdrawal_date = this.dtValStr;
        }, 100);
      }
      else if( eleId == "payPostdDt" && compMGo )
      {
        this.ppostdt = true;
        setTimeout(() =>
        {
         this.payroll_post_date = this.dtValStr;
        }, 100);
      }
  }

  completeDate( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 1  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" && parseInt(tempStr) >= 4 && parseInt(tempStr) < 10 )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + "0" + tempStr + "/";
          this.dtValStr = assignDtFill;
          if( eleId == "chkDt" )
          {
            this.ckDtInv = true;
            setTimeout(() =>
            {
              this.check_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "rptDt" )
          {
            this.rpdtInv = true;
            setTimeout(() =>
            {
              this.report_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "prEndDt" )
          {
            this.pedDtInv = true;
            setTimeout(() =>
            {
              this.payroll_end_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "achdDt" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.ach_withdrawal_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "payPostdDt" )
          {
            this.ppostdt = true;
            setTimeout(() =>
            {
              this.payroll_post_date = this.dtValStr;
            }, 100);
          }
        }
      }

  }

  completeDt1( eleId )
  {
      var tempStr = this.dtValStr;
      var tmpNumb = "";

      if( tempStr != undefined && tempStr != null && tempStr.length == 3 )
      {
        tmpNumb = tempStr.charAt(2);
      }

      if( tmpNumb != "" && parseInt(tmpNumb) >= 4 && parseInt(tmpNumb) < 10 )
      {
        var assignDtFill = tempStr.charAt(0) + tempStr.charAt(1) + "/" + "0" + tmpNumb + "/";
        this.dtValStr = assignDtFill;

        if( eleId == "chkDt" )
        {
          this.ckDtInv = true;
          setTimeout(() =>
          {
            this.check_date = this.dtValStr;
          }, 100);
        }
        else if( eleId == "rptDt" )
        {
          this.rpdtInv = true;
          setTimeout(() =>
          {
            this.report_date = this.dtValStr;
          }, 100);
        }
        else if( eleId == "prEndDt" )
        {
          this.pedDtInv = true;
          setTimeout(() =>
          {
            this.payroll_end_date = this.dtValStr;
          }, 100);
        }
        else if( eleId == "achdDt" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.ach_withdrawal_date = this.dtValStr;
          }, 100);
        }
        else if( eleId == "payPostdDt" )
        {
          this.ppostdt = true;
          setTimeout(() =>
          {
            this.payroll_post_date = this.dtValStr;
          }, 100);
        }
      }
  }

  completeDt2( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 2  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + tempStr + "/";
          this.dtValStr = assignDtFill;
          if( eleId == "chkDt" )
          {
            this.ckDtInv = true;
            setTimeout(() =>
            {
              this.check_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "rptDt" )
          {
            this.rpdtInv = true;
            setTimeout(() =>
            {
              this.report_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "prEndDt" )
          {
            this.pedDtInv = true;
            setTimeout(() =>
            {
              this.payroll_end_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "achdDt" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.ach_withdrawal_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "payPostdDt" )
          {
            this.ppostdt = true;
            setTimeout(() =>
            {
              this.payroll_post_date = this.dtValStr;
            }, 100);
          }
        }
      }

  }
  
  setYear(ev)
  {
    var justBackSpaced = false;

    if( ev.inputType != undefined && ev.inputType != null )
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined  )
      {
        this.dtValStr = ev.srcElement.value;
      }
      if( ev.inputType.trim() == "deleteContentBackward" || ev.inputType.trim() == "deleteContentForward" )
      {
        this.dtValStr = ev.srcElement.value;
        justBackSpaced = true;
      }
    }
    else
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined )
      {
        if( ev.srcElement.value.length < this.chromePrevStr.length )
        {
          justBackSpaced = true;
        }
        this.dtValStr = ev.srcElement.value;
        this.chromePrevStr = ev.srcElement.value;
      }
    }

    if( ! justBackSpaced && (this.dtValStr.length == 4 && ! this.dtValStr.includes("/")) )
    {
      // this.appendYr( ev.srcElement.id );
    }
    else if( ! justBackSpaced && (this.dtValStr.length == 1 || this.dtValStr.length == 2) )
    {
       // this.completeMonth( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 4 )
    {
       // this.completeDate( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 5 )
    {
       // this.completeDt2( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 3 && ! this.dtValStr.includes("/") )
    {
      // this.completeDt1( ev.srcElement.id );
    }
  }

  resetDtVal( dtFieldNum:number = 0 )
  {
    setTimeout(() =>
    {
      this.dtValStr = '';
      if( dtFieldNum == 1 )
      {
        if( this.report_date != undefined && this.report_date != null && this.report_date != '' )
        {
          this.setAchDate();
        }
      }
    }, 50);
  }

  showInvNotes( currInv: any )
  {
    this.currInvNotes = currInv.notes;
    this.popupInvNotes = true;
  }

  openPDF(invSettingId: number)
  { 
    window.open(this.configServiceService.getApiUrl() + '/pdfview?download=pdf&invoiceId=' + invSettingId + '&id=' + this.id + '&up=0', '_blank');
  }

  openPFPDF(invSettingId: number) {
    window.open(this.configServiceService.getApiUrl() + '/pfpdf?download=pdf&invoiceId=' + invSettingId + '&id=' + this.id + '&up=0', '_blank');
  }

  openNPRPDF(invSettingId: number) {
    window.open(this.configServiceService.getApiUrl() + '/nprpdf?download=pdf&invoiceId=' + invSettingId + '&id=' + this.id + '&up=0', '_blank');
  }

  setAchDate() {
    var date = new Date(this.report_date);
    var newdate = new Date(date);
    var getDayValue = newdate.getDay();

    // console.log('getDayValue', getDayValue);

    if(getDayValue == 0){
      newdate.setDate(newdate.getDate() + (1 - getDayValue));
    }else {
      if(getDayValue == 1){
        newdate.setDate(newdate.getDate() + 7);
      }else if(getDayValue == 2){
        newdate.setDate(newdate.getDate() + 6);
      }else if(getDayValue == 3){
        newdate.setDate(newdate.getDate() + 5);
      }else if(getDayValue == 4){
        newdate.setDate(newdate.getDate() + 4);
      }else if(getDayValue == 5){
        newdate.setDate(newdate.getDate() + 3);
      }else if(getDayValue == 6){
        newdate.setDate(newdate.getDate() + 2);
      }
    }
    var newdate1 = new Date(newdate);
    
    this.ach_withdrawal_date = newdate1.toLocaleDateString('en-US');
  }

  achPrefillTab()
  {
    if( this.report_date != undefined && this.report_date != null )
    {
      this.setAchDate();
    }
  }

  saveInvoiceeNote() {

    this.submitted = true;

    this.invoiceNotePostObj = {};
    this.invoiceNotePostObj.client_id = this.id;
    this.invoiceNotePostObj.notes = $('#clientNotes').summernote('code');
    this.invoiceNotePostObj.mode = this.invAddUpd;
    this.invoiceNotePostObj.user_id = this.storage.get('app.auth').id;

    this.policyService.saveInvoiceeNote(this.invoiceNotePostObj).subscribe(
      response => {
        console.log(response);
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
          alert("Could not save Invoice Note.");
          this.submitted = false;
        } else {
          this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });
          if (this.invAddUpd == 0) {
            // alert("Invoice Notes Saved Successfully.");
          }
          else if (this.invAddUpd == 1) {
            // alert("Invoice Notes Updated Successfully.");
          }
          this.invAddUpd = 1;
          this.submitted = false;
          this.editorDisp = true;
          this.showSavedMsg = true;

          $('#clientNotes').summernote('destroy');
          this.invNotesBk = this.invoiceNotePostObj.notes;

          setTimeout(() => {
              $('#notesDisp').html(this.invoiceNotePostObj.notes);
          }, 50);

          setTimeout(() => {
              this.showSavedMsg = false;
          }, 2500);

        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        alert("Could not save Invoice Note.");
        this.submitted = false;
      });
  }

  paginate(event) {
    this.spinnerService.show();
    this.skip = event.first;
    this.take = event.rows;
    this.loadInvHistory();
  }

  pagiPF(event) {
    this.spinnerService.show();
    this.skipPF = event.first;
    this.takePF = event.rows;
    this.loadPFHistory();
  }

  pagiMB(event) {
    this.spinnerService.show();
    this.skipPF = event.first;
    this.takePF = event.rows;
    this.loadMBHistory();
  }

  loadInvHistory()
  {
    // console.log("Sidebar: " + this.policyService.getSidebarValue());
    var sb = this.policyService.getSidebarValue();

    setTimeout(() =>
    {
      if( sb != undefined && sb )
      {
        // console.log("11 loadInvHistory");
        setTimeout(() => {
          // Load Invoice History
          this.invCorsArr = [];
          this.invNSFsArr = [];
          this.invREVsArr = [];
          this.invoicesList = [];
          // console.log("About to call history");
          this.policyService.getInvoiceHistoryPagination(this.id, this.skip, this.take,0).subscribe(
            response => {
              if (response instanceof HttpErrorResponse) {
                this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
              } else {
                this.invoicesList = <any[]>response.invoiceSettings;
                if(this.skip == 0){
                  this.allInvoicesCount = response.invoiceSettingsCount;
                }
                this.spinnerService.hide();
                // this.scrollTo('scroll123');
              }
            },
            error => {
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
            });
        }, 4000);
      }
      else
      {
        // console.log("22 loadInvHistory");
        // Load Invoice History
        this.invCorsArr = [];
        this.invNSFsArr = [];
        this.invREVsArr = [];
        this.invoicesList = [];
        this.policyService.getInvoiceHistoryPagination(this.id, this.skip, this.take,0).subscribe(
          response => {
            if (response instanceof HttpErrorResponse) {
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            } else {
              this.invoicesList = <any[]>response.invoiceSettings;
              if(this.skip == 0){
                this.allInvoicesCount = response.invoiceSettingsCount;
              }
              this.spinnerService.hide();
              // this.scrollTo('scroll123');
            }
          },
          error => {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
          });
      }
    }, 1000);

  }

  loadPFHistory()
  {
    var sb = this.policyService.getSidebarValue();

    setTimeout(() =>
    {
      if( sb != undefined && sb )
      {
        setTimeout(() => {
          // Load Invoice History
          this.invCorsArr = [];
          this.invNSFsArr = [];
          this.invREVsArr = [];
          this.pfList = [];
          this.policyService.getInvoiceHistoryPagination(this.id, this.skipPF, this.takePF,2).subscribe(
            response => {
              if (response instanceof HttpErrorResponse) {
                this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
              } else {
                this.pfList = <any[]>response.invoiceSettings;
                if(this.skipPF == 0){
                  this.pfInvoicesCount = response.invoiceSettingsCount;
                }
                this.spinnerService.hide();
              }
            },
            error => {
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
            });
        }, 4000);
      }
      else
      {
        // Load Invoice History
        this.invCorsArr = [];
        this.invNSFsArr = [];
        this.invREVsArr = [];
        this.pfList = [];
        this.policyService.getInvoiceHistoryPagination(this.id, this.skipPF, this.takePF,2).subscribe(
          response => {
            if (response instanceof HttpErrorResponse) {
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            } else {
              this.pfList = <any[]>response.invoiceSettings;
              if(this.skipPF == 0){
                this.pfInvoicesCount = response.invoiceSettingsCount;
              }
              this.spinnerService.hide();
            }
          },
          error => {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
          });
      }
    }, 1000);

  }

  loadMBHistory()
  {
    var sb = this.policyService.getSidebarValue();

    setTimeout(() =>
    {
      if( sb != undefined && sb )
      {
        setTimeout(() => {
          // Load Invoice History
          this.invCorsArr = [];
          this.invNSFsArr = [];
          this.invREVsArr = [];
          this.mbList = [];
          this.policyService.getInvoiceHistoryPagination(this.id, this.skipPF, this.takePF,5).subscribe(
            response => {
              if (response instanceof HttpErrorResponse) {
                this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
              } else {
                this.mbList = <any[]>response.invoiceSettings;
                if(this.skipPF == 0){
                  this.mbInvoicesCount = response.invoiceSettingsCount;
                }
                this.spinnerService.hide();
              }
            },
            error => {
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
            });
        }, 4000);
      }
      else
      {
        // Load Invoice History
        this.invCorsArr = [];
        this.invNSFsArr = [];
        this.invREVsArr = [];
        this.mbList = [];
        this.policyService.getInvoiceHistoryPagination(this.id, this.skipPF, this.takePF,5).subscribe(
          response => {
            if (response instanceof HttpErrorResponse) {
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            } else {
              this.mbList = <any[]>response.invoiceSettings;
              if(this.skipPF == 0){
                this.mbInvoicesCount = response.invoiceSettingsCount;
              }
              this.spinnerService.hide();
            }
          },
          error => {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
          });
      }
    }, 1000);

  }

  scrollTo(className: string):void {
    setTimeout(function(){
      const elementList = document.querySelectorAll('.' + className);
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    },1000);
  }

  allowEdit()
  {
      this.editorDisp = false;

      setTimeout(() => {

        $('#clientNotes').summernote({
            toolbar: [
                //[groupname, [button list]]
                ["style", ['style']],
                ["font", ["bold",'italic', "underline", "clear"]],
                ['color', ['forecolor','backcolor']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['fontname', ['fontname']],
                ['fullscreen', ['fullscreen']],
                ['link', ['link']],
            ],
            height:320,
            focus:true
           
        });

        // $('.note-toolbar span').removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-style").find("span.note-icon-caret").removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-fontname").find("span.note-icon-caret").removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-para").find("span.note-icon-caret").removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-table").find("span.note-icon-caret").removeClass('note-icon-caret');
    
        $('.note-editable').css('font-family','Times New Roman');
        $('div .note-btn-group .note-color i').css( 'background-color','#ffffff' );

        $('#clientNotes').summernote('fontName','Times New Roman');
        $('#clientNotes').summernote('code', this.invNotesBk);
    
    }, 105);

  }

  ngOnInit() {

    // var numberOfDaysToAdd = 2;
    // this.ach_withdrawal_date.setDate(this.ach_withdrawal_date.getDate() + numberOfDaysToAdd);

    this.superDeletorIds.push( 'tina.meyer@reliablepremium.com' );
    this.superDeletorIds.push( 'caryn.freeman@reliablepremium.com' );
    this.superDeletorIds.push( 'sathyam.yaramala@gmail.com' );
    this.superDeletorIds.push( 'jade.bjerke@reliablepremium.com' );

    this.invoiceNoteForm = this.fb.group({
      'notes': new FormControl('', Validators.required)
    });

    this.spinnerService.show();

    // this.invCorsArr = [239918];

    this.corrInProgress = false;

    // Load invoice types
    this.invoice_types = [
      // { label: 'Premium Invoice', value: 'premium_invoice' },
      // { label: 'Policy Fee Invoice', value: 'policy_fee' },
      { label: 'Payroll Service Fee Invoice', value: 'policy_fee' },
      { label: 'Misc Billing Invoice', value: 'misc_fee' },
      // { label: 'NPR Invoice', value: 'npr' },
      // { label: 'Payroll Reporting', value: 'payroll_reporting' },
    ];

    this.invoice_type = 'policy_fee';
    this.msgs = [];

    this.clientService.getDispServerTime().subscribe(res =>
      {
        if( res != undefined && res != null && typeof res[0] !== 'undefined' )
        
        this.serverYr = res[0];
      });

    
    this.clientService.getUserEmail( this.storage.get('app.auth').id ).subscribe(res =>
    {
      if( res != undefined && res != null && typeof res[0] !== 'undefined' )
      {
        this.loggedInEmail = res[0].email;
        this.userEmailLoaded = true;
      }
    });
        
    // Load client   
    this.clientService.getClient(this.id).subscribe(res => {
      this.client = res;
      if( this.client != undefined && this.client != null && this.client.def_inv_type != undefined && this.client.def_inv_type != null )
      {
        if( this.client.def_inv_type == 1 )
        {
            this.invoice_type = "premium_invoice";
        }
        else if( this.client.def_inv_type == 2 )
        {
            this.invoice_type = "policy_fee";
        }
        else if( this.client.def_inv_type == 5 )
        {
            this.invoice_type = "misc_fee";
        }
        else if( this.client.def_inv_type == 3 )
        {
            this.invoice_type = "npr";
        }
        else if( this.client.def_inv_type == 4 )
        {
            this.invoice_type = "payroll_reporting";
        }
      }
    });
    this.loggedUserRole = localStorage.getItem('loggedUserRole');
    this.invoiceNotePostObj = {};
    this.invoiceNotePostObj.client_id = this.id;
    this.invoiceNotePostObj.mode = -1;

    this.policyService.getInvoiceeNote(this.invoiceNotePostObj).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
        } else {
          if (response != undefined && response != null && response.notes != undefined && response.notes != null) {
            
            this.invNotesBk = response.notes;
            // $('#notesDisp').html(response.notes);
            // this.invoiceNoteForm.controls['notes'].setValue(response.notes);
            
            this.invAddUpd = 1;

            setTimeout(() => {
              // this.editorDisp = false;
          }, 2000);
            
          }

          if (response == undefined || response == null || response.notes == undefined || response.notes == null)
           {
            setTimeout(() => {
              // this.editorDisp = false;
          }, 2000);
            
          }
          
        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
      });

    this.loadInvHistory();
    this.loadPFHistory();
    this.loadMBHistory();
  }

  changeDateFormate(oldDate){
    if (typeof oldDate !== 'undefined' && oldDate !== '' && oldDate !== null) {
      var splitDate = oldDate.split(' ');
      var splitDateFinal = splitDate[0].split('-');
      // var dtArrr = new Date(splitDateFinal[0], splitDateFinal[1], splitDateFinal[2]);
      // return (dtArrr.getMonth())+'/'+dtArrr.getDate()+'/'+dtArrr.getFullYear();
      return splitDateFinal[1]+'/'+splitDateFinal[2]+'/'+splitDateFinal[0];
    }else{
      return 'No date';
    }
  }
  
  setCurrFormat()
  {
    if( this.payrollTotal != undefined && this.payrollTotal != null && this.payrollTotal.trim() != "" )
    {
      var tv = parseFloat(this.payrollTotal.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
  
      this.payrollTotal = figure;
    }
  }

  premInvGoAhead()
  {
    var payrollTotal="";
    var qParams = {};
    if( this.payrollTotal != undefined && this.payrollTotal != null && this.payrollTotal.trim() != "" )
    {
      qParams = {
        invoice_type: this.invoice_type,
        check_date: this.check_date,
        payroll_end_date: this.payroll_end_date,
        report_date: this.report_date,
        ach_withdrawal_date: this.ach_withdrawal_date,
        payrollTotal:this.payrollTotal
      };
    }
    else
    {
      qParams = {
        invoice_type: this.invoice_type,
        check_date: this.check_date,
        payroll_end_date: this.payroll_end_date,
        report_date: this.report_date,
        ach_withdrawal_date: this.ach_withdrawal_date
      };
    }

    this.router.navigate(['/clients/list/' + this.id + '/invoice/create'], { queryParams: qParams });
  }

  closeIncPopUp()
  {
    this.invCancldCrsd = false;
  }

  incompleteCheck( invoiceId )
  {
    this.spinnerService.show();
    this.invPolPostObj = {};
    this.invPolPostObj.checkIncomplete =  1;
    this.invPolPostObj.invoiceSettingId = invoiceId;
    this.policyService.checkIncCancld(this.invPolPostObj).subscribe(data =>
    {
      this.spinnerService.hide();
      this.invPolPostObj = {};

      if( data != undefined && data != null )
      {
        if( (data.polFoundCancld != undefined && data.polFoundCancld != null) || (data.polEndDateCrossed != undefined && data.polEndDateCrossed != null) )
        {
          this.invCancldCrsd = true;
          this.invCnCrMsg = data.message;
        }
        else
        {
          // router.navigateByUrl('/clients/list/'+clientId+'/invoice/revise/'+invoiceId)
          this.router.navigate( ['/clients/list/' + this.id + '/invoice/revise/'+invoiceId] );
        }
      }
      else
      {
        alert( "Could Not Check Invoice Policy Status, cannot open invoice." );
      }
    });
  }

  sendtoInvGen()
  {
    var genInv = true;

    this.chkdt_nameError = 'none';
    this.prdt_nameError = 'none';
    this.rptdt_nameError = 'none';
    this.achwdt_nameError = 'none';
    this.payroll_post_date_Error = 'none';

    if( this.invoice_type == 'premium_invoice' || this.invoice_type == 'payroll_reporting' )
    {
      if (this.check_date == undefined || this.check_date == null || this.check_date== "") {
        this.chkdt_nameError = 'block';
        genInv = false;
      }
      else {
        this.chkdt_nameError = 'none';
      }

      if (this.payroll_end_date == undefined || this.payroll_end_date == null || this.payroll_end_date == "") {
        this.prdt_nameError = 'block';
        genInv = false;
      }
      else {
        this.prdt_nameError = 'none';
      }
    }
    if (this.invoice_type == 'premium_invoice' || this.invoice_type == 'policy_fee' || this.invoice_type == 'misc_fee' || this.invoice_type == 'npr')
    {
      if (this.report_date == undefined || this.report_date == null || this.report_date == "") {
        this.rptdt_nameError = 'block';
        genInv = false;
      }
      else {
        this.rptdt_nameError = 'none';
      }

      if (this.ach_withdrawal_date == undefined || this.ach_withdrawal_date == null || this.ach_withdrawal_date == "") {
        this.achwdt_nameError = 'block';
        genInv = false;
      }
      else {
        this.achwdt_nameError = 'none';
      }
    }
    if( this.invoice_type == 'payroll_reporting' )
    {
      if (this.report_date == undefined || this.report_date == null || this.report_date == "") {
        this.rptdt_nameError = 'block';
        genInv = false;
      }
      else {
        this.rptdt_nameError = 'none';
      }
    }
    if (this.invoice_type == 'npr')
    {
      if (this.payroll_post_date == undefined || this.payroll_post_date == null || this.payroll_post_date == "") {
        this.payroll_post_date_Error = 'block';
        genInv = false;
      }
      else {
        this.payroll_post_date_Error = 'none';
      }
    }
    if( this.invoice_type == 'premium_invoice' || this.invoice_type == 'payroll_reporting' )
    {
      if (this.payrollTotal == undefined || this.payrollTotal == null || this.payrollTotal.trim() == '' ) {
        this.ptError = 'block';
        genInv = false;
      }
      else {
        this.ptError = 'none';
      }
    }

    
    if (genInv) {
      var invGoAhead = false;
      if (this.invoice_type == 'premium_invoice' && this.check_date != undefined && this.payroll_end_date != undefined && this.report_date != undefined && this.ach_withdrawal_date != undefined) {
        invGoAhead = true;
      }
      else if (this.invoice_type == 'policy_fee' && this.report_date != undefined && this.ach_withdrawal_date != undefined) {
        invGoAhead = true;
      }
      else if (this.invoice_type == 'misc_fee' && this.report_date != undefined && this.ach_withdrawal_date != undefined) {
        invGoAhead = true;
      }
      else if (this.invoice_type == 'npr' && this.report_date != undefined && this.ach_withdrawal_date != undefined && this.payroll_post_date != undefined) {
        invGoAhead = true;
      }
      if (this.invoice_type == 'payroll_reporting' && this.check_date != undefined && this.payroll_end_date != undefined && this.report_date != undefined ) {
        invGoAhead = true;
      }

      if (invGoAhead) {
        this.spinnerService.show();
        var dtMsg = "";

        if (this.invoice_type == 'premium_invoice' || this.invoice_type == 'payroll_reporting') {
          // this.requiredCheckDate = this.check_date;

          this.invPolPostObj = {};
          this.invPolPostObj.requiredCheckDate =  this.check_date;
          this.invPolPostObj.clientId = this.id;
          this.policyService.getInvoicePolicy(this.invPolPostObj).subscribe(data => {
            this.spinnerService.hide();

            if (data != undefined && data != null && data.invoiceExists != undefined && data.invoiceExists != null) {
              alert("Invoice exists with given check date.");
            }
            else if (data != undefined && data != null && (data.policyToConsider == undefined || data.policyToConsider == null)) {
              if(  (data.polFoundCancld != undefined && data.polFoundCancld != null) || (data.polEndDateCrossed != undefined && data.polEndDateCrossed != null) )
              {
                alert(data.message);
              }
              else
              {
                alert("No matching policy found.");
              }
            }
            else
            {
              if( this.invoice_type == 'premium_invoice' )
              {
                if (this.check_date != undefined && this.payroll_end_date != undefined && this.report_date != undefined && this.ach_withdrawal_date != undefined)
                {
                  var chkDateAchDtSame = false;
          
                  if( this.check_date.trim() != "" && this.ach_withdrawal_date.trim() != "" )
                  {
                    var sameChkDate = this.check_date;
                    var sameAchDt = "";
                    var finalAchDtArr =  this.ach_withdrawal_date.split( '/' );
                    if( typeof finalAchDtArr !== 'undefined' && typeof finalAchDtArr[0] !== 'undefined' && typeof finalAchDtArr[1] !== 'undefined' && typeof finalAchDtArr[2] !== 'undefined' )
                    {
                        if( finalAchDtArr[0].length == 1 )
                        {
                          sameAchDt += "0" + finalAchDtArr[0];
                        }
                        else
                        {
                          sameAchDt = finalAchDtArr[0];
                        }
                        if( finalAchDtArr[1].length == 1 )
                        {
                          sameAchDt += "/" + "0" + finalAchDtArr[1];
                        }
                        else
                        {
                          sameAchDt += "/" + finalAchDtArr[1];
                        }
                        sameAchDt += "/" + finalAchDtArr[2];
                    }
          
                    if( sameChkDate == sameAchDt )
                    {
                      chkDateAchDtSame = true;
                    }
                  }
          
                  // console.log(sameChkDate);
                  // console.log(sameAchDt);
                  // console.log(chkDateAchDtSame);
          
                  if( chkDateAchDtSame )
                  {
                    this.confirmationService.confirm
                    ({
                      message: 'Withdrawal date is on or before the check date! Do you want to continue?',
                      accept: () =>
                      {
                        this.premInvGoAhead();
                      },
                      reject: () => {
                      }
                    });
                  }
                  else
                  {
                    // Checking Whether Withdrawal date is before Check Date.
                    this.invPolPostObj = {};
                    this.invPolPostObj.check_date =  sameChkDate;
                    this.invPolPostObj.ach_date =  sameAchDt;
                    this.policyService.checkChkDtAchDt(this.invPolPostObj).subscribe(data =>
                    {
                      if( data != undefined && data.withDtBefore != undefined && data.withDtBefore == 1 )
                      {
                        this.confirmationService.confirm
                        ({
                          message: 'Withdrawal date is on or before the check date! Do you want to continue?',
                          accept: () =>
                          {
                            this.premInvGoAhead();
                          },
                          reject: () => {
                          }
                        });
                      }
                      else
                      {
                        this.premInvGoAhead();
                      }
                    });
                  }
                }
              }
              else if( this.invoice_type == 'payroll_reporting' )
              {
                var qParams = this.buildQueryParams();
                this.router.navigate(['/clients/list/' + this.id + '/invoice/create'], { queryParams: qParams });
              }
            }
          });
        }
        else if (this.invoice_type == 'policy_fee' || this.invoice_type == 'misc_fee') {
          this.spinnerService.hide();
          this.spinnerService.show();

          this.invPolPostObj = {};
          // this.invPolPostObj.requiredCheckDate = this.ach_withdrawal_date;
          this.invPolPostObj.requiredCheckDate = this.report_date;
          this.invPolPostObj.clientId = this.id;
          this.invPolPostObj.PF_NPR = 2;
          this.policyService.checkPFNPRInvoice(this.invPolPostObj).subscribe(data => {
            this.spinnerService.hide();

            if (data != undefined && data != null && data.invoiceExists != undefined && data.invoiceExists != null) {
              alert("Invoice exists with given CHECK DATE.");
            }
            else {
              this.spinnerService.hide();
              var qParams = this.buildQueryParams();
              this.router.navigate(['/clients/list/' + this.id + '/invoice/create'], { queryParams: qParams });
            }
          });
        }
        else if (this.invoice_type == 'npr') {
          this.spinnerService.hide();
          this.spinnerService.show();

          // this.requiredCheckDate = this.payroll_post_date;

          this.invPolPostObj = {};
          this.invPolPostObj.requiredCheckDate = this.payroll_post_date;
          this.invPolPostObj.clientId = this.id;
          this.invPolPostObj.PF_NPR = 3;
          this.policyService.checkPFNPRInvoice(this.invPolPostObj).subscribe(data => {
            this.spinnerService.hide();

            if (data != undefined && data != null && data.invoiceExists != undefined && data.invoiceExists != null) {
              alert("Invoice exists with given Payroll Post Date.");
            }
            else if (data != undefined && data != null && (data.policyToConsider == undefined || data.policyToConsider == null)) {
              if(  (data.polFoundCancld != undefined && data.polFoundCancld != null) || (data.polEndDateCrossed != undefined && data.polEndDateCrossed != null) )
              {
                alert(data.message);
              }
              else
              {
                alert("No matching policy found.");
              }
            }
            else {
              this.spinnerService.hide();
              var qParams = this.buildQueryParams();
              this.router.navigate(['/clients/list/' + this.id + '/invoice/create'], { queryParams: qParams });
            }
          });
        }
      }
    }
  }

  doCorrection(invoiceSettingId: number, actionType: number, invoiceType: any)
  {
    this.invActionPostObj = {};
    this.invActionPostObj.invoiceSettingId = invoiceSettingId;
    this.invActionPostObj.invoiceAction = actionType;
    this.invActionPostObj.user_id = this.storage.get('app.auth').id;

    var invType: number = 0;
    if (invoiceType == 1) {
      invType = 1;
    }
    else if (invoiceType == 2 || invoiceType == 5) {
      invType = 2;
    }
    else if (invoiceType == 3) {
      invType = 3;
    }

    if (invType == 1)
    {
      this.policyService.invAction(this.invActionPostObj, 1).subscribe(
        response =>
        {
          console.log(response);
          if (response instanceof HttpErrorResponse) {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            this.spinnerService.hide();
            alert("Could not perform correction action.");
          }
          else
          {
            this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });
            this.corrInProgress = false;
            this.spinnerService.hide();
            if (actionType == 1)
            {
              // alert( "Successfully performed Correction action." );
            }
            else if (actionType == 2)
            {
              // alert( "Successfully performed NSF action." );
            }
            else if (actionType == 3)
            {
              // alert( "Successfully performed Revision action." );
            }

            this.spinnerService.show();
            var newInvSettingId = 0;
            if (response != undefined && response != null && response.invoiceSetting != undefined && response.invoiceSetting != null) {
              if (response.invoiceSetting.id != undefined && response.invoiceSetting.id != null) {
                newInvSettingId = response.invoiceSetting.id
              }
            }

            // this.loadInvHistory();
            this.router.navigateByUrl('/clients/list/' + this.id + '/invoice/revise/' + newInvSettingId);
          }

        },
        error => {
          console.log(error);
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
          this.spinnerService.hide();
          alert("Could not perform correction action.");
        });
    }
    else if (invType == 2)
    {
      this.policyService.invAction(this.invActionPostObj, invType).subscribe(
        response => {
          console.log(response);
          if (response instanceof HttpErrorResponse) {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            this.spinnerService.hide();
            alert("Could not perform correction action.");
          } else {
            this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });
            this.corrInProgress = false;
            this.spinnerService.hide();
            if (actionType == 1) {
              // alert( "Successfully performed Correction action." );
            }
            else if (actionType == 2) {
              // alert( "Successfully performed NSF action." );
            }
            else if (actionType == 3) {
              // alert( "Successfully performed Revision action." );
            }

            this.spinnerService.show();
            var newInvSettingId = 0;
            if (response != undefined && response != null && response.invoiceSetting != undefined && response.invoiceSetting != null) {
              if (response.invoiceSetting.id != undefined && response.invoiceSetting.id != null) {
                newInvSettingId = response.invoiceSetting.id
              }
            }

            // this.loadInvHistory();
            this.router.navigateByUrl('/clients/list/' + this.id + '/invoice/revise/' + newInvSettingId);
          }

        },
        error => {
          console.log(error);
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
          this.spinnerService.hide();
          alert("Could not perform correction action.");
        });
    }
    else if (invType == 3)
    {
      this.policyService.invAction(this.invActionPostObj, 3).subscribe(
        response => {
          console.log(response);
          if (response instanceof HttpErrorResponse) {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            this.spinnerService.hide();
            alert("Could not perform correction action.");
          } else {
            this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });
            this.corrInProgress = false;
            this.spinnerService.hide();
            if (actionType == 1) {
              // alert( "Successfully performed Correction action." );
            }
            else if (actionType == 2) {
              // alert( "Successfully performed NSF action." );
            }
            else if (actionType == 3) {
              // alert( "Successfully performed Revision action." );
            }

            this.spinnerService.show();
            var newInvSettingId = 0;
            if (response != undefined && response != null && response.invoiceSetting != undefined && response.invoiceSetting != null) {
              if (response.invoiceSetting.id != undefined && response.invoiceSetting.id != null) {
                newInvSettingId = response.invoiceSetting.id
              }
            }

            // this.loadInvHistory();
            this.router.navigateByUrl('/clients/list/' + this.id + '/invoice/revise/' + newInvSettingId);
          }

        },
        error => {
          console.log(error);
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
          this.spinnerService.hide();
          alert("Could not perform correction action.");
        });
    }
  }

  corrAction(invoiceSettingId: number, actionType: number, invoiceType: any)
  {
    if (invoiceSettingId != undefined && invoiceSettingId != null && !this.corrInProgress)
    {
      this.corrInProgress = true;

      this.spinnerService.show();
      this.actionsGoStop = false;
      this.invPolPostObj = {};
      this.invPolPostObj.checkActions =  1;
      this.invPolPostObj.invoiceSettingId = invoiceSettingId;
      this.policyService.checkActnsCancld(this.invPolPostObj).subscribe(data =>
      {
        this.spinnerService.hide();
        this.invPolPostObj = {};
  
        if( data != undefined && data != null )
        {
          if( (data.polFoundCancld != undefined && data.polFoundCancld != null) || (data.polEndDateCrossed != undefined && data.polEndDateCrossed != null) )
          {
            this.actionsGoStop = true;
            this.corrInProgress = false;
            if (actionType == 1) {
              this.invCorsArr = [];
            }
            else if (actionType == 2) {
              this.invNSFsArr = [];
            }
            else if (actionType == 3) {
              this.invREVsArr = [];
            }
        
            this.invCancldCrsd = true;
            this.invCnCrMsg = data.message;
          }
          else
          {
            this.actionsGoStop = false;

            this.confirmationService.confirm
            ({
              message: 'Are you sure you want to make this change?',
              accept: () => {
                this.spinnerService.show();
                this.doCorrection(invoiceSettingId, actionType, parseInt(invoiceType));
              },
              reject: () => {
                this.corrInProgress = false;
                if (actionType == 1) {
                  this.invCorsArr = [];
                }
                else if (actionType == 2) {
                  this.invNSFsArr = [];
                }
                else if (actionType == 3) {
                  this.invREVsArr = [];
                }
              }
            });
          }
        }
        else
        {
          this.corrInProgress = false;
          alert( "Could Not Check Invoice Policy Status, cannot perform action." );
        }
      });
    }
  }

  doVoidAction(invoiceSettingId: number, actionType: number)
  {
    this.invActionPostObj = {};
    this.invActionPostObj.invoiceSettingId = invoiceSettingId;
    this.invActionPostObj.invoiceAction = actionType;

    this.policyService.voidAction(this.invActionPostObj).subscribe(
      response => {
        console.log(response);
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
          this.spinnerService.hide();
          alert("Could not perform correction action.");
        } else {
          this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });

          this.corrInProgress = false;
          this.voidInProgress = false;

          if (response != undefined && response != null && response.invUpdated != undefined && response.invUpdated != null) {
            console.log(response.invUpdated);
          }

          this.spinnerService.hide();
          this.spinnerService.show();

          this.loadInvHistory();
        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        this.spinnerService.hide();
        alert("Could not perform void action.");
      });
  }

  voidAction(invoiceSettingId: number, actionType: number) {
    if (invoiceSettingId != undefined && invoiceSettingId != null && !this.corrInProgress) {
      this.corrInProgress = true;

      this.confirmationService.confirm
        ({
          message: 'Are you sure you want to Void the Invoice?',
          accept: () => {
            this.spinnerService.show();
            this.voidInProgress = true;

            this.doVoidAction(invoiceSettingId, actionType);
          },
          reject: () => {
            this.corrInProgress = false;
            this.voidInProgress = false;
          }
        });
    }
  }

  doHoldAction(invoiceSettingId: number, actionType: number,invType: number)
  {
    this.invActionPostObj = {};
    this.invActionPostObj.invoiceSettingId = invoiceSettingId;
    this.invActionPostObj.invoiceAction = actionType;
    this.invActionPostObj.reason_held = this.reason_held;
    this.invActionPostObj.user_id = this.storage.get('app.auth').id;

    this.policyService.holdAction(this.invActionPostObj).subscribe(
      response => {
        // console.log(response);
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });

          this.holdInvoiceId = 0;
          this.holdActionType = 0;
          this.holdInvoiceType = 0;
          this.reason_held = '';
          this.reasonValdn = false;
                
          this.holdInProgress = false;
          
          this.spinnerService.hide();
          alert("Could not perform hold action.");
        } else {
          this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });

          if (response != undefined && response != null && response.invUpdated != undefined && response.invUpdated != null) {
            // console.log(response.invUpdated);
          }

          this.holdInvoiceId = 0;
          this.holdActionType = 0;
          this.holdInvoiceType = 0;
          this.reason_held = '';
          this.reasonValdn = false;
                
          this.holdInProgress = false;

          this.spinnerService.hide();

          if( response != undefined && response != null && response.invoiceExists != undefined && response.invoiceExists != null && response.invoiceExists == 1 )
          {
            // console.log(response);
            if( response != undefined && response != null && response.invoice_type != undefined && response.invoice_type != null )
            {
                if( response.invoice_type == 1 )
                {
                  this.notActivatedField = "check date";
                }
                else if( response.invoice_type == 2 )
                {
                  this.notActivatedField = "ACH Withdrawal date";
                }
                else if( response.invoice_type == 3 )
                {
                  this.notActivatedField = "Payroll Due date";
                }
            }
            this.invNotUnHold = true;
          }
          else
          {
            if( invType == 2 )
            {
              this.spinnerService.show();
              this.loadPFHistory();
            }
            else if( invType == 5 )
            {
              this.spinnerService.show();
              this.loadMBHistory();
            }
            else if( invType != 2 && invType != 5 )
            {
              this.spinnerService.show();
              this.loadInvHistory();
            }
          }
        }
      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });

        this.holdInvoiceId = 0;
        this.holdActionType = 0;
        this.holdInvoiceType = 0;
        this.reason_held = '';
        this.reasonValdn = false;
              
        this.holdInProgress = false;
      
        this.spinnerService.hide();
        alert("Could not perform hold action.");
      });
  }

  holdPopupYes()
  {
    if( this.reason_held == undefined || this.reason_held == null || this.reason_held.trim() == "" )
    {
      this.reasonValdn = true;
    }
    else
    {
      this.holdConfirmation = false;

      this.spinnerService.show();
  
      this.doHoldAction(this.holdInvoiceId,this.holdActionType,this.holdInvoiceType);
    }
  }
  closeHoldPopUp()
  {
    this.holdConfirmation = false;

    this.holdInvoiceId = 0;
    this.holdActionType = 0;
    this.holdInvoiceType = 0;
    this.reason_held = '';
    this.reasonValdn = false;

    this.holdInProgress = false;
  }

  holdAction(invoiceSettingId: number, actionType: number,invType: number)
  {
    if (invoiceSettingId != undefined && invoiceSettingId != null && !this.holdInProgress)
    {
      this.holdInProgress = true;

      if( actionType == 0 )
      {
        var msg='Are you sure you want to Activate the Invoice?';
        this.confirmationService.confirm
        ({
            message: msg,
            accept: () => {
              this.spinnerService.show();
  
              this.doHoldAction(invoiceSettingId,actionType,invType);
            },
            reject: () => {
              this.holdInProgress = false;
            }
        });
      }
      else
      {
        this.holdMsg='Are you sure you want to Hold the Invoice?';
        this.holdHeader = 'Hold Invoice.';
        this.reason_held = '';
        this.reasonValdn = false;

        this.holdInvoiceId = invoiceSettingId;
        this.holdActionType = actionType;
        this.holdInvoiceType = invType;
    
        this.holdConfirmation = true;
      }
    }
  }

  doDelAction( invoiceSettingId: number,invType: number )
  {
    this.invActionPostObj = {};
    this.invActionPostObj.invoiceSettingId = invoiceSettingId;
    this.invActionPostObj.user_id = this.storage.get('app.auth').id;

    this.policyService.delInvoice(this.invActionPostObj).subscribe
    (
      response =>
      {
        console.log(response);
        if (response instanceof HttpErrorResponse)
        {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
          this.spinnerService.hide();
          alert("Could not delete invoice.");
          this.delInProgress = false;
        }
        else
        {
          this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });

          this.delInProgress = false;

          if (response != undefined && response != null && response.invUpdated != undefined && response.invUpdated != null)
          {
            // console.log(response.invUpdated);
          }

          this.spinnerService.hide();

          if (response != undefined && response != null && response.invDelNA != undefined && response.invDelNA != null)
          {
              this.invDelNotAllowed = true;
          }
          else
          {
              // this.loadInvHistory();
              if( invType == 2 )
              {
                this.spinnerService.show();
                this.loadPFHistory();
              }
              if( invType == 5 )
              {
                this.spinnerService.show();
                this.loadMBHistory();
              }
              else if( invType != 2 && invType != 5 )
              {
                this.spinnerService.show();
                this.loadInvHistory();
              }
          }
        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        this.spinnerService.hide();
        alert("Could not delete invoice.");
        this.delInProgress = false;
      });
  }

  delAction( invoiceSettingId: number,invType: number )
  {
    if (invoiceSettingId != undefined && invoiceSettingId != null && !this.delInProgress)
    {
      this.delInProgress = true;

      this.confirmationService.confirm
        ({
          message: 'Are you sure you want to Delete the Invoice?',
          accept: () => {
            this.spinnerService.show();

            this.doDelAction(invoiceSettingId,invType);
          },
          reject: () => {
            this.delInProgress = false;
          }
        });
    }
  }

  doPFColAction( invoiceSettingId: number )
  {
    this.invActionPostObj = {};
    this.invActionPostObj.invoiceSettingId = invoiceSettingId;

    this.policyService.pfColInvoice(this.invActionPostObj).subscribe
    (
      response =>
      {
        if (response instanceof HttpErrorResponse)
        {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
          this.spinnerService.hide();
          alert("Could not set Invoice as Collected.");
          this.pfColInProgress = false;
        }
        else
        {
          this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });

          this.pfColInProgress = false;

          if (response != undefined && response != null && response.invUpdated != undefined && response.invUpdated != null)
          {
            // console.log(response.invUpdated);
          }

          this.spinnerService.hide();

          this.spinnerService.show();
          this.loadPFHistory();
          this.loadMBHistory();
        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        this.spinnerService.hide();
        alert("Could not set Invoice as Collected.");
        this.pfColInProgress = false;
      });
  }

  pfColAction( invoiceSettingId: number )
  {
    if (invoiceSettingId != undefined && invoiceSettingId != null && !this.pfColInProgress)
    {
      this.pfColInProgress = true;

      this.confirmationService.confirm
        ({
          message: 'Are you sure you want to Set as Collected?',
          accept: () => {
            this.spinnerService.show();

            this.doPFColAction(invoiceSettingId);
          },
          reject: () => {
            this.pfColInProgress = false;
          }
        });
    }
  }

  buildQueryParams() {
    if (this.invoice_type == 'premium_invoice')
    {
    }
    else if (this.invoice_type == 'payroll_reporting') {
      if (this.check_date != undefined && this.payroll_end_date != undefined && this.report_date != undefined) {

        var payrollTotal="";
        if( this.payrollTotal != undefined && this.payrollTotal != null && this.payrollTotal.trim() != "" )
        {
          return {
            invoice_type: this.invoice_type,
            check_date: this.check_date,
            payroll_end_date: this.payroll_end_date,
            report_date: this.report_date,
            payrollTotal:this.payrollTotal
          };
        }
        else
        {
          return {
            invoice_type: this.invoice_type,
            check_date: this.check_date,
            payroll_end_date: this.payroll_end_date,
            report_date: this.report_date
          };
        }
      }
    }
    else if (this.invoice_type == 'policy_fee' || this.invoice_type == 'misc_fee') {
      if (this.report_date != undefined && this.ach_withdrawal_date != undefined) {
        return {
          invoice_type: this.invoice_type,
          report_date: this.report_date,
          ach_withdrawal_date: this.ach_withdrawal_date
        };
      }
    }
    else if (this.invoice_type == 'npr') {
      if (this.report_date != undefined && this.ach_withdrawal_date != undefined && this.payroll_post_date != undefined) {
        return {
          invoice_type: this.invoice_type,
          report_date: this.report_date,
          ach_withdrawal_date: this.ach_withdrawal_date,
          payroll_post_date: this.payroll_post_date
        };
      }
    }
  }

}
