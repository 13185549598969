export class Worklist {
  id: number;
  name: string;
  worklist_users:any = [];

  constructor(data: any) {

    // Set normal fields
    this.id = data.id;
    this.name = data.name;
    this.worklist_users = data.worklist_users;
  }
}
