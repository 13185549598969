import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs';

export interface SpinnerState {
  show: boolean;
}

@Injectable()
export class SpinnerService {
  private spinnerSubject = new Subject<SpinnerState>();

  spinnerState = this.spinnerSubject.asObservable();

  constructor(@Optional() @SkipSelf() prior: SpinnerService) {
    if (prior) {
      return prior;
    }
  }

  show() {
    this.spinnerSubject.next(<SpinnerState>{ show: true });
  }

  hide(delay = 0) {
    if (delay == 0) {
      this.spinnerSubject.next(<SpinnerState>{ show: false });
    } else {
      //setTimeout(this.delayHide(), delay);
      setTimeout(() => {
        this.delayHide();
      }, delay);
    }
  }

  delayHide() {
    this.spinnerSubject.next(<SpinnerState>{ show: false });
  }
}
