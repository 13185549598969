import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CarrierService } from '../carrier.service';
import { ClientService } from '../client.service';
import { SpinnerService } from '../../core';
import { Carrier } from "../carrier";
import { emailValidator, phoneNumberValidator, zipCodeValidator } from '../../shared';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Message } from 'primeng/components/common/api';


@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['./carrier.component.css']
})
export class CarrierComponent implements OnInit {
  msgs: Message[] = [];
  carrierForm: FormGroup;
  editCarrier = false;
  btnShow:string='none';
  popUpOverflow:string='hidden';
  allObject: any;
  allObjectCount: any;
  usDateValue: any;
  formattedNumber: any;
  area: any;
  front: any;
  end: any;
  c: any;
  states = [];
  redError = 'red';
  carrier_nameError = 'none';
  carrier_websiteError = 'none';
  carrier_main_phoneError = 'none';
  carrier_claims_phoneError = 'none';
  physical_addressError = 'none';
  physical_cityError = 'none';
  physical_stateError = 'none';
  physical_zipError = 'none';
  mailing_addressError = 'none';
  mailing_cityError = 'none';
  mailing_stateError = 'none';
  mailing_zipError = 'none';
  contact_1_nameError = 'none';
  contact_1_roleError = 'none';
  contact_1_phoneError = 'none';
  contact_2_phoneError = 'none';
  contact_3_phoneError = 'none';
  contact_4_phoneError = 'none';
  contact_1_emailError = 'none';
  contact_2_emailError = 'none';
  contact_3_emailError = 'none';
  contact_4_emailError = 'none';
  carrier_notesError = 'none';
  carrier_main_phoneError_message = "";
  physical_zipError_message = "";
  mailing_zipError_message = "";
  contact_1_phoneError_message = "";
  contact_2_phoneError_message = "";
  contact_3_phoneError_message = "";
  contact_4_phoneError_message = "";
  contact_1_emailError_message = "";
  contact_2_emailError_message = "";
  contact_3_emailError_message = "";
  contact_4_emailError_message = "";
  carrier_claims_phoneError_message = "";
  skip: any;
  take: any;
  searchVal: any;
  filteredStates: any[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private carrierService: CarrierService,
    private clientService: ClientService,
    private spinnerService: SpinnerService
  ) { }

  filterStates(event) {
    this.filteredStates = [];
    for (let i = 0; i < this.states.length; i++) {
      let s = this.states[i].value;
      if (s.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredStates.push(s);
      }
    }
  }
  scrollTo(className: string): void {
    setTimeout(function () {
      const elementList = document.querySelectorAll('.' + className);
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }

  feleFocus()
  {
    this.btnShow = 'block';
  }


  ngOnInit() {
    this.carrierForm = this.formBuilder.group({
      carrier_name: ['', Validators.required],
      carrier_website: '',
      carrier_main_phone: '',
      carrier_claims_phone: '',
      physical_address: '',
      physical_city: '',
      physical_state: '',
      physical_zip: '',
      mailing_address: '',
      mailing_city: '',
      mailing_state: '',
      mailing_zip: '',
      contact_1_name: '',
      contact_1_role: '',
      contact_1_phone: '',
      contact_1_email: '',
      contact_2_name: '',
      contact_2_role: '',
      contact_2_phone: '',
      contact_2_email: '',
      contact_3_name: '',
      contact_3_role: '',
      contact_3_phone: '',
      contact_3_email: '',
      contact_4_name: '',
      contact_4_role: '',
      contact_4_phone: '',
      contact_4_email: '',
      carrier_notes: ''
    });
    //Get All Carriers
    this.skip = 0;
    this.take = 12;
    this.searchVal = '';
    this.getCarriersInfo();

    this.clientService.getStates().subscribe(res => {
      this.states = res;
      this.states.unshift({ label: 'Select', value: '' });
    });
  }
  // Hide All Errors in the popup 
  hideAllErrors() {

    this.btnShow='none';

    this.carrier_nameError = 'none';
    this.carrier_websiteError = 'none';
    this.carrier_main_phoneError = 'none';
    this.carrier_claims_phoneError = 'none';
    this.physical_addressError = 'none';
    this.physical_cityError = 'none';
    this.physical_stateError = 'none';
    this.physical_zipError = 'none';
    this.mailing_addressError = 'none';
    this.mailing_cityError = 'none';
    this.mailing_stateError = 'none';
    this.mailing_zipError = 'none';
    this.contact_1_nameError = 'none';
    this.contact_1_roleError = 'none';
    this.contact_1_phoneError = 'none';
    this.contact_2_phoneError = 'none';
    this.contact_3_phoneError = 'none';
    this.contact_4_phoneError = 'none';
    this.contact_1_emailError = 'none';
    this.contact_2_emailError = 'none';
    this.contact_3_emailError = 'none';
    this.contact_4_emailError = 'none';
    this.contact_1_phoneError_message = "";
    this.contact_2_phoneError_message = "";
    this.contact_3_phoneError_message = "";
    this.contact_4_phoneError_message = "";
    this.contact_1_emailError_message = "";
    this.contact_2_emailError_message = "";
    this.contact_3_emailError_message = "";
    this.contact_4_emailError_message = "";
    this.carrier_notesError = 'none';
    this.carrier_main_phoneError_message = "";
    this.physical_zipError_message = "";
    this.mailing_zipError_message = "";
    this.carrier_claims_phoneError_message = "";
  }
  // Submit Carrier Form
  submitCarrierForm() {
    if (this.carrierForm.valid) {
      this.carrier_nameError = 'none';

      var validateFlag = 0;

      if (this.carrierForm.value.physical_zip == '' || this.carrierForm.value.physical_zip == null) {
        // this.physical_zipError = 'block';
        // this.physical_zipError_message = 'Required.';
      } else {
        const pattern = /^[0-9=]*$/;
        if (!this.carrierForm.value.physical_zip.match(pattern)) {
          this.physical_zipError = 'block';
          this.physical_zipError_message = 'Zip Code is not valid.';
          validateFlag = 1;
        } else {
          this.physical_zipError = 'none';
        }
      }

      if (this.carrierForm.value.mailing_zip == '' || this.carrierForm.value.mailing_zip == null) {
        // this.mailing_zipError = 'block';
        // this.mailing_zipError_message = 'Required.';
      } else {
        const pattern = /^[0-9=]*$/;
        if (!this.carrierForm.value.mailing_zip.match(pattern)) {
          this.mailing_zipError = 'block';
          this.mailing_zipError_message = 'Zip Code is not valid.';
          validateFlag = 1;
        } else {
          this.mailing_zipError = 'none';
        }
      }

      if (this.carrierForm.value.contact_1_email == '' || this.carrierForm.value.contact_1_email == null) {
        // this.contact_1_emailError = 'block';
        // this.contact_1_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.carrierForm.value.contact_1_email)) {
          this.contact_1_emailError = 'block';
          this.contact_1_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_1_emailError = 'none';
        }
      }
      
      if (this.carrierForm.value.contact_2_email == '' || this.carrierForm.value.contact_2_email == null) {
        // this.contact_2_emailError = 'block';
        // this.contact_2_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.carrierForm.value.contact_2_email)) {
          this.contact_2_emailError = 'block';
          this.contact_2_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_2_emailError = 'none';
        }
      }

      if (this.carrierForm.value.contact_3_email == '' || this.carrierForm.value.contact_3_email == null) {
        // this.contact_3_emailError = 'block';
        // this.contact_3_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.carrierForm.value.contact_3_email)) {
          this.contact_3_emailError = 'block';
          this.contact_3_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_3_emailError = 'none';
        }
      }

      if (this.carrierForm.value.contact_4_email == '' || this.carrierForm.value.contact_4_email == null) {
        // this.contact_4_emailError = 'block';
        // this.contact_4_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.carrierForm.value.contact_4_email)) {
          this.contact_4_emailError = 'block';
          this.contact_4_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_4_emailError = 'none';
        }
      }
      
      if(validateFlag == 0){
        var carrierFormObject = this.carrierForm.value;
        // console.log(carrierFormObject);
        this.carrierService.add(carrierFormObject).subscribe(response => {

          if( response != undefined && response != null && response.exists != undefined && response.exists != null )
          {
            alert("Carrier exists by this name, Cannot add.");
          }
          else
          {
            this.skip = 0;
            this.take = 12;
            this.searchVal = '';
            this.getCarriersInfo();
            this.editCarrier = false;
            this.btnShow='none';
          }
        });
      }

    } else {
      if (this.carrierForm.value.carrier_name == '' || this.carrierForm.value.carrier_name == null) {
        this.carrier_nameError = 'block';
      } else {
        this.carrier_nameError = 'none';
      }
      // if (this.carrierForm.value.carrier_website == '' || this.carrierForm.value.carrier_website == null) {
      //   this.carrier_websiteError = 'block';
      // } else {
      //   this.carrier_websiteError = 'none';
      // }

      // if (this.carrierForm.value.carrier_main_phone == '' || this.carrierForm.value.carrier_main_phone == null) {
      //   this.carrier_main_phoneError = 'block';
      //   this.carrier_main_phoneError_message = 'Required';
      // } else {
      //   const pattern1 = /^\d{10}$/;
      //   const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      //   if (!this.carrierForm.value.carrier_main_phone.match(pattern1) && !this.carrierForm.value.carrier_main_phone.match(pattern2)) {
      //     this.carrier_main_phoneError = 'block';
      //     this.carrier_main_phoneError_message = 'Format allow (xxx) xxx-xxxx';
      //   } else {
      //     this.carrier_main_phoneError = 'none';
      //   }
      // }
      // if (this.carrierForm.value.carrier_claims_phone == '' || this.carrierForm.value.carrier_claims_phone == null) {
      //   this.carrier_claims_phoneError = 'block';
      //   this.carrier_claims_phoneError_message = 'Required';
      // } else {
      //   const pattern1 = /^\d{10}$/;
      //   const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      //   if (!this.carrierForm.value.carrier_claims_phone.match(pattern1) && !this.carrierForm.value.carrier_claims_phone.match(pattern2)) {
      //     this.carrier_claims_phoneError = 'block';
      //     this.carrier_claims_phoneError_message = 'Format allow (xxx) xxx-xxxx';
      //   } else {
      //     this.carrier_claims_phoneError = 'none';
      //   }
      // }
      // if (this.carrierForm.value.physical_address == '' || this.carrierForm.value.physical_address == null) {
      //   this.physical_addressError = 'block';
      // } else {
      //   this.physical_addressError = 'none';
      // }

      // if (this.carrierForm.value.physical_city == '' || this.carrierForm.value.physical_city == null) {
      //   this.physical_cityError = 'block';
      // } else {
      //   this.physical_cityError = 'none';
      // }
      // if (this.carrierForm.value.physical_state == '' || this.carrierForm.value.physical_state == null) {
      //   this.physical_stateError = 'block';
      // } else {
      //   this.physical_stateError = 'none';
      // }
      // if (this.carrierForm.value.physical_zip == '' || this.carrierForm.value.physical_zip == null) {
      //   this.physical_zipError = 'block';
      //   this.physical_zipError_message = 'Required.';
      // } else {
      //   const pattern = /^[0-9=]*$/;
      //   if (!this.carrierForm.value.physical_zip.match(pattern)) {
      //     this.physical_zipError = 'block';
      //     this.physical_zipError_message = 'Zip Code is not valid.';
      //   } else {
      //     this.physical_zipError = 'none';
      //   }
      // }

      // if (this.carrierForm.value.mailing_address == '' || this.carrierForm.value.mailing_address == null) {
      //   this.mailing_addressError = 'block';
      // } else {
      //   this.mailing_addressError = 'none';
      // }

      // if (this.carrierForm.value.mailing_city == '' || this.carrierForm.value.mailing_city == null) {
      //   this.mailing_cityError = 'block';
      // } else {
      //   this.mailing_cityError = 'none';
      // }
      // if (this.carrierForm.value.mailing_state == '' || this.carrierForm.value.mailing_state == null) {
      //   this.mailing_stateError = 'block';
      // } else {
      //   this.mailing_stateError = 'none';
      // }
      // if (this.carrierForm.value.mailing_zip == '' || this.carrierForm.value.mailing_zip == null) {
      //   this.mailing_zipError = 'block';
      //   this.mailing_zipError_message = 'Required.';
      // } else {
      //   const pattern = /^[0-9=]*$/;
      //   if (!this.carrierForm.value.mailing_zip.match(pattern)) {
      //     this.mailing_zipError = 'block';
      //     this.mailing_zipError_message = 'Zip Code is not valid.';
      //   } else {
      //     this.mailing_zipError = 'none';
      //   }
      // }

      // if (this.carrierForm.value.contact_1_name == '' || this.carrierForm.value.contact_1_name == null) {
      //   this.contact_1_nameError = 'block';
      // } else {
      //   this.contact_1_nameError = 'none';
      // }

      // if (this.carrierForm.value.contact_1_role == '' || this.carrierForm.value.contact_1_role == null) {
      //   this.contact_1_roleError = 'block';
      // } else {
      //   this.contact_1_roleError = 'none';
      // }

      // if (this.carrierForm.value.contact_1_phone == '' || this.carrierForm.value.contact_1_phone == null) {
      //   this.contact_1_phoneError = 'block';
      //   this.contact_1_phoneError_message = 'Required';
      // } else {
      //   const pattern1 = /^\d{10}$/;
      //   const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      //   if (!this.carrierForm.value.contact_1_phone.match(pattern1) && !this.carrierForm.value.contact_1_phone.match(pattern2)) {
      //     this.contact_1_phoneError = 'block';
      //     this.contact_1_phoneError_message = 'Format allow (xxx) xxx-xxxx';
      //   } else {
      //     this.contact_1_phoneError = 'none';
      //   }
      // }

      // if (this.carrierForm.value.contact_1_email == '' || this.carrierForm.value.contact_1_email == null) {
      //   this.contact_1_emailError = 'block';
      //   this.contact_1_emailError_message = 'Required';
      // } else {
      //   const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //   if (!EMAIL_REGEXP.test(this.carrierForm.value.contact_1_email)) {
      //     this.contact_1_emailError = 'block';
      //     this.contact_1_emailError_message = 'Email Format is not valid.';
      //   } else {
      //     this.contact_1_emailError = 'none';
      //   }
      // }
      // if (this.carrierForm.value.carrier_notes == '' || this.carrierForm.value.carrier_notes == null) {
      //   this.carrier_notesError = 'block';
      // } else {
      //   this.carrier_notesError = 'none';
      // }
    }
  }
  getCarriersInfo() {
    this.carrierService.getAll(this.skip, this.take, this.searchVal).subscribe(response => {
      this.allObject = response[1];
      this.allObjectCount = response[0];
      this.spinnerService.hide();
    });
  }
  loadData(event) {
    this.skip = event.first;
    this.take = event.rows;
    this.getCarriersInfo();
  }
  search(event) {
    this.searchVal = event.target.value;
    this.skip = 0;
    this.take = 12;
    this.getCarriersInfo();
  }
  usFormatteddate(phonenumbrerlabel) {
    if (phonenumbrerlabel == 0) {
      this.usDateValue = this.carrierForm.value.carrier_main_phone;
    } else if (phonenumbrerlabel == 1) {
      this.usDateValue = this.carrierForm.value.carrier_claims_phone;
    } else if (phonenumbrerlabel == 2) {
      this.usDateValue = this.carrierForm.value.contact_1_phone;
    } else if (phonenumbrerlabel == 3) {
      this.usDateValue = this.carrierForm.value.contact_2_phone;
    } else if (phonenumbrerlabel == 4) {
      this.usDateValue = this.carrierForm.value.contact_3_phone;
    } else if (phonenumbrerlabel == 5) {
      this.usDateValue = this.carrierForm.value.contact_4_phone;
    }

    if(this.usDateValue != null){
      this.formattedNumber = String(this.usDateValue.replace(/[- )(]/g, ''));
      //  this.c = (this.formattedNumber[0] == '1') ? '1 ' : '';
      //   this.formattedNumber = this.formattedNumber[0] == '1' ? this.formattedNumber.slice(1) : this.formattedNumber;

      // # (###) ###-#### as c (area) front-end
      this.area = this.formattedNumber.substring(0, 3);
      this.front = this.formattedNumber.substring(3, 6);
      this.end = this.formattedNumber.substring(6);

      if (this.front) {
        this.formattedNumber = ("(" + this.area + ") " + this.front);
      }
      if (this.end) {
        this.formattedNumber += ("-" + this.end);
      }

    // New Code for Ext Cleaned
    if( this.formattedNumber != undefined && this.formattedNumber != null )
    {
      var pContent = this.formattedNumber.toLowerCase().split( "ext" );
      var finalNum = "";
      if( typeof pContent !== 'undefined' && pContent.length )
      {
        if( typeof pContent[0] !== 'undefined' )
        {
          finalNum = pContent[0];
        }
        if( typeof pContent[1] !== 'undefined' )
        {
          finalNum += " Ext " + pContent[1];
        }
        this.formattedNumber = finalNum;
      }
    }

      if (phonenumbrerlabel == 0) {
        this.carrierForm.controls['carrier_main_phone'].setValue(this.formattedNumber);
      } else if (phonenumbrerlabel == 1) {
        this.carrierForm.controls['carrier_claims_phone'].setValue(this.formattedNumber);
      } else if (phonenumbrerlabel == 2) {
        this.carrierForm.controls['contact_1_phone'].setValue(this.formattedNumber);
      } else if (phonenumbrerlabel == 3) {
        this.carrierForm.controls['contact_2_phone'].setValue(this.formattedNumber);
      } else if (phonenumbrerlabel == 4) {
        this.carrierForm.controls['contact_3_phone'].setValue(this.formattedNumber);
      } else if (phonenumbrerlabel == 5) {
        this.carrierForm.controls['contact_4_phone'].setValue(this.formattedNumber);
      }
    }
  }
}
