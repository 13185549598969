import { Component, OnInit } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/api';
import { DocumentService } from '../document.service';
import { DocumentQueue } from '../document-queue';

@Component({
  selector: 'app-queues',
  templateUrl: './queues.component.html',
  styleUrls: ['./queues.component.css']
})
export class QueuesComponent implements OnInit {
  queues: DocumentQueue[];
  msgs: Message[] = [];

  constructor(private docService: DocumentService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.loadQueues();
  }

  loadQueues() {
    //this.docService.getQueues().subscribe(res => this.queues = res);
  }

  deleteQueue(id: number) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this queue?',
      accept: () => {
        this.docService.deleteQueue(id).subscribe(res => {
          // Display message for 2 seconds
          this.msgs.push({severity: 'success', summary: 'Queue Deleted', detail: res.message});
          setTimeout(() => {
            this.msgs = [];
            this.loadQueues();
          }, 2000);
        });
      }
    });
  }

}
