import { Component, OnInit } from '@angular/core';
import { MenuItem } from "primeng/api";

@Component({
  selector: 'settings-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css']
})
export class MenubarComponent implements OnInit {

  public items: MenuItem[];

  constructor() { }

  ngOnInit() {
    this.items = [
      {
        label: 'Settings',
        icon: 'fa-gear',
        routerLink: [ '/settings' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Create User',
        icon: 'fa-user',
        routerLink: [ '/settings/users/create' ],
        routerLinkActiveOptions: { exact: true }
      },
      // {
      //   label: 'Create Queue',
      //   icon: 'fa-list',
      //   routerLink: [ '/settings/queues/create' ],
      //   routerLinkActiveOptions: { exact: true }
      // },
      {
        label: 'Create Printer',
        icon: 'fa-print',
        routerLink: [ '/settings/printers/create' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'Create Template',
        icon: 'fa-file-text-o',
        routerLink: [ '/settings/templates/create' ],
        routerLinkActiveOptions: { exact: true }
      }
    ];
  }

}
