export class InvoiceDetail {
  id:number;
  location: string;
  state: string;
  class_code: string;
  class_code_description: string;
  gross_wages: number;
  overtime_wages: number;
  overtime_premium: number;
  workers_comp_wages: number;
  net_rate: number;
  premium: number;
  gross_calculation:string;
  overtime_calculation:string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.location = data.location;
    this.state = data.state;
    this.class_code = data.class_code;
    this.class_code_description = data.class_code_description;
    this.gross_wages = data.gross_wages;
    this.overtime_wages = data.overtime_wages;
    this.overtime_premium = data.overtime_premium;
    this.workers_comp_wages = data.workers_comp_wages;
    this.net_rate = data.net_rate;
    this.premium = data.premium;
    this.gross_calculation = '';
    this.overtime_calculation = '';
    if( data.gross_calculation != undefined && data.gross_calculation != null && data.gross_calculation != "" )
    {
      this.gross_calculation = data.gross_calculation;
    }
    if( data.overtime_calculation != undefined && data.overtime_calculation != null && data.overtime_calculation != "" )
    {
      this.overtime_calculation = data.overtime_calculation;
    }
  }

  public calc() {
    // Overtime Premium
    this.overtime_premium = this.overtime_wages * 0.33;
    this.overtime_premium = parseFloat(this.overtime_premium.toFixed(2));

    // Workers Comp Pages
    this.workers_comp_wages = this.gross_wages - this.overtime_premium;
    this.workers_comp_wages = parseFloat(this.workers_comp_wages.toFixed(2));

    // Premium
    this.premium = this.workers_comp_wages * (this.net_rate / 100);
    this.premium = parseFloat(this.premium.toFixed(2));
  }
}
