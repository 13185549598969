import { AbstractControl } from '@angular/forms';

const isEmptyValue = (value: any) => {
  return value === null || typeof value === 'string' && value.length === 0;
};

export const phoneNumberValidator = (control: AbstractControl): { [key: string]: boolean } => {
  if (isEmptyValue(control.value)) {
    return null;
  }
  const pattern1 = /^\d{10}$/;
  // const pattern2 = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;
  const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
  
  return control.value.match(pattern1) || control.value.match(pattern2)  ? null : { 'inValidPhoneNumber': true };
};

export const zipCodeValidator = (control: AbstractControl): { [key: string]: boolean } => {
  if (isEmptyValue(control.value)) {
    return null;
  }
  const pattern = /^[0-9=]*$/;
  return control.value.match(pattern) ? null : { 'inValidZipCode': true };
};

export const emailValidator = (control: AbstractControl): { [key: string]: boolean } => {
  if (isEmptyValue(control.value)) {
    return null;
  }
  const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!EMAIL_REGEXP.test(control.value)) {
      return { "incorrectMailFormat": true };
  }
};
