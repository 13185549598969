import { Component, OnInit, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, RouterEvent, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { PolicyService } from '../policy.service';
import { ClientService } from '../client.service';
import { Client } from '../client';
import { Policy } from "../policy";
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Message, SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { Invoice } from '../invoice';
import { InvoiceDetail } from '../invoice-detail';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SpinnerService } from '../../core';
import { ConfirmationService } from 'primeng/components/common/api';
import { ConfigServiceService } from '../../config-service.service';
import { isNumber } from 'util';
import {BrowserModule, DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { DocumentService } from '../../settings/document.service';
import { DocumentInvoiceDescriptions } from '../../settings/document-invoice-descriptions';

@Component({
  selector: 'app-ship-info',
  templateUrl: './ship-info.component.html',
  styleUrls: ['./ship-info.component.css']
})
export class ShipInfoComponent implements OnInit {
  id: number;
  invoice_id: number;
  client: Client;

  requiredCheckDate: Date;
  invPolPostObj: any = {};
  requiredPolicy: any = {};
  ratesNeededArr: any = [];
  originalRatesArr: any = [];

  invoice_types: SelectItem[];
  states: SelectItem[];
  class_codes: SelectItem[];

  invoice: Invoice;
  new_line: InvoiceDetail;

  invoiceNoteForm: FormGroup;
  invoiceNoteFormPF: FormGroup;
  invoiceNoteFormNPR: FormGroup;
  invoiceNotePostObj: any = {};

  msgs: Message[] = [];

  invoiceSubmitted = false;
  invoicePostObj: any = {};

  premInvFormA: FormGroup; 
  premInvFormB: FormGroup; 

  in_check_date: String;
  in_payroll_end_date: String;
  in_report_date: String;
  in_ach_withdrawal_date: String;
  in_backup_ach_withdrawal_date: String;
  pf_db_ach_dt: String;

  pf_backup_ach_withdrawal_date: Date;

  db_report_date: String = '';
  db_withdrawal_date: String = '';
  save_dates_nsf: boolean = false;

  queue_manual_type: number = 0;
  fax_queued:number = 0;
  text_queued:number = 0;

  formValid: boolean = false;
  showMsg: boolean = false;

  datesLoaded: boolean = false;

  statusBeingUpdated = false;
  invoiceStatus: string = 'Incomplete';
  isQueueStatus: boolean = false;

  oriAdminFee: number = 0;
  oriFirst: number = 0;
  oricc: number = 0;
  orinsf: number = 0;
  orirp: number = 0;

  is_void: number = 0;
  is_nsf: number = 0;
  is_revised: number = 0;

  ori_cust_inv_number: string = '';
  ori_nsf_inv_number: string = '';
  ori_rev_inv_number: string = '';

  ori_inv_number: string = '';

  custom_inv_number: string = '';
  invSysNumber: string = '';

  isFreshInvoice: boolean = false;
  currInvoiceSettingId: number = 0;

  fireIntimation: boolean = false;

  PFPolicies: any = [];
  actionDates: any = [];
  currPol: any = {};
  tc_types: SelectItem[];
  bill_to_types: SelectItem[];

  bill_to_id: number = 0;

  tc_type: number = 1;
  policy_fee_form: FormGroup;
  brokers: any;

  qnts: SelectItem[];
  addressOptions: SelectItem[];
  descSrvcs: DocumentInvoiceDescriptions[];
  serviceFeeForm: FormGroup; 
  shippingFeeForm: FormGroup; 

  rate_btn_add = 'inline-block';
  rate_btn_upd = 'none';
  newRatesCount: number = -1;
  pfRates = [];
  pfInvoiceTotal: number = 0;
  pfOverallTotal: number = 0;

  pfRatesNeededArr: any = [];
  oriPFRatesArr: any = [];

  pfFormValid: boolean = false;
  pfFormChanged: boolean = false;
  pfAchInvalid: boolean = false;
  pfShowAchDate: boolean = false; 
  pfEditDate: String;
  showAchAlert: boolean = false;

  isCorRev: number = 0;

  oriBillToId: number = 0;
  oriBillToType: any;
  oriPolId: number = 0;
  oriActionId: number = 0;
  oriTCType: number = 0;
  oriPFCc: boolean = false;
  oriPFAfee:boolean=false;

  npr_form: FormGroup;
  NPRPolicies: any = [];
  NPRactionDates: any = [];
  nprInvoiceTotal: number = 0;
  nprOverallTotal: number = 0;
  nprCarrierName: any = '';
  db_payroll_due_date: String = '';
  db_report_dt: String = '';
  db_ach_dt: String = '';

  nprFormValid: boolean = false;
  nprFormChanged: boolean = false;
  oriNPRCc: boolean = false;

  pfData: any = {};
  pfLoaded: boolean = false;

  premiumValid: boolean = false;
  redError = 'red';
  quantityError = 'none';
  descSrvcError = 'none';
  noteError = 'none';
  priceError = 'none';
  priceMessageError = 'none';
  priceButtonError = 'none';
  THOUSANDS_SEPARATOR = ",";
  totalGW = 0.00;
  totalOW = 0.00;

  previousUrl: string;

  compNameToDisp:string='';
  feinToDisp:string='';
  clientEmailToDisp:string='';
  nprTopTableDisp:boolean=false;
  srAgencyName:string='';
  srAgencyPhone:string='';
  prInvEditLoaded:boolean=false;
  prInvData:any=0;

  pfShowName:string='';
  pfShowAddr:string='';
  pfShowCity:string='';
  pfShowSt:string='';
  pfShowZip:any=0;
  pfShowPh:string='';
  pfShowEmail:string='';
  pfBillToId:number=0;

  payrollTotal:number=0;
  showPayrollTotal=null;
  oriPT:number=0;

  piPTMismatch:boolean=false;

  isUploadedInv:boolean=false;

  is_pay_reporting:number = 0;

  messagePopUp = "";
  showPopup:boolean = false;
  showGenFailed:boolean = false;
  showGenFailed1:boolean = false;
  showGenFailed2:boolean = false;

  default_credit_card:number = 0;

  PFAgentEmails: any = [];
  pfOriAgEmail:string='';

  dtValStr:string='';
  serverYr:string='';
  chromePrevStr:string='';

  rpDtInv:boolean=false;
  achDtInv:boolean = false;
  nprPPDtInv:boolean = false;

  pfHasAdminFee:boolean=false;

  wagesCopyBtnShow:boolean = false;
  dataObjWagesCopy:any = {};
  wagesCopyObjsArr:any = [];
  statesArr:any = [];

  invNotesBk:SafeHtml='';
  notesInvSave:string='';

  selIdNPRPF:any = {};
  payrollDtStr: Date;
  nprFocusPolId:any = 0;
  showNPRMsg1:boolean = false;
  showNPRMsg2:boolean = false;

  constructor(private aroute: ActivatedRoute, private router: Router, public location: Location,
    private clientService: ClientService, private policyService: PolicyService, private fb: FormBuilder,
    private messageService: MessageService, @Inject(LOCAL_STORAGE) private storage: WebStorageService
    , private spinnerService: SpinnerService, private confirmationService: ConfirmationService, private configServiceService: ConfigServiceService,private sanitizer: DomSanitizer, private docService: DocumentService) {
    this.aroute.params.subscribe(params => {
      this.id = params['id'];
      this.invoice_id = params['invoice_id'];
    });

  }

  GetMonthName(monthNumber) {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthNumber] || 'December';
  }

  ngOnInit() {
    // Create Invoice
    this.invoice = new Invoice();
    this.invoice.premium_total = 0;
    this.invoice.metadata.admin_fee = 0;
    this.invoice.metadata.setup_fee = 0;
    this.invoice.metadata.credit_card_fee = 0;
    this.invoice.metadata.nsf_fee = 0;
    this.invoice.metadata.retry_payment_fee = 0;
    this.invoice.invoice_total = 0;

    this.invoice.ngFirst = false;
    this.invoice.ngCC = false;
    this.invoice.ngNPR = false;

    this.invoice.is_nsf = false;

    this.invoice.flags = [];

    this.spinnerService.show();

    this.serviceFeeForm = this.fb.group({
      'id': ['', ''],
      'uniqueIdentifier': ['', ''],
      'quantity': new FormControl('', Validators.required),
      'descSrvc': new FormControl('', Validators.required),
      'note': new FormControl('', Validators.required),
      'price': new FormControl('', Validators.required),
      'commissionable': false
    });

    this.shippingFeeForm = this.fb.group({
      'w2mailingaddress': false,
      'w2mailingaddresstype': '1',
      'deliveryaddress': false,
      'deliveryaddresstype': '1'
    });

    this.spinnerService.show();
    this.clientService.getClient(this.id).subscribe(res => {
      this.client = res;
      this.pfRates = res.invoice_masters_data;
      if(this.pfRates.length != 0){
        this.newRatesCount = this.pfRates[(this.pfRates.length) - 1].uniqueIdentifier;
      }
      for (var i = 0; i < this.pfRates.length; i++) {
          var obj = this.pfRates[i];
          this.pfInvoiceTotal += (obj.quantity * obj.price);
          this.pfInvoiceTotal = parseFloat(this.pfInvoiceTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      }
      this.pfFlagChange(2, 0);
      // this.spinnerService.hide();

      var shipBillInfo = res.ship_bill_info_data;
      if(shipBillInfo.length != 0){
        var w2mailingaddress = false;
        var deliveryaddress = false;
        if(shipBillInfo[0].w2mailingaddress == '1'){
          w2mailingaddress = true;
        }
        if(shipBillInfo[0].deliveryaddress == '1'){
          deliveryaddress = true;
        }
        this.shippingFeeForm.patchValue({
          'w2mailingaddress': w2mailingaddress,
          'w2mailingaddresstype': shipBillInfo[0].w2mailingaddresstype,
          'deliveryaddress': deliveryaddress,
          'deliveryaddresstype': shipBillInfo[0].deliveryaddresstype,
        });
      }

      this.docService.getInvoiceDescriptionsList().subscribe((res) => {
        this.descSrvcs = res;
        this.spinnerService.hide();
      });
    });

    this.addressOptions = [
      { label: 'Shipping Address', value: '1' },
      { label: 'Mailling Address', value: '2' }
    ];

    // this.descSrvcs = [
    //   { label: 'Select', value: '' },
    //   { label: 'One-Time Implementation', value: 'One-Time Implementation' },
    //   { label: 'Base Payroll Service Weekly', value: 'Base Payroll Service Weekly' },
    //   { label: 'Base Payroll Service Bi-Weekly', value: 'Base Payroll Service Bi-Weekly' },
    //   { label: 'Base Payroll Service Semi-Monthly', value: 'Base Payroll Service Semi-Monthly' },
    //   { label: ' Base Payroll Service Monthly', value: ' Base Payroll Service Monthly' },
    //   { label: 'Per Employee Paid', value: 'Per Employee Paid' },
    //   { label: 'Delivery-2 Day', value: 'Delivery-2 Day' },
    //   { label: 'Delivery-Overnight', value: 'Delivery-Overnight' },
    //   { label: 'W2 Delivery', value: 'W2 Delivery' },
    //   { label: 'Rush Implementation', value: 'Rush Implementation' },
    //   { label: 'Pay-Go Work Comp', value: 'Pay-Go Work Comp' },
    //   { label: 'ERMA Membership', value: 'ERMA Membership' },
    //   { label: 'Other', value: 'Other' }
    // ];

  }
  
  pfQtyOnlyNumbers(event)
  {
    var onlyNums = false;

    // Ensure that it is a number and stop the keypress
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        onlyNums = true;
    }

    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
    // Allow: Ctrl+A
    (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
    // Allow: Ctrl+C
    (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
    // Allow: Ctrl+V
    (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
    // Allow: Ctrl+X
    (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
    // Allow: home, end, left, right
    (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      onlyNums = false;
    }

    if( onlyNums )
    {
      event.preventDefault();
    }
  }
  
  setMPFDefault() {
    var matchInvoice = this.descSrvcs.filter(p => p.value == this.serviceFeeForm.value.descSrvc);

    this.serviceFeeForm.controls['note'].setValue('');
    this.serviceFeeForm.controls['price'].setValue('');
    
    this.serviceFeeForm.controls['price'].setValue(matchInvoice[0].price);
    this.serviceFeeForm.controls['commissionable'].setValue(matchInvoice[0].commission);

    // if (this.serviceFeeForm.value.descSrvc == "One-Time Implementation") {
    //   this.serviceFeeForm.controls['price'].setValue('225');
    // }
    // if (this.serviceFeeForm.value.descSrvc == "Delivery-2 Day") {
    //   this.serviceFeeForm.controls['price'].setValue('12');
    // }
    // if (this.serviceFeeForm.value.descSrvc == "Delivery-Overnight") {
    //   this.serviceFeeForm.controls['price'].setValue('17');
    // }
  }
  
  priceFormateChange(typeForm) {
    if (typeForm == 1 && this.serviceFeeForm.value.price != "") {
      if (!isNaN(this.serviceFeeForm.value.price.replace(/,/g, ''))) {
        var newPrice = this.serviceFeeForm.value.price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

        this.serviceFeeForm.controls['price'].setValue(newPrice);
      }
    }
  }
  
  addRatePF(newLocInfo) {

    var rateValdtnFailed = false;
    this.quantityError = 'none';
    this.descSrvcError = 'none';
    this.noteError = 'none';
    this.priceError = 'none';
    this.priceMessageError = 'none';
    this.priceButtonError = 'none';

    var quantity = this.serviceFeeForm.value.quantity;
    var descSrvc = this.serviceFeeForm.value.descSrvc;
    var note = this.serviceFeeForm.value.note;
    var price = this.serviceFeeForm.value.price;

    if (quantity == '' || quantity == null || typeof quantity == 'undefined') {
      this.quantityError = 'block';
      rateValdtnFailed = true;
    }
    if (descSrvc == '' || descSrvc == null || typeof descSrvc == 'undefined') {
      this.descSrvcError = 'block';
      rateValdtnFailed = true;
    }
    if (note == '' || note == null || typeof note == 'undefined') {
      if( note == null || typeof note == 'undefined' )
      {
        note = "";
      }
    }
    if (price == '' || price == null || typeof price == 'undefined' || isNaN(price.replace(/,/g, ''))) {
      this.priceError = 'block';
      rateValdtnFailed = true;
    } else {
      var regexp =/^-?[0-9]+(?:\.[0-9]+)?$/
      var newPrice = parseFloat(price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      if (regexp.test(newPrice.toString()) == false) {
        this.priceMessageError = 'block';
        rateValdtnFailed = true;
      }
    }

    if (!rateValdtnFailed) {
      this.newRatesCount = this.newRatesCount + 1;
      var newRate1: any = {};
      newRate1.id = 0;
      newRate1.uniqueIdentifier = this.newRatesCount;

      newRate1.quantity = newLocInfo.quantity;
      newRate1.descSrvc = newLocInfo.descSrvc;
      newRate1.commissionable = newLocInfo.commissionable;
      newRate1.note = newLocInfo.note;
      newRate1.price = parseFloat(newLocInfo.price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      newRate1.amount = (newRate1.price * newLocInfo.quantity);
      newRate1.amount = parseFloat(newRate1.amount).toFixed(2);

      this.pfInvoiceTotal += parseFloat(newRate1.amount);
      this.pfInvoiceTotal = parseFloat(parseFloat(this.pfInvoiceTotal.toString()).toFixed(2));
      this.pfInvoiceTotal = parseFloat(this.pfInvoiceTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      this.pfRates.push(newRate1);
      this.serviceFeeForm.reset();
      this.pfFlagChange(2,1);
      this.serviceFeeForm.patchValue({
        commissionable: false
      });
    }else{
      this.priceButtonError = 'block';
    }
  }
  
  changePF(updLocInfo) {
    var rateValdtnFailed = false;
    this.quantityError = 'none';
    this.descSrvcError = 'none';
    this.noteError = 'none';
    this.priceError = 'none';
    this.priceMessageError = 'none';
    this.priceButtonError = 'none';

    var quantity = this.serviceFeeForm.value.quantity;
    var descSrvc = this.serviceFeeForm.value.descSrvc;
    var note = this.serviceFeeForm.value.note;
    var price = this.serviceFeeForm.value.price;

    if (quantity == '' || quantity == null || typeof quantity == 'undefined') {
      this.quantityError = 'block';
      rateValdtnFailed = true;
    }
    if (descSrvc == '' || descSrvc == null || typeof descSrvc == 'undefined') {
      this.descSrvcError = 'block';
      rateValdtnFailed = true;
    }
    if (note == '' || note == null || typeof note == 'undefined') {
      if( note == null || typeof note == 'undefined' )
      {
        note = "";
      }
    }
    if (price == '' || price == null || typeof price == 'undefined' || isNaN(price.replace(/,/g, ''))) {
      this.priceError = 'block';
      rateValdtnFailed = true;
    } else {
      var regexp = /^-?[0-9]+(?:\.[0-9]+)?$/;
      var newPrice = parseFloat(price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      if (regexp.test(newPrice.toString()) == false) {
        this.priceMessageError = 'block';
        rateValdtnFailed = true;
      }
    }

    if (!rateValdtnFailed) {

      var rateId = updLocInfo.uniqueIdentifier;

      for (var i = 0; i < this.pfRates.length; i++) {
        var obj = this.pfRates[i];

        if (rateId == obj.uniqueIdentifier) {
          this.pfInvoiceTotal -= (obj.quantity * obj.price);

          obj.quantity = updLocInfo.quantity;
          obj.descSrvc = updLocInfo.descSrvc;
          obj.commissionable = updLocInfo.commissionable;
          obj.note = updLocInfo.note;
          obj.price = parseFloat(updLocInfo.price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
          obj.amount = (obj.quantity * obj.price);
          obj.amount = parseFloat(obj.amount).toFixed(2);

          this.pfInvoiceTotal += (obj.quantity * obj.price);
          this.pfInvoiceTotal = parseFloat(this.pfInvoiceTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);

          this.pfRates[i] = obj;
          this.serviceFeeForm.reset();
          this.rate_btn_upd = 'none';
          this.rate_btn_add = 'inline-block';
          this.pfFlagChange(2,1);
          this.serviceFeeForm.patchValue({
            commissionable: false
          });

          break;
        }
      }
    }else{
      this.priceButtonError = 'block';
    }
  }
  
  deleteRatePF(rateId) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this row?',
      accept: () => {
        for (var i = 0; i < this.pfRates.length; i++) {
          var obj = this.pfRates[i];
    
          if (rateId == obj.uniqueIdentifier) {
            this.pfRates.splice(i, 1);
            this.pfInvoiceTotal -= obj.amount;
            this.pfFlagChange(2,1);
          }
        }
      },
      reject: () => {
      }
    });
  }
  
  updRatePF(rateId) {
    this.quantityError = 'none';
    this.descSrvcError = 'none';
    this.noteError = 'none';
    this.priceError = 'none';
    this.priceMessageError = 'none';
    this.priceButtonError = 'none';

    for (var i = 0; i < this.pfRates.length; i++) {
      var obj = this.pfRates[i];

      if (rateId == obj.uniqueIdentifier) {
        this.serviceFeeForm.controls['id'].setValue(obj.id);
        this.serviceFeeForm.controls['uniqueIdentifier'].setValue(obj.uniqueIdentifier);
        this.serviceFeeForm.controls['quantity'].setValue(obj.quantity);
        this.serviceFeeForm.controls['descSrvc'].setValue(obj.descSrvc);
        this.serviceFeeForm.controls['note'].setValue(obj.note);
        this.serviceFeeForm.controls['commissionable'].setValue(obj.commissionable);
        this.serviceFeeForm.controls['price'].setValue(obj.price.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR));

        this.rate_btn_add = 'none';
        this.rate_btn_upd = 'block';
      }
    }

    // console.log(this.nrcRates);
  }

  resetRatePF(){
    this.serviceFeeForm.reset();
    this.rate_btn_upd = 'none';
    this.rate_btn_add = 'inline-block';
    this.serviceFeeForm.patchValue({
      commissionable: false
    });
  }
  
  updatedPfRfee($event: Event)
  {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.admin_fee = numeric;

    if( this.invoice.is_rpf_editable )
    {
      this.pfOverallTotal = this.pfInvoiceTotal + this.invoice.metadata.nsf_fee + numeric;
      this.invoice.metadata.retry_payment_fee = this.pfInvoiceTotal + numeric;
    }
    else
    {
      this.pfOverallTotal = this.pfInvoiceTotal + numeric;
    }
    
    if( this.invoice.pfCC )
    {
      var ccTotal = 0;
      if( this.invoice.is_rpf_editable )
      {
        ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
      }
      else
      {
        ccTotal = this.pfInvoiceTotal + numeric;
      }
      
      console.log(ccTotal);

      this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

      var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv3.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure3 = parts.join(".");
  
      this.invoice.metadata.credit_card_fee = parseFloat(figure3);
        
      this.pfOverallTotal += parseFloat(figure3);
      this.pfOverallTotal = parseFloat(this.pfOverallTotal.toFixed(2));
    }
    this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }
  
  updatedPFRPF($event: Event) {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.retry_payment_fee = numeric;

    if( this.invoice.is_rpf_editable )
    {
      this.pfOverallTotal = numeric;
    }
    if (this.invoice.is_nsf) {
      this.pfOverallTotal += parseFloat(this.invoice.metadata.nsf_fee.toFixed(2));
    }
    if( this.invoice.pfAdminFee )
    {
      // this.pfOverallTotal += this.invoice.metadata.admin_fee;
    }
    
    if (this.invoice.pfCC) {

      var ccTotal = 0;
      if( this.invoice.is_rpf_editable )
      {
        ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
      }
      if( this.invoice.pfAdminFee )
      {
        // ccTotal += this.invoice.metadata.admin_fee;
      }
      // console.log(ccTotal);

      this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

      var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv3.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure3 = parts.join(".");
  
      this.invoice.metadata.credit_card_fee = parseFloat(figure3);
        
      this.pfOverallTotal += parseFloat(figure3);
      this.pfOverallTotal = parseFloat(this.pfOverallTotal.toFixed(2));
    }
    this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }
  
  updatedPFNSF($event: Event) {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.nsf_fee = numeric;

    if( this.invoice.is_rpf_editable )
    {
      this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + numeric;
    }
    else
    {
      this.pfOverallTotal = this.pfInvoiceTotal + numeric;
    }
    if( this.invoice.pfAdminFee )
    {
      // this.pfOverallTotal += this.invoice.metadata.admin_fee;
    }
    
    if (this.invoice.pfCC) {

      var ccTotal = 0;
      if( this.invoice.is_rpf_editable )
      {
        ccTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee;
      }
      if( this.invoice.pfAdminFee )
      {
        // ccTotal += this.invoice.metadata.admin_fee;
      }
        // console.log(ccTotal);

      this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

      var tv3 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv3.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure3 = parts.join(".");
  
      this.invoice.metadata.credit_card_fee = parseFloat(figure3);
        
      this.pfOverallTotal += parseFloat(figure3);
      this.pfOverallTotal = parseFloat(this.pfOverallTotal.toFixed(2));
    }
    this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }
  
  updatedPFCC($event: Event) {
    const target = $event.target as any;

    let tarGetvalue = 0;
    if(target.value != "" && !isNaN(target.value.replace(/,/g, ''))){
      // tarGetvalue = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      var tv = parseFloat(target.value.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      var parts = tv.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
      var figure = parts.join(".");

      tarGetvalue = parseFloat(figure);
    }

    let numeric: number = tarGetvalue;
    if (Number.isNaN(numeric)) {
      numeric = 0;
    }

    this.invoice.metadata.credit_card_fee = numeric;

    var tv1 = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    var parts = tv1.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{7})+(?!\d))/g, ",");;
    var figure1 = parts.join(".");

    this.invoice.metadata.credit_card_fee = parseFloat(figure);

    if( this.invoice.is_rpf_editable )
    {
      this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.credit_card_fee;
    }
    else
    {
      this.pfOverallTotal = this.pfInvoiceTotal + this.invoice.metadata.credit_card_fee;
      if( this.invoice.pfAdminFee )
      {
        this.pfOverallTotal += this.invoice.metadata.admin_fee;
      }
    }

    if (this.invoice.is_nsf) {
      this.pfOverallTotal += parseFloat(this.invoice.metadata.nsf_fee.toFixed(2));
    }

    this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }

  openDscSrvDD(ev)
  {
    if( ev != undefined && ev != null && ev.relatedTarget != undefined && ev.relatedTarget != null )
    {
      ev.relatedTarget.click();
    }
  }
  
  saveMasterRates() {      
    this.invoicePostObj = {};
    this.invoicePostObj.rates = [];
    this.invoicePostObj.rates = this.pfRates;
    this.invoicePostObj.clientId = this.client.id;
    
    this.spinnerService.show();
    this.policyService.saveInvoiceMaster(this.invoicePostObj).subscribe(
      response => {
        this.spinnerService.hide();
        // this.messagePopUp = response.message;
        // this.showPopup = true;
      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        this.messagePopUp = "Could not save Invoice.";
        this.showPopup = true;
      });
  }

  saveShipBillInfo() {      
    this.invoicePostObj = {};
    this.invoicePostObj.rates = [];
    this.invoicePostObj.clientId = this.client.id;
    this.invoicePostObj.shipBillInfo = this.shippingFeeForm.value;
    
    this.spinnerService.show();
    this.policyService.saveShipBillInfo(this.invoicePostObj).subscribe(
      response => {
        this.spinnerService.hide();
        // this.messagePopUp = response.message;
        // this.showPopup = true;
      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        this.messagePopUp = "Could not save Invoice.";
        this.showPopup = true;
      });
  }

  closePopup(){
    this.messagePopUp = "";
      this.showPopup = false;
  }

  pfFlagChange(chkType: number, typeSave: any)
  {
    if (chkType == 2) {
      this.invoice.metadata.nsf_fee = 0;

      // Credit Card Fee
      if (this.invoice.pfCC)
      {
        var ccTotal = 0;
        if( this.pfInvoiceTotal != undefined && this.pfInvoiceTotal != null )
        {
          if( this.invoice.pfAdminFee )
          {
            ccTotal = this.pfInvoiceTotal + this.invoice.metadata.admin_fee;
          }
          else
          {
            ccTotal = this.pfInvoiceTotal;
          }
        }

        if( this.invoice.is_rpf_editable )
        {
          if( this.invoice.pfAdminFee )
          {
            ccTotal = this.invoice.metadata.retry_payment_fee;
          }
          else
          {
            ccTotal = this.invoice.metadata.retry_payment_fee;
          }
        }

        // console.log(ccTotal);

        this.invoice.metadata.credit_card_fee = ccTotal * 0.0425;

        var tv = parseFloat(this.invoice.metadata.credit_card_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]).toFixed(2).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        var parts = tv.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{10})+(?!\d))/g, ",");;
        var figure = parts.join(".");

        this.invoice.metadata.credit_card_fee = parseFloat(figure);
        
      }
      else {
        this.invoice.metadata.credit_card_fee = 0;
        if (this.invoice.is_nsf) {
          this.invoice.metadata.nsf_fee = 40;
        }
      }

      if( this.invoice.is_rpf_editable )
      {
        this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + parseFloat(this.invoice.metadata.credit_card_fee.toFixed(2)) + this.invoice.metadata.nsf_fee;
      }
      else
      {
        this.pfOverallTotal = this.pfInvoiceTotal + parseFloat(this.invoice.metadata.credit_card_fee.toFixed(2)) + this.invoice.metadata.nsf_fee;
        if( this.invoice.pfAdminFee )
        {
          this.pfOverallTotal += this.invoice.metadata.admin_fee;
        }
      }

      this.pfOverallTotal = parseFloat(this.pfOverallTotal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      if (this.invoice.is_nsf && ! this.invoice.is_rpf_editable ) {
        this.invoice.metadata.retry_payment_fee = this.pfInvoiceTotal;
      }

      if(typeSave == 1){
        this.saveMasterRates();
      }
    }
    else if (chkType == 0) {
      if (this.invoice.is_nsf) {
        if( this.invoice.is_rpf_editable )
        {
          this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee + this.invoice.metadata.credit_card_fee;
        }
        else
        {
          this.pfOverallTotal = this.pfInvoiceTotal + this.invoice.metadata.nsf_fee + this.invoice.metadata.credit_card_fee;
        }
      }
      else
      {
        if( this.invoice.is_rpf_editable )
        {
          this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.credit_card_fee;
        }
        else
        {
          this.pfOverallTotal = this.pfInvoiceTotal + this.invoice.metadata.credit_card_fee;
        }
        if( this.invoice.pfAdminFee )
        {
          this.pfOverallTotal += this.invoice.metadata.admin_fee;
        }
      }

      if( this.invoice.pf_edit_rnew && this.invoice.pfAdminFee )
      {
          this.invoice.pf_edit_rnew = false;
          this.invoice.metadata.retry_payment_fee += this.invoice.metadata.admin_fee;
          this.pfOverallTotal = this.invoice.metadata.retry_payment_fee + this.invoice.metadata.nsf_fee + this.invoice.metadata.credit_card_fee;
          // this.pfNSFCorr();
      }
    }
  }
}
