import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../../settings/document.service';
import { DocumentQueue } from '../../settings/document-queue';
import { ConfirmationService } from 'primeng/api';
import { SpinnerService } from '../../core';
import { Router, ActivatedRoute } from "@angular/router";
import { ConfigServiceService } from '../../config-service.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboardprint.component.html',
  styleUrls: ['./dashboardprint.component.css']
})
export class DashboardPrintComponent implements OnInit {

  queues = [];
  queueSettingIds = [];
  allQueuesCount = 0;
  skip = 0;
  take = 150;
  sortField = 'company_name';
  sortOrder = 1;
  first = 0;
  showRemInvoices: boolean = false;
  carriers: any;
  printers: any;
  selectedPrinter = null;
  selectedCarriers = [];
  selectedAll = false;

  printPDFError:boolean = false;
  showPdfSuccess:boolean = false;
  
  filterDates: any;
  selectedDates = [];

  constructor(
    private documentService: DocumentService,
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute
    ,private configServiceService: ConfigServiceService
  ) { }

  ngOnInit() {
    this.documentService.getAllCarriersPrinters().subscribe(response => {
      this.carriers = response[0];
      this.printers = response[1];
    });
  }

  openPDF(invSettingId: number,invType:number,clientId:number)
  { 
    if( invType == 1 )
    {
      window.open(this.configServiceService.getApiUrl() + '/pdfview?download=pdf&invoiceId=' + invSettingId + '&id=' + clientId + '&up=0&printpdf=1', '_blank');
    }
    else if( invType == 2 )
    {
      window.open(this.configServiceService.getApiUrl() + '/pfpdf?download=pdf&invoiceId=' + invSettingId + '&id=' + clientId + '&up=0&printpdf=1', '_blank');
    }
    else if( invType == 3 )
    {
      window.open(this.configServiceService.getApiUrl() + '/nprpdf?download=pdf&invoiceId=' + invSettingId + '&id=' + clientId + '&up=0&printpdf=1', '_blank');
    }

  }
  
  changePrinterInfo( mode:number){

    if( this.selectedCarriers.length == 0 && this.selectedDates.length == 0 )
    {
      this.selectedCarriers = [];
      this.selectedDates = [];
      this.sortField = 'company_name';
    }
    else
    {
      this.sortField = 'sort_carrier';
    }
    
    this.skip = 0;
    this.take = 150;
    this.sortOrder = 1;
    this.first = 0;
    this.documentService.getQueues(this.skip, this.take, this.sortField, this.sortOrder, 1, 1,this.selectedCarriers,this.selectedDates).subscribe(response => {
      this.getAllQueues(response);
    });
  }

  deleteFromPQ()
  {
    if( this.queueSettingIds.length != 0 )
	  {
        this.confirmationService.confirm
        ({
            message: 'Are you sure you want to delete the selected invoices?',
            accept: () =>
            {
              this.documentService.clearFromPQ(this.queueSettingIds).subscribe(response =>
              {
                this.spinnerService.hide();
                this.documentService.getQueues(this.skip, this.take, this.sortField, this.sortOrder, 1, 1, this.selectedCarriers,this.selectedDates).subscribe(response =>
                {
                  this.queueSettingIds = [];
                  this.getAllQueues(response);
                });
              },
              error =>
              {
                this.spinnerService.hide();
                alert( "We faced an issue deleting the invoices from queue." );
              });
            },
            reject: () => { }
          });
      }
	  else
	  {
        alert('Please select invoices.');
      }
  }

  getAllQueues(response) {
    this.queues = response.invoiceSetting;
    this.allQueuesCount = response.invoiceSettingsCount;
    if( this.skip == 0 && this.sortField == 'company_name' )
    {
      this.filterDates = response.achDatesArr;
    }

    this.selectedAll = false;
    if( this.queueSettingIds.length )
    {
      var idsArr = [];
      for( var i = 0; i < this.queueSettingIds.length;i++ )
      {
        idsArr.push(this.queueSettingIds[i].id)
      }

      var key = 0;
      var selectedIts = 0;
      this.queues.forEach((queueClientinfo) =>
      {
        if( idsArr.includes(this.queues[key].id) )
        {
          this.queues[key].selected_checkbox = true;
          selectedIts++;
        }
        key++;
      });
      if( key == selectedIts )
      {
        this.selectedAll = true;
      }
    }

    
    this.spinnerService.hide();
  }

  paginate(event) {
    this.skip = event.first;
    this.take = event.rows;
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.documentService.getQueues(this.skip, this.take, this.sortField, this.sortOrder, 1, 1, this.selectedCarriers,this.selectedDates).subscribe(response => {
      this.getAllQueues(response);
    });
  }

  checkUncheckAll(e){
    
    // this.queueSettingIds = [];

    if(e.target.checked == true){
      this.selectedAll = true;
      var key = 0;
      this.queues.forEach((queueClientinfo) => {
        this.queues[key].selected_checkbox = true;
        var objectClient = {};
        objectClient['id'] = queueClientinfo.id;
        objectClient['email'] = queueClientinfo.email;
        objectClient['company_name'] = queueClientinfo.company_name;
        objectClient['pfInvoiceTotal'] = queueClientinfo.pfInvoiceTotal;
        objectClient['check_date_formate'] = queueClientinfo.check_date_formate;
        this.queueSettingIds.push(objectClient);
        key++;
      });
    }else{
      this.selectedAll = false;
      var key = 0;
      this.queues.forEach((queueClientinfo) => {
        for( var em = 0;em < this.queueSettingIds.length;em++ )
        {
          if( this.queueSettingIds[em].id == queueClientinfo.id )
          {
            this.queueSettingIds.splice(em, 1);
          }
        }
        this.queues[key].selected_checkbox = false;
        key++;
      });
    }
  }

  checkBoxEvent(id, e, i){

    if( i >= this.take )
    {
      i = i % this.take;
    }

    if(e.target.checked == true){
      this.queues[i].selected_checkbox = true;
      var objectClient = {};
      objectClient['id'] = id;
      var queueClientinfo = this.queues.filter(item => item.id == id);
      objectClient['email'] = queueClientinfo[0].email;
      objectClient['company_name'] = queueClientinfo[0].company_name;
      objectClient['pfInvoiceTotal'] = queueClientinfo[0].pfInvoiceTotal;
      objectClient['check_date_formate'] = queueClientinfo[0].check_date_formate;
      this.queueSettingIds.push(objectClient);

      this.selectedAll = this.queues.every(function(item:any) {
        return item.selected_checkbox == true;
      });

    }else{
      this.selectedAll = false;
      this.queues[i].selected_checkbox = false;
      this.queueSettingIds = this.queueSettingIds.filter(item => item.id != id);
    }
  }

  sendInvoicePdf() {
    if (this.selectedPrinter) {
      if (this.queueSettingIds.length != 0) {
        this.confirmationService.confirm
          ({
            message: 'Are you sure you want to print for selected invoices?',
            accept: () => {
              //Add printerid
              var objectClient = {};
              objectClient['printerId'] = this.selectedPrinter;
              this.queueSettingIds.push(objectClient);
              //End
              this.documentService.printGoogleCloud(this.queueSettingIds).subscribe(response => {
                this.spinnerService.hide();
                this.documentService.getQueues(this.skip, this.take, this.sortField, this.sortOrder, 1, 1, this.selectedCarriers,this.selectedDates).subscribe(response => {
                  this.queueSettingIds = [];
                  this.getAllQueues(response);
                  this.showRemInvoices = true;
                });
              });
            },
            reject: () => { }
          });
      } else {
        alert('Please select invoices');
      }
    } else {
      alert('Please select printer');
    }
  }

  combineGivePDF()
  {
    if( this.queueSettingIds.length != 0 )
    {
      this.confirmationService.confirm
      ({
          message: 'Are you sure you want to combine selected invoices for print?',
          accept: () =>
          {
            this.spinnerService.show();
            this.documentService.combinePrintPDF(this.queueSettingIds).subscribe(response =>
            {
              this.spinnerService.hide();
              if( response instanceof HttpErrorResponse)
              {
                this.spinnerService.hide();
                this.printPDFError = true;
              }
              else
              {
                if( response != undefined && response.output != undefined && response.combinedPDFName != undefined )
                {
                    if( response.output == '0' )
                    {
                      // this.showInvoiceAlert = true;
                    }
                    if( response.output == '1' )
                    {
                      // window.open(this.configServiceService.getApiUrl() + '/download-combined?combinedPDFName=' + response.combinedPDFName, '_blank');
                      if( response.newPdfS3Path != undefined && response.newPdfS3Path != null )
                      {
                        window.open( response.newPdfS3Path );
                      }

                      this.documentService.getQueues(this.skip, this.take, this.sortField, this.sortOrder, 1, 1, this.selectedCarriers,this.selectedDates).subscribe(response => {
                        this.queueSettingIds = [];
                        this.getAllQueues(response);
                        this.showPdfSuccess = true;
                      });
                    }
                }
                else
                {
                  alert( "Sorry, We faced an issue.\nPlease try again." );
                }
              }
            },
            error =>
            {
              this.spinnerService.hide();
              this.printPDFError = true;
            });
          },
          reject: () => {}
      });
    }
    else
    {
      alert('Please select invoices to print.');
    }
  }
  
}
