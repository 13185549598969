import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ConfigServiceService } from '../config-service.service';
import { User } from './user';
import { UserChangeResponse } from './user-change-response';
import { SpinnerService } from '../core';
import 'rxjs/add/operator/map'

@Injectable()
export class UserService {

  readonly apiUrl;

  constructor(
    private http: HttpClient, 
    private auth: AuthService, 
    config: ConfigServiceService,
    private spinnerService: SpinnerService) {
    this.apiUrl = config.getApiUrl();
  }

  getUsers() {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<User[]>(this.apiUrl + '/users', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getUsersAll(q, skip, take) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<User[]>(this.apiUrl + '/users?q=' + q + '&skip=' + skip +'&take=' + take, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getUser(id: number) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<User>(this.apiUrl + '/users/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).map(res => {
      return new User(res);
    });
  }

  updateUser(id: number, data: User) {
    const token = this.auth.getToken();
    return this.http.put<UserChangeResponse>(this.apiUrl + '/users/' + id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  createUser(data: User) {
    const token = this.auth.getToken();
    return this.http.post<UserChangeResponse>(this.apiUrl + '/users', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  changePassword(id: number, data: User) {
    const token = this.auth.getToken();
    return this.http.post<UserChangeResponse>(this.apiUrl + '/users/change-password/'+ id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  logoutUser(id: number) {
    const token = this.auth.getToken();
    return this.http.post<UserChangeResponse>(this.apiUrl + '/users/logout-user/' + id, null, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  disableUser(id: number) {
    const token = this.auth.getToken();
    return this.http.post<UserChangeResponse>(this.apiUrl + '/users/disable-user/' + id, null, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  enableUser(id: number) {
    const token = this.auth.getToken();
    return this.http.post<UserChangeResponse>(this.apiUrl + '/users/enable-user/' + id, null, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  deleteUser(id: number) {
    const token = this.auth.getToken();
    return this.http.post<UserChangeResponse>(this.apiUrl + '/users/delete-user/' + id, null, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

}
