import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { PayrollService } from '../payroll.service';
import { ClientService } from '../client.service';
import { SpinnerService } from '../../core';
import { emailValidator, phoneNumberValidator, zipCodeValidator } from '../../shared';
import { Message } from 'primeng/components/common/api';

@Component({
  selector: 'app-payrollview',
  templateUrl: './payrollview.component.html',
  styleUrls: ['./payrollview.component.css']
})
export class PayrollviewComponent implements OnInit {
  msgs: Message[] = [];
  payrollId: any;
  getPayrollObject: any;
  btnShow:string='none';
  states: any;
  payrollViewForm: FormGroup;
  editPayroll = false;
  deletePayroll = false;
  usDateValue: any;
  formattedNumber: any;
  area: any;
  front: any;
  end: any;
  c: any;
  redError = 'red';
  worklist_master_Error = 'none';
  worklistError = 'none';
  payroll_rep_referringError = 'none';
  payroll_company_websiteError = 'none';
  payroll_company_nameError = 'none';
  payroll_platformError = 'none';
  main_phone_lineError = 'none';
  contact_1_nameError = 'none';
  contact_1_roleError = 'none';
  contact_1_phoneError = 'none';
  contact_2_phoneError = 'none';
  contact_3_phoneError = 'none';
  contact_4_phoneError = 'none';
  contact_1_emailError = 'none';
  contact_2_emailError = 'none';
  contact_3_emailError = 'none';
  contact_4_emailError = 'none';
  payroll_notesError = 'none';
  contact_1_phoneError_message = "";
  contact_2_phoneError_message = "";
  contact_3_phoneError_message = "";
  contact_4_phoneError_message = "";
  contact_1_emailError_message = "";
  contact_2_emailError_message = "";
  contact_3_emailError_message = "";
  contact_4_emailError_message = "";
  main_phone_lineError_message="";
  worklists: any;  
  masterworklists: any;
  currWorklists:any=[];
  platFormsCount:number = 1;
  currPlatFormIndex:number = -1;
  payrollPlatformsDisp:any = [];
  loadedPayroll:number = 0;
  firstTimeEditing:number = 1;
  isOpeningEditPopUp:number = 0;
  platFormsInvalid = 0;
  justUpdated = 0;
  submittedUpdate = false;
  editIsLoading:boolean= true;
  constructor(
    private payrollService: PayrollService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService
  ) { }
  scrollTo(className: string): void {
    setTimeout(function () {
      const elementList = document.querySelectorAll('.' + className);
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }

  feleFocus()
  {
    this.btnShow = 'block';
  }

  fillWorklistUsers(platformIndex,mode:number,processor_user_id:number)
  {
    const control = <FormArray>this.payrollViewForm.controls['payroll_platforms'];
    var currPlatformValue = control.value[platformIndex];
    var currPlatformFrmGrp = control.at(platformIndex);

    this.currWorklists[platformIndex] = [];

    if( currPlatformValue != undefined && currPlatformValue != null && currPlatformValue.worklist_master_id != undefined && currPlatformValue.worklist_master_id != null && currPlatformValue.worklist_master_id != '' )
    {
      this.clientService.getWorklist(currPlatformValue.worklist_master_id).subscribe(res =>
      {
        this.spinnerService.hide();

        var editWorklistUsers = [];
        if( res != undefined && res != null && res.worklist_users != undefined && res.worklist_users != null )
        {
          for( let currUser of res.worklist_users )
          {
            editWorklistUsers.push(currUser.worklistuser_info);
          }
        }

        this.currWorklists[platformIndex] = editWorklistUsers;
        if( mode == 0 )
        {
          currPlatformFrmGrp.patchValue({
            processor_user_id:processor_user_id           
          });
        }
        if( ! this.firstTimeEditing && this.isOpeningEditPopUp && platformIndex == (this.payrollPlatformsDisp.length-1) )
        {
          this.isOpeningEditPopUp = 0;
          this.spinnerService.hide();

          this.btnShow = 'block';
          this.editPayroll=true;
        }
      });
    }

    if( mode == 1 )
    {
      currPlatformFrmGrp.patchValue({
        processor_user_id: ''           
      });
    }
  }
  
  clearPlatforms(formArray)
  {
      while (formArray.length !== 0) {
        formArray.removeAt(0)
      }
  }
  openEditPayrollPopup()
  {
    if( ! this.firstTimeEditing )
    {
      var payrollPlatformsFA = <FormArray>this.payrollViewForm.controls['payroll_platforms'];
      this.clearPlatforms(payrollPlatformsFA);

      this.isOpeningEditPopUp = 1;
      this.spinnerService.show();
  
      this.loadPlatforms();
    }
    else
    {
      this.firstTimeEditing = 0;
      this.btnShow = 'block';
      this.editPayroll=true;
    }
  }
  
  loadPlatforms()
  {
    var platformIndex = -1;
    for( let currPayrollPlatform of this.payrollPlatformsDisp )
    {
      const control = <FormArray>this.payrollViewForm.controls['payroll_platforms'];
      control.push(this.initPayrollPlatform());
      this.platFormsCount = control.length;

      platformIndex++;
      var currPlatformFrmGrp = control.at(platformIndex);
      currPlatformFrmGrp.patchValue({
        payroll_platform:currPayrollPlatform.payroll_platform,
        worklist_master_id:currPayrollPlatform.worklist_master_id
      });

      this.fillWorklistUsers(platformIndex,0,currPayrollPlatform.processor_user_id);
    }
    if( this.payrollPlatformsDisp.length == 0 )
    {
      platformIndex++;
      var payrollPlatformsFA = <FormArray>this.payrollViewForm.controls['payroll_platforms'];
      this.clearPlatforms(payrollPlatformsFA);
      this.addPlatform(-1);

      if( ! this.editIsLoading )
      {
        this.isOpeningEditPopUp = 0;
        this.spinnerService.hide();
  
        this.btnShow = 'block';
        this.editPayroll=true;
      }
    }
  }
  
  addPlatform(platformIndex)
  {
    this.currPlatFormIndex = -1;
    if( platformIndex != -1 )
    {
      const control1 = <FormArray>this.payrollViewForm.controls['payroll_platforms'];
      var currPlatformFrmGrp = control1.at(platformIndex);
      if( currPlatformFrmGrp.invalid  )
      {
        this.currPlatFormIndex = platformIndex;
        return;
      }
    }

    const control = <FormArray>this.payrollViewForm.controls['payroll_platforms'];
    control.push(this.initPayrollPlatform());
    this.platFormsCount = control.length;

    if( this.currWorklists[this.platFormsCount-1] != undefined )
    {
      this.currWorklists[this.platFormsCount-1] = [];
    }
  }
  removePlatform(i: number)
  {
    const control = <FormArray>this.payrollViewForm.controls['payroll_platforms'];
    control.removeAt(i);
    this.platFormsCount = control.length;
  }
  initPayrollPlatform()
  {
    return this.formBuilder.group
    ({
      payroll_company_id:this.getPayrollObject.id,
      payroll_platform: ['', Validators.required],
      worklist_master_id: ['', Validators.required],
      processor_user_id: ['', Validators.required],
    });
  }
  ngOnInit() {
    this.payrollId = this.activatedRoute.snapshot.params['id'];
    this.payrollViewForm = this.formBuilder.group({
	    worklist: '',
      payroll_rep_referring: '',
      payroll_company_website: '',
      payroll_company_name: ['', Validators.required],

      payroll_platforms: this.formBuilder.array
      ([
      ]),

      main_phone_line: '',
      contact_1_name: '',
      contact_1_role: '',
      contact_1_phone: '',      
      contact_1_email: '', 
      contact_2_name: '',
      contact_2_role: '',
      contact_2_phone:'',
      contact_2_email:'',
      contact_3_name: '',
      contact_3_role: '',
      contact_3_phone:'',
      contact_3_email:'',
      contact_4_name: '',
      contact_4_role: '',
      contact_4_phone:'',
      contact_4_email:'',
      payroll_notes: '',
      id: ''
    });

    this.getPayrollInfo();

    /*
    //Get worklist users
    this.clientService.getUsers().subscribe(res => {
      this.worklists = res;
    });
    */

    // Get Master Worklists.
    this.clientService.getWorklistsForPayroll().subscribe(res => {
      this.masterworklists = res;
    });
    
    this.clientService.getStates().subscribe(res => {
      this.states = res;
      this.states.unshift({ label: 'Select', value: '' });
    });
  }
  // Hide All Errors in the popup 
	hideAllErrors(){

    this.btnShow='none';

	  this.worklistError = 'none';
	  this.payroll_rep_referringError = 'none';
	  this.payroll_company_websiteError = 'none';
	  this.payroll_company_nameError = 'none';
	  this.main_phone_lineError = 'none';
	  this.contact_1_nameError = 'none';
	  this.contact_1_roleError = 'none';
	  this.contact_1_phoneError = 'none';
    this.contact_2_phoneError = 'none';
    this.contact_3_phoneError = 'none';
    this.contact_4_phoneError = 'none';
    this.contact_1_emailError = 'none';
    this.contact_2_emailError = 'none';
    this.contact_3_emailError = 'none';
    this.contact_4_emailError = 'none';
    this.contact_1_phoneError_message = "";
    this.contact_2_phoneError_message = "";
    this.contact_3_phoneError_message = "";
    this.contact_4_phoneError_message = "";
    this.contact_1_emailError_message = "";
    this.contact_2_emailError_message = "";
    this.contact_3_emailError_message = "";
    this.contact_4_emailError_message = "";
	  this.payroll_notesError = 'none';
	  this.main_phone_lineError_message="";
	}
  // Update Agency Form
  updatePayrollForm() {

    this.platFormsInvalid = 0;

    if( this.platFormsCount == 1 )
    {
      const control = <FormArray>this.payrollViewForm.controls['payroll_platforms'];
      var currPlatformFrmGrp = control.at(0);
      var currPlatformValue = control.value[0];
      if( currPlatformValue != undefined && currPlatformValue != null && ( (currPlatformValue.payroll_platform != undefined && currPlatformValue.payroll_platform != null && currPlatformValue.payroll_platform == '') && ((currPlatformValue.worklist_master_id != undefined && currPlatformValue.worklist_master_id != null && currPlatformValue.worklist_master_id == '') && (currPlatformValue.processor_user_id != undefined && currPlatformValue.processor_user_id != null && currPlatformValue.processor_user_id == '')) ) )
      {
        currPlatformFrmGrp.get('payroll_platform').setValidators(null);
        currPlatformFrmGrp.get('payroll_platform').updateValueAndValidity();
        currPlatformFrmGrp.get('worklist_master_id').setValidators(null);
        currPlatformFrmGrp.get('worklist_master_id').updateValueAndValidity();
        currPlatformFrmGrp.get('processor_user_id').setValidators(null);
        currPlatformFrmGrp.get('processor_user_id').updateValueAndValidity();
      }
    }

    if (this.payrollViewForm.valid) {

      var validateFlag = 0;
      this.payroll_company_nameError = 'none';
      this.worklistError = 'none';
      
      if (this.payrollViewForm.value.main_phone_line == '' || this.payrollViewForm.value.main_phone_line == null) {
        // this.main_phone_lineError = 'block';
        // this.main_phone_lineError_message = 'Required';
      } else {
      }
      
      if (this.payrollViewForm.value.contact_1_phone == '' || this.payrollViewForm.value.contact_1_phone == null) {
        // this.contact_1_phoneError = 'block';
        // this.contact_1_phoneError_message = 'Required';
      } else {
      }

      if (this.payrollViewForm.value.contact_1_email == '' || this.payrollViewForm.value.contact_1_email == null) {
        // this.contact_1_emailError = 'block';
        // this.contact_1_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.payrollViewForm.value.contact_1_email)) {
          this.contact_1_emailError = 'block';
          this.contact_1_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_1_emailError = 'none';
        }
      }
      
      if (this.payrollViewForm.value.contact_2_phone == '' || this.payrollViewForm.value.contact_2_phone == null) {
        // this.contact_2_phoneError = 'block';
        // this.contact_2_phoneError_message = 'Required';
      } else {
      }

      if (this.payrollViewForm.value.contact_2_email == '' || this.payrollViewForm.value.contact_2_email == null) {
        // this.contact_2_emailError = 'block';
        // this.contact_2_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.payrollViewForm.value.contact_2_email)) {
          this.contact_2_emailError = 'block';
          this.contact_2_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_2_emailError = 'none';
        }
      }

      if (this.payrollViewForm.value.contact_3_phone == '' || this.payrollViewForm.value.contact_3_phone == null) {
        // this.contact_3_phoneError = 'block';
        // this.contact_3_phoneError_message = 'Required';
      } else {
      }

      if (this.payrollViewForm.value.contact_3_email == '' || this.payrollViewForm.value.contact_3_email == null) {
        // this.contact_3_emailError = 'block';
        // this.contact_3_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.payrollViewForm.value.contact_3_email)) {
          this.contact_3_emailError = 'block';
          this.contact_3_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_3_emailError = 'none';
        }
      }

      if (this.payrollViewForm.value.contact_4_phone == '' || this.payrollViewForm.value.contact_4_phone == null) {
        // this.contact_4_phoneError = 'block';
        // this.contact_4_phoneError_message = 'Required';
      } else {
      }

      if (this.payrollViewForm.value.contact_4_email == '' || this.payrollViewForm.value.contact_4_email == null) {
        // this.contact_4_emailError = 'block';
        // this.contact_4_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.payrollViewForm.value.contact_4_email)) {
          this.contact_4_emailError = 'block';
          this.contact_4_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_4_emailError = 'none';
        }
      }
      
      if(validateFlag == 0){

        this.submittedUpdate = true;
        this.spinnerService.show();

        var payrollViewFormObject = this.payrollViewForm.value;
        this.payrollService.update(this.payrollId, payrollViewFormObject).subscribe(response => {
          this.justUpdated = 1;
          this.getPayrollInfo();
          this.editPayroll = false;
          this.submittedUpdate = false;
          this.payrollViewForm.reset();
        });
      }

    }else {
      // if (this.payrollViewForm.value.worklist == '' || this.payrollViewForm.value.worklist == null) {
      //   this.worklistError = 'block';
      // } else {
      //   this.worklistError = 'none';
      // }
      // if (this.payrollViewForm.value.payroll_rep_referring == '' || this.payrollViewForm.value.payroll_rep_referring == null) {
      //   this.payroll_rep_referringError = 'block';
      // } else {
      //   this.payroll_rep_referringError = 'none';
      // }
      // if (this.payrollViewForm.value.payroll_company_website == '' || this.payrollViewForm.value.payroll_company_website == null) {
      //   this.payroll_company_websiteError = 'block';
      // } else {
      //   this.payroll_company_websiteError = 'none';
      // }
	  if (this.payrollViewForm.value.payroll_company_name == '' || this.payrollViewForm.value.payroll_company_name == null) {
        this.payroll_company_nameError = 'block';
      } else {
        this.payroll_company_nameError = 'none';
      }


      const control = <FormArray>this.payrollViewForm.controls['payroll_platforms'];
      if( this.platFormsCount == 1 && control.value[0] != undefined )
      {
        var currPlatformValue = control.value[0];
        if( currPlatformValue != undefined && currPlatformValue != null && ( (currPlatformValue.payroll_platform != undefined && currPlatformValue.payroll_platform != null && currPlatformValue.payroll_platform != '') && ((currPlatformValue.worklist_master_id == undefined || currPlatformValue.worklist_master_id == null || currPlatformValue.worklist_master_id == '') || (currPlatformValue.processor_user_id == undefined || currPlatformValue.processor_user_id == null || currPlatformValue.processor_user_id == '')) ) )
        {
          this.platFormsInvalid = 1;
        }      
      }
      else if( this.platFormsCount > 1 && this.platFormsInvalid == 0 )
      {
        this.platFormsInvalid = 2;
      }
      
      // if (this.payrollViewForm.value.main_phone_line == '' || this.payrollViewForm.value.main_phone_line == null) {
      //   this.main_phone_lineError = 'block';
      //   this.main_phone_lineError_message = 'Required';
      // } else {
      //   const pattern1 = /^\d{10}$/;
      //   const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      //   if (!this.payrollViewForm.value.main_phone_line.match(pattern1) && !this.payrollViewForm.value.main_phone_line.match(pattern2)) {
      //     this.main_phone_lineError = 'block';
      //     this.main_phone_lineError_message = 'Format allow (xxx) xxx-xxxx';
      //   } else {
      //     this.main_phone_lineError = 'none';
      //   }
      // }
      // if (this.payrollViewForm.value.contact_1_name == '' || this.payrollViewForm.value.contact_1_name == null) {
      //   this.contact_1_nameError = 'block';
      // } else {
      //   this.contact_1_nameError = 'none';
      // }

      // if (this.payrollViewForm.value.contact_1_role == '' || this.payrollViewForm.value.contact_1_role == null) {
      //   this.contact_1_roleError = 'block';
      // } else {
      //   this.contact_1_roleError = 'none';
      // }

      // if (this.payrollViewForm.value.contact_1_phone == '' || this.payrollViewForm.value.contact_1_phone == null) {
      //   this.contact_1_phoneError = 'block';
      //   this.contact_1_phoneError_message = 'Required';
      // } else {
      //   const pattern1 = /^\d{10}$/;
      //   const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      //   if (!this.payrollViewForm.value.contact_1_phone.match(pattern1) && !this.payrollViewForm.value.contact_1_phone.match(pattern2)) {
      //     this.contact_1_phoneError = 'block';
      //     this.contact_1_phoneError_message = 'Format allow (xxx) xxx-xxxx';
      //   } else {
      //     this.contact_1_phoneError = 'none';
      //   }
      // }

      // if (this.payrollViewForm.value.contact_1_email == '' || this.payrollViewForm.value.contact_1_email == null) {
      //   this.contact_1_emailError = 'block';
      //   this.contact_1_emailError_message = 'Required';
      // } else {
      //   const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //   if (!EMAIL_REGEXP.test(this.payrollViewForm.value.contact_1_email)) {
      //     this.contact_1_emailError = 'block';
      //     this.contact_1_emailError_message = 'Email Format is not valid.';
      //   } else {
      //     this.contact_1_emailError = 'none';
      //   }
      // }
      // if (this.payrollViewForm.value.payroll_notes == '' || this.payrollViewForm.value.payroll_notes == null) {
      //   this.payroll_notesError = 'block';
      // } else {
      //   this.payroll_notesError = 'none';
      // }
    }
  }
  usFormatteddate(phonenumbrerlabel) {
    if (phonenumbrerlabel == 1) {
      this.usDateValue = this.payrollViewForm.value.main_phone_line;
    }else if (phonenumbrerlabel == 2) {
      this.usDateValue = this.payrollViewForm.value.contact_1_phone;
    } else if (phonenumbrerlabel == 3) {
      this.usDateValue = this.payrollViewForm.value.contact_2_phone;
    } else if (phonenumbrerlabel == 4) {
      this.usDateValue = this.payrollViewForm.value.contact_3_phone;
    } else if (phonenumbrerlabel == 5) {
      this.usDateValue = this.payrollViewForm.value.contact_4_phone;
    }

    this.formattedNumber = String(this.usDateValue.replace(/[- )(]/g, ''));
    //  this.c = (this.formattedNumber[0] == '1') ? '1 ' : '';
    //   this.formattedNumber = this.formattedNumber[0] == '1' ? this.formattedNumber.slice(1) : this.formattedNumber;

    // # (###) ###-#### as c (area) front-end
    this.area = this.formattedNumber.substring(0, 3);
    this.front = this.formattedNumber.substring(3, 6);
    this.end = this.formattedNumber.substring(6);

    if (this.front) {
      this.formattedNumber = ("(" + this.area + ") " + this.front);
    }
    if (this.end) {
      this.formattedNumber += ("-" + this.end);
    }

    // New Code for Ext Cleaned
    if( this.formattedNumber != undefined && this.formattedNumber != null )
    {
      var pContent = this.formattedNumber.toLowerCase().split( "ext" );
      var finalNum = "";
      if( typeof pContent !== 'undefined' && pContent.length )
      {
        if( typeof pContent[0] !== 'undefined' )
        {
          finalNum = pContent[0];
        }
        if( typeof pContent[1] !== 'undefined' )
        {
          finalNum += " Ext " + pContent[1];
        }
        this.formattedNumber = finalNum;
      }
    }

	if (phonenumbrerlabel == 1) {
      this.payrollViewForm.controls['main_phone_line'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 2) {
      this.payrollViewForm.controls['contact_1_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 3) {
      this.payrollViewForm.controls['contact_2_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 4) {
      this.payrollViewForm.controls['contact_3_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 5) {
      this.payrollViewForm.controls['contact_4_phone'].setValue(this.formattedNumber);
    }

  }
  delete() {
    this.payrollService.delete(this.payrollId).subscribe(response => {
      this.editPayroll = false;
      this.deletePayroll = false;
      this.router.navigate(['/payroll-platform']);
    });
  }
  // get Agency Information
  getPayrollInfo() {
    this.loadedPayroll = 0;
    this.payrollService.get(this.payrollId).subscribe(response => {
      this.updatePayroll(response);
    });
  }
  updatePayroll(response) {
    this.getPayrollObject = response[0];
    
    this.payrollPlatformsDisp = this.getPayrollObject.payroll_platforms;
    this.loadedPayroll = 1;

    this.payrollViewForm.patchValue({
      payroll_rep_referring: this.getPayrollObject.payroll_rep_referring,
      payroll_company_website: this.getPayrollObject.payroll_company_website,
      payroll_company_name: this.getPayrollObject.payroll_company_name,
      main_phone_line: this.getPayrollObject.main_phone_line,
      contact_1_name: this.getPayrollObject.contact_1_name,
      contact_1_role: this.getPayrollObject.contact_1_role,
      contact_1_phone: this.getPayrollObject.contact_1_phone,
      contact_1_email: this.getPayrollObject.contact_1_email,
      contact_2_name: this.getPayrollObject.contact_2_name,
      contact_2_role: this.getPayrollObject.contact_2_role,
      contact_2_phone: this.getPayrollObject.contact_2_phone,
      contact_2_email: this.getPayrollObject.contact_2_email,
      contact_3_name: this.getPayrollObject.contact_3_name,
      contact_3_role: this.getPayrollObject.contact_3_role,
      contact_3_phone: this.getPayrollObject.contact_3_phone,
      contact_3_email: this.getPayrollObject.contact_3_email,
      contact_4_name: this.getPayrollObject.contact_4_name,
      contact_4_role: this.getPayrollObject.contact_4_role,
      contact_4_phone: this.getPayrollObject.contact_4_phone,
      contact_4_email: this.getPayrollObject.contact_4_email,
      payroll_notes: this.getPayrollObject.payroll_notes,
      id: this.getPayrollObject.id
    });

    if( this.justUpdated != 1 )
    {
      this.loadPlatforms();
      this.editIsLoading = false;
    }
    else
    {
      this.justUpdated = 0;
    }

    this.spinnerService.hide();
  }
}
