import { Component, OnInit } from '@angular/core';
import { MenuItem } from "primeng/api";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'client-menu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.css']
})
export class ClientMenuComponent implements OnInit {

  public items: MenuItem[];
  public id: number;

  constructor(aroute:ActivatedRoute) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    this.items = [
      {
        label: 'View Client',
        icon: 'fa-address-card',
        routerLink: [ '/clients/list/view/' + this.id ],
        routerLinkActiveOptions: { exact: true }
      },
        {
        label: 'Contact Card',
        icon: 'fa-address-card',
        routerLink: [ '/clients/list/view-client/' + this.id],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'Shipping/Billing Information',
        icon: 'fa-file',
        routerLink: [ '/clients/list/shipinfo/' + this.id ],
        routerLinkActiveOptions: { exact: true }
      },
      // {
      //   label: 'Policy Information',
      //   icon: 'fa-file',
      //   routerLink: [ '/clients/list/policy/' + this.id ],
      //   routerLinkActiveOptions: { exact: true }
      // },
      {
        label: 'Invoice',
        icon: 'fa-file-text-o',
        routerLink: [ '/clients/list/' + this.id + '/invoice' ]
      },
      {
        label: 'Report',
        icon: 'fa-paper-plane',
        routerLink: [ '/clients/list/remit/' + this.id ],
        routerLinkActiveOptions: { exact: true }
      }
      // {
      //   label: 'Upload',
      //   icon: 'fa-file-text-o',
      //   routerLink: [ '/clients/list/upload/' + this.id ],
      //   routerLinkActiveOptions: { exact: true }
      // }
    ];
  }

}
