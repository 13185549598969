import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService } from '../document.service';
import { DocumentQueue } from '../document-queue';
import { Message, SelectItem } from 'primeng/api';

@Component({
  selector: 'app-queue-edit',
  templateUrl: './queue-edit.component.html',
  styleUrls: ['./queue-edit.component.css']
})
export class QueueEditComponent implements OnInit {
  id: number;
  queue: DocumentQueue;
  queueTypes: SelectItem[];
  printers: SelectItem[];
  faxCoverSheets: SelectItem[];
  msgs: Message[] = [];

  constructor(aroute: ActivatedRoute, private router: Router, private docService: DocumentService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    // Load queue (if editing)
    if (this.id) {
      this.loadQueue();
    } else {
      this.queue = new DocumentQueue();
    }

    // Load queue types
    this.queueTypes = [
      { label: '(select)', value: null },
      { label: 'Printer', value: 'print' },
      { label: 'Email', value: 'email' },
      { label: 'Text', value: 'text' },
      { label: 'Fax', value: 'fax' }
    ];

    // Load printers
    this.printers = [];
    this.printers.push({ label: '(select)', value: null });
    this.docService.getPrinters().subscribe(printers => {
      for (const printer of printers) {
        this.printers.push({ label: printer.name, value: printer.id });
      }
    });

    // Load cover sheets
    this.faxCoverSheets = [
      { label: '(select)', value: null },
      { label: 'None', value: 'none' }
    ];
  }

  loadQueue() {
    this.docService.getQueue(this.id).subscribe(res => this.queue = res);
  }

  saveQueue() {
    if(!this.id) {
      this.docService.createQueue(this.queue).subscribe(res => {
        // Display message for 2 seconds
        this.msgs.push({severity: 'success', summary: 'Queue Created', detail: res.message});
        setTimeout(() => {
          this.msgs = [];
          this.router.navigate(['settings/queues']);
        }, 2000);
      });
    } else {
      this.docService.saveQueue(this.id, this.queue).subscribe(res => {
        // Display message for 2 seconds
        this.msgs.push({severity: 'success', summary: 'Queue Updated', detail: res.message});
        setTimeout(() => {
          this.msgs = [];
          this.router.navigate(['settings/queues']);
        }, 2000);
      });
    }
  }

}
