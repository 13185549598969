import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../../settings/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentQueue } from '../../settings/document-queue';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/components/common/api';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  id: number;
  queue: DocumentQueue;
  msgs: Message[] = [];

  constructor(aroute: ActivatedRoute, private router: Router, private docService: DocumentService,
      private confirmationService: ConfirmationService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    if (this.id) {
      this.loadQueue();
    }
  }

  loadQueue() {
    this.docService.getQueue(this.id).subscribe(queue => this.queue = queue);
  }

  runBatch() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to run this batch?',
      accept: () => {
        console.log('accepted');
      }
    });
  }

}
