import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";
import { ConfigServiceService } from "../config-service.service";
import { Policy } from "./policy";
import { PolicyChangeResponse } from './policy-change-response';
import { Observable ,  Subject } from 'rxjs';
@Injectable()
export class PolicyService {

  private apiUrl;

  isSidebarVisible: boolean;
  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();  

  constructor(private http: HttpClient, private auth: AuthService, config: ConfigServiceService) {
    this.apiUrl = config.getApiUrl();

    this.sidebarVisibilityChange.subscribe((value) => {
        this.isSidebarVisible = value
    });    
  }

  toggleSidebarVisibility() {
    this.sidebarVisibilityChange.next(!this.isSidebarVisible);
  }

  public getSidebarValue()
  {
      return this.isSidebarVisible;
  }
  
  getPolicy(id: Number) {
    let token = this.auth.getToken();
    return this.http.get<Policy>(this.apiUrl + '/policies/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  savePolicy(policy: Policy): Observable<any>
  {
      const token = this.auth.getToken();
      return this.http.post(this.apiUrl + '/policies', policy,{
          headers: {
              Authorization: 'Bearer ' + token
          }
      });
  }
  deletePolicy(policy_id: number)    
  {
      const token = this.auth.getToken();
      return this.http.get<any>(this.apiUrl + '/policy-delete-action?id=' + policy_id, {
        headers: {
          Authorization: 'Bearer ' + token 
        }
      });  
  }
  dependencyOnPolicy(policy_id: number){
    const token = this.auth.getToken();
      return this.http.get<any>(this.apiUrl + '/policy-dependency-action?id=' + policy_id, {
        headers: {
          Authorization: 'Bearer ' + token 
        }
      });  
  }
  saveRates(rates: any): Observable<any>
  {
      const token = this.auth.getToken();
      return this.http.post(this.apiUrl + '/rates', rates,{
          headers: {
              Authorization: 'Bearer ' + token
          }
      });
  }

  updatePolicy(id: number, data: Policy) {

    const token = this.auth.getToken();
    return this.http.put<PolicyChangeResponse>(this.apiUrl + '/policies/' + id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getSearchStates( codesPostObj:any ): Observable<any>
  {
    const token = this.auth.getToken();
    return this.http.post(this.apiUrl + '/search-states', codesPostObj,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
   }

   getSearchCarriers( codesPostObj:any ): Observable<any>
   {
     const token = this.auth.getToken();
     return this.http.post(this.apiUrl + '/search-carriers', codesPostObj,{
         headers: {
             Authorization: 'Bearer ' + token
         }
     });
    }
 
   getPolStateClCodes( codesPostObj:any ): Observable<any>
  {
    const token = this.auth.getToken();
    return this.http.post(this.apiUrl + '/codes-descriptions', codesPostObj,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
   }

   checkActnsCancld( invPolPostObj:any ): Observable<any>
   {
     const token = this.auth.getToken();
     return this.http.post(this.apiUrl + '/check-actions-cancld', invPolPostObj,{
         headers: {
             Authorization: 'Bearer ' + token
         }
     });
    }
   checkIncCancld( invPolPostObj:any ): Observable<any>
   {
     const token = this.auth.getToken();
     return this.http.post(this.apiUrl + '/check-incomplete-cancld', invPolPostObj,{
         headers: {
             Authorization: 'Bearer ' + token
         }
     });
    }

   getInvoicePolicy( invPolPostObj:any ): Observable<any>
   {
     const token = this.auth.getToken();
     return this.http.post(this.apiUrl + '/invoice-policy', invPolPostObj,{
         headers: {
             Authorization: 'Bearer ' + token
         }
     });
    }
    saveOverrideOption( invActionPostObj:any ): Observable<any>
    {
      const token = this.auth.getToken();

      return this.http.post(this.apiUrl + '/set-action-to-override', invActionPostObj,{
          headers: {
              Authorization: 'Bearer ' + token
          }
      });
    }

    getUplAchDate( reportDate )
    {
      const token = this.auth.getToken();
      return this.http.get<any>(this.apiUrl + '/getUplAch?reportDate='+reportDate, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    
    getSavedInvoice( invPolPostObj:any ): Observable<any>
    {
        const token = this.auth.getToken();
        return this.http.post(this.apiUrl + '/get-saved-invoice', invPolPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }
 
    saveInvoiceeNote( invoiceNotePostObj:any ): Observable<any>
    {
      const token = this.auth.getToken();
      return this.http.post(this.apiUrl + '/invoice-note', invoiceNotePostObj,{
          headers: {
              Authorization: 'Bearer ' + token
          }
      });
     }

     getInvoiceeNote( invoiceNotePostObj:any ): Observable<any>
     {
       const token = this.auth.getToken();
       return this.http.post(this.apiUrl + '/invoice-note', invoiceNotePostObj,{
           headers: {
               Authorization: 'Bearer ' + token
           }
       });
      }
      
      saveInvoice( invoicePostObj:any,invoiceType:number ): Observable<any>
      {
        const token = this.auth.getToken();

        if( invoiceType == 1 )
        {
            return this.http.post(this.apiUrl + '/invoice-save', invoicePostObj,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        else if( invoiceType == 2 )
        {
            return this.http.post(this.apiUrl + '/pf-invoice-save', invoicePostObj,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        else if( invoiceType == 3 )
        {
            return this.http.post(this.apiUrl + '/npr-invoice-save', invoicePostObj,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
      }

      saveInvoiceMaster( invoicePostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();
        return this.http.post(this.apiUrl + '/invoice-save-master', invoicePostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }

      saveShipBillInfo( invoicePostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();
        return this.http.post(this.apiUrl + '/ship_bill_info', invoicePostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }

      genInvoicePDF( invoicePDFObj:any ): Observable<any>
      {
        const token = this.auth.getToken();

        return this.http.post(this.apiUrl + '/invoice-pdf-file-gen', invoicePDFObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }
      
       updateInvoice(id: number, data: any)
       {
        const token = this.auth.getToken();
        return this.http.put<any>(this.apiUrl + '/invoice-save/' + id, data, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
       }
           
       getInvoiceHistory(client_id: Number) {
        var filter = "";
        const token = this.auth.getToken();
        return this.http.get<any>(this.apiUrl + '/client-invoice-history?id=' + client_id, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }

      getInvoiceHistoryPagination(client_id: Number, skip, take,invType) {
        var filter = "";
        const token = this.auth.getToken();
        return this.http.get<any>(this.apiUrl + '/client-invoice-history?id=' + client_id+ '&skip=' +skip+ '&take=' +take+ '&invType=' +invType, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }

      getRemitLog(client_id: Number)
      {
        const token = this.auth.getToken();
        return this.http.get<any>(this.apiUrl + '/client-remit-log?id=' + client_id, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }
      
      remitAction( invActionPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();

        return this.http.post(this.apiUrl + '/remit-action', invActionPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }

      getCollected(client_id: Number) {
        var filter = "";
        const token = this.auth.getToken();
        return this.http.get<any>(this.apiUrl + '/client-collected-premium?id=' + client_id, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }

      getRemitted(remitLogId: Number) {
        var filter = "";
        const token = this.auth.getToken();
        return this.http.get<any>(this.apiUrl + '/client-remitted?id=' + remitLogId, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }

      generateOverall( invActionPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();

        return this.http.post(this.apiUrl + '/client-overall-report', invActionPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }

      getOverallLog(client_id: Number)
      {
        const token = this.auth.getToken();
        return this.http.get<any>(this.apiUrl + '/client-overall-log?id=' + client_id, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }
      
      batchExcel( invActionPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();

        return this.http.post(this.apiUrl + '/excelBatchReport', invActionPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }
      
      reverseQueue( invActionPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();

        return this.http.post(this.apiUrl + '/queue-revert', invActionPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }

      checkChkDtAchDt( invPolPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();
        return this.http.post(this.apiUrl + '/check-chk-ach', invPolPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
       }
         

      invAction( invActionPostObj:any,invoiceType:number ): Observable<any>
      {
        const token = this.auth.getToken();

        if( invoiceType == 1 )
        {
            return this.http.post(this.apiUrl + '/invoice-action', invActionPostObj,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        else if( invoiceType == 2 )
        {
            return this.http.post(this.apiUrl + '/policy-fee-actions', invActionPostObj,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        else if( invoiceType == 3 )
        {
            return this.http.post(this.apiUrl + '/npr-actions', invActionPostObj,{
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
      }
      
      voidAction( invActionPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();

        return this.http.post(this.apiUrl + '/void-action', invActionPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }

      holdAction( invActionPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();

        return this.http.post(this.apiUrl + '/hold-action', invActionPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }

      delInvoice( invActionPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();

        return this.http.post(this.apiUrl + '/delete-invoice', invActionPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }

      pfColInvoice( invActionPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();

        return this.http.post(this.apiUrl + '/pf-set-col-action', invActionPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
      }

      getInvoicePDF(invoiceSettingID: Number) {
          
        var filter = "";
        const token = this.auth.getToken();
        return this.http.get<any>(this.apiUrl + '/pdfview?id=' + invoiceSettingID, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }
      
      checkPFNPRInvoice( invPolPostObj:any ): Observable<any>
      {
        const token = this.auth.getToken();
        return this.http.post(this.apiUrl + '/check-pf-npr-invoice', invPolPostObj,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
       }

       getPFNPRPolicy( invPolPostObj:any ): Observable<any>
       {
         const token = this.auth.getToken();
         return this.http.post(this.apiUrl + '/policy-pf-npr', invPolPostObj,{
             headers: {
                 Authorization: 'Bearer ' + token
             }
         });
        }
         
}
