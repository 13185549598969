import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from '../auth.service';
import { Auth } from '../auth';
import { AuthData } from '../auth-response';
import { Message } from 'primeng/components/common/api';
import { HttpErrorResponse } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isLogged: any;
  LoginUserName: any;
  items: MenuItem[];
  public username: string;
  public password: string;
  public msgs: Message[];

  constructor(public auth: AuthService,
    private router: Router,
    private dataService: DataService) { }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['']);
    } else {
      this.dataService.currentUserName.subscribe(LoginUserName => setTimeout(() => this.LoginUserName = LoginUserName, 0));
      this.dataService.currentMessage.subscribe(isLogged => setTimeout(() => this.isLogged = isLogged, 0));
      this.isLogged = '0';
      this.dataService.changeMessage(this.isLogged);
    }
  }

  doAuth() {
    let auth: Auth = new Auth(this.username, this.password, 1);

    this.auth.login(auth).subscribe((res) => {
      // console.log(res);
      if (res instanceof HttpErrorResponse) {
        this.updateMessage('Login Failed:', res.error.message);
        this.clearForm();
      } else if (res instanceof AuthData) {
     this.LoginUserName = res.first_name+' '+res.last_name;
     localStorage.setItem('userName',this.LoginUserName);
     localStorage.setItem('userId',res.id.toString());
     localStorage.setItem('loggedUserRole',res.role); 
     this.dataService.changeUserName(this.LoginUserName);
        localStorage.setItem('logUser', '1');
        // Role
        this.dataService.currentRole.subscribe(items => setTimeout(() => this.items = items, 0));
        if(res.role == 'Super Admin'){
          this.items = [
            { label: 'Settings', icon: 'fa-gear', routerLink: '/settings' },
            { separator: true },
            { label: 'Logout', icon: 'fa-sign-out', routerLink: '/logout' }
          ];
          this.dataService.changeRole(this.items);
        }else{
          this.items = [
            { label: 'Logout', icon: 'fa-sign-out', routerLink: '/logout' }
          ];
          this.dataService.changeRole(this.items);
        }
        // End
        this.router.navigateByUrl('/');
      } else {
        this.updateMessage('Login Failed:', 'An unknown error occurred');
        this.clearForm();
      }
    });
  }

  private updateMessage(summary: string, detail: string) {
    this.msgs = [];
    this.msgs.push({
      severity: 'error',
      summary: summary,
      detail: detail
    });
  }

  private clearForm() {
    this.password = '';
  }

}
