import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../config-service.service';
import { AuthService } from '../auth/auth.service';
import { Client } from './client';
import { Brokers } from './brokers';
import { ClientChangeResponse } from './client-change-response';
import { Observable } from 'rxjs';
import { Stats } from './stats';
import { State } from './state';
import { ClassCode } from './class-code';
import { NumberSymbol } from '@angular/common';
import { SpinnerService } from '../core';


@Injectable()
export class BrokersService {

  readonly apiUrl;
  public selectedClient: Client;

  constructor(private http: HttpClient, 
    private auth: AuthService, 
    config: ConfigServiceService,
    private spinnerService: SpinnerService) {
    this.apiUrl = config.getApiUrl();
  }

  add(activity: Brokers): Observable<any> {
    const token = this.auth.getToken();
    return this.http.post(this.apiUrl + '/brokers', activity, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  update(id: number, data: Brokers) {
    const token = this.auth.getToken();
    return this.http.put<Brokers>(this.apiUrl + '/brokers/' + id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getAll(skip, take, search) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Brokers[]>(this.apiUrl + '/brokers?skip=' + skip + '&take=' +take + '&search=' +search, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  get(id: number) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Brokers[]>(this.apiUrl + '/brokers?id=' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  delete(id: number) {
    const token = this.auth.getToken();
    return this.http.get<Brokers>(this.apiUrl + '/brokers?deleteId=' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
}
