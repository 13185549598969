import { Component, OnInit } from '@angular/core';
import { MenuItem } from "primeng/api";
import { NavigationEnd, Router } from "@angular/router";
import { ClientService } from '../../client/client.service';
import * as moment from 'moment-timezone';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  breadcrumb: MenuItem[];
  home: MenuItem;
  dispTime : any; 

  constructor(router: Router,private clientService: ClientService,) {
    router.events
      .subscribe((event: NavigationEnd) => {
        if(event instanceof NavigationEnd) {
          let url = event.urlAfterRedirects.split('?');
          url = url[0].split('/');
          this.breadcrumb = [];
          let routerLink = '';
          url.forEach( (val) => {
            if(val != '') {
              routerLink += val + '/';
              this.breadcrumb.push({
                label: val.charAt(0).toUpperCase() + val.slice(1).toLowerCase(),
                routerLink: [ routerLink ],
                routerLinkActiveOptions: { exact: true }
              });              
            }
          });
        }        
      });
  }
  loadTimeDisplay(){
    var timeeee = moment().tz('America/Chicago').format('HH:mm:ss');
    var dateTee = moment().tz('America/Chicago').format("MM/DD/YYYY");
   setTimeout(() => {
    this.dispTime = dateTee+' '+timeeee; 
  }, 1);
    /* this.clientService.getDispServerTime().subscribe(res =>
      {
        this.dispTime = res;
      }); 
     */
  }
  ngOnInit() {
    this.home = { icon: 'fa-home', routerLink: [ '' ], routerLinkActiveOptions: { exact: true } };
      // this.loadTimeDisplay();  
  }
  ngAfterViewChecked(){
    // this.loadTimeDisplay();      
  }

}
