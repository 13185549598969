import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ContactsService } from '../contacts.service';
import { Contact } from '../contact';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.css']
})
export class ClientContactsComponent implements OnInit {
  clientId: number;
  contacts: any = [];
  roles: any;
  showContactCard = false;
  showEditContactCard = false;

  newContact: any = {};
  editContact: any = {};

  msgs = [];
  contactform: FormGroup;
  editcontactform: FormGroup;
  submitted: boolean;
  allowedMaxContacts = 6;

  constructor(private confirmService: ConfirmationService, private fb: FormBuilder, private contactService: ContactsService, aroute: ActivatedRoute, private messageService: MessageService) {
    aroute.params.subscribe(params => {
      this.clientId = params['id'];
    });
  }

  ngOnInit() {
    this.contactform = this.fb.group({
      'name': new FormControl('', Validators.required),
      'role': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.compose([Validators.required, Validators.email])),
      'phone': new FormControl('', Validators.compose([Validators.required, Validators.pattern('[0-9]{10}')])),
    });

    this.editcontactform = this.fb.group({
      'id': new FormControl(''),
      'name': new FormControl('', Validators.required),
      'role': new FormControl('', Validators.required),
      'email': new FormControl('', Validators.compose([Validators.required, Validators.email])),
      'phone': new FormControl('', Validators.compose([Validators.required, Validators.pattern('[0-9]{10}')])),
    });

    this.roles = [
      {label: 'Select', value: ''},
      {label: 'Role 1', value: 'role1'},
      {label: 'Role 2', value: 'role2'},
      {label: 'Role 3', value: 'role3'}
    ];
    this.refresh();
  }

  validateAddForm() {
    Object.keys(this.contactform.controls).forEach(item => {
      if (this.contactform.controls[item].status === 'INVALID' && this.contactform.controls[item].touched === true) {
        this.setMessage('error', 'Validaion Error:', '');
      }
    });
  }

  validateEditForm() {
    Object.keys(this.editcontactform.controls).forEach(item => {
      if (this.editcontactform.controls[item].status === 'INVALID' && this.editcontactform.controls[item].touched === true) {
        this.setMessage('error', 'Validaion Error:', '');
      }
    });
  }

  private setMessage(severity: string, summary: string, detail: string) {
    this.msgs = [];
    this.msgs.push({severity: severity, summary: summary, detail: detail});
  }

  refresh() {
    this.contactService.getContacts(this.clientId).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
        } else {
          this.contacts = [];
          this.contacts = <Contact[]> response;
        }
      },
      error => {
        this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText});
      });
  }

  addContact(value: any) {
    this.newContact.client_id = this.clientId;
    /****** Call Add Contact Service ******/
    this.contactService.saveContact(this.newContact).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
        } else {
          this.contacts.push(response.contact);
          this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Contact added successfully'});
        }
      },
      error => {
        this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message});
      });
    /**************************************/
    this.newContact = {};
    this.contactform.reset();
    this.showContactCard = false;
  }

  showEditContact(offset) {
    this.showEditContactCard = true;
    this.editContact = this.contacts[offset];
  }

  updateContact(value: any) {
    /****** Call Update Contact Service ******/
    this.contactService.updateContact(this.editContact.id, this.editContact).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
        } else {
          this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Status updated successfully'});
        }
      },
      error => {
        this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message});
      });
    /**************************************/
    this.editContact = {};
    this.editcontactform.reset();
    this.showEditContactCard = false;
  }

  deleteContact(contact) {
    this.confirmService.confirm({
      message: 'Are you sure that you want to perform this action?',
      key: 'deleteContact',
      accept: () => {
        /****** Call Delete Contact Service ******/
        this.contactService.deleteContact(contact.id).subscribe(
          response => {
            if (response instanceof HttpErrorResponse) {
              this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
            } else {
              let index = this.contacts.indexOf(contact);
              this.contacts.splice(index, 1);
              this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Contact deleted successfully'});
            }
          },
          error => {
            this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message});
          });
        /**************************************/
      }
    });
  }

  onHideAddForm() {
    this.msgs = [];
    this.newContact = {};
    this.contactform.reset();
  }

  onHideEditForm() {
    this.msgs = [];
    this.editContact = {};
    this.editcontactform.reset();
    this.refresh();
  }

  isLastContact(index: number) {
    return (index === this.contacts.length - 1);
  }

}
