import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../config-service.service';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { Contact } from './contact';
import { ContactChangeResponse } from './contact-change-response';

@Injectable()
export class ContactsService {

    readonly apiUrl;

    constructor(private http: HttpClient, private auth: AuthService, config: ConfigServiceService) {
        this.apiUrl = config.getApiUrl();
    }
    
    getContacts(client_id: Number) {
        const token = this.auth.getToken();
        return this.http.get<Contact[]>(this.apiUrl + '/contacts?client_id=' + client_id, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    } 

    saveContact(data: Contact) {
        const token = this.auth.getToken();
        return this.http.post<ContactChangeResponse>(this.apiUrl + '/contacts', data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    updateContact(id: number, data: Contact) {
        const token = this.auth.getToken();
        return this.http.put<ContactChangeResponse>(this.apiUrl + '/contacts/' + id, data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    deleteContact(id: Number) {
        const token = this.auth.getToken();
        return this.http.delete<Contact[]>(this.apiUrl + '/contacts/'+id, {
            headers: {
            Authorization: 'Bearer ' + token
            }
        });
    }
}
