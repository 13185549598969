import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SpinnerService } from '../core';
import { ClientService } from "../client/client.service";
import { PolicyService } from '../client/policy.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigServiceService } from '../config-service.service';
import { Message,SelectItem } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { emailValidator, phoneNumberValidator, zipCodeValidator } from '../shared';
import { AutoCompleteModule } from 'primeng/autocomplete';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  elementRef: ElementRef;

  fieldsArr:string[]=[];

  ach_withdrawal_date: string;
  selectedCarriers = [];
  carriers:any = [];
  requiredCheckDate:Date;
  invActionPostObj :any = {};

  selectedCarrIds = [];
  carriersByIds:any = [];
  remitCarrIds = [];
  remrec_start_date: string;
  remrec_end_date: string;

  dtValStr:string='';
  serverYr:string='';
  chromePrevStr:string='';

  achDtInv:boolean = false;

  rptTypes: SelectItem[];
  selectedRType:number=0;
  PayrollPlatformselected:any = [];
  showRenDates:boolean = false;
  renrepInProgress:boolean = false;
  rengenInProgress:boolean = false;
  startDateReport: string;
  endDateReport: string;
  startDateReport_Error = 'none';
  endDateReport_Error = 'none';
  redError = 'red';

  
  Statusselected:any = [];
  AddonStatusselected:any = [];
  PhysicalStateselected:any = [];
  MailingStateselected:any = [];
  Agenciesselected:any = [];
  Brokerselected:any = [];
  PayrollFrequencyselected:any = [];
  Worklistselected:any = [];
  OtherOutselected:any = [];
  OtherFeeselected:any = [];
  OtherGarnishmentsselected:any = [];
  OtherEnteredselected:any = [];
  OtherDeliveryselected:any = [];
  OtherReportsselected:any = [];
  ccFeeTypesselected:any = [];

  repStDtInv:boolean = false;
  repEndDtInv:boolean = false;

  remRecStDtInv:boolean = false;
  remRecEndDtInv:boolean = false;

  batch_withdrawal_date: string;
  batchAchInv:boolean = false;

  rpmMiscCarrs = [ "ABC Insurance Carrier","Accident Fund","AFGroup-AccuPremium","Amerisafe","Amerisure","Atlas General","Benchmark Insurance Company",
  "BHHC","Builders Mutual","Chesapeake Employers Insurance","Clear Spring","CNA","Employers","FCCI",
  "FFVA","FHM","FUBA/Lancer Indemnity","GUARD-Berkshire Hathaway","GUARD/GRIP","Hanover","Liberty Mutual","Markel",
  "Markel PayGo","Meadowbrook","Memic-CompAsYouGo","Midwest Insurance","MIGPay-Merchants","National Liability & Fire",
  "Nationwide E&S/Specialty","NYSIF","Omaha National","Pinnacol","PrecisePay-Employers","Preferred Employers","Republic Indemnity",
  "Sathyammm","Selective Insurance","SFM Companies","Shelter Point","ShelterPoint Life Insurance Company","TravPay-Travelers",
  "Utica National's Right Pay","XactPay-Hartford" ];

  rpmPick = [];

  comissionDrop:any = [];
  comissionDropValue = 1;
  comissionBADropValue = [];
  PayrollPlatforms:any = [];
  comissionReportRadioValue = 1;
  comissionReportRadioView = 1;

  StatusDrop:any = [];
  AddonStatusDrop:any = [];
  PhysicalStateDrop:any = [];
  agencies:any = [];
  broker:any = [];
  PayrollfrequencyDrop:any = [];
  WorklistDrop:any = [];
  OtherOutDrop:any = [];
  OtherFeeDrop:any = [];
  OtherGarnishmentsDrop:any = [];
  ccFeeTypes:any = [];
  OtherEnteredDrop:any = [];
  OtherDeliveryDrop:any = [];
  OtherReportsDrop:any = [];
  reportSelectField:any = [];

  dragSrcEl:any = {};

  constructor(private policyService: PolicyService,
    private configServiceService: ConfigServiceService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private clientService:ClientService,
    @Inject(ElementRef) elementRef: ElementRef
  ) { 
    this.elementRef = elementRef;
  }

  ngAfterViewInit() {
    const y = this.elementRef.nativeElement.querySelectorAll('.ui-radiobutton-label');
    for (var i = 0; i < y.length; i++) {
      y[i].style.marginBottom = '0';
    }
  }

  validatedateInv(inputText)
  {
    var isDateValid = true;
    var dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  // Match the date format through regular expression
  if(inputText.match(dateformat))
  {
  //Test which seperator is used '/' or '-'
  var opera1 = inputText.split('/');
  var opera2 = inputText.split('-');
  var lopera1 = opera1.length;
  var lopera2 = opera2.length;
  // Extract the string into month, date and year
  if (lopera1>1)
  {
  var pdate = inputText.split('/');
  }
  else if (lopera2>1)
  {
  var pdate = inputText.split('-');
  }
  var mm  = parseInt(pdate[0]);
  var dd = parseInt(pdate[1]);
  var yy = parseInt(pdate[2]);
  // Create list of days of a month [assume there is no leap year by default]
  var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
  if (mm==1 || mm>2)
  {
  if (dd>ListofDays[mm-1])
  {
  isDateValid = false;
  }
  }
  if (mm==2)
  {
  var lyear = false;
  if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
  {
  lyear = true;
  }
  if ((lyear==false) && (dd>=29))
  {
    isDateValid = false;
  }
  if ((lyear==true) && (dd>29))
  {
    isDateValid = false;
  }
  }
  }
  else
  {
    isDateValid = false;
  }

  return isDateValid;
  }
  
  appendYr( eleId )
  {
      var isValidDate = true;
      var checkingDt = this.dtValStr;
      var dtGoAhead = false;

      if( checkingDt.length == 4 && ! checkingDt.includes('/') )
      {
        checkingDt = checkingDt.substring(0,2) + "/" + checkingDt.substring(2,4) + "/";
        if( this.validatedateInv(checkingDt) )
        {
          this.dtValStr = checkingDt;
          dtGoAhead = true;
        } 
      }
      else if( checkingDt.length == 5 )
      {
        var dtArr = this.dtValStr.split("/");
        if( dtArr != undefined && dtArr != null && typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' )
        {
          checkingDt += "/";
          if( this.validatedateInv(checkingDt) )
          {
            this.dtValStr += "/";
            dtGoAhead = true;
          }
        }
      }

      if( dtGoAhead )
      {
        if( eleId == "achdDt" )
        {
          this.achDtInv = true;
          this.ach_withdrawal_date = this.dtValStr;
          setTimeout(() =>
          {
            this.achDtInv = false;
          }, 50);
        }
        else if( eleId == "repStInp" )
        {
          this.repStDtInv = true;
          this.startDateReport = this.dtValStr;
          setTimeout(() =>
          {
            this.repStDtInv = false;
          }, 50);
        }
        else if( eleId == "repEndInp" )
        {
          this.repEndDtInv = true;
          this.endDateReport = this.dtValStr;
          setTimeout(() =>
          {
            this.repEndDtInv = false;
          }, 50);
        }
        if( eleId == "remrecStdDt" )
        {
          this.remRecStDtInv = true;
          this.remrec_start_date = this.dtValStr;
          setTimeout(() =>
          {
            this.remRecStDtInv = false;
          }, 50);
        }
        if( eleId == "remrecEnddDt" )
        {
          this.remRecEndDtInv = true;
          this.remrec_end_date = this.dtValStr;
          setTimeout(() =>
          {
            this.remRecEndDtInv = false;
          }, 50);
        }
        if( eleId == "batchAchInp" )
        {
          this.batchAchInv = true;
          this.batch_withdrawal_date = this.dtValStr;
          setTimeout(() =>
          {
            this.batchAchInv = false;
          }, 50);
        }
      }
  }

  fillDtTab( eleId )
  {
    var goFillField = false;
    var dtBkStr = "";

    var chkTempStr = this.dtValStr;
    if( chkTempStr.length == 8 && chkTempStr.includes("/") && chkTempStr.charAt(2)=="/" && chkTempStr.charAt(5)=="/" )
    {
        var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
            if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[2].length == 2 )
              {
                var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                if( this.validatedateInv(checkingDt1) )
                {
                  this.dtValStr = checkingDt1;
                  dtBkStr = checkingDt1;
                  goFillField = true;
                }
              }
            }
        }
    }
    
    if( goFillField )
    {
      if( eleId == "achdDt" )
      {
        this.achDtInv = false;
        this.ach_withdrawal_date = dtBkStr;
      }
      else if( eleId == "repStInp" )
      {
        this.repStDtInv = false;
        this.startDateReport = dtBkStr;
      }
      else if( eleId == "repEndInp" )
      {
        this.repEndDtInv = false;
        this.endDateReport = dtBkStr;
      }
      else if( eleId == "remrecStdDt" )
      {
        this.remRecStDtInv = false;
        this.remrec_start_date = dtBkStr;
      }
      else if( eleId == "remrecEnddDt" )
      {
        this.remRecEndDtInv = false;
        this.remrec_end_date = dtBkStr;
      }
      else if( eleId == "batchAchInp" )
      {
        this.batchAchInv = false;
        this.batch_withdrawal_date = dtBkStr;
      }
    }
  }
 
  outOfDtField( event,eleId )
  {
    this.fillDtTab( eleId );

    if( eleId == "achdDt" )
    {
      this.achDtInv = false;
      setTimeout(() =>
      {
        if( ! this.achDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.ach_withdrawal_date = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "repStInp" )
    {
      this.repStDtInv = false;
      setTimeout(() =>
      {
        if( ! this.repStDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.startDateReport = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "repEndInp" )
    {
      this.repEndDtInv = false;
      setTimeout(() =>
      {
        if( ! this.repEndDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.endDateReport = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "remrecStdDt" )
    {
      this.remRecStDtInv = false;
      setTimeout(() =>
      {
        if( ! this.remRecStDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.remrec_start_date = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "remrecEnddDt" )
    {
      this.remRecEndDtInv = false;
      setTimeout(() =>
      {
        if( ! this.remRecEndDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.remrec_end_date = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "batchAchInp" )
    {
      this.batchAchInv = false;
      setTimeout(() =>
      {
        if( ! this.batchAchInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.batch_withdrawal_date = this.dtValStr;
        }
      }, 15);
    }
  }

  completeMonth( eleId )
  {
      var tempStr = this.dtValStr; 
      var compMGo = false;
      if( tempStr.length == 1 && (parseInt(tempStr) > 1 && parseInt(tempStr) < 10) )
      {
        this.dtValStr = "0" + this.dtValStr + "/";
        compMGo = true;
      }
      else if( tempStr.length == 2 && (parseInt(tempStr) >= 1 && parseInt(tempStr) <= 12) )
      {
        this.dtValStr = this.dtValStr + "/";
        compMGo = true;
      }

      if( eleId == "achdDt" && compMGo )
      {
        this.achDtInv = true;
        setTimeout(() =>
        {
         this.ach_withdrawal_date = this.dtValStr;
        }, 100);
      }
      else if( eleId == "repStInp" && compMGo )
      {
        this.repStDtInv = true;
        setTimeout(() =>
        {
         this.startDateReport = this.dtValStr;
        }, 100);
      }
      else if( eleId == "repEndInp" && compMGo )
      {
        this.repEndDtInv = true;
        setTimeout(() =>
        {
         this.endDateReport = this.dtValStr;
        }, 100);
      }
      else if( eleId == "remrecStdDt" && compMGo )
      {
        this.remRecStDtInv = true;
        setTimeout(() =>
        {
         this.remrec_start_date = this.dtValStr;
        }, 100);
      }
      else if( eleId == "remrecEnddDt" && compMGo )
      {
        this.remRecEndDtInv = true;
        setTimeout(() =>
        {
         this.remrec_end_date = this.dtValStr;
        }, 100);
      }
      else if( eleId == "batchAchInp" && compMGo )
      {
        this.batchAchInv = true;
        setTimeout(() =>
        {
         this.batch_withdrawal_date = this.dtValStr;
        }, 100);
      }
  }

  completeDate( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 1  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" && parseInt(tempStr) >= 4 && parseInt(tempStr) < 10 )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + "0" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "achdDt" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.ach_withdrawal_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "repStInp" )
          {
            this.repStDtInv = true;
            setTimeout(() =>
            {
              this.startDateReport = this.dtValStr;
            }, 100);
          }
          else if( eleId == "repEndInp" )
          {
            this.repEndDtInv = true;
            setTimeout(() =>
            {
              this.endDateReport = this.dtValStr;
            }, 100);
          }
          else if( eleId == "remrecStdDt" )
          {
            this.remRecStDtInv = true;
            setTimeout(() =>
            {
              this.remrec_start_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "remrecEnddDt" )
          {
            this.remRecEndDtInv = true;
            setTimeout(() =>
            {
              this.remrec_end_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "batchAchInp" )
          {
            this.batchAchInv = true;
            setTimeout(() =>
            {
              this.batch_withdrawal_date = this.dtValStr;
            }, 100);
          }
        }
      }

  }

  completeDt2( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 2  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "achdDt" )
          {
            this.achDtInv = true;
            setTimeout(() =>
            {
              this.ach_withdrawal_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "repStInp" )
          {
            this.repStDtInv = true;
            setTimeout(() =>
            {
              this.startDateReport = this.dtValStr;
            }, 100);
          }
          else if( eleId == "repEndInp" )
          {
            this.repEndDtInv = true;
            setTimeout(() =>
            {
              this.endDateReport = this.dtValStr;
            }, 100);
          }
          else if( eleId == "remrecStdDt" )
          {
            this.remRecStDtInv = true;
            setTimeout(() =>
            {
              this.remrec_start_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "remrecEnddDt" )
          {
            this.remRecEndDtInv = true;
            setTimeout(() =>
            {
              this.remrec_end_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "batchAchInp" )
          {
            this.batchAchInv = true;
            setTimeout(() =>
            {
              this.batch_withdrawal_date = this.dtValStr;
            }, 100);
          }
        }
      }

  }

  completeDt1( eleId )
  {
      var tempStr = this.dtValStr;
      var tmpNumb = "";

      if( tempStr != undefined && tempStr != null && tempStr.length == 3 )
      {
        tmpNumb = tempStr.charAt(2);
      }

      if( tmpNumb != "" && parseInt(tmpNumb) >= 4 && parseInt(tmpNumb) < 10 )
      {
        var assignDtFill = tempStr.charAt(0) + tempStr.charAt(1) + "/" + "0" + tmpNumb + "/";
        this.dtValStr = assignDtFill;

        if( eleId == "achdDt" )
        {
          this.achDtInv = true;
          setTimeout(() =>
          {
            this.ach_withdrawal_date = this.dtValStr;
          }, 100);
        }
        else if( eleId == "repStInp" )
        {
          this.repStDtInv = true;
          setTimeout(() =>
          {
            this.startDateReport = this.dtValStr;
          }, 100);
        }
        else if( eleId == "repEndInp" )
        {
          this.repEndDtInv = true;
          setTimeout(() =>
          {
            this.endDateReport = this.dtValStr;
          }, 100);
        }
        else if( eleId == "remrecStdDt" )
        {
          this.remRecStDtInv = true;
          setTimeout(() =>
          {
            this.remrec_start_date = this.dtValStr;
          }, 100);
        }
        else if( eleId == "remrecEnddDt" )
        {
          this.remRecEndDtInv = true;
          setTimeout(() =>
          {
            this.remrec_end_date = this.dtValStr;
          }, 100);
        }
        else if( eleId == "batchAchInp" )
        {
          this.batchAchInv = true;
          setTimeout(() =>
          {
            this.batch_withdrawal_date = this.dtValStr;
          }, 100);
        }
      }
  }

  setYear(ev)
  {
    var justBackSpaced = false;

    if( ev.inputType != undefined && ev.inputType != null )
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined  )
      {
        this.dtValStr = ev.srcElement.value;
      }
      if( ev.inputType.trim() == "deleteContentBackward" || ev.inputType.trim() == "deleteContentForward" )
      {
        this.dtValStr = ev.srcElement.value;
        justBackSpaced = true;
      }
    }
    else
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined )
      {
        if( ev.srcElement.value.length < this.chromePrevStr.length )
        {
          justBackSpaced = true;
        }
        this.dtValStr = ev.srcElement.value;
        this.chromePrevStr = ev.srcElement.value;
      }
    }

    if( ! justBackSpaced && (this.dtValStr.length == 4 && ! this.dtValStr.includes("/"))  )
    {
      this.appendYr( ev.srcElement.id );
    }
    else if( ! justBackSpaced && (this.dtValStr.length == 1 || this.dtValStr.length == 2) )
    {
       this.completeMonth( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 4 )
    {
       this.completeDate( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 5 )
    {
       this.completeDt2( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 3 && ! this.dtValStr.includes("/") )
    {
      this.completeDt1( ev.srcElement.id );
    }
  }

  resetDtVal( dtFieldNum:number = 0 )
  {
    setTimeout(() =>
    {
      this.dtValStr = '';
    }, 50);
  }
  
  ngOnInit()
  {
    
    this.rptTypes = [
      { label: 'Select Report Type', value: 0 },
      // { label: 'Premium Batch Report', value: 1 },
      { label: 'Payroll Service Fee Invoice Batch Report', value: 2 },
      // { label: 'Premium Credit Card Batch Report', value: 3 },
      { label: 'Payroll Service Fee Invoice Credit Card Batch Report', value: 4 },
      { label: 'Misc Billing Invoice Batch Report', value: 5 },
      { label: 'Misc Billing Invoice Credit Card Batch Report', value: 6 }
    ];

    this.StatusDrop = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 0 }
    ];

    this.AddonStatusDrop = [
      { label: 'Prospect', value: 0 },
      { label: 'New', value: 1 },
      { label: '1st INV Created', value: 2 }
    ];

    this.PayrollfrequencyDrop = [
      { label: 'Weekly', value: 1 },
      { label: 'Bi-Weekly', value: 2 },
      { label: 'Semi-Monthly', value: 3 },
      { label: 'Monthly', value: 4 }
    ];

    this.OtherOutDrop = [
      { label: 'MON', value: "MON" },
      { label: 'TUE', value: "TUE" },
      { label: 'WED', value: "WED" },
      { label: 'THUR', value: "THUR" },
      { label: 'FRI', value: "FRI" }
    ];
    
    this.OtherFeeDrop = [
      { label: 'COUNTEE', value: "COUNTEE" },
      { label: 'FLAT', value: "FLAT" },
      { label: 'OTHER', value: "OTHER" }
    ];

    this.OtherGarnishmentsDrop = [
      { label: 'YES', value: "YES" },
      { label: 'NO', value: "NO" }
    ];

    this.ccFeeTypes = [
      { label: 'YES', value: "1" },
      { label: 'NO', value: "0" }
    ];

    this.OtherEnteredDrop = [
      { label: 'CLIENT', value: "CLIENT" },
      { label: 'EMAIL', value: "EMAIL" },
      { label: 'CALL', value: "CALL" },
      { label: 'AUTO', value: "AUTO" }
    ];

    this.OtherDeliveryDrop = [
      { label: 'YES', value: "YES" },
      { label: 'NO', value: "NO" },
      { label: 'NextDay', value: "NextDay" }
    ];

    this.OtherReportsDrop = [
      { label: 'EMAIL', value: "EMAIL" },
      { label: 'NO', value: "NO" },
      { label: 'W/PKG', value: "W/PKG" }
    ];

    this.clientService.getCarriersByName().subscribe(res => {
      this.carriers = res;

      setTimeout(() =>
      {
        this.clientService.getCarrierSet().subscribe(res =>
        {
          this.loadSelectedSet( res );
        });
      },200);
    });

this.clientService.getPayrollPlatformList().subscribe(ress => {
  
    this.PayrollPlatforms = ress.platformlist; 
      });

    this.clientService.getCarriers().subscribe(res => {
      this.carriersByIds = res;

      this.clientService.getAgencyBrokersList().subscribe(ress => {
        
        this.comissionDrop = ress.brokerList;
      });

    });

    this.clientService.getDispServerTime().subscribe(res =>
      {
        if( res != undefined && res != null && typeof res[0] !== 'undefined' )
        
        this.serverYr = res[0];
      });

    this.clientService.getStates().subscribe(res => {
        this.PhysicalStateDrop = res;
    });

    this.clientService.getAllAgencies().subscribe(res => {
      for (var agencies_arr of res) {
        var temp_obj:any = {}
        temp_obj.value = agencies_arr.id;
        temp_obj.label = agencies_arr.agency_name;
        this.agencies.push(temp_obj);
      }
    });

    this.clientService.getAllBrokers().subscribe(res => {
      for (var broker_arr of res) {
        var temp_obj:any = {}
        temp_obj.value = broker_arr.id;
        temp_obj.label = broker_arr.broker_name;
        this.broker.push(temp_obj);
      }
    });

    this.clientService.getUsers().subscribe(res => {
      for (var user_arr of res) {
        var temp_obj:any = {}
        temp_obj.value = user_arr.id;
        temp_obj.label = user_arr.first_name+' '+user_arr.last_name;
        this.WorklistDrop.push(temp_obj);
      }
    });
   
  }

  comissionRadioChange(typeSlected:any){
    this.comissionBADropValue = [];
    this.spinnerService.show();
    this.clientService.getAgencyBrokersList().subscribe(ress => {
      if(typeSlected == 1){
        
        this.comissionDrop = ress.brokerList;
      }else{
        this.comissionDrop = ress.agencyList;
      }
      this.spinnerService.hide();
    });
  }

  loadSelectedSet( res )
  {
    var pickSelCars:any = [];
    if( res != undefined && res != null && res.length )
    {
      for( let carrier of res)
      {
        if( carrier != undefined && carrier != null )
        {
          if( carrier.carrier_name != undefined && carrier.carrier_name != null )
          {
            pickSelCars.push(carrier.carrier_name);
          }
        }
      }
    }

    if( pickSelCars != undefined && pickSelCars != null && pickSelCars.length )
    {
      this.rpmMiscCarrs = pickSelCars;
    }
  }
  
  setMiscCarrsSel(event)
  {
    this.rpmPick = [];
    if( event != undefined && event != null && this.rpmMiscCarrs != undefined && this.rpmMiscCarrs != null && this.rpmMiscCarrs.length )
    {
      if( this.selectedCarriers != undefined && this.selectedCarriers != null )
      {
        if( event )
        {
          for( var rm = 0; rm < this.rpmMiscCarrs.length; rm++ )
          {
            if( this.rpmMiscCarrs[rm] != undefined && this.rpmMiscCarrs[rm] != null )
            {
                var presentCarrFound = false;
                for( let exisistingCarr of this.carriersByIds )
                {
                  if( exisistingCarr != undefined && exisistingCarr != null && exisistingCarr.label != undefined && exisistingCarr.label != null )
                  {
                      if( exisistingCarr.label == this.rpmMiscCarrs[rm] )
                      {
                        presentCarrFound = true;
                        break;
                      }
                  }
                }
                if( presentCarrFound && ! this.selectedCarriers.includes(this.rpmMiscCarrs[rm]) )
                {
                    this.rpmPick.push(this.rpmMiscCarrs[rm]);
                }
            }
          }

          for( var rm1 = 0; rm1 < this.selectedCarriers.length; rm1++ )
          {
            if( this.selectedCarriers[rm1] != undefined && this.selectedCarriers[rm1] != null )
            {
              if( ! this.rpmMiscCarrs.includes(this.selectedCarriers[rm1]) )
              {
                  this.rpmPick.push(this.selectedCarriers[rm1]);
              }
            }
          }
        }
        else
        {
          this.rpmPick = [];

          for( var rm1 = 0; rm1 < this.selectedCarriers.length; rm1++ )
          {
            if( this.selectedCarriers[rm1] != undefined && this.selectedCarriers[rm1] != null )
            {
              if( ! this.rpmMiscCarrs.includes(this.selectedCarriers[rm1]) )
              {
                  this.rpmPick.push(this.selectedCarriers[rm1]);
              }
            }
          }
        }
      }
    }

    this.selectedCarriers = this.rpmPick;
  }

  clearRenDates()
  {
      this.startDateReport = "";
      this.endDateReport = "";
  }

  genRenExcel()
  {
    var goAhead = true;
    var givenCount = 0;

    var stDt = "";
    var endDt = "";
    if (this.startDateReport == undefined || this.startDateReport == null || this.startDateReport == "" )
    {
    }
    else
    {
      givenCount++;
      stDt = this.startDateReport;
    }
    if (this.endDateReport == undefined || this.endDateReport == null || this.endDateReport == "" )
    {
    }
    else
    {
      givenCount++;
      endDt = this.endDateReport;
    }

    if( givenCount < 2 )
    {
      goAhead = false;
      alert( "Please select start date and end date." );
    }

    if( goAhead )
    {
        this.spinnerService.show();

        var spinner = this.spinnerService;

        var request = new XMLHttpRequest();
        request.open('POST',this.configServiceService.getApiUrl() +  '/excelRensReport', true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.responseType = 'blob';
    
        request.onload = function()
        { 
            if(request.status === 200)
            {
              var blob = new Blob([request.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "Renewals-Report.xlsx";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              spinner.hide();
            }
            else
            {
              console.log( "Error 500." );
              spinner.hide();
            }
          };

        request.send('ren_start_date=' + stDt +'&ren_end_date='+endDt);
    }
  }

  genRensDueRep()
  {
    if( ! this.rengenInProgress )
    {
        this.rengenInProgress = true;

        var genInv = true;
        this.startDateReport_Error = 'none';
        this.endDateReport_Error = 'none';

        if (this.startDateReport == undefined || this.startDateReport == null || this.startDateReport == "")
        {
          this.startDateReport_Error = 'block';
          genInv = false;
        }
        else
        {
          this.startDateReport_Error = 'none';
        }

        if (this.endDateReport == undefined || this.endDateReport == null || this.endDateReport == "")
        {
          this.endDateReport_Error = 'block';
          genInv = false;
        }
        else
        {
          this.endDateReport_Error = 'none';
        }

        if( genInv )
        {
          this.rengenInProgress = false;
          this.renrepInProgress = false;
          this.showRenDates = false;
          this.spinnerService.show();
          this.genRenExcel();
        }
        else
        {
          this.rengenInProgress = false;
        }
    }
  }

  showRenRepPopup()
  {
    if( ! this.renrepInProgress )
    {
        this.renrepInProgress = true;
        this.startDateReport = "";
        this.endDateReport = "";
        
        this.startDateReport_Error = 'none';
        this.endDateReport_Error = 'none';
        
        // this.spinnerService.show();
        this.showRenDates = true;
    }
  }
  
  stearnsReport()
  {
    var goAhead = true;
    var givenCount = 0;

    var achw = "";
    if (this.ach_withdrawal_date == undefined || this.ach_withdrawal_date == null || this.ach_withdrawal_date == "" )
    {
    }
    else
    {
      givenCount++;
      achw = this.ach_withdrawal_date;
    }
    if( this.selectedCarriers == undefined || this.selectedCarriers == null || this.selectedCarriers.length == 0 )
    {
    }
    else
    {
      givenCount++;
    }

    if( givenCount == 0 )
    {
      goAhead = false;
      alert( "Please select withdrawal date,or, atleast one carrier." );
    }

    if( goAhead )
    {
        this.selectedRType = 0;
        this.spinnerService.show();
 
        var spinner = this.spinnerService;

        var request = new XMLHttpRequest();
        request.open('POST',this.configServiceService.getApiUrl() +  '/excelStearnsReport', true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.responseType = 'blob';
    
        request.onload = function()
        { 
            if(request.status === 200)
            {
              var blob = new Blob([request.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "Stearns-Report.xlsx";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              spinner.hide();
            }
            else
            {
              console.log( "Error 500." );
              spinner.hide();
            }
        };

        var selCars = encodeURIComponent(this.selectedCarriers.toString());
        request.send('ach_withdrawal_date=' + achw +'&selectedCarriers='+selCars);
    }
  }

  batchReport()
  {
    var goAhead = true;
    var givenCount = 0;

    var achw = "";
    if (this.ach_withdrawal_date == undefined || this.ach_withdrawal_date == null || this.ach_withdrawal_date == "" )
    {
    }
    else
    {
      givenCount++;
      achw = this.ach_withdrawal_date;
    }
    // if( this.selectedCarriers == undefined || this.selectedCarriers == null || this.selectedCarriers.length == 0 )
    // {
    // }
    // else
    // {
    //   givenCount++;
    // }

    if( givenCount == 0 )
    {
      goAhead = false;
      alert( "Please select withdrawal date." );
    }

    if( goAhead && (this.selectedRType == undefined || this.selectedRType == null || this.selectedRType == 0) )
    {
      goAhead = false;
      alert( "Please select report type." );
    }

    if( goAhead )
    {
        this.spinnerService.show();

        var spinner = this.spinnerService;

        var request = new XMLHttpRequest();
        request.open('POST',this.configServiceService.getApiUrl() +  '/excelBatchReport', true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.responseType = 'blob';
    
        request.onload = function()
        {
            if(request.status === 200)
            {
              var blob = new Blob([request.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "Batch-Report.xlsx";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              spinner.hide();
            }
            else
            {
              console.log( "Error 500." );
              spinner.hide();
            }
        };

        var selCars = encodeURIComponent(this.selectedCarriers.toString());
        if( achw )
        {
          request.send('ach_withdrawal_date=' + achw +'&selectedCarriers='+selCars+"&selectedRType="+this.selectedRType);
        }
        else
        {
          request.send('selectedCarriers='+selCars+"&selectedRType="+this.selectedRType);
        }
      }
  }

  carrierrReport()
  {
    var goAhead = true;
    var givenCount = 0;

    if( this.selectedCarrIds == undefined || this.selectedCarrIds == null || this.selectedCarrIds.length == 0 )
    {
    }
    else
    {
      givenCount++;
    }

    if( givenCount == 0 )
    {
      goAhead = false;
      alert( "Please atleast one carrier." );
    }

    if( goAhead )
    {
        this.spinnerService.show();
 
        var spinner = this.spinnerService;

        var request = new XMLHttpRequest();
        request.open('POST',this.configServiceService.getApiUrl() +  '/excelCarrierReport', true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.responseType = 'blob';
    
        request.onload = function()
        { 
            if(request.status === 200)
            {
              var blob = new Blob([request.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "Carrier-Report.xlsx";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              spinner.hide();
            }
            else
            {
              console.log( "Error 500." );
              spinner.hide();
            }
        };

        var selCars = encodeURIComponent(this.selectedCarrIds.toString());
        request.send('selectedCarriers='+selCars);
      }
  }

  getComissionReport()
  {
    var goAhead = true;

    var stDt = "";
    if (this.remrec_start_date == undefined || this.remrec_start_date == null || this.remrec_start_date == "" )
    {
      goAhead = false;
    }
    else
    {
      stDt = this.remrec_start_date;
    }
    
    var endDt = "";
    if (this.remrec_end_date == undefined || this.remrec_end_date == null || this.remrec_end_date == "" )
    {
      goAhead = false;
    }
    else
    {
      endDt = this.remrec_end_date;
    }

    if( this.comissionDropValue == undefined || this.comissionDropValue == null || this.comissionDropValue == 0 )
    {
      goAhead = false;
    }

    if( this.comissionBADropValue.length == 0 )
    {
      goAhead = false;
    }

    if( ! goAhead )
    {
      alert( "From Date, To Date and Comission Type Required." );
    }

    if( goAhead )
    {
        var finalComissionBADropValue = this.comissionBADropValue.toString();
       

        this.spinnerService.show();

        var spinner = this.spinnerService;

        if(this.comissionReportRadioValue == 1){
          var request = new XMLHttpRequest();
          request.open('POST',this.configServiceService.getApiUrl() +  '/excelComissionReport', true);
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          request.responseType = 'blob';
      
          request.onload = function()
          { 
              if(request.status === 200)
              {
                
                var blob = new Blob([request.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Comission-Report.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                spinner.hide();
                
              }
              else
              {
                spinner.hide();
                alert( "We faced 1 an issue generating Comission Report. Please try with less date range." );
              }
          };

          request.onerror = function()
          {
            spinner.hide();
            alert( "We faced 2 an issue generating Comission Report. Please try with less date range." );
          };
          
          var selCars = this.comissionDropValue;
          request.send('fromDate=' + stDt + '&endDate=' + endDt + '&ba='+selCars+'&ep='+this.comissionReportRadioValue+'&ds='+this.comissionReportRadioView+'&ids='+finalComissionBADropValue);
        }else{
          spinner.hide();
          window.open(this.configServiceService.getApiUrl() + '/pdfComissionReport?fromDate=' + stDt + '&endDate=' + endDt + '&ba='+this.comissionDropValue+'&ep='+this.comissionReportRadioValue+'&ds='+this.comissionReportRadioView+'&ids='+finalComissionBADropValue, 'blank');
        }
      }
  }

  remitReconcileReport()
  {
    var goAhead = true;
    var givenCount = 0;

    var stDt = "";
    if (this.remrec_start_date == undefined || this.remrec_start_date == null || this.remrec_start_date == "" )
    {
      goAhead = false;
    }
    else
    {
      givenCount++;
      stDt = this.remrec_start_date;
    }
    
    var endDt = "";
    if (this.remrec_end_date == undefined || this.remrec_end_date == null || this.remrec_end_date == "" )
    {
      goAhead = false;
    }
    else
    {
      givenCount++;
      endDt = this.remrec_end_date;
    }

    if( this.remitCarrIds == undefined || this.remitCarrIds == null || this.remitCarrIds.length == 0 )
    {
    }
    else
    {
      givenCount++;
    }

    if( ! goAhead )
    {
      alert( "From Date, To Date Required." );
    }

    if( goAhead )
    {
        this.spinnerService.show();
 
        var spinner = this.spinnerService;

        var request = new XMLHttpRequest();
        request.open('POST',this.configServiceService.getApiUrl() +  '/excelRemRecReport', true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.responseType = 'blob';
    
        request.onload = function()
        {
            // console.log( request.status );
            if(request.status === 200)
            {
              
              var blob = new Blob([request.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "Remit-Reconcile-Report.xlsx";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              spinner.hide();
              
            }
            else
            {
              spinner.hide();
              alert( "We faced 1 an issue generating Remit Reconcile Report. Please try with less date range." );
            }
        };

        request.onerror = function()
        {
          spinner.hide();
          alert( "We faced 2 an issue generating Remit Reconcile Report. Please try with less date range." );
        };
        

        var selCars = encodeURIComponent(this.remitCarrIds.toString());
        request.send('fromDate=' + stDt + '&endDate=' + endDt + '&remitCarrIds='+selCars);
      }
  }
  setMiscRemitRecon(event)
  {
    this.rpmPick = [];
    if( event != undefined && event != null && this.rpmMiscCarrs != undefined && this.rpmMiscCarrs != null && this.rpmMiscCarrs.length )
    {
      if( this.remitCarrIds != undefined && this.remitCarrIds != null )
      {
        if( event )
        {
          for( var rm = 0; rm < this.rpmMiscCarrs.length; rm++ )
          {
            if( this.rpmMiscCarrs[rm] != undefined && this.rpmMiscCarrs[rm] != null )
            {
                var presentCarrFound = false;
                for( let exisistingCarr of this.carriersByIds )
                {
                  if( exisistingCarr != undefined && exisistingCarr != null && exisistingCarr.label != undefined && exisistingCarr.label != null )
                  {
                      if( exisistingCarr.label == this.rpmMiscCarrs[rm] )
                      {
                        presentCarrFound = true;
                        break;
                      }
                  }
                }
                if( presentCarrFound && ! this.remitCarrIds.includes(this.rpmMiscCarrs[rm]) )
                {
                    this.rpmPick.push(this.rpmMiscCarrs[rm]);
                }
            }
          }

          for( var rm1 = 0; rm1 < this.remitCarrIds.length; rm1++ )
          {
            if( this.remitCarrIds[rm1] != undefined && this.remitCarrIds[rm1] != null )
            {
              if( ! this.rpmMiscCarrs.includes(this.remitCarrIds[rm1]) )
              {
                  this.rpmPick.push(this.remitCarrIds[rm1]);
              }
            }
          }
        }
        else
        {
          this.rpmPick = [];

          for( var rm1 = 0; rm1 < this.remitCarrIds.length; rm1++ )
          {
            if( this.remitCarrIds[rm1] != undefined && this.remitCarrIds[rm1] != null )
            {
              if( ! this.rpmMiscCarrs.includes(this.remitCarrIds[rm1]) )
              {
                  this.rpmPick.push(this.remitCarrIds[rm1]);
              }
            }
          }
        }
      }
    }

    this.remitCarrIds = this.rpmPick;
  }
  
  batchReconcileReport()
  {
    var goAhead = true;
    var givenCount = 0;

    var achw = "";
    if (this.batch_withdrawal_date == undefined || this.batch_withdrawal_date == null || this.batch_withdrawal_date == "" )
    {
    }
    else
    {
      givenCount++;
      achw = this.batch_withdrawal_date;
    }

    if( givenCount == 0 )
    {
      goAhead = false;
      alert( "Please select withdrawal date." );
    }

    if( goAhead )
    {
        this.spinnerService.show();

        var spinner = this.spinnerService;

        var request = new XMLHttpRequest();
        request.open('POST',this.configServiceService.getApiUrl() +  '/excelBatchReconcileRep', true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        request.responseType = 'blob';
    
        request.onload = function()
        {
            // console.log( request.status );
            if(request.status === 200)
            {
              var blob = new Blob([request.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "Batch-Reconcile-Report.xlsx";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              spinner.hide();
            }
            else
            {
              spinner.hide();
              alert( "We faced an issue generating Batch Reconcile Report." );
            }
        };

        request.onerror = function()
        {
          spinner.hide();
          alert( "We faced an issue generating Batch Reconcile Report." );
        };

        var selCars = encodeURIComponent(this.selectedCarriers.toString());
        if( achw.trim() != "" )
        {
          request.send('ach_withdrawal_date=' + achw);
        }
      }
  }

  setupActivityReport(typeReport:any)
  {
      this.spinnerService.show();
      var spinner = this.spinnerService;

      var request = new XMLHttpRequest();
      if(typeReport == 1){
        request.open('POST',this.configServiceService.getApiUrl() +  '/excelSetupReport', true);
      }else if(typeReport == 2){
        request.open('POST',this.configServiceService.getApiUrl() +  '/excelSetupProspectReport', true);
      }else if(typeReport == 3){
        request.open('POST',this.configServiceService.getApiUrl() +  '/excelMidwesternReport', true);
      }
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      request.responseType = 'blob';

      request.onload = function()
      {
          // console.log( request.status );
          if(request.status === 200)
          {
            var blob = new Blob([request.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            if(typeReport == 1){
              link.download = "Setup-Activity-Report.xlsx";
            }else if(typeReport == 2){
              link.download = "Setup-Prospect-Activity-Report.xlsx";
            }else if(typeReport == 3){
              link.download = "Midwestern-Report.xlsx";
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            spinner.hide();
          }
          else
          {
            console.log( "Error 500." );
            spinner.hide();
          }
      };

      request.send();
  }

  chkPCompany(event)
  {
    if( event )
    {
      if( this.fieldsArr.includes("payroll_worklist") && ! this.fieldsArr.includes("payroll_company") )
      {
        this.fieldsArr.push("payroll_company");
      }
    }
  }

  buildAReport()
  {
   
    var index = this.fieldsArr.indexOf("isPayrollRep");
    if( index > -1 )
    {
      this.fieldsArr.splice(index, 1);
    }      
 
    if( this.fieldsArr.length == 1 && this.fieldsArr.includes("payroll_worklist") && ! this.fieldsArr.includes("payroll_company") )
    {
      this.fieldsArr.push("payroll_company");
    }
    if( this.fieldsArr.length == 2 && this.fieldsArr.includes("payroll_worklist") && this.fieldsArr.includes("payroll_company") )
    {
      if( this.fieldsArr.indexOf("isPayrollRep") == -1 )
      {
        this.fieldsArr.push("isPayrollRep");
      }
    }
  
    var queryString = "";

    var fi = 0;
    for(let field of this.fieldsArr)
    {
      if( fi == 0 )
      {
        queryString += "?" + field + "=1";
      }
      else
      {
        queryString += "&" + field + "=1";
      }
      fi++;
    }

    if(this.PayrollPlatformselected.length){
      queryString += "&payrollplatforms="+ this.PayrollPlatformselected;
    }

    if(this.Statusselected.length){
      queryString += "&status="+ this.Statusselected;
    }

    if(this.AddonStatusselected.length){
      queryString += "&addon_status="+ this.AddonStatusselected;
    }
    
    if(this.PhysicalStateselected.length){
      queryString += "&physical_state="+ this.PhysicalStateselected;
    }
    if(this.MailingStateselected.length){
      queryString += "&mailing_state="+ this.MailingStateselected;
    }
    if(this.Agenciesselected.length){
      queryString += "&insurance_agency="+ this.Agenciesselected;
    }
    if(this.Brokerselected.length){
      queryString += "&broker_id="+ this.Brokerselected;
    }
    if(this.PayrollFrequencyselected.length){
      queryString += "&payroll_frequency="+ this.PayrollFrequencyselected;
    }
    if(this.Worklistselected.length){
      queryString += "&worklist="+ this.Worklistselected;
    }
    
    if(this.OtherOutselected.length){
      queryString += "&other_out="+ this.OtherOutselected;
    }
    if(this.OtherFeeselected.length){
      queryString += "&other_fee="+ this.OtherFeeselected;
    }
    if(this.OtherGarnishmentsselected.length){
      queryString += "&other_garnishments="+ this.OtherGarnishmentsselected;
    }
    if(this.OtherReportsselected.length){
      queryString += "&other_reports="+ this.OtherReportsselected;
    }
    if(this.OtherDeliveryselected.length){
      queryString += "&other_delivery="+ this.OtherDeliveryselected;
    }
    if(this.OtherEnteredselected.length){
      queryString += "&other_entered="+ this.OtherEnteredselected;
    }
    if(this.ccFeeTypesselected.length){
      queryString += "&cc_fee_applicable="+ this.ccFeeTypesselected;
    }

    if( ! this.fieldsArr.length )
    {
      alert( "Please select atleast one fied.");
    }
    else
    { 
      // var sorting_arr = [...document.querySelectorAll(".selected-fields-list [id]")].map(({id}) => id);
      var sorting_arr = [];
      var parentElement = document.querySelector('.selected-fields-list');
      var childElements = parentElement.querySelectorAll("div.list-item");
      
      for (var i = 0; i < childElements.length; i++) {
        sorting_arr.push(childElements[i].getAttribute("id"));
      }
      
      window.open( this.configServiceService.getApiUrl() +  '/excelDefinedFieldsReport'+queryString+'&sorting_arr='+encodeURIComponent(JSON.stringify(sorting_arr)),'blank');  
    }
  }  

  showHideFields(e){
    return true;
    // var parent_elem = e.target.parentElement;
    
    // if(e.target.getAttribute('dataOpen') == 'no'){
    //   $(parent_elem).find('.fields-list').removeClass('hide').show();
    //   e.target.setAttribute('dataOpen','yes');
    // }else{
    //   $(parent_elem).find('.fields-list').addClass('hide').hide();
    //   e.target.setAttribute('dataOpen','no');
    // }
  }

  fieldCheckValue(ele,value,label,field_type = ''){
    
    var check_ele = ele;
    if(field_type != '' && field_type == 'dropdown'){
      check_ele = false;
      if(ele.value.length > 0){
        check_ele = true;
        
        var dom_exist_ele = document.querySelectorAll(".selected-fields-list > #"+value).length;

        if(dom_exist_ele > 0){
          return false;
        } 
      }
    }

    // console.log(check_ele);

    if(check_ele){      
      var listElement   = document.createElement('div');
      listElement.className = 'list-item';
      listElement.draggable = true; 
      listElement.innerHTML = label;
      
      listElement.setAttribute("id", value); 
      document.getElementsByClassName("selected-fields-list")[0].appendChild(listElement);

      listElement.addEventListener('dragstart', this.dragStart, false);
      listElement.addEventListener('dragenter', this.dragEnter, false);
      listElement.addEventListener('dragover', this.dragOver, false);
      listElement.addEventListener('dragleave', this.dragLeave, false);
      listElement.addEventListener('drop', this.dragDrop, false);
      listElement.addEventListener('dragend', this.dragEnd, false);

    }else{
      document.getElementById(value).remove();
    }

  }

  dragStart = (e) => {
    e.target.style.opacity = '0.4';
    this.dragSrcEl = e.target;
    // e.dataTransfer.effectAllowed = 'move';
    // e.dataTransfer.setData('text/html', e.target.innerHTML);
    // e.dataTransfer.setData('drag_start_id', e.target.id);
  }
  
  dragEnter = (e) => {
    e.target.classList.add('over');
  }
  
  dragLeave = (e) => {
    e.stopPropagation();
    e.target.classList.remove('over');
  }
  
  dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    return false;
  }
  
  dragDrop = (e) => {
    if (this.dragSrcEl != this) {
      document.getElementsByClassName('selected-fields-list')[0].insertBefore(this.dragSrcEl, e.target);
      // this.dragSrcEl.innerHTML = e.target.innerHTML;
      // this.dragSrcEl.id = e.target.id;
      // e.target.innerHTML = e.dataTransfer.getData('text/html');
      // e.target.id = e.dataTransfer.getData('drag_start_id');
    }
    return false;
  }
  
  dragEnd = (e) => {
    var listItens = document.querySelectorAll('.list-item');
    [].forEach.call(listItens, function(item) {
      item.classList.remove('over');
    });
    e.target.style.opacity = '1';
  }
  
}