import { Component, OnInit } from '@angular/core';
import { ClientService } from '../client.service';
import { PolicyService } from '../policy.service';
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService } from 'primeng/api';
import { SpinnerService } from '../../core';
import { ConfigServiceService } from '../../config-service.service';

@Component({
  selector: 'incomplete',
  templateUrl: './incomplete.component.html',
  styleUrls: ['./incomplete.component.css']
})
export class IncompleteComponent implements OnInit {

  queues = [];
  queueSettingIds = [];
  allQueuesCount = 0;
  skip = 0;
  take = 15;
  sortField = 'company_name';
  sortOrder = 1;
  showInvoiceAlert = false;
  selectedAll = false;

  invPolPostObj: any = {};
  invCancldCrsd:boolean = false;
  invCnCrMsg:String='';

  first = 0;
  nsfBtnLbl = "NSF Invoices";
  nsfNormalMode:number = 0;
  
  constructor(
    private router: Router,
    private clientService: ClientService,
    private policyService: PolicyService,   
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService
    ,private configServiceService: ConfigServiceService
  ) { }

  ngOnInit()
  {
  }

  closeIncPopUp()
  {
    this.invCancldCrsd = false;
  }

  incompleteCheck( clientId,invoiceId )
  {
    this.spinnerService.show();
    this.invPolPostObj = {};
    this.invPolPostObj.checkIncomplete =  1;
    this.invPolPostObj.invoiceSettingId = invoiceId;
    this.policyService.checkIncCancld(this.invPolPostObj).subscribe(data =>
    {
      this.spinnerService.hide();
      this.invPolPostObj = {};

      if( data != undefined && data != null )
      {
        if( (data.polFoundCancld != undefined && data.polFoundCancld != null) || (data.polEndDateCrossed != undefined && data.polEndDateCrossed != null) )
        {
          this.invCancldCrsd = true;
          this.invCnCrMsg = data.message;
        }
        else
        {
          // router.navigateByUrl('/clients/list/'+row.clientId+'/invoice/revise/'+row.id)
          this.router.navigate( ['/clients/list/' + clientId + '/invoice/revise/'+invoiceId] );
        }
      }
      else
      {
        alert( "Could Not Check Invoice Policy Status, cannot open invoice." );
      }
    });
  }


  getAllQueues(response)
  {
    this.queues = response.invoiceSetting;
    this.allQueuesCount = response.invoiceSettingsCount;

    this.selectedAll = false;
    if( this.queueSettingIds.length )
    {
      var idsArr = [];
      for( var i = 0; i < this.queueSettingIds.length;i++ )
      {
        idsArr.push(this.queueSettingIds[i].id)
      }

      var key = 0;
      var selectedIts = 0;
      this.queues.forEach((queueClientinfo) =>
      {
        if( idsArr.includes(this.queues[key].id) )
        {
          this.queues[key].selected_checkbox = true;
          selectedIts++;
        }
        key++;
      });
      if( key == selectedIts )
      {
        this.selectedAll = true;
      }
    }

    if( this.nsfNormalMode == 0 )
    {
      this.nsfBtnLbl = "NSF Invoices";
    }
    else
    {
      this.nsfBtnLbl = "Non-NSF Invoices";
    }

    this.spinnerService.hide();
  }

  paginate(event)
  {
      this.skip = event.first;
      this.take = event.rows;
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.clientService.getQueues(this.skip, this.take, this.sortField, this.sortOrder,0,this.nsfNormalMode).subscribe(response => {
        this.getAllQueues(response);
      });
  }

  toggleNSF()
  {
    if( this.nsfNormalMode == 0 )
    {
      this.nsfNormalMode = 1;
    }
    else
    {
      this.nsfNormalMode = 0;
    }
    this.skip = 0;
    this.take = 15;
    this.first = 0;
    this.clientService.getQueues(this.skip, this.take, this.sortField, this.sortOrder,0,this.nsfNormalMode).subscribe(response => {
      this.getAllQueues(response);
    });
  }
  
  checkUncheckAll(e){
    
    // this.queueSettingIds = [];

    if(e.target.checked == true){
      this.selectedAll = true;
      var key = 0;
      this.queues.forEach((queueClientinfo) => {
        this.queues[key].selected_checkbox = true;
        var objectClient = {};
        objectClient['id'] = queueClientinfo.id;
        objectClient['email'] = queueClientinfo.email;
        objectClient['company_name'] = queueClientinfo.company_name;
        objectClient['pfInvoiceTotal'] = queueClientinfo.pfInvoiceTotal;
        objectClient['check_date_formate'] = queueClientinfo.check_date_formate;
        this.queueSettingIds.push(objectClient);
        key++;
      });
    }else{
      this.selectedAll = false;
      var key = 0;
      this.queues.forEach((queueClientinfo) => {
        for( var em = 0;em < this.queueSettingIds.length;em++ )
        {
          if( this.queueSettingIds[em].id == queueClientinfo.id )
          {
            this.queueSettingIds.splice(em, 1);
          }
        }
        this.queues[key].selected_checkbox = false;
        key++;
      });
    }
  }

  checkBoxEvent(id, e, i){

    if( i >= this.take )
    {
      i = i % this.take;
    }


    if(e.target.checked == true){
      this.queues[i].selected_checkbox = true;
      var objectClient = {};
      objectClient['id'] = id;
      var queueClientinfo = this.queues.filter(item => item.id == id);
      objectClient['email'] = queueClientinfo[0].email;
      objectClient['company_name'] = queueClientinfo[0].company_name;
      objectClient['pfInvoiceTotal'] = queueClientinfo[0].pfInvoiceTotal;
      objectClient['check_date_formate'] = queueClientinfo[0].check_date_formate;
      this.queueSettingIds.push(objectClient);

      this.selectedAll = this.queues.every(function(item:any) {
        return item.selected_checkbox == true;
      });

    }else{
      this.selectedAll = false;
      this.queues[i].selected_checkbox = false;
      this.queueSettingIds = this.queueSettingIds.filter(item => item.id != id);
    }
  }

}
