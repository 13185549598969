import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService, SpinnerState } from './spinner.service';

@Component({
  selector: 'homecook-spinner',
  template: `
    <div class="spinner-container" [class.is-active]="visible">
      <div class="spinner">
      <img src="assets/spinner.gif" width="150">
      </div>
    </div>
  `,
  styles: [`
    .spinner-container {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 1;
      z-index: 1304;
      /*background: rgba(0, 0, 0, .5);*/
      -webkit-transition: opacity 250ms ease-out;
      transition: opacity 250ms ease-out;
      overflow: hidden !important;
      -webkit-overflow-scrolling: touch;
      touch-action: cross-slide-y pinch-zoom double-tap-zoom;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      display: none;
    }

    .spinner {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      border-radius: 5px;
    }

    .is-active {
      display: block !important;
    }
  `]
})
export class SpinnerComponent implements OnInit, OnDestroy {

  visible = false;

  private spinnerStateChanged: Subscription;

  constructor(private spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this.spinnerStateChanged = this.spinnerService.spinnerState
      .subscribe((state: SpinnerState) => this.visible = state.show);
  }

  ngOnDestroy() {
    this.spinnerStateChanged.unsubscribe();
  }

}
