import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, NavigationStart, NavigationEnd, NavigationError } from "@angular/router";
import { Location } from '@angular/common';
import { ClientService } from '../client.service';
import { Client } from "../client";
import { Message } from 'primeng/components/common/api';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
  msgs: Message[] = [];
  id: number;
  client: Client;
  is_new:number = 0;

  constructor(aroute:ActivatedRoute, private router:Router, public location:Location,
    private clientService:ClientService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  	});            
    
    // Load client
    this.clientService.getClientNameOnly(this.id).subscribe(res =>
    {
        this.client = res;
        if( this.client != undefined && this.client != null && this.client.is_new != undefined && this.client.is_new != null )
        {
            this.is_new = this.client.is_new;
        }
    });
  }


}
